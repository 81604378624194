import React from 'react';
import { observer } from 'mobx-react-lite';

// Components
import Layout from '../../components/Layout';

// Styles

const Dashboard = observer((): JSX.Element => {
  return (
    <Layout>
      <h2>Dashboard</h2>
    </Layout>
  );
});

export default Dashboard;
