import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import kyeSvg from '../../../assets/images/signIn/key.svg';
import { useInjection } from '../../../core/inversify';
import { TokenService } from '../../../services/api/TokenService';
// Store
import { AuthStore } from '../../../stores/AuthStore';

// Styles
import { StyledEndStep, StyledButton } from '../styles';

interface IProps {
  nextSteps: () => void;
}

const EndStepRegistrationKey = observer(({ nextSteps }: IProps): JSX.Element => {
  const history = useHistory();
  const tokenService = useInjection(TokenService);
  const [keysName, setKeysName] = useState<Array<string>>(['']);
  const authStore = useInjection(AuthStore);
  useEffect(() => {
    const arr: string[] = [];

    authStore.getKeysName().map((el) => arr.push(el.name));
    setKeysName(arr);
  }, [authStore.getKeysName()]);

  return (
    <StyledEndStep>
      <h2>Add Security key</h2>
      <p className="key-add__text">Already added keys</p>
      <ul className="key">
        {keysName.map((name) => {
          return (
            <li key={name + 1}>
              <img className="key-name__svg" src={kyeSvg} alt="key" />
              <div>
                <p className="key-name">{name}</p>
              </div>
            </li>
          );
        })}
      </ul>
      <button onClick={nextSteps} type="button" className="button__add-key">
        +Add Another one
      </button>
      <StyledButton
        onClick={() => {
          authStore.setIsAuthorized(true);
          tokenService.saveIsAauthorized(true);
          history.push('/');
        }}
        className="button__save-key"
      >
        <p>Complete</p>
      </StyledButton>
    </StyledEndStep>
  );
});

export default EndStepRegistrationKey;
