// Core
import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useInjection } from '../../../core/inversify';
import { AuthStore } from '../../../stores/AuthStore';

// Types
import { ILoginParam } from '../../../services/api/types';

// Components
import TextField from '../../../components/input/TextField';

// Styles
import { WrapperForm, RelativeBox, StyledButton, StyledErr } from '../styles';

export enum FieldName {
  verifiCode = 'verifiCode',
  recovery = 'recovery',
}

const SignupSchema = Yup.object().shape({
  [FieldName.verifiCode]: Yup.string()
    .min(6, 'Code must be at 6 characters')
    .max(6, 'Code must be at 6 characters')
    .matches(/^[0-9]+$/, 'Enter valid code.'),
  [FieldName.recovery]: Yup.string()
    .min(10, 'Code must be at 10 characters')
    .max(10, 'Code must be at 10 characters')
    .matches(/^[A-Za-z0-9]+$/, 'Enter valid recovery code'),
});
export default function StepTwoApp(): JSX.Element {
  const authStore = useInjection(AuthStore);
  const [showRecoveryCode, setShowRecoveryCode] = useState<boolean>(false);
  const [valueVerifiCode, setValueVerifiCode] = useState<string>('');
  const [valueRecovery, setValueRecovery] = useState<string>('');
  const [_isEmpty, setIsEmpty] = useState<boolean>(false);

  //const user = authStore.getUserInfo();
  const loginData = authStore.getLoginData();
  const onSubmit = (): Promise<void> | void => {
    let otp = valueVerifiCode;
    const otpRecovery = valueRecovery;
    if (showRecoveryCode && !valueRecovery) {
      return setIsEmpty(true);
    }
    if (!showRecoveryCode && !valueVerifiCode) {
      return setIsEmpty(true);
    }
    if (showRecoveryCode) {
      otp = '';
    }
    const data = {
      email: loginData?.email,
      hash_password: loginData?.hash_password,
    } as ILoginParam;

    if (otp) {
      const value = otp.toString();
      data.otp = value;
    } else if (otpRecovery) {
      const value = otpRecovery.toString();
      data.otp_recovery = value;
    }
    return authStore.loginUser(data);
  };

  return (
    <>
      <Formik
        validationSchema={SignupSchema}
        initialValues={{
          [FieldName.verifiCode]: '',
          [FieldName.recovery]: '',
        }}
        onSubmit={() => onSubmit()}
      >
        {({ values, touched, errors, handleReset, isValid, dirty }) => {
          dirty  // for eslint to be used
          setValueVerifiCode(values[FieldName.verifiCode]);
          setValueRecovery(values[FieldName.recovery]);
          return (
            <WrapperForm>
              <h2>Enter code</h2>
              <p className="sign-in__description">Enter the 6-digit code you see in the app.</p>
              <RelativeBox className="sign-in__required" onClick={() => setIsEmpty(false)}>
                <TextField disabled={showRecoveryCode} name={FieldName.verifiCode} placeholder="Enter code" />
                {touched.verifiCode && !valueVerifiCode && !errors.verifiCode && !showRecoveryCode ? (
                  <StyledErr>Please enter code</StyledErr>
                ) : null}
                {touched.verifiCode && errors.verifiCode ? <StyledErr>{errors.verifiCode}</StyledErr> : null}
              </RelativeBox>
              <StyledButton
                disabled={!isValid || (touched.verifiCode && !valueVerifiCode && !errors.verifiCode) || showRecoveryCode}
                type="submit"
              >
                <p>Verify</p>
              </StyledButton>
              {showRecoveryCode && <p className="sign-in__text--bottom ">Don’t have your phone?</p>}
              <button
                className="sign-in__btn--code"
                type="button"
                onClick={() => {
                  setIsEmpty(false);
                  handleReset();
                  setShowRecoveryCode(!showRecoveryCode);
                }}
              >
                Use a recovery code
              </button>
              {showRecoveryCode && (
                <>
                  <p className="sign-in__description--recovery">Enter the recovery code.</p>
                  <RelativeBox className="sign-in__required" onClick={() => setIsEmpty(false)}>
                    <TextField name={FieldName.recovery} placeholder="Enter code" />
                    {touched.recovery && !valueRecovery && !errors.recovery ? <StyledErr>Please enter recovery code</StyledErr> : null}
                    {touched.recovery && errors.recovery ? <StyledErr>{errors.recovery}</StyledErr> : null}
                  </RelativeBox>
                  <StyledButton disabled={!isValid || (touched.recovery && !valueRecovery && !errors.recovery)} type="submit">
                    <p>Verify</p>{' '}
                  </StyledButton>
                </>
              )}
            </WrapperForm>
          );
        }}
      </Formik>
    </>
  );
}
