import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { TooltipCustomize } from './styles';
import { formatDate } from '../../library/Utils';

const useStyles = makeStyles({
  tooltip: {
    background: '#fff',
    left: '-12px',
    minWidth: '10px',
    minHeight: '10px',
    padding: '0',
    margin: '0',
    boxShadow: '0px 32px 47px -4px rgba(34, 60, 80, 0.2)',
  },
  arrow: {
    fontSize: '3.5em',
    color: '#fff',
  },
});

export const TooltipTemplate = ({ dates }: any): JSX.Element => {
  const classes = useStyles();

  const listDate = (
    <TooltipCustomize>
      <ul>
        {dates.allocated && (
          <li>
            <h3>{formatDate(dates.allocated)}</h3>
            <p>Date Allocated</p>
          </li>
        )}
        {dates.send_to_review && (
          <li>
            <h3>{formatDate(dates.send_to_review)}</h3>
            <p>Date Submitted</p>
          </li>
        )}
        {dates.approved && (
          <li>
            <h3>{formatDate(dates.send_to_review)}</h3>
            <p>Date Approved</p>
          </li>
        )}
      </ul>
    </TooltipCustomize>
  );

  return (
    <Tooltip title={listDate} arrow classes={classes} placement="top">
      <div style={{ position: 'absolute', height: '100%', width: '100%' }}>&nbsp;</div>
    </Tooltip>
  );
};
