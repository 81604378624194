// Core
import React, { useEffect, useRef,useState } from 'react';
import { useFormikContext } from 'formik';
// Styles
import { StyledTextField, StyledCurrentCharacters,Wrapper } from './styles';

export interface IProps {
  name: string;
  className?: string;
  width?: string;
  placeholder?: string;
  disabled?: boolean;
  //autocomplete?: boolean;
  id?: string;
  maxCharacters: number;
  defaultValue?: string;
  onChange?: (name: string, value: string) => void;
  originValue?: string;
}

const TextArea = ({
  name,
  id,
  defaultValue,
  placeholder,
  //autocomplete,
  maxCharacters,
  className,
  disabled = false,
  onChange,
  ...props
}: IProps): JSX.Element => {
  onChange // currently we don't use it
  /* eslint-disable react/jsx-props-no-spreading */
  const { setFieldValue, submitForm } = useFormikContext<any>();
  const [charactersLeft, setCharactersLeft] = useState<number>()

  const inputRef = useRef<any>(null);
  useEffect(() => {
    setFieldValue(name, inputRef.current.value);
  }, [defaultValue]);

  useEffect(() => {
    setCharactersLeft(maxCharacters - inputRef?.current?.value?.length);
  }, [inputRef, defaultValue]);

  return (
    <Wrapper>
      <StyledTextField
        className={className}
        disabled={disabled}
        ref={inputRef}
        maxLength={maxCharacters}
        defaultValue={defaultValue}
        onChange={()=>setCharactersLeft(maxCharacters - inputRef?.current?.value?.length)}
        onBlur={() => {
          setFieldValue(name, inputRef.current.value);
          setTimeout(() => {
            submitForm();
          }, 500);
        }}
        name={name}
        placeholder={placeholder}
        id={id}
        {...props}
      />
      {inputRef?.current?.value && <StyledCurrentCharacters> characters left: <span>{charactersLeft}</span></StyledCurrentCharacters>}

    </Wrapper> 
  );
};

export default TextArea;
