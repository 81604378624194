export enum ITitleName {
  SOLUTION_NAME = 'SOLUTION NAME',
  COMPANY_NAME = 'COMPANY NAME',
  WHAT_IT_DOES = 'WHAT IT DOES',
  WHO_USES_IT = 'WHO USES IT',
  WHAT_DOES_IT_COST = 'WHAT DOES IT COST',
  SPECIAL_MENTIONS = 'SPECIAL MENTIONS',
  NOTES_ON_PRODUCT = 'NOTES ON PRODUCT',
}

export enum FieldsSolutionName {
  solutionNameInTitle = 'solutionNameInTitle',
  selectCompanyToLinkWithSolution = 'selectCompanyToLinkWithSolution',
  slug = 'slug',
}

export enum FieldsNameWhatItDoes {
  categories = 'categories',
  subCategories = 'subcategories',
  otherCategories = 'otherCategories',
  description = 'description',
  shortDescription = 'shortDescription',
  longDescription = 'longDescription',
  keyDifferentiators = 'keyDifferentiators',
  youtubeLink = 'youtubeLink',
  logoImg = 'logo',
  brochure = 'brochure',
}

export enum FieldsNameWhoUsesIt {
  industry = 'industry',
  type = 'type',
  location = 'location',
  customerDescription = 'customerDescription',
  marquee = 'marquee',
  languages = 'languages',
  productLanguages = 'productLanguages',
  group = 'group',
}

export enum FieldsNameDoesItCost {
  starts = 'starts',
  to = 'to',
  pricing = 'pricing',
  return = 'return',
}

export enum FieldsNameMentions {
  awards = 'awards',
  comprassion = 'comprassion',
  technical = 'technical',
}

export enum FieldsNameNotes {
  notesOnProduct = 'notesOnProduct',
  notesOnCompretitors = 'notesOnCompretitors',
  nextInterview = 'nextInterview',
  ownerName = 'ownerName',
  ownerTitle = 'ownerTitle',
  ownerEmail = 'ownerEmail',
  ownerPhone = 'ownerPhone',
  code = 'code',
}

export enum FieldsSolutionNameSource {
  solutionNameInTitleSource = 'solutionNameInTitleSource',
  selectCompanyToLinkWithSolutionSource = 'selectCompanyToLinkWithSolutionSource',
  slugSource = 'slugSource',
}

export enum FieldsNameWhatItDoesSource {
  categoriesSource = 'categoriesSource',
  subCategoriesSource = 'subcategoriesSource',
  otherCategoriesSource = 'otherCategoriesSource',
  descriptionSource = 'descriptionSource',
  longDescriptionSource = 'longDescriptionSource',
  keyDifferentiatorsSource = 'keyDifferentiatorsSource',
  youtubeLinkSource = 'youtubeLinkSource',
  logoImgSource = 'logoSource',
  brochureSource = 'brochureSource',
  shortDescriptionSource = 'shortDescriptionSource',
}

export enum FieldsNameWhoUsesItSource {
  industrySource = 'industrySource',
  typeSource = 'typeSource',
  locationSource = 'locationSource',
  customerDescriptionSource = 'customerDescriptionSource',
  marqueeSource = 'marqueeSource',
  languagesSource = 'languagesSource',
  productLanguagesSource = 'productLanguagesSource',
  groupSource = 'groupSource',
}

export enum FieldsNameDoesItCostSource {
  startsSource = 'startsSource',
  toSource = 'toSource',
  pricingSource = 'pricingSource',
  returnSource = 'returnSource',
}

export enum FieldsNameMentionsSource {
  awardsSource = 'awardsSource',
  comprassionSource = 'comprassionSource',
  technicalSource = 'technicalSource',
}

export enum FieldsNameNotesSource {
  notesOnProductSource = 'notesOnProductSource',
  notesOnCompretitorsSource = 'notesOnCompretitorsSource',
  nextInterviewSource = 'nextInterviewSource',
  ownerNameSource = 'ownerNameSource',
  ownerTitleSource = 'ownerTitleSource',
  ownerEmailSource = 'ownerEmailSource',
  ownerPhoneSource = 'ownerPhoneSource',
  logoSource = 'logoSource',
  brochuresSource = 'brochuresSource',
}
