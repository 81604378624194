/* eslint-disable react/no-array-index-key */
import React from 'react';
import { usePagination } from '@material-ui/lab/Pagination';
import arrowLeftIcon from '../../assets/images/pagination-arrow-left.svg';
import arrowRightIcon from '../../assets/images/pagination-arrow-right.svg';
import { Container } from './styles';

type IPaginationProps = {
  pagesCount: any;
  currentPage: number;
  onChangePage: (value: number) => void;
};

const transformNumber = (number: number) => {
  if (String(number).length === 1) {
    return `0${number}`;
  }
  return number;
};

const Pagination: React.FC<IPaginationProps> = ({ pagesCount, onChangePage, currentPage }) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onChangePage(value);
  };

  const { items } = usePagination({
    onChange: handleChange,
    count: pagesCount,
    page: currentPage,
  });

  return (
    <nav>
      <Container>
        <ul>
          {items.map(({ page, type, selected, ...item }, index) => {
            let children = null;
            if (type === 'start-ellipsis' || type === 'end-ellipsis') {
              children = '…';
            } else if (type === 'page') {
              children = (
                <button type="button" className={selected ? 'active' : undefined} {...item}>
                  {transformNumber(page)}
                </button>
              );
            } else {
              children = (
                <button type="button" {...item}>
                  <img src={arrowLeftIcon} alt="arrow-left" />
                  <img src={arrowRightIcon} alt="arrow-right" />
                </button>
              );
            }
            return <li key={index}>{children}</li>;
          })}
        </ul>
      </Container>
    </nav>
  );
};

export default Pagination;
