import { observable, action, makeAutoObservable } from 'mobx';
import { inject, injectable, decorate } from 'inversify';
//import { Redirect } from 'react-router-dom';
import utc from 'dayjs/plugin/utc';
import { Base64 } from 'js-base64';
import dayjs from 'dayjs';
import { ApiService } from '../../services/api/ApiService';
import { TokenService } from '../../services/api/TokenService';

// Type
import { ILoginParam, IOtpInfo, IUser, ISetupWebAuthn, IRegisterNewCredential, INeedStep } from '../../services/api/types';
import { Roles } from '../../services/api/types';

export class AuthStore {
  constructor() {
    makeAutoObservable(this);
    dayjs.extend(utc);
  }

  @inject(TokenService)
  private tokenService!: TokenService;

  @inject(ApiService)
  private api!: ApiService;

  @observable private keysName = [{ name: '' }];

  @observable private isAuthorized = false;

  @observable private role = [''];

  @observable private csrfToken = '';

  @observable private error = false;

  @observable private isLoading = false;

  @observable private errorMessage = '';

  @observable private status = '';

  @observable private jswToken = '';

  @observable bad_auth = 0;

  @observable private showUnblockAccountText = false;

  @observable private needStep: Array<string> = [''];

  @observable private recoveryCodes: Array<string> = [''];

  @observable private otp: IOtpInfo | null = null;

  @observable private user: IUser | null = null;

  @observable private loginData: ILoginParam | null = null;

  @observable private setupWebAuthn: ISetupWebAuthn | null = null;

  @observable private newCredential: IRegisterNewCredential | null = null;

  @observable private checkTokenIntervalId: number | undefined = undefined;

  @action getKeysName = (): { name: string }[] => {
    return this.keysName;
  };

  @action getShowUnblockAccountText = () => {
    return this.showUnblockAccountText;
  };

  @action getIsAuthorized = (): boolean => {
    return this.isAuthorized;
  };

  @action getcsrfToken = (): string => {
    return this.csrfToken;
  };

  @action getError = (): boolean => {
    return this.error;
  };

  @action getRole = (): string => {
    return this.role[0];
  };

  @action getIsResearcher = (): boolean => {
    return this.tokenService.getRole() === Roles.RESEARCHER
  };

  @action getIsLoading = (): boolean => {
    return this.isLoading;
  };

  @action getErrorMessage = (): string => {
    return this.errorMessage;
  };

  @action getStatus = (): string => {
    return this.status;
  };

  @action getOtp = (): IOtpInfo | null => {
    return this.otp;
  };

  @action getNeedStep = (): string[] => {
    return this.needStep;
  };

  @action getRecoveryCodes = (): string[] => {
    return this.recoveryCodes;
  };

  @action getUserInfo = (): IUser | null => {
    return this.user;
  };

  @action getLoginData = (): ILoginParam | null => {
    return this.loginData;
  };

  @action getSetupWebAuthn = (): ISetupWebAuthn | null => {
    return this.setupWebAuthn;
  };

  @action setIsAuthorized = (isAuthorized: boolean) => {
    this.isAuthorized = isAuthorized;
  };

  @action setRole = (role: [string]) => {
    this.role = role;
  };

  @action setErrorMessage = (message: string) => {
    this.errorMessage = message;
  };

  @action private setCsrfToken = (csrfToken: string) => {
    this.csrfToken = csrfToken;
  };

  @action private setOtpInfo = (otp: IOtpInfo | null) => {
    this.otp = otp;
  };

  @action setError = (error: boolean) => {
    this.error = error;
  };

  @action private setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action private setStatus = (status: string) => {
    this.status = status;
  };

  @action private setNeedStep = (needStep: string[]) => {
    this.needStep = needStep;
  };

  @action private setJswToken = (jswToken: string) => {
    this.jswToken = jswToken;
  };

  @action private setRecoveryCodes = (recoveryCodes: string[]) => {
    this.recoveryCodes = recoveryCodes;
  };

  @action private setUser = (user: IUser | null) => {
    this.user = user;
  };

  @action private setLoginData = (loginData: ILoginParam | null) => {
    this.loginData = loginData;
  };

  @action private setSetupWebAuthn = (setupWebAuthn: ISetupWebAuthn | null) => {
    this.setupWebAuthn = setupWebAuthn;
  };

  @action private setKeysName = (keysName: [{ name: string }]) => {
    this.keysName = keysName;
  };

  bufferEncode = (value: Uint8Array): any => {
    Base64.extendString();
    return Base64.fromUint8Array(value, true).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
  };

  bufferDecode = (value: any): any => {
    return Uint8Array.from(atob(value), (c) => c.charCodeAt(0));
  };

  saveLogoutTime = (jwtToken: string): void => {
    const time = this.tokenService.parseLogoutTime(jwtToken)
    this.tokenService.saveLogoutTime(time)
  };

  public loginUser = async (loginParam: ILoginParam): Promise<void> => {
    this.setLoginData(loginParam)
    let res;
    try {
      this.setLoading(true);
      this.setError(false);

      res = await this.api.loginUser(loginParam, this.jswToken)

      if (["ERROR"].includes(res.status)) {  // removed "WRONG" on backend too
        this.setError(true)
        if (res.message)
          this.setErrorMessage(res.message)
        return
      }

      const isAuthorized = this._checkIsAuthorized({
        needStep: res.need_step,
        token: res.token,
      })

      if (isAuthorized) {
        this._saveAuthData({
          token: res.token as string,
          needStep: res.need_step as string[],
          user: res.user as IUser,
        })
      }
      else {
        if (res.need_step) {
          this.setNeedStep(res.need_step);
        }
        if (res.token) {
          this.setJswToken(res.token);
          this.tokenService.saveToken(res.token);
        }
      }

      this.setStatus(res.status);
    } catch (e) {
      this.setError(true);
    } finally {
      this.setLoading(false);
    }
  };

  public getOtpData = async (): Promise<void> => {
    try {
      this.setError(false);
      this.setLoading(true);
      const res = await this.api.getOtpData(this.jswToken);
      if (res.message) {
        this.setErrorMessage(res.message);
      }
      this.setOtpInfo(res);
    } catch (e) {
      this.setError(true);
    } finally {
      this.setLoading(false);
    }
  };

  public setOtpCode = async (code: string): Promise<void> => {
    try {
      this.setError(false);
      this.setLoading(true);
      const res = await this.api.setOtpCode(code, this.jswToken);
      if (res.message) {
        this.setErrorMessage(res.message);
        if (res.status === 'error') {
          this.setError(true);
        }
      } else {
        this.setRecoveryCodes(res.recovery_codes);
        this.setNeedStep([INeedStep.RECOVERY]);
      }
    } catch (e) {
      this.setError(true);
    } finally {
      this.setLoading(false);
    }
  };

  public mfaWebauthnRegisterBegin = async (name: string): Promise<void> => {
    try {
      this.setError(false);
      this.setLoading(true);
      const { publicKey, token, message, status } = await this.api.mfaWebauthnRegisterBegin(this.jswToken);
      if (message) {
        this.setErrorMessage(message);
        if (status === 'error') {
          this.setError(true);
        }
      }
      const enc = new TextEncoder()
      const createCredentialOptions = {
        publicKey: {
          rp: publicKey.rp,
          // TODO: encode these values
          // challenge: this.bufferDecode(publicKey.challenge),
          challenge: enc.encode(publicKey.challenge),
          user: {
            // id: this.bufferDecode(publicKey.user.id),  // invalid char
            id: enc.encode(publicKey.user.id),
            name: publicKey.user.name,
            displayName: publicKey.user.displayName,
          },
          pubKeyCredParams: publicKey.pubKeyCredParams as {
            alg: number;
            type: any;
          }[],
          authenticatorSelection: {
            requireResidentKey: true,
            // userVerification: {ok: true},
          },
          timeout: 10,
        },
      } as ISetupWebAuthn;

      this.setSetupWebAuthn(createCredentialOptions);
      navigator.credentials
        .create(createCredentialOptions)
        .then((newCredential) => {
          this.mfaWebauthnRegisterComplete({newCredential, name, token});
        })
        .catch((e) => {
          console.error('yubikey:  error creating credentials: ', e)
          this.setError(true);
        });
    } catch (e) {
      console.error('yubikey:  unknown error: ', e)
      this.setError(true);
    } finally {
      this.setLoading(false);
    }
  };

  public mfaWebauthnRegisterComplete = async (
      {
        newCredential,
        name,
        token,
      }:
      {
        newCredential: any;
        name: string;
        token: string;
      }
  ): Promise<void> => {
    try {
      this.setError(false);
      this.setLoading(true);
      const rawId = new Uint8Array(newCredential.rawId);
      const attestationObject = new Uint8Array(newCredential.response.attestationObject);
      const clientDataJSON = new Uint8Array(newCredential.response.clientDataJSON);

      const credential = {
        id: newCredential.id,
        rawId: this.bufferEncode(rawId),
        type: newCredential.type,
        keyName: name,
        token,
        response: {
          attestationObject: this.bufferEncode(attestationObject),
          clientDataJSON: this.bufferEncode(clientDataJSON),
        },
      };

      const res = await this.api.mfaWebauthnRegisterComplete(credential, this.jswToken);

      if (res.status !== 'OK') {
        this.setErrorMessage(res.message);
          this.setError(true);
          this.setNeedStep([INeedStep.ADD_KEY]);
          return
      }
      // were previously:
      //this.setKeysName(res.keys)
      //this.setNeedStep([INeedStep.KEY_LIST])
      // to render list of keys
      // but we don't support multiple keys

      this.setKeysName(res.keys as [{ name: string }])

      this._saveAuthData({
        needStep: [],  // authorized if ok, prev steps completed earlier
        token: res.token as string,
        user: res.user as IUser,
      })

    } catch (e) {
      this.setError(true);
    } finally {
      this.setLoading(false);
    }
  };

  public mfaWebauthnEnterBegin = async (): Promise<void> => {
    try {
      this.setError(false);
      const { publicKey, user, token, status, message } = await this.api.mfaWebauthnEnterBegin(this.jswToken);
      if (status === 'ERROR') {
          this.setError(true);
          if (message)
            this.setErrorMessage(message)
      }

      const enc = new TextEncoder()
      const credentialsGetOptions = {
        publicKey: {
          challenge: enc.encode(publicKey.challenge),
          allowCredentials: [],
          // TODO_: maybe to use specific credentials
          //allowCredentials: publicKey.allowCredentials.map((el: any) => {
          //  return {
          //    //id: el.id,
          //    //id: this.bufferEncode(el.id),
          //    //id: this.bufferDecode(el.id),
          //    //id: enc.encode('c8diDR_dTOBVYZbHJ2ht3kFQIWmdkjVyIkOV4P4s30fYAQbt0cB8Gzu0e-bT0SjLRqxXDHOgT6ry4R2sOwUWDw'),
          //    id: new Uint8Array(enc.encode('DOQXgjXJEmZTx2nq2yAY5jzE10xZsWlagmsZ5gG4bf_5dy9Tsmxv-6dvETsccHLupAEpNxzUFkI8ifKl2OiuWQ')),
          //    type: el.type,
          //  };
          //}),
          rpId: publicKey.rpId,
          user: {
            id: user.id,
            name: user.name,
            displayName: user.displayName,
          },
          timeout: publicKey.timeout,
          attestation: "none",
        },
      } as any

      const credentials = await navigator.credentials.get(credentialsGetOptions);
      this.mfaWebauthnEnterComplete(credentials, token);
    } catch (e) {
      this.setError(true);
      console.error('yubikey:  error initiatingy credentials verification: ', e)
    } finally {
      this.setLoading(false);
    }
  };

  public mfaWebauthnEnterComplete = async (assertedCredential: any, token: string): Promise<void> => {
    try {
      this.setError(false);
      this.setLoading(true);
      const authData = new Uint8Array(assertedCredential.response.authenticatorData);
      const clientDataJSON = new Uint8Array(assertedCredential.response.clientDataJSON);
      const rawId = new Uint8Array(assertedCredential.rawId);
      const sig = new Uint8Array(assertedCredential.response.signature);
      const userHandle = new Uint8Array(assertedCredential.response.userHandle);

      const credential = {
        id: assertedCredential.id,
        rawId: this.bufferEncode(rawId),
        type: assertedCredential.type,
        response: {
          authenticatorData: this.bufferEncode(authData),
          clientDataJSON: this.bufferEncode(clientDataJSON),
          signature: this.bufferEncode(sig),
          userHandle: this.bufferEncode(userHandle),
        },
        token,
      };

      const res = await this.api.mfaWebauthnEnterComplete(credential, this.jswToken);

      if (res.status !== 'OK') {
          this.setError(true);
          if (res.message)
            this.setErrorMessage(res.message);
          return
      }

      const isAuthorized = this._checkIsAuthorized({
        needStep: [],  // all previous steps should be completed first
        token: res.token,
      })
      if (isAuthorized)
        this._saveAuthData({
          needStep: [],
          token: res.token as string,
          user: res.user as IUser,
        })

      this.setStatus(res.status)
    } catch (e) {
      console.error('yubikey:  error verifying credentials: ', e)
      this.setError(true);
    } finally {
      this.setLoading(false);
    }
  };

  _checkIsAuthorized(data: {
    needStep: string[] | undefined,  // TODO_: set enum
    token: string | undefined,
  }) {
    const isAuthorized = Array.isArray(data.needStep) && !data.needStep.length && data.token
    return isAuthorized
  }

  _saveAuthData(data: {
    token: string,
    needStep: string[],
    user: IUser,
  }) {
    this.tokenService.saveToken(data.token)
    this.setNeedStep(data.needStep)
    this.setUser(data.user)
    this.tokenService.saveFullName(`${data.user.first_name} ${data.user.last_name}`)
    this.tokenService.saveRole(data.user.roles[0])
    this.setRole(data.user.roles)
    this.tokenService.saveIsAauthorized(true)
    this.setIsAuthorized(true)
    this.saveLogoutTime(data.token)
  }

  logout(redirect?: any): void {
    this.setNeedStep([INeedStep.START])

    this.tokenService.saveIsAauthorized(false)
    this.tokenService.saveLogoutTime(0)
    this.tokenService.saveToken('')
    this.tokenService.saveFullName('')
    this.setStatus('');
    this.setIsAuthorized(false)

    if (redirect)
      redirect()
  }

  public checkTokenStart = async (logoutRedirect: Function | undefined = undefined): Promise<void> => {
    const isAuthorized = this.tokenService.getIsAuthorized()
    const authorizedDidntSetupCheck = isAuthorized && !this.checkTokenIntervalId

    console.info(`periodical JWT check:  isAuthorized: ${isAuthorized},  checkTokenIntervalId: ${this.checkTokenIntervalId}`)

    if (authorizedDidntSetupCheck) {
      console.info('periodical JWT check:  setting up periodical check')
      this.checkTokenIntervalId = setInterval(() => {
        console.info('periodical JWT check:  checking authorization')
        const becameUnauthorized = !this.tokenService.getIsAuthorized()
        const didntStopPeriodicalChecks = this.checkTokenIntervalId
        console.info(`periodical JWT check:  becameUnauthorized: ${becameUnauthorized}`)
        if (becameUnauthorized && didntStopPeriodicalChecks) {
          console.info('periodical JWT check:  became unauthorized, removing check')
          clearInterval(this.checkTokenIntervalId)
          return
        }
        const timeCurrent = new Date().getTime()
        const timeTokenExpires =  this.tokenService.getLogoutTime()
        const tokenExpired = !timeTokenExpires || (timeCurrent >= timeTokenExpires)
        console.info(`periodical JWT check:  timeCurrent: ${timeCurrent}, timeTokenExpires: ${timeTokenExpires}`)
        console.info(`periodical JWT check:  expired: ${tokenExpired}`)
        if (tokenExpired) {
          console.info('periodical JWT check:  logging out')
          const redirectOrDummy = logoutRedirect || (() => undefined)
          this.checkTokenStop()
          this.logout(redirectOrDummy)
        }
      }, 30000) as unknown as number;
    }
  };

  public checkTokenStop = () => {
    if (this.checkTokenIntervalId) {
      clearInterval(this.checkTokenIntervalId)
      this.checkTokenIntervalId = undefined
    }
  }
}

decorate(injectable(), AuthStore);
