/**
 * Render array from backend to corresponding string.
 * Input: [
 *   "University 1",
 *   "University 2",
 *   "College 2",
 * ]
 * Output:
 *   "University 1, University 2, College 2"
 * Would be rendered with raw string interpolation as:
 *   "University 1,University 2,College 2"
 */
export function renderArrayToString(array: string[] | null | undefined): string {
  if (!array)
    array = []
  return array.join(', ')
}
