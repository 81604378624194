import React from 'react';
import { StepProgress } from './styles';

interface IProps {
  steps: number;
  currentStep: number;
}
const Steps = ({ currentStep, steps }: IProps): JSX.Element => {
  const renderSteps = () => {
    const step = [];
    for (let i = 1; i < steps + 1; i += 1) {
      step.push(i);
    }
    return step.map((s) => (
      <div className="step" key={s}>
        {s}
      </div>
    ));
  };
  return (
    <StepProgress currentStep={currentStep}>
      <div className="box">{renderSteps()}</div>
    </StepProgress>
  );
};
export default Steps;
