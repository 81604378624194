import styled from '@emotion/styled';

export const StyledWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  right: 15px;
  top: 30%;
  width: 50px;
  height: 130px;
  background-color: #f2f4f8;
  z-index: 100;
  box-shadow: 0px 40px 30px -15px rgba(119, 124, 152, 0.5);

  button {
    width: 60px;
    height: 60px;
    background-color: #fff;
    border-radius: 50%;

    &:hover {
      background-color: #777c9f;
    }
  }
`;
