import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { components } from 'react-select';
// Styles
import { StyledSelect, colourStyles } from './styles';
import opnenSvg from '../../../assets/images/company/open.svg';
import closeSvg from '../../../assets/images/company/close.svg';

export interface SelectItem {
  value: string | number;
  label?: string;
}

interface Items {
  id: string;
  title: string;
}

export interface IProps {
  className?: string;
  onChange?: (name: string, valuse: string) => void;
  onBlur?: (name: string, touched: boolean) => void;
  valueFormik?: any;
  width?: string;
  name?: string;
  items?: Items[];
  placeholder: string;
  styles?: any;
  CustomSelect?: any;
  id?: string;
  defaultValue?: string;
  disabled?: boolean;
}

const MySelect = ({
  items,
  id,
  className,
  name,
  placeholder,
  onBlur,
  onChange,
  styles,
  defaultValue,
  CustomSelect,
  disabled = false,
}: IProps): JSX.Element => {
  const { submitForm } = useFormikContext<any>();
  const [value, setValue] = useState<SelectItem | undefined>(undefined);

  useEffect(() => {
    if (items && defaultValue) {
      const res = items.find((el) => el.id === defaultValue);
      if (res) {
        setValue({
          value: res.id,
          label: res.title,
        });
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    if (name && onChange && value) {
      onChange(name, value.value.toString());
    }
  }, [value]);

  const itemsOption = (item: Items[] | undefined): any | void => {
    if (item) {
      return item?.map((el) => ({ value: el.id, label: el.title }));
    }
    return [];
  };

  const DropdownIndicator = ({ selectProps, ...props }: any) => (
    // eslint-disable-next-line
    <components.DropdownIndicator {...props}>
      <img alt="" src={`${selectProps.menuIsOpen ? closeSvg : opnenSvg}`} />
    </components.DropdownIndicator>
  );

  const handleChange = (e: any) => {
    setValue(e);
    if (name && onChange) {
      onChange(name, e);
      setTimeout(() => {
        submitForm();
      }, 500);
    }
  };

  const handleBlur = () => {
    if (name && onBlur) {
      onBlur(name, true);
    }
  };

  const Select = CustomSelect || StyledSelect;

  return (
    <Select
      isDisabled={disabled}
      className={className}
      id={id}
      name={name}
      options={itemsOption(items)}
      components={{ DropdownIndicator }}
      placeholder={placeholder}
      isSearchable={false}
      styles={styles || colourStyles}
      onChange={(e: any) => handleChange(e)}
      onBlur={handleBlur}
      value={value}
    />
  );
};

export default MySelect;
