// Core
import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import { Multiselect } from 'multiselect-react-dropdown';
import { ISearchCompany } from '../../../services/api/types';

// Styles
import { WrapperMultiselect, Overlay } from './styles';

export interface SelectItem {
  value: string | number;
  label?: string;
}

export interface Items {
  id: string;
  title: string;
  parent_category?: string;
  sub_category?: boolean;
}

export interface IProps {
  name: string;
  className?: string;
  onChange: (name: string, valuse: string | string[]) => void;
  setSubCategories?: (items: Items[] | undefined) => void;
  items?: Items[] | null;
  defaultSearchValue?: Items;
  searchItems?: ISearchCompany;
  filteredItem?: Items[] | null;
  defaultValue?: any;
  placeholder?: string;
  disabled?: boolean;
  id?: string;
  subCategory?: boolean;
  selectionLimit?: boolean;
  onSearch?: (query: string) => Promise<any>;
}
const itemsOption = (elements: Items[] | undefined | null, isSubCategory?: boolean): any | void => {
  if (elements) {
    let res = elements;
    if (isSubCategory) {
      res = elements.filter((items) => items.sub_category);
      res.sort((a, b) => {
        if (a.title > b.title) {
          return 1;
        }
        if (a.title < b.title) {
          return -1;
        }
        return 0;
      });
    } else {
      res = elements.filter((items) => !items.sub_category);
    }
    res.sort((a, b) => {
      if (a.title > b.title) {
        return 1;
      }
      if (a.title < b.title) {
        return -1;
      }
      return 0;
    });
    return res?.map((el) => ({ value: el.id, label: el.title }));
  }
  return [];
};

export const SelectMultiple = observer(
  ({
    id,
    placeholder,
    onChange,
    onSearch,
    className,
    items,
    defaultValue,
    defaultSearchValue,
    name,
    subCategory = false,
    selectionLimit = false,
    setSubCategories,
    filteredItem,
    disabled = false,
  }: IProps): JSX.Element => {
    const [optionsValue, setOptionsValue] = useState<any>();
    const [value, setValue] = useState<any>();
    const [valueSearch, setValueSearch] = useState<string>('');
    const [toggleSvg, setToggleSvg] = useState<boolean>(false);
    const [selectedList, setSelectedList] = useState<any>([]);
    const [selectedValues, setSelectedValues] = useState<SelectItem>();
    const { submitForm } = useFormikContext<any>();

    useEffect(() => {
      onChange(name, value);
    }, [value]);

    useEffect(() => {
      setSelectedList([...selectedList, selectedValues]);
    }, [selectedValues]);

    useEffect(() => {
      if (onSearch && valueSearch) {
        setOptionsValue(itemsOption(items, subCategory));
      }
    }, [valueSearch, items]);

    useEffect(() => {
      setOptionsValue(itemsOption(items, subCategory));
      if (defaultValue) {
        const res = defaultValue.map((item: string) => ({ value: item }));
        onChange(name, res);
        itemsOption(defaultValue);

        // eslint-disable-next-line
        setDefaultValue(defaultValue);
      }
      if (defaultSearchValue) {
        // eslint-disable-next-line
        setDefaultValue([defaultSearchValue.id]);
        onChange(name, [defaultSearchValue.id]);
        setSelectedValues({ value: defaultSearchValue.id, label: defaultSearchValue.title });
      }
    }, [items]);

    useEffect(() => {
      if (filteredItem) {
        setOptionsValue(itemsOption(filteredItem, subCategory));
      }
    }, [filteredItem]);

    const setDefaultValue = (val: string | string[]) => {
      const defaultSelectedValues = items?.filter((el) => val.includes(el.id));
      if (defaultSelectedValues) {
        setValue(defaultSelectedValues.map((el) => el.id));
      }
      setSelectedList(itemsOption(defaultSelectedValues, subCategory));
      setSelectedValues(itemsOption(defaultSelectedValues, subCategory));
    };

    const handleSelect = (selectedElement: SelectItem[]) => {
      if (setSubCategories && items) {
        const filteredItems: Items[] = [];
        selectedElement.forEach((selectedItem) => {
          items.forEach((el) => {
            if (selectedItem.value === el.parent_category) {
              filteredItems.push(el);
            }
          });
        });
        setSubCategories(filteredItems);
      }
      onChange(name, value);
      setSelectedList(selectedElement);
      setValue(selectedElement);
    };
    return (
      <WrapperMultiselect
        toggleSvg={toggleSvg}
        onClick={() => {
          setToggleSvg(true);
        }}
        onBlur={() => {
          setToggleSvg(false);
          setTimeout(() => {
            submitForm();
          }, 500);
        }}
        className={className}
      >
        {!selectedList.length || !value ? null : (
          <div className="list">
            <ul>
              {selectedList?.map((el: any) => {
                if (el?.label) {
                  return <li key={`${el.value}`}>{el.label}</li>;
                }
                return null;
              })}
            </ul>
          </div>
        )}
        {disabled && <Overlay />}
        <Multiselect
          selectionLimit={selectionLimit ? 1 : -1}
          placeholder={placeholder}
          id={id}
          onSearch={(e) => {
            if (onSearch) {
              onSearch(e);
              setValueSearch(e);
            }
          }}
          options={optionsValue}
          selectedValues={defaultSearchValue ? [{ value: defaultSearchValue.id, label: defaultSearchValue.title }] : selectedValues}
          onSelect={(selectedElement: SelectItem[]) => handleSelect(selectedElement)}
          onRemove={(list, chip) => {
            const newIntems = optionsValue.filter((el: any) => el?.value !== chip?.value);
            //const newSelectedList = list?.filter((el: any) => el?.value !== chip?.value);
            const newValue = list?.map((el: any) => el?.value);
            setOptionsValue(newIntems);
            setValue(newValue);
            setSelectedList(list);
            if (setSubCategories) {
              setSubCategories(undefined);
            }
          }}
          displayValue="label"
        />
      </WrapperMultiselect>
    );
  }
);
