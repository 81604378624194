import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { StyledFormComponent, WrapperChildren } from './styles';
import opnenSvg from '../../assets/images/company/open.svg';
import closeSvg from '../../assets/images/company/close.svg';
import { useInjection } from '../../core/inversify';

// Store
import { CompanyStore } from '../../stores/Company&SolutionStore';

export const FormComponent = observer(({ title, children }: any) => {
  const companyStore = useInjection(CompanyStore);
  const [showFormContent, showFormContentSet] = useState(false);
  const [toggle, setToggle] = useState(true);

  useEffect(() => {
    const isLoading = companyStore.getIsLoading()
    if (isLoading && showFormContent)
      showFormContentSet(false)
    else if (!isLoading)
      showFormContentSet(true)
  }, [companyStore.getIsLoading()]);

  return (
    <StyledFormComponent>
      <button type="button" onClick={() => setToggle(!toggle)}>
        <div className="wrapper">
          <img src={toggle ? closeSvg : opnenSvg} alt="toggle" />
          <p>{title}</p>
        </div>
      </button>
      {showFormContent && <WrapperChildren toggle={toggle}>{children}</WrapperChildren>}
    </StyledFormComponent>
  );
});
