import styled from '@emotion/styled';

export const StyledDropDoun = styled.div`
  button {
    padding-top: 5px;
    background-color: transparent;
    cursor: pointer;
    border: none;
  }
  .toggler {
    display: block;
    padding: 9px;
  }

  .menu {
    position: absolute;
    display: none;
    left: -2%;
    top: 30px;
    list-style: none;
    background-color: #fff;
    padding: 0 7px 0 16px;
    border-radius: 0 0 6px 6px;
    box-shadow: 0px 15px 20px -10px #424a5d66;
    width: 100%;

    z-index: 2;
  }

  .menu_visible {
    display: block;
  }

  .item {
    display: flex;
    flex-wrap: nowrap;
    color: #777c98;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    border-bottom: 1px solid #f2f4f8;
    line-height: 13px;
    letter-spacing: 1px;
    padding: 12px 0;
    cursor: pointer;
  }

  .item:last-child {
    border-bottom: none;
  }

  a {
    color: #777c98;
    font-size: 11px;

    &:hover,
    &:focus {
      color: #9c0745;
    }
    text-decoration: none;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 30px;
  button {
    display: flex;
    align-items: center;
  }

  img {
    margin-right: 5px;
  }

  p {
    margin: 0;
    color: #1d2553;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  p.role {
    padding: 5px;
    padding-bottom: 0;
    font-size: 11px;
    background: linear-gradient(134.72deg, #fc4d9a 0%, #9c0745 101.5%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
  }
`;
