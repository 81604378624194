import styled from '@emotion/styled';
import Button from '@material-ui/core/Button';

export const StyledButton = styled(Button)`
  background: linear-gradient(134.72deg, #fc4d9a 0%, #9c0745 101.5%);

  .MuiButton-label {
    color: #ffffff;
    z-index: 100;
  }
  p {
    color: #fff;
  }
`;
