// Core
import React from 'react';

// Styles
import { StyledTextField } from './styles';

export interface IProps {
  name?: string;
  className?: string;
  type?: string;
  width?: string;
  placeholder?: string;
  disabled?: boolean;
  autocomplete?: boolean;
  id?: string;
  rows?: string | number;
  as?: string;
  maxCharacters?: number;
  validate?: (value: string) => string | undefined;
  onChange?: (e: any) => void;
}

const TextField = ({
  name,
  id,
  type,
  placeholder,
  autocomplete,
  maxCharacters,
  className,
  disabled = false,
  ...props
}: IProps): JSX.Element => {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <StyledTextField
      className={className}
      autoComplete={!autocomplete ? 'off' : 'on'}
      disabled={disabled}
      name={name}
      type={type}
      maxLangth={maxCharacters}
      placeholder={placeholder}
      id={id}
      {...props}
    />
  );
};

export default TextField;
