import styled from '@emotion/styled';
import { css } from '@emotion/react';
import circleIcon from '../../assets/images/circle.svg';

export const Overlay = styled.div`
  position: fixed;
  z-index: 101;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(29, 37, 83, 0.6);
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
`;


export const Container = styled.div<{ width?: string }>`
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 6px;
  padding: 40px 40px 30px;
  max-height: 90vh;
  overflow-y: auto;

  ${({ width = '454' }) =>
    css`
      width: ${width}px;
    `}
  position: relative;

  &::before {
    display: block;
    position: absolute;
    content: '';
    top: 0px;
    left: 40px;
    width: 50px;
    height: 2px;
    background: linear-gradient(134.72deg, #fc4d9a 0%, #9c0745 101.5%);
  }
`;

export const Header = styled.div`
  position: relative;
`;

export const Body = styled.div`
  margin: 30px 0 40px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const Button = styled.button<{
  btnType: 'accept' | 'decline';
  disabled?: boolean;
  width?: string;
}>`
  font-size: 11px;
  text-transform: uppercase;
  line-height: 13.5px;
  letter-spacing: 1px;
  padding: 13.5px 0;
  border-radius: 6px;
  cursor: pointer;

  ${({ width = '165' }) =>
    css`
      width: ${width}px;
    `}
  align-text: center;

  ${({ btnType }) =>
    btnType === 'decline'
      ? css`
          background: #fff;
          border: 1px solid #d2d3dd;
        `
      : css`
          border: none;
          color: #ffffff;
          background: linear-gradient(#fc4d9a, #9c0745);
          background-color: red;
        `}

  ${({ disabled }) =>
    disabled
      ? css`
          background: linear-gradient(134.72deg, rgba(252, 77, 154, 0.5) 0%, rgba(156, 7, 69, 0.5) 101.5%);
        `
      : null}
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-transform: uppercase;
  color: #1d2553;
  margin: 0;
`;

export const Subtitle = styled.h1<{ requiredSubtitleSign?: boolean }>`
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  color: #777c98;
  margin: 5px 0;
  position: relative;
  display: inline-block;
  text-transform: initial;

  ${({ requiredSubtitleSign }) =>
    requiredSubtitleSign
      ? css`
          &::after {
            display: block;
            content: '';
            background-image: url(${circleIcon});
            background-size: 6px 6px;
            background-position: 50% 50%;
            position: absolute;
            top: 50%;
            right: -15px;
            transform: translate(-50%, -40%);
            width: 6px;
            height: 6px;
          }
        `
      : null}
`;

export const RelativeBox = styled.div<{ mandatory?: boolean; type?: string; error?: boolean }>`
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  min-height: 40px;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid transparent;

  ${({ error }) =>
    error
      ? css`
          border: 1px solid #fc4d9a;
        `
      : null}
  ${({ mandatory }) =>
    mandatory
      ? css`
          &::before {
            display: block;
            content: '';
            background-image: url(${circleIcon});
            background-size: 6px 6px;
            background-position: 50% 50%;
            position: absolute;
            top: 50%;
            left: -15px;
            transform: translateY(-50%);
            width: 6px;
            height: 6px;
          }
        `
      : null}
    & img {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;

    ${({ type }) =>
      type === 'textarea'
        ? css`
            top: 22px;
          `
        : css`
              top: 50%;
              transform: translateY(-50%);
            }
          `}
  }
`;

export const AutocompleteItem = styled.div<{ isHighlighted?: boolean }>`
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #1d2553;
  padding: 10px;
  border-top: 1px solid #fff;
  cursor: pointer;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  overflow: hidden;

  ${({ isHighlighted }) =>
    isHighlighted
      ? css`
          color: #fc4d9a;
        `
      : null};
`;

export const StyledError = styled.div<{ type?: 'warn' }>`
  margin-top: -5px;
  margin-bottom: 10px;
  width: 100%;
  text-transform: initial;
  margin-left: 5px;
  font-size: 14px;

  ${({ type }) =>
    type
      ? css`
          color: rgba(29, 37, 83, 0.5);
        `
      : css`
          color: #fc4d9a;
        `}
`;

export const CommentContainer = styled.div`
  color: #777c98;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;

  &:not(:last-child) {
    margin-bottom: 25px;
    border-bottom: 1px solid #d2d3dd;

    .comment {
      padding-bottom: 25px;
    }
  }

  & .commentDate {
    position: relative;
    padding-left: 10px;
    margin-bottom: 5px;

    &::before {
      position: absolute;
      display: block;
      content: '';
      width: 2px;
      height: 10px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background: linear-gradient(134.72deg, #fc4d9a 0%, #9c0745 101.5%);
    }
  }

  & .name {
    color: #1d2553;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0px;
    margin-bottom: 10px;
  }

  & .comment {
    text-transform: none;
  }
`;
