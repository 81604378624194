export enum STORES {
  AUTH = 'AUTH_STORE',
}

export enum ActionsType {
  approve = 'approve',
  reject = 'reject',
  publish = 'publish',
  comment = 'comment',
  unpublish = 'unpublish',
  rework = 'rework',
  edit = 'edit',
  feedback = 'feedback', // currently unsupported
  delete = 'delete',
  thumbUp = 'thumbUp', // currently unsupported
  thumbDown = 'thumbDown', // currently unsupported
  startProgress = 'start_progress',
  review = 'review',
}

export enum RecordStatus {
  unsetRecordStage = '',
  requestRecordStage = 'request',
  allocatedRecordStage = 'allocated',
  inProgressRecordStage = 'in_progress',
  reviewRecordStage = 'review',
  completedRecordStage = 'completed',
}
