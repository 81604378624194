/**
 * Format date to display.
 */

import dayjs from 'dayjs';

export function displayDate(date: string | number | undefined) {
  const isStringNotEmpty = (typeof date === 'string') && date
  const isNumberNotNull = (typeof date === 'number') && (date > 0)
  console.debug('date: ', date)
  if (isStringNotEmpty) {
    // in ISO format:  "2021-09-14T14:56:17.9992"
    // test regexp code:  /^(\d{4}-\d{2}-\d{2}).*&/g.exec("2021-09-14T14:56:17.9992")
    const dateInValidFormat = /^(\d{4}-\d{2}-\d{2}).*/g.exec(date)
    const dateParsed = dateInValidFormat?.[1] || date
    console.debug('dateInValidFormat: ', dateInValidFormat)
    return dateParsed
  }
  if (isNumberNotNull) {
    return dayjs(date * 1000).format('MM.DD.YYYY');
  }
  return '-';
};

export default displayDate
