import { observable, action, makeAutoObservable } from 'mobx';
import { toJS } from 'mobx';
import { inject, injectable, decorate } from 'inversify';
import utc from 'dayjs/plugin/utc';

import dayjs from 'dayjs';
import { ApiService } from '../../services/api/ApiService';
import { TokenService } from '../../services/api/TokenService';
import { ActionsType } from '../types';
import { ICompanyInfo, IPutDataCompany, ISolutionInfo, IPutDataSolution, IActions } from '../../services/api/types';

// Type
interface IListCompany {
  id: string;
  title: string;
}

export class CompanyStore {
  constructor() {
    makeAutoObservable(this);
    dayjs.extend(utc);
  }

  @inject(TokenService)
  private tokenService!: TokenService;

  @inject(ApiService)
  private api!: ApiService;

  @observable otherContacnts: number[] = [];

  @observable company: ICompanyInfo | null = null;

  @observable brochureId: string[] = [];

  @observable solution: ISolutionInfo | null = null;

  @observable acceptActions: ActionsType[] | null = null;

  @observable companiesList: IListCompany[] | null = null;

  @observable private error = false;

  @observable private isLoading = false;

  @observable private errorMessage = '';

  @observable private status = '';

  @action getCompany = (): ICompanyInfo | null => {
    return toJS(this.company);
  };

  @action private setCompanyInfo = (companyInfo: ICompanyInfo) => {
    this.company = companyInfo;
  };

  @action private setBrochureId = (id: string) => {
    this.brochureId = [...this.brochureId, id];
  };

  @action private setCompaniesList = (companiesList: IListCompany[]) => {
    this.companiesList = companiesList;
  };

  @action private setSolutionInfo = (solutionInfo: ISolutionInfo) => {
    this.solution = solutionInfo;
  };

  @action private setAcceptActions = (acceptActions: ActionsType[] | null) => {
    this.acceptActions = acceptActions;
  };

  @action getError = (): boolean => {
    return this.error;
  };

  @action getStatus = (): string => {
    return this.status;
  };

  @action getErrorMessage = (): string => {
    return this.errorMessage;
  };

  @action private setError = (error: boolean) => {
    this.error = error;
  };

  @action private setErrorMessage = (message: string) => {
    this.errorMessage = message;
  };

  @action private setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action private setStatus = (status: string) => {
    this.status = status;
  };

  @action getIsLoading = (): boolean => {
    return this.isLoading;
  };

  @action getAcceptActions = (): string[] | null => {
    return this.acceptActions;
  };

  public fetchCompany = async (slug: string): Promise<void> => {
    try {
      this.setLoading(true);
      this.setError(false);
      const res = await this.api.getCompany(slug);
      if (res.message) {
        this.setErrorMessage(res.message);
      }
      this.setCompanyInfo(res);
      const setAcceptActions = res.company.record_accept_actions;
      this.setAcceptActions(setAcceptActions);
      if (res.status) {
        this.setStatus(res.status);
      }
    } catch (err) {
      console.log('error: ', err)
      this.setError(true)
      this.setErrorMessage(JSON.stringify(err, null, 4))
    } finally {
      this.setLoading(false);
    }
  };

  public putCompany = async (id: string, data: IPutDataCompany): Promise<void> => {
    try {
      this.setStatus('');
      this.setLoading(true);
      this.setError(false);
      this.setErrorMessage('');
      const res = await this.api.putCompanyInfo(id, { ...data });
      if (res.message) {
        this.setErrorMessage(res.message);
      }
      if (res.status) {
        this.setStatus(res.status);
      }
    } catch (e) {
      this.setError(true);
    } finally {
      this.setLoading(false);
    }
  };

  public autoSaveCompany = async (id: string, data: IPutDataCompany): Promise<void> => {
    this.setStatus('');
    this.setError(false);
    this.setErrorMessage('');
    await this.api.putCompanyInfo(id, { ...data });
  };

  public getSolution = async (slug: string): Promise<ISolutionInfo | undefined> => {
    try {
      this.setLoading(true);
      this.setError(false);
      const res = await this.api.getSolution(slug);
      if (res.message) {
        this.setErrorMessage(res.message);
      }
      this.setSolutionInfo(res);
      if (res.status) {
        this.setStatus(res.status);
      }
      return res
    } catch (e) {
      this.setError(true);
    } finally {
      this.setLoading(false);
    }
  };

  public getCompaniesList = async (query: string): Promise<void> => {
    try {
      this.setError(false);
      const res = await this.api.getCompanyList(query);
      if (res.message) {
        this.setErrorMessage(res.message);
      }
      const nerRes = res.companies.map((el) => {
        return {
          id: el.id,
          title: el.name,
        };
      });
      this.setCompaniesList(nerRes);
      if (res.status) {
        this.setStatus(res.status);
      }
    } catch (e) {
      this.setError(true);
    }
  };

  public putSolution = async (id: string, data: IPutDataSolution): Promise<void> => {
    try {
      this.setStatus('');
      this.setLoading(true);
      this.setError(false);
      this.setErrorMessage('');
      const res = await this.api.putSolutionInfo(id, { ...data });
      if (res.message) {
        this.setErrorMessage(res.message);
      }
      if (res.status) {
        this.setStatus(res.status);
      }
    } catch (e) {
      this.setError(true);
    } finally {
      this.setLoading(false);
    }
  };

  public autoSaveSolution = async (id: string, data: IPutDataSolution): Promise<void> => {
    this.setStatus('');
    this.setError(false);
    this.setErrorMessage('');
    await this.api.putSolutionInfo(id, { ...data });
  };

  public pushBrochures = async (data: any, id: string, recordsType: 'company' | 'solution'): Promise<void> => {
    try {
      this.setStatus('');
      this.setLoading(true);
      this.setError(false);
      const res = await this.api.pustBrochures(data);
      this.setBrochureId(res.id);
      if (res.status) {
        this.setStatus(res.status);
      }
      if (recordsType === 'solution') {
        this.getSolution(id);
      }
      if (recordsType === 'company') {
        this.fetchCompany(id);
      }
    } catch (e) {
      console.error(e);
      this.setError(true);
    } finally {
      this.setLoading(false);
    }
  };

  public changeStatusRecord = async (statusAction: IActions, recordsType: 'company' | 'solution', redirect?: () => void): Promise<void> => {
    try {
      this.setLoading(true);
      this.setError(false);
      let res = { status: '' };
      if (this.company?.company.record_id) {
        res = await this.api.changeStatusRecord(this.company?.company?.record_id, statusAction);
      } else if (this.solution?.solution?.record_id) {
        res = await this.api.changeStatusRecord(this.solution?.solution?.record_id, statusAction);
      }

      if (res.status) {
        this.setStatus(res.status);
      }
      const keyActions = Object.keys(statusAction);
      if (this.company?.company.id || this.solution?.solution.id) {
        if (res.status === 'OK' && keyActions.includes(ActionsType.rework)) {
          if (this.company?.company?.id) {
            this.fetchCompany(this.company?.company?.id);
          }
        }

        if (res.status === 'OK' && recordsType === 'company') {
          if (this.company?.company?.id) {
            if (redirect) {
              redirect();
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
            this.fetchCompany(this.company?.company?.id);
          }
        }
        if (res.status === 'OK' && recordsType === 'solution') {
          if (this.solution?.solution?.id) {
            if (redirect) {
              redirect();
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
            this.getSolution(this.solution?.solution?.id);
          }
        }
      }
    } catch (e) {
      this.setError(true);
    } finally {
      this.setLoading(false);
    }
  };

  public deleteRecord = async (redirect: any, id: string): Promise<void> => {
    try {
      this.setLoading(true);
      this.setError(false);
      const res = await this.api.deleteRecord(id);
      if (res.status) {
        this.setStatus(res.status);
      }
      if (res.status === 'OK') {
        if (redirect) {
          redirect();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    } catch (e) {
      this.setError(true);
    } finally {
      this.setLoading(false);
    }
  };
}

decorate(injectable(), CompanyStore);
