// Core
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Formik } from 'formik';
import * as Yup from 'yup';
import crypto from 'crypto';
import { useInjection } from '../../../core/inversify';

// Store
import { AuthStore } from '../../../stores/AuthStore';

// Utils
import { EmailExp } from '../../../components/utils';

// Svg and Img
import emailSvg from '../../../assets/images/signIn/email.svg';
import vision from '../../../assets/images/signIn/vision.svg';
import showPasswordSvg from '../../../assets/images/signIn/show-password.svg';
import passwordSvg from '../../../assets/images/signIn/password.svg';

// Types
import { ILoginParam } from '../../../services/api/types';

// Styles
import { WrapperForm, RelativeBox, StyledButton, StyledErr, StyledTextField } from '../styles';

export enum FieldName {
  email = 'email',
  verifiCode = 'code',
  password = 'password',
  recovery = 'recovery',
}

interface FormData {
  [FieldName.email]: string;
  [FieldName.password]: string;
}

const SignupSchema = Yup.object().shape({
  [FieldName.email]: Yup.string().required('Please enter email address').matches(EmailExp, 'Enter valid email address'),
  [FieldName.password]: Yup.string().required('Please enter password'),
});
interface IProps {
  login: (param: ILoginParam) => void;
}

const StepOne = observer(({ login }: IProps): JSX.Element => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const authStore = useInjection(AuthStore);
  const [showContactEmail, setShowContactEmail] = useState<boolean>(false);
  const [badAuthCount, setBadAuthCount] = useState<number>(0);
  const getHashPassword = (password: string) => crypto.createHash('sha512').update(password).digest('hex');
  useEffect(() => {
    if (authStore.bad_auth) {
      setShowContactEmail(true);
      switch (authStore.bad_auth) {
        case 2:
          setBadAuthCount(2);
          return;
        case 3:
          setBadAuthCount(1);
          break;
        default:
          break;
      }
    }
  }, [authStore.bad_auth]);

  const onSubmit = async (data: FormData) => {
    const hashPasswors = getHashPassword(data.password);
    const userData = {
      email: data.email,
      hash_password: hashPasswors,
    };
    login(userData);
  };

  const userBlockingMessage = ` You have ${badAuthCount} more attempts to enter the correct password`;
  return (
    <>
      <Formik
        validationSchema={SignupSchema}
        initialValues={{
          [FieldName.email]: '',
          [FieldName.password]: '',
        }}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ errors, touched, isValid }) => {
          return (
            <WrapperForm>
              <h2>Sign in</h2>
              <RelativeBox>
                <img className="sign-in__svg" src={`${emailSvg}`} alt="" />
                <StyledTextField name={FieldName.email} placeholder="Email" type="email" />
              </RelativeBox>
              {touched.email && errors.email ? <StyledErr>{errors.email}</StyledErr> : null}
              <RelativeBox>
                <img className="sign-in__svg" src={`${passwordSvg}`} alt="password" />
                <StyledTextField name={FieldName.password} type={showPassword ? 'text' : 'password'} placeholder="Password" />
                <button className="sign-in__btn" type="button" onClick={() => setShowPassword(!showPassword)}>
                  <img className="sign-in__svg--password" src={`${showPassword ? vision : showPasswordSvg}`} alt="password" />
                </button>
              </RelativeBox>
              {touched.password && errors.password ? <StyledErr>{errors.password}</StyledErr> : null}
              <StyledButton disabled={!isValid && touched.email} type="submit">
                <p>Sign in</p>
              </StyledButton>
              {showContactEmail && <p className="blocked">{authStore.bad_auth >= 4 ? `Your account is blocked. ` : userBlockingMessage}</p>}
            </WrapperForm>
          );
        }}
      </Formik>
    </>
  );
});

export default StepOne;
