// Core
import React, { useState, useEffect, useRef } from 'react';
import PhoneInput from 'react-phone-number-input';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';

// Styles
import { StyledWrapper } from './styles';

export interface IProps {
  name: string;
  className?: string;
  type?: string;
  width?: string;
  isValid?: any;
  placeholder?: string;
  disabled?: boolean;
  autocomplete?: boolean;
  id?: string;
  defaultValue?: string;
  onChange?: (name: string, value: string) => void;
  originValue?: string;
  maxCharacters?: number;
}

const InternationalPhoneInput = observer(
  ({ name, id, defaultValue, type, placeholder, className, maxCharacters = 16, disabled = false, isValid }: IProps): JSX.Element => {
    /* eslint-disable react/jsx-props-no-spreading */
    const { setFieldValue, submitForm, setFieldError, setFieldTouched } = useFormikContext<any>();
    const [value, setValue] = useState(defaultValue);

    const inputRef = useRef<any>(null);

    useEffect(() => {
      setFieldValue(name, defaultValue);
      setValue(defaultValue ? `+${defaultValue}` : '');
    }, [defaultValue]);

    useEffect(() => {
      if (value && value.replace('+', '').length < 10) {
        setTimeout(() => {
          setFieldTouched(name, true);
          setFieldError(name, "Telephone number can't be less than 10 characters");
        });
      }
      if (value && value.replace('+', '').length > 13) {
        setTimeout(() => {
          setFieldTouched(name, true);

          setFieldError(name, "Telephone number can't exceed 13 characters");
        });
      }

      if (value && value.replace('+', '').length >= 10 && value.replace('+', '').length < 13) {
        setTimeout(() => {
          setFieldTouched(name, false);
          setFieldError(name, '');
        });
      }
    }, [defaultValue, value]);

    return (
      <StyledWrapper isValid={isValid}>
        <PhoneInput
          international
          maxLength={maxCharacters}
          value={value || ''}
          ref={inputRef}
          onBlur={() => {
            setFieldValue(name, value?.replace('+', ''));
            setTimeout(() => {
              submitForm();
            }, 500);
          }}
          onChange={setValue}
          className={className}
          disabled={disabled}
          name={name}
          type={type}
          placeholder={placeholder}
          id={id}
        />
      </StyledWrapper>
    );
  }
);

export default InternationalPhoneInput;
