import dayjs from 'dayjs';
import React from 'react';
import { StyledFooter } from './styles';

const Footer = ({ children }: any): JSX.Element => {
  return (
    <StyledFooter>
      {children}
      <p>{dayjs().format("YYYY")} © MELISSA all rights reserved</p>
    </StyledFooter>
  );
};

export default Footer;
