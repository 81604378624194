import styled from '@emotion/styled';

export const Container = styled.div`
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  font-weight: 600px;

  ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none !important;
  }

  li {
    position: relative;
    cursor: pointer;
    margin: 0 20px;

    & button {
      background: transparent;
      border: none;
      color: #777c98;
      cursor: pointer;
      display: flex;
      justify-content: center;
    }
    & button:hover {
      color: #fc4d9a;
    }

    &:last-of-type {
      margin: 0 0 0 20px;
    }

    &:first-of-type {
      margin: 0 20px 0 0;
    }

    &:first-of-type img:first-of-type {
      transform: rotate(180deg);
    }

    &:first-of-type img:last-of-type {
      display: none;
      transform: rotate(0deg);
    }

    &:first-of-type:not(.disabled):hover img {
      display: unset !important;
    }

    &:first-of-type:not(.disabled):hover img:first-of-type {
      display: none !important;
    }

    &:last-of-type img:last-of-type {
      display: none;
      transform: rotate(180deg);
    }

    &:last-of-type:not(.disabled):hover img {
      display: unset !important;
    }

    &:last-of-type:not(.disabled):hover img:first-of-type {
      display: none !important;
    }

    &::marker {
      content: '';
    }

    svg {
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      color: #1d2553;
    }

    .active {
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      color: #1d2553 !important;
    }

    &:hover div {
      background: linear-gradient(134.72deg, #fc4d9a 0%, #9c0745 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    div {
      position: relative;
      display: flex;
      align-items: center;
      text-decoration: unset;
      justify-content: center;
      text-align: center;
      font-weight: 600;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #777c98;
    }
  }
`;
