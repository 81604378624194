import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';

export const SideBarStyled = styled.div`
  min-width: 100px;
  min-height: 100%;
  display: flex;
  margin-right: 50px;
  align-items: flex-start;
  justify-content: center;
  background-color: #fff;
  ul {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    margin-top: 10px;
    list-style: none;
    li:first-of-type {
      &::before {
        content: '';
        position: absolute;
        background-color: #f2f4f8;
        top: -10px;
        left: 0;
        display: block;
        height: 2px;
        width: 100%;
      }
    }

    li {
      position: relative;
      width: 100%;
      margin-bottom: 35px;
      &::after {
        content: '';
        position: absolute;
        bottom: -20px;
        background-color: #f2f4f8;
        display: block;
        height: 2px;
        width: 100%;
      }

      &:hover {
        p.popover {
          display: block;
        }
      }

      p.popover {
        position: absolute;
        display: none;
        border-radius: 6px;
        text-align: center;
        width: 120px;
        height: 35px;
        background-color: #fff;
        padding: 10px;
        right: -130%;
        color: #777c98;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 130%;
        text-transform: uppercase;

        &::before {
          content: '';
          position: absolute;
          top: 40%;
          left: -5px;
          transform: translateY(-30%);
          transform: rotate(45deg);
          width: 10px;
          height: 10px;
          background-color: #fff;
          display: block;
        }
      }

      button {
        height: 50px;
        width: 100%;
        border: none;
        background-color: transparent;
        cursor: pointer;
      }
    }
  }
`;

export const StyledTypography = styled(Typography)`
  margin-left: 20px;
  width: 100%;
`;

export const StyledPopover = styled(Typography)`
  width: 240px;
  height: 240px;
  background-color: #fff;
  padding: 18px 70px 20px 40px;
  z-index: 1200;

  div:first-of-type {
    &::before {
      content: '';
      position: absolute;
      background-color: #f2f4f8;
      top: -10px;
      left: 0;
      display: block;
      height: 2px;
      width: 100%;
    }
  }

  div {
    position: relative;
    width: 100%;
    margin-bottom: 35px;
    &::after {
      content: '';
      position: absolute;
      bottom: -20px;
      background-color: #f2f4f8;
      display: block;
      height: 2px;
      width: 100%;
    }

    button {
      height: 50px;
      width: 100%;
      border: none;
      background-color: transparent;
      cursor: pointer;
      font-weight: 600;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 1px;
      color: #777c98;
      text-transform: uppercase;
      display: flex;
    }
  }
`;
