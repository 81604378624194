import styled from '@emotion/styled';

export const Layout = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  min-height: 90vh;
  background-color: #f2f4f8;
  h2 {
    margin: 0;
    padding: 0;
    font-weight: 800;
    font-size: 36px;
    line-height: 44px;
    text-transform: uppercase;
    margin-top: 50px;
  }
`;
