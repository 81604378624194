export enum Links {
  linkedin = 'https://www.linkedin.com/',
  twitter = 'https://twitter.com/',
  facebook = 'https://www.facebook.com/',
  youtube = 'https://www.youtube.com/',
}

export enum ITitleName {
  COMPANY_NAME = 'COMPANY NAME',
  MAIN_INFO = 'MAIN INFO',
  CONTACTS = 'CONTACTS',
  WHAT_THEY_DO = 'WHAT THEY DO',
  WHO_THEY_DO_IT_FOR = 'WHO THEY DO IT FOR',
  WHAT_STAGE_ARE_THEY_AT = 'WHAT STAGE ARE THEY AT',
  WHO_HAS_AN_INTEREST = 'WHO HAS AN INTEREST',
  SPECIAL_MENTIONS = 'SPECIAL MENTIONS',
  COMPLIANCE_RISK = 'COMPLIANCE & RISK',
  CATALISTO_INTERNAL_SCREENING_NOTES = 'CATALISTO INTERNAL SCREENING NOTES',
  COMPANY_PRODUCTS_SOLUTIONS = 'COMPANY PRODUCTS/SOLUTIONS',
}

export enum FieldsNameMain {
  name = 'name',
  legalName = 'legalName',
  trading = 'trading',
  founded = 'founded',
  incorporated = 'incorporated',
  incorporatedState = 'incorporatedState',
  status = 'status',
  nameInTitle = 'nameInTitle',
  slug = 'slug',
}

export enum FieldsNameContacts {
  addressOne = 'addressOne',
  addressTwo = 'addressTwo',
  city = 'city',
  country = 'country',
  state = 'state',
  code = 'code',
  otherOffices = 'otherOffices',
  otherCity = 'otherCity',
  otherCountry = 'otherCountry',
  otherAddress = 'otherAddress',
  location = 'location',
  officesPhone = 'officesPhone',
  webSite = 'webSite',
  mail = 'mail',
  linkedin = 'linkedin',
  twitter = 'twitter',
  facebook = 'facebook',
  youtube = 'youtube',
  counterOfOffice = 'counterOfOffice',
  codePhone = 'codePhone',
}

export enum FieldsNameTheyDo {
  categories = 'categories',
  subCategories = 'subcategories',
  otherCategories = 'otherCategories',
  description = 'description',
  longDescription = 'longDescription',
  revenue = 'revenue',
  youtubeLink = 'youtubeLink',
  logoImg = 'logo',
  brochure = 'brochure',
}

export enum FieldsNameTheyDoItFor {
  industry = 'industry',
  type = 'type',
  sizeEmployees = 'sizeEmployees',
  sizeRevenues = 'sizeRevenues',
  locationCustomer = 'locationCustomer',
  revenue = 'revenue',
  customerDescription = 'customerDescription',
  marquee = 'marquee',
  userGroup = 'userGroup',
  languages = 'languages',
}

export enum FieldsNameStage {
  privat = 'privat',
  exchange = 'exchange',
  ticker = 'ticker',
  capital = 'capital',
  listRound = 'listRound',
  phase = 'phase',
  orgEmployees = 'orgEmployees',
  orgRevenues = 'orgRevenues',
  ebitda = 'ebitda',
}

export enum FieldsNameInterest {
  ceo = 'ceo',
  board = 'board',
  investors = 'investors',
  controlInvestors = 'controlInvestors',
  investorsNationality = 'investorsNationality',
  original = 'original',
}

export enum FieldsNameMentions {
  mentors = 'mentors',
  mentees = 'mentees',
  awards = 'awards',
  graduated = 'graduated',
  industryMentions = 'industryMentions',
  classes = 'classes',
}

export enum FieldsNameRisk {
  audited = 'audited',
  compliance = 'compliance',
  security = 'security',
}

export enum FieldsNameNotes {
  connect = 'connect',
  hotes = 'hotes',
  risk = 'risk',
  notesOnManagement = 'notesOnManagement',
  notesFocusNextInterview = 'notesFocusNextInterview',
  profileName = 'profileName',
  profileTitle = 'profileTitle',
  profileEmail = 'profileEmail',
  profilePhone = 'profilePhone',
  profilePhoneCode = 'profilePhoneCode',
}

export enum FieldsNameMainSource {
  nameSource = 'nameSource',
  tradingSource = 'tradingSource',
  foundedSource = 'foundedSource',
  incorporatedSource = 'incorporatedSource',
  incorporatedStateSource = 'incorporatedStateSource',
  statusSource = 'statusSource',
  legalNameSours = 'legalNameSours',
  nameInTitleSours = 'nameInTitleSours',
  slugSource = 'slugSource',
  logoSource = 'logoSource',
  brochureSource = 'brochureSource',
}

export enum FieldsNameContactsSource {
  addressOneSource = 'addressOneSource',
  addressTwoSource = 'addressTwoSource',
  citySource = 'citySource',
  countrySource = 'countrySource',
  stateSource = 'stateSource',
  codeSource = 'codeSource',
  otherOfficesSource = 'otherOfficesSource',
  otherCitySource = 'otherCitySource',
  locationSource = 'locationSource',
  officesPhoneSource = 'officesPhoneSource',
  webSiteSource = 'webSiteSource',
  mailSource = 'mailSource',
  linkedinSource = 'linkedinSource',
  twitterSource = 'twitterSource',
  facebookSource = 'facebookSource',
  youtubeSource = 'youtubeSource',
  counteOfOfficeSource = 'counteOfOfficeSource',
}

export enum FieldsNameTheyDoSource {
  categoriesSource = 'categoriesSource',
  subCategoriesSource = 'subcategoriesSource',
  otherCategoriesSource = 'otherCategoriesSource',
  descriptionSource = 'descriptionSource',
  longDescriptionSource = 'longDescriptionSource',
  revenueSource = 'revenueSource',
  youtubeLinkSource = 'youtubeLinkSource',
}

export enum FieldsNameTheyDoItForSource {
  industrySource = 'industrySource',
  typeSource = 'typeSource',
  sizeEmployeesSource = 'sizeEmployeesSource',
  sizeRevenuesSource = 'sizeRevenuesSource',
  locationCustomerSource = 'locationCustomerSource',
  revenueSource = 'revenueSource',
  customerDescriptionSource = 'customerDescriptionSource',
  marqueeSource = 'marqueeSource',
  userGroupSource = 'userGroupSource',
  languagesSource = 'languagesSource',
}

export enum FieldsNameStageSource {
  privatSource = 'privatSource',
  exchangeSource = 'exchangeSource',
  tickerSource = 'tickerSource',
  capitalSource = 'capitalSource',
  listRoundSource = 'listRoundSource',
  phaseSource = 'phaseSource',
  orgEmployeesSource = 'orgEmployeesSource',
  orgRevenuesSource = 'orgRevenuesSource',
  ebitdaSource = 'ebitdaSource',
}

export enum FieldsNameInterestSource {
  ceoSource = 'ceoSource',
  boardSource = 'boardSource',
  investorsSource = 'investorsSource',
  controlInvestorsSource = 'controlInvestorsSource',
  investorsNationalitySource = 'investorsNationalitySource',
  originalSource = 'originalSource',
  tickerSource = 'tickerSource',
}

export enum FieldsNameMentionsSource {
  mentorsSource = 'mentorsSource',
  menteesSource = 'menteesSource',
  awardsSource = 'awardsSource',
  graduatedSource = 'graduatedSource',
  industryMentionsSource = 'industryMentionsSource',
  classesSource = 'classesSource',
}

export enum FieldsNameRiskSource {
  auditedSource = 'auditedSource',
  complianceSource = 'complianceSource',
  securitySource = 'securitySource',
}

export enum FieldsNameNotesSource {
  connectSource = 'connectSource',
  riskSource = 'riskSource',
  notesFocusNextInterviewSource = 'notesFocusNextInterviewSource',
  notesOnManagementSource = 'notesOnManagementSource',
  profileNameSource = 'profileNameSource',
  profileTitleSource = 'profileTitleSource',
  profileEmailSource = 'profileEmailSource',
  profilePhoneSource = 'profilePhoneSource',
}
