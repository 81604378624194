import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { createBrowserHistory } from 'history';
import { observer } from 'mobx-react-lite';
import { Formik } from 'formik';
import { throttle } from 'throttle-debounce';
import { makeStyles, Paper, TextField as MaterialTextField } from '@material-ui/core';
import * as Yup from 'yup';
import { Autocomplete } from '@material-ui/lab';
import { ModalStore } from '../../stores/ModalStore';
import { useInjection } from '../../core/inversify';
import {
  Overlay,
  Container,
  Header,
  Title,
  Subtitle,
  CloseButton,
  Body,
  Footer,
  Button,
  RelativeBox,
  StyledError,
  AutocompleteItem,
  CommentContainer,
} from './styles';
import closeIcon from '../../assets/images/close.svg';
import { RecordsStore } from '../../stores/RecordsStore';
import { CompanyStore } from '../../stores/Company&SolutionStore';
import TextField from '../input/TextField';

import companyIcon from '../../assets/images/company.svg';
import solutionIcon from '../../assets/images/solution.svg';
import websiteIcon from '../../assets/images/website.svg';
import researcherIcon from '../../assets/images/researcher.svg';
import commentIcon from '../../assets/images/comment.svg';
import { formatDate } from '../../library/Utils';

const useStyles = makeStyles({
  textField: {
    background: '#f2f4f8',
    border: 'none',
    borderRadius: '6px',
    width: '100%',
  },
  input: {
    color: '#1d2553',
    paddingLeft: '38px',
    height: '40px',
  },
  paper: {
    position: 'absolute',
    border: 'none',
    width: '100%',
    height: '200px',
    overflowY: 'auto',
    margin: 0,
    marginLeft: '2px',
    padding: '0',
    background: '#f2f4f8',
    color: '#1d2553',
    '& ul': {
      height: '100%',
    },
    '& li': {
      margin: 0,
      padding: 0,
    },
  },
  root: {
    '&:before': {
      content: 'none',
    },
    '&:after': {
      content: 'none',
    },
  },
});

enum FieldName {
  company = 'company',
  solution = 'solution',
  website = 'website',
  researcher = 'researcher',
  comment = 'comment',
}

enum RecordType {
  editCompany = 'editCompany',
  editSolution = 'editSolution',
  create = 'create',
}

const renderValidationSchema = (type: RecordType) => {
  switch (type) {
    case 'create':
      return Yup.object().shape({
        [FieldName.company]: Yup.object().shape({
          title: Yup.string().required('Please enter company name').max(140, 'Please the name cannot be more than 140 characters!'),
        }),
        [FieldName.solution]: Yup.string().max(255, 'Please the solution name cannot be more than 255 characters!'),
        [FieldName.website]: Yup.string().max(100, 'Please the website cannot be more than 100 characters!'),
        [FieldName.comment]: Yup.string().max(1000, 'Please the comment cannot be more than 1000 characters!'),
      });
    case 'editCompany':
      return Yup.object().shape({
        [FieldName.company]: Yup.string().required('Please enter company name'),
        [FieldName.website]: Yup.string().max(100, 'Please the website cannot be more than 100 characters!'),
        [FieldName.comment]: Yup.string().max(1000, 'Please the comment cannot be more than 1000 characters!'),
      });
    case 'editSolution':
      return Yup.object().shape({
        [FieldName.company]: Yup.object().shape({
          name: Yup.string().required('Please enter company name'),
        }),
        [FieldName.solution]: Yup.string()
          .max(255, 'Please the solution name cannot be more than 255 characters!')
          .required('Please enter solution name'),
        [FieldName.website]: Yup.string().max(100, 'Please the website cannot be more than 100 characters!'),
        [FieldName.comment]: Yup.string().max(1000, 'Please the comment cannot be more than 1000 characters!'),
      });
    default:
      return null;
  }
};

interface IModalContent {
  title: string;
  subtitle?: ReactNode;
  acceptButton?: string;
  declineButton?: string;
  onDecline: () => void;
}

interface IDeleteModalContext extends IModalContent {
  onAccept: () => void;
}

interface ICreateRecordModalContent extends IModalContent {
  onAccept: (props: any) => void;
}

interface IEditRecordModalContent extends IModalContent {
  onAccept: (data: any) => void;
  record: any;
}

interface ILeaveCommentModalContent extends IModalContent {
  onAccept: (data: any) => void;
}

interface IShowRecordCommentsModalContent extends IModalContent {
  comments: any;
}

export function DeleteRecordModalContent({
  title,
  subtitle,
  acceptButton,
  declineButton,
  onDecline,
  onAccept,
}: IDeleteModalContext): JSX.Element {
  return (
    <>
      <Header>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <CloseButton onClick={onDecline}>
          <img src={closeIcon} alt="close" />
        </CloseButton>
      </Header>
      <Body />
      <Footer>
        <Button btnType="decline" onClick={onDecline}>
          {declineButton}
        </Button>
        <Button type="button" btnType="accept" onClick={onAccept}>
          {acceptButton}
        </Button>
      </Footer>
    </>
  );
}

export const CreateRecordModalContent = observer(
  ({ title, subtitle, acceptButton, declineButton, onDecline, onAccept }: ICreateRecordModalContent): JSX.Element => {
    const classes = useStyles();
    const { getCompaniesList, getCompanies, getResearchersData, getResearchers } = useInjection(RecordsStore);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
      getResearchersData();
      getCompanies('');
    }, []);

    const [isSelected, setIsSelected] = useState(false);
    const formRef = useRef<any>();

    const handleSubmit = () => {
      if (formRef.current) {
        formRef.current.handleSubmit();
      }
    };
    const throttleFunc = useCallback(
      throttle(2000, false, (value: string) => {
        getCompanies(value);
      }),
      []
    );
    const isValidSite = (site: string) => {
      if (site.includes('https://')) {
        return site;
      }
      return `https://${site}`;
    };

    const companiesData = getCompaniesList() || [];
    const companies = companiesData.sort((a: any, b: any) => a.slug.localeCompare(b.slug));

    const researchers = getResearchers();
    return (
      <>
        <Header>
          <Title>{title}</Title>
          <Subtitle requiredSubtitleSign>{subtitle}</Subtitle>
          <CloseButton onClick={onDecline}>
            <img src={closeIcon} alt="close" />
          </CloseButton>
        </Header>
        <Body>
          <Formik
            innerRef={formRef}
            validationSchema={renderValidationSchema(RecordType.create)}
            enableReinitialize
            initialValues={{
              [FieldName.company]: { id: '', title: '', slug: '' },
              [FieldName.solution]: '',
              [FieldName.website]: '',
              [FieldName.researcher]: { full_name: '', id: '' },
              [FieldName.comment]: '',
            }}
            onSubmit={(values) => {
              const researcherId = values[FieldName.researcher]?.id;
              const comapanyUrl = values.website ? isValidSite(values.website) : ''
              onAccept({
                company: { id: values[FieldName.company].id, title: values.company.title, url: comapanyUrl },
                ...(values.solution && { solution: { title: values.solution } }),
                ...(researcherId && { researcher: { id: researcherId } }),
                ...(values.comment && { comment: values.comment }),
              });
            }}
          >
            {({ errors, touched, setFieldTouched, values, setFieldValue }: any) => {
              useEffect(() => {
                if (Object.keys(errors).some((key) => touched[key] === true)) {
                  setIsDisabled(true);
                } else {
                  setIsDisabled(false);
                }
              });

              return (
                <>
                  <RelativeBox mandatory error={Boolean(errors[FieldName.company]?.name && touched[FieldName.company])}>
                    <Autocomplete
                      options={companies}
                      getOptionLabel={(option: any) => option.title}
                      onChange={(_, v: any) => {
                        setFieldValue(FieldName.company, v || { title: '' });
                      }}
                      inputValue={values[FieldName.company].title || ''}
                      onInputChange={(e: any) => {
                        throttleFunc(e?.target.value);
                        setFieldValue(FieldName.company, { title: e.target.value?.substring(0, 140) || '' });
                        setIsSelected(false);
                      }}
                      onClose={(_, reason) => {
                        if (reason === 'select-option') {
                          setIsSelected(true);
                        }
                      }}
                      disablePortal
                      PaperComponent={({ children }: any) => {
                        return <Paper classes={{ root: classes.paper }}>{children}</Paper>;
                      }}
                      renderOption={(option) => {
                        return <AutocompleteItem>{option.title}</AutocompleteItem>;
                      }}
                      renderInput={(params: any) => {
                        return (
                          <div ref={params.InputProps.ref}>
                            <MaterialTextField
                              {...params.inputProps}
                              name={FieldName.company}
                              placeholder="Select company"
                              className={classes.textField}
                              onBlur={(event) => {
                                setFieldTouched(FieldName.company, true);
                                params.inputProps.onBlur(event);
                              }}
                              InputProps={{
                                helperText: {
                                  length: 140,
                                },
                                className: classes.input,
                                classes: {
                                  root: classes.root,
                                },
                              }}
                            />
                          </div>
                        );
                      }}
                    />
                    <img src={companyIcon} alt="company" />
                  </RelativeBox>
                  {errors[FieldName.company]?.name && touched[FieldName.company] && (
                    <StyledError>{errors[FieldName.company]?.name}</StyledError>
                  )}
                  <RelativeBox error={Boolean(errors[FieldName.solution] && touched[FieldName.solution])}>
                    <img src={solutionIcon} alt="solution" />
                    <TextField
                      disabled={!isSelected}
                      name={FieldName.solution}
                      onChange={(e) => setFieldValue(FieldName.solution, e.target.value?.substring(0, 255))}
                      type="records"
                      placeholder="Solution"
                      maxCharacters={255}
                    />
                  </RelativeBox>
                  {errors[FieldName.solution] && touched[FieldName.solution] && <StyledError>{errors[FieldName.solution]}</StyledError>}
                  {!isSelected && <StyledError type="warn">In order to create solution please select existing company!</StyledError>}
                  <RelativeBox error={Boolean(errors[FieldName.website])}>
                    <img src={websiteIcon} alt="website" />
                    <TextField
                      name={FieldName.website}
                      type="records"
                      placeholder="Company website"
                      onChange={(e) => setFieldValue(FieldName.website, e.target.value?.substring(0, 100))}
                      maxCharacters={250}
                    />
                  </RelativeBox>
                  {errors[FieldName.website] && touched[FieldName.website] && <StyledError>{errors[FieldName.website]}</StyledError>}
                  <RelativeBox error={Boolean(errors[FieldName.researcher])}>
                    <Autocomplete
                      options={researchers}
                      getOptionLabel={(option: { full_name: string; id: string }) => option.full_name}
                      onChange={(_, v: any) => {
                        setFieldValue(FieldName.researcher, v || { full_name: '' });
                      }}
                      disablePortal
                      PaperComponent={({ children }) => <Paper classes={{ root: classes.paper }}>{children}</Paper>}
                      renderOption={(option) => <AutocompleteItem>{option.full_name}</AutocompleteItem>}
                      renderInput={(params: any) => {
                        return (
                          <div ref={params.InputProps.ref}>
                            <MaterialTextField
                              {...params.inputProps}
                              name={FieldName.researcher}
                              placeholder="Select researcher"
                              className={classes.textField}
                              InputProps={{
                                className: classes.input,
                                classes: {
                                  root: classes.root,
                                },
                              }}
                            />
                          </div>
                        );
                      }}
                    />
                    <img src={researcherIcon} alt="company" />
                  </RelativeBox>
                  {errors[FieldName.researcher]?.full_name && touched[FieldName.researcher] && (
                    <StyledError>{errors[FieldName.researcher]?.full_name}</StyledError>
                  )}
                  <RelativeBox type="textarea" error={Boolean(errors[FieldName.comment])}>
                    <img src={commentIcon} alt="comments" />
                    <TextField
                      as="textarea"
                      name={FieldName.comment}
                      type="records"
                      placeholder="Write a comment"
                      rows="4"
                      onChange={(e) => setFieldValue(FieldName.comment, e.target.value?.substring(0, 1000))}
                      maxCharacters={1000}
                    />
                  </RelativeBox>
                  {errors[FieldName.comment] && touched[FieldName.comment] && <StyledError>{errors[FieldName.comment]}</StyledError>}
                </>
              );
            }}
          </Formik>
        </Body>
        <Footer>
          <Button btnType="decline" onClick={onDecline}>
            {declineButton}
          </Button>
          <Button type="button" disabled={isDisabled} onClick={handleSubmit} btnType="accept">
            {acceptButton}
          </Button>
        </Footer>
      </>
    );
  }
);

export const LeaveCommentModalContent = observer(
  ({ onAccept, title, onDecline, declineButton, acceptButton }: ILeaveCommentModalContent): JSX.Element => {
    const formRef = useRef<any>();
    const initialValues = {
      comment: '',
    };

    return (
      <>
        <Header>
          <Title>{title}</Title>
          <CloseButton onClick={onDecline}>
            <img src={closeIcon} alt="close" />
          </CloseButton>
        </Header>

        <Formik innerRef={formRef} enableReinitialize initialValues={initialValues} onSubmit={(values) => onAccept(values.comment)}>
          {({ errors, touched, values, setFieldValue }: any) => {
            return (
              <>
                {' '}
                <Body>
                  <TextField
                    as="textarea"
                    name="comment"
                    type="records"
                    placeholder="Comment"
                    onChange={(e) => {
                      setFieldValue('comment', e?.target.value.substring(0, 255));
                    }}
                  />
                  {errors.comment && touched.comment && <StyledError>{errors.comment}</StyledError>}
                </Body>
                <Body>
                  <Footer>
                    <Button btnType="decline" onClick={onDecline}>
                      {declineButton}
                    </Button>
                    <Button btnType="accept" type="submit" disabled={!values.comment} onClick={() => onAccept(values.comment)}>
                      {acceptButton}
                    </Button>
                  </Footer>
                </Body>
              </>
            );
          }}
        </Formik>
      </>
    );
  }
);

export const EditRecordModalContent = observer(
  ({ title, subtitle, acceptButton, declineButton, record, onDecline, onAccept }: IEditRecordModalContent): JSX.Element => {
    const classes = useStyles();
    const formRef = useRef<any>();
    const [isDisabled, setIsDisabled] = useState(false);
    const { getCompaniesList, getCompanies, getResearchersData, getResearchers } = useInjection(RecordsStore);

    const handleSubmit = () => {
      if (formRef.current) {
        formRef.current.handleSubmit();
      }
    };

    useEffect(() => {
      getResearchersData();
      getCompanies('');
    }, []);

    const throttleFunc = useCallback(
      throttle(2000, false, (value: string) => {
        getCompanies(value);
      }),
      []
    );

    const companies = getCompaniesList();

    const recordType = record.solution?.title ? RecordType.editSolution : RecordType.editCompany;

    let companyInitValue;
    const companyDefaultValue = RecordType.editSolution ? { name: record.company.title, id: record.company.id } : null;
    const researcherInitValue = record.researcher?.id
      ? { full_name: record.researcher.full_name, id: record.researcher.id }
      : { full_name: '', id: '' };

    if (recordType === RecordType.editCompany) {
      companyInitValue = record.company.title;
    } else {
      companyInitValue = record.company.id ? { name: record.company.title, id: record.company.id } : { name: '' };
    }
    const initialValues = {
      [FieldName.company]: companyInitValue,
      [FieldName.solution]: record.solution?.title || '',
      [FieldName.website]: record.company?.url || '',
      [FieldName.researcher]: researcherInitValue,
      [FieldName.comment]: record.comment || '',
    };

    const researchers = getResearchers();

    return (
      <>
        <Header>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <CloseButton onClick={onDecline}>
            <img src={closeIcon} alt="close" />
          </CloseButton>
        </Header>
        <Body>
          <Formik
            innerRef={formRef}
            enableReinitialize
            validationSchema={renderValidationSchema(recordType)}
            initialValues={initialValues}
            onSubmit={(values) => {
              const researcherId = values[FieldName.researcher]?.id;
              const company =
                recordType === RecordType.editCompany
                  ? { id: record.company.id, title: values[FieldName.company] }
                  : { id: values[FieldName.company]?.id };
              onAccept({
                company: { ...company, ...(values.website && { url: values.website }) },
                ...(values.solution && { solution: { title: values.solution, id: record.solution.id } }),
                ...(researcherId && { researcher: { id: researcherId } }),
                ...(values.comment && { comment: values.comment }),
              });
            }}
          >
            {({ errors, touched, setFieldTouched, values, setFieldValue }: any) => {
              useEffect(() => {
                if (Object.keys(errors).some((key) => touched[key] === true)) {
                  setIsDisabled(true);
                } else {
                  setIsDisabled(false);
                }
              });
              return (
                <>
                  {recordType === RecordType.editCompany ? (
                    <>
                      <RelativeBox mandatory error={Boolean(errors[FieldName.company] && touched[FieldName.company])}>
                        <TextField
                          name={FieldName.company}
                          onChange={(e) => {
                            setFieldValue(FieldName.company, e?.target.value.substring(0, 140));
                          }}
                          type="records"
                          placeholder="Company"
                        />
                        <img src={companyIcon} alt="company" />
                      </RelativeBox>
                      {errors[FieldName.company] && touched[FieldName.company] && <StyledError>{errors[FieldName.company]}</StyledError>}
                    </>
                  ) : (
                    <>
                      <RelativeBox
                        mandatory
                        error={Boolean((errors[FieldName.company] as { name: string; id?: string })?.name && touched[FieldName.company])}
                      >
                        <Autocomplete
                          options={companies}
                          getOptionLabel={(option: any) => option.name}
                          onChange={(_, v: any) => {
                            setFieldValue(FieldName.company, v || { name: '' });
                          }}
                          inputValue={values[FieldName.company]?.name || ''}
                          onInputChange={(e: any, v) => {
                            throttleFunc(e?.target.value || '');
                            setFieldValue(FieldName.company, { name: v || '' });
                          }}
                          disablePortal
                          defaultValue={companyDefaultValue}
                          PaperComponent={({ children }: any) => {
                            return <Paper classes={{ root: classes.paper }}>{children}</Paper>;
                          }}
                          renderOption={(option) => {
                            return <AutocompleteItem>{option.name}</AutocompleteItem>;
                          }}
                          renderInput={(params: any) => {
                            return (
                              <div ref={params.InputProps.ref}>
                                <MaterialTextField
                                  {...params.inputProps}
                                  name={FieldName.company}
                                  placeholder="Select company"
                                  className={classes.textField}
                                  onBlur={(event) => {
                                    setFieldTouched(FieldName.company, true);
                                    params.inputProps.onBlur(event);
                                  }}
                                  InputProps={{
                                    className: classes.input,
                                    classes: {
                                      root: classes.root,
                                    },
                                  }}
                                />
                              </div>
                            );
                          }}
                        />

                        <img src={companyIcon} alt="company" />
                      </RelativeBox>

                      {recordType === RecordType.editSolution
                        ? (errors[FieldName.company] as { name?: string })?.name &&
                          touched[FieldName.company] && <StyledError>{(errors[FieldName.company] as { name?: string })?.name}</StyledError>
                        : errors[FieldName.company] && touched[FieldName.company] && <StyledError>{errors[FieldName.company]}</StyledError>}
                      <RelativeBox mandatory error={Boolean(errors[FieldName.solution] && touched[FieldName.solution])}>
                        <img src={solutionIcon} alt="solution" />
                        <TextField
                          name={FieldName.solution}
                          type="records"
                          placeholder="Solution"
                          onChange={(e) => {
                            setFieldValue(FieldName.solution, e?.target.value.substring(0, 255));
                          }}
                        />
                      </RelativeBox>
                      {errors[FieldName.solution] && touched[FieldName.solution] && <StyledError>{errors[FieldName.solution]}</StyledError>}
                    </>
                  )}
                  <RelativeBox error={Boolean(errors[FieldName.website])}>
                    <img src={websiteIcon} alt="website" />
                    <TextField
                      name={FieldName.website}
                      type="records"
                      placeholder="Company website"
                      onChange={(e) => {
                        setFieldValue(FieldName.website, e?.target.value.substring(0, 100));
                      }}
                    />
                  </RelativeBox>
                  {errors[FieldName.website] && touched[FieldName.website] && <StyledError>{errors[FieldName.website]}</StyledError>}
                  <RelativeBox>
                    <Autocomplete
                      options={researchers}
                      getOptionLabel={(option: any) => option.full_name}
                      onChange={(e, v: any) => {
                        setFieldValue(FieldName.researcher, v || '');
                      }}
                      onInputChange={(e, v) => {
                        setFieldValue(FieldName.researcher, { full_name: v || '' });
                      }}
                      inputValue={String(values[FieldName.researcher]?.full_name || '')}
                      disablePortal
                      defaultValue={researcherInitValue}
                      PaperComponent={({ children }) => <Paper classes={{ root: classes.paper }}>{children}</Paper>}
                      renderOption={(option) => <AutocompleteItem>{option.full_name}</AutocompleteItem>}
                      renderInput={(params: any) => {
                        return (
                          <div ref={params.InputProps.ref}>
                            <MaterialTextField
                              {...params.inputProps}
                              name={FieldName.researcher}
                              placeholder="Select researcher"
                              className={classes.textField}
                              InputProps={{
                                className: classes.input,
                                classes: {
                                  root: classes.root,
                                },
                              }}
                            />
                          </div>
                        );
                      }}
                    />
                    <img src={researcherIcon} alt="company" />
                  </RelativeBox>
                  <RelativeBox type="textarea" error={Boolean(errors[FieldName.comment])}>
                    <img src={commentIcon} alt="comments" />
                    <TextField
                      as="textarea"
                      name={FieldName.comment}
                      type="records"
                      placeholder="Write a comment"
                      rows="4"
                      onChange={(e) => {
                        setFieldValue(FieldName.comment, e?.target.value.substring(0, 1000));
                      }}
                    />
                  </RelativeBox>
                  {errors[FieldName.comment] && touched[FieldName.comment] && <StyledError>{errors[FieldName.comment]}</StyledError>}
                </>
              );
            }}
          </Formik>
        </Body>
        <Footer>
          <Button btnType="decline" onClick={onDecline}>
            {declineButton}
          </Button>
          <Button btnType="accept" disabled={isDisabled} onClick={handleSubmit}>
            {acceptButton}
          </Button>
        </Footer>
      </>
    );
  }
);

export const ShowRecordsCommentsModalContent = observer(({ title, comments, onDecline }: IShowRecordCommentsModalContent): JSX.Element => {
  return (
    <>
      <Header>
        <Title>{title}</Title>
        <CloseButton onClick={onDecline}>
          <img src={closeIcon} alt="close" />
        </CloseButton>
      </Header>
      <Body>
        {comments.map((comment: any) => (
          <CommentContainer key={comment.id}>
            <div className="commentDate">{formatDate(comment.created_at)}</div>
            <div className="name">{comment.created_by_full_name}</div>
            <div className="comment">{comment.body}</div>
          </CommentContainer>
        ))}
      </Body>
    </>
  );
});

const Modal = observer((): JSX.Element => {
  const history = createBrowserHistory();
  const { getModalProps, setModalProps } = useInjection(ModalStore);
  const { createRecord, deleteRecord, updateRecord, changeStatusRecord } = useInjection(RecordsStore);
  const companySolutionStore = useInjection(CompanyStore);

  const onCloseModal = useCallback(() => {
    setModalProps(false, {});
  }, []);

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        onCloseModal();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  const modalStore = getModalProps();

  const renderModalContent = useCallback(() => {
    switch (modalStore.context.type) {
      case 'deleteRecord':
        return (
          <DeleteRecordModalContent
            title="Delete record"
            subtitle={
              modalStore.context.record.solution?.title
                ? 'Are you sure you want to delete the solution record?'
                : 'Are you sure you want to delete the company record?'
            }
            acceptButton="Delete record"
            declineButton="Cancel"
            onAccept={() => {
              deleteRecord(modalStore.context.record.id);
              onCloseModal();
            }}
            onDecline={onCloseModal}
          />
        );
      case 'delete':
        return (
          <DeleteRecordModalContent
            title="Delete record"
            subtitle={
              modalStore.context.companySolution?.title
                ? 'Are you sure you want to delete the solution?'
                : 'Are you sure you want to delete the company?'
            }
            acceptButton="Delete record"
            declineButton="Cancel"
            onAccept={() => {
              onCloseModal();
              companySolutionStore.deleteRecord(() =>history.push('/records'), modalStore.context.companySolution.id);
            }}
            onDecline={onCloseModal}
          />
        );
      case 'createRecord':
        return (
          <CreateRecordModalContent
            title="Create record"
            subtitle="Required fields -"
            acceptButton="Create record"
            declineButton="Cancel"
            onAccept={(data: any) => {
              createRecord(data);
              onCloseModal();
            }}
            onDecline={onCloseModal}
          />
        );
      case 'editRecord': {
        return (
          <EditRecordModalContent
            title="Edit record"
            subtitle="Required fields -"
            acceptButton="Save record"
            declineButton="Cancel"
            record={modalStore.context.record}
            onAccept={(data: any) => {
              updateRecord(modalStore.context.record.id, data);
              onCloseModal();
            }}
            onDecline={onCloseModal}
          />
        );
      }
      case 'showComments':
        return <ShowRecordsCommentsModalContent title="Records comments" onDecline={onCloseModal} comments={modalStore.context.comments} />;
      case 'leaveComment':
        return (
          <LeaveCommentModalContent
            title="Add a comment"
            acceptButton="Save Comment"
            declineButton="Cancel"
            onAccept={(data: any) => {
              const { actionType, recordType } = modalStore?.context.context;
              if (recordType) {
                try {
                  companySolutionStore.changeStatusRecord({ [actionType]: true, comment: data }, recordType, () =>history.push('/records'));
                  onCloseModal();
                  
                } catch {
                  onCloseModal();
                }
              } else {
                const { typeAction, id } = modalStore?.context.context;
                try {
                  changeStatusRecord(id, typeAction, data);
                  onCloseModal();
                } catch {
                  onCloseModal();
                }
              }
            }}
            onDecline={onCloseModal}
          />
        );
      default:
        return null;
    }
  }, [modalStore.context.type]);

  const containerWidth = modalStore.context.type === 'showComments' ? '569' : undefined;

  return (
    <Overlay onClick={onCloseModal}>
      <Container width={containerWidth} onClick={(e) => e.stopPropagation()}>
        {renderModalContent()}
      </Container>
    </Overlay>
  );
});

export default Modal;
