import React, { useEffect } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import lottie from 'lottie-web';
import loader from '../../assets/start-logo.json';
import { StyledLoader } from './styles';

export const Loader = (): JSX.Element => {
  useEffect(() => {
    smoothscroll.polyfill();
    lottie.loadAnimation({
      container: document.querySelector('#loader') as Element,
      animationData: loader,
      name: 'start-logo',
    });
  }, []);

  return <StyledLoader id="loader" />;
};
