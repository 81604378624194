// Core
import styled from '@emotion/styled';
import Select from 'react-select';

export const StyledSelect = styled(Select)`
  & > div {
    padding: 0;
    height: 40px;
    background-color: #f2f4f8;
    border-radius: 6px;
    cursor: pointer;
    border: none;
    width: 97%;
    color: #777c98;
    font-size: 16px;
    text-transform: none;
    margin-bottom: 16px;
    div {
      margin-left: 5px;
    }
  }

  & div:nth-of-type(2) {
    align-items: flex-start;
    span {
      display: none;
    }
  }
  &.input-group {
    margin-top: 5px;
    width: 230px;
    margin-bottom: 0;
    flex-direction: row;
  }

  &.phoneCode {
    width: 120px;
    margin-top: 5px;
  }

  @media screen and (min-width: 320px) and (max-width: 740px) {
    width: 280px;
    font-size: 12px;
    & > div {
      width: 320px;
    }
  }
`;
export const colourStyles = {
  placeholder: (styles: any): void => ({
    ...styles,
    color: '#777c98',
    display: 'block',
  }),
  control: (styles: any, props: any): void => ({
    ...styles,
    color: '#ffffff',
    fontSize: '16px',
    border: '0px solid red',
    borderRadius: props.menuIsOpen ? '6px 6px 0px 0px' : '6px',
    boxShadow: 'none',
  }),
  option: (styles: any, { isFocused, isSelected }: any): void => ({
    ...styles,
    padding: '8px 0px',
    borderTop: '1px solid #F2F4F8',
    color: isFocused || isSelected ? 'rgba(252, 77, 154, 1)' : '#777C98',
    ':active': { ...styles[':active'], backgroundColor: '#f2f4f8' },
  }),
  singleValue: (styles: any): void => ({
    ...styles,
    color: '#1d2553',
    fontWeight: 400,
    fontSize: '16px',
  }),
  menu: (styles: any): void => ({
    ...styles,
    marginTop: 0,
    boxShadow: 'none',
    borderRadius: '0px 0px 6px 6px',
  }),
  menuList: (styles: any): void => ({
    ...styles,
    padding: '0px 0',
    backgroundColor: '#fff',
    flexWrap: 'wrap',
    width: '101%',
    transform: 'translateX(-2%)',
    overflowX: 'hidden',
    '@media only screen and (max-width: 740px)': {
      ...styles['@media only screen and (max-width: 740px)'],
      width: '320px',
    },
  }),
};
