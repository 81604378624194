import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Formik } from 'formik';

import TextArea from 'src/components/input/TextArea';
import { scrollToElement } from 'src/helpers/scroll-to-anchor'
import { displayDate } from 'src/helpers/display-date'

import { useInjection } from '../../core/inversify';
import { ModalStore } from '../../stores/ModalStore';

// Store
import { CompanyStore } from '../../stores/Company&SolutionStore';

// Utils
import { EmailExp } from '../../components/utils';

// Components
import { Loader } from '../../components/loader';
import ErrorBox from '../../components/errorBox';
import Layout from '../../components/Layout';
import { FormComponent } from './formComponent';
import { SelectMultiple, Items, SelectItem } from '../../components/input/SelectMultiple';
import { BtnPageScroll } from '../../components/BtnPageScroll';
import InternationalPhoneInput from '../../components/input/PhoneInput';
import { ButtonGroup } from '../../components/ButtonGroup';
import Modal from '../../components/modal';

// IMG and SVG
import backSvg from '../../assets/images/company/back.svg';
import mockupSvg from '../../assets/images/company/mockup.svg';
import imgSvg from '../../assets/images/company/img.svg';
import fileSvg from '../../assets/images/company/file.svg';
import deleteFileSvg from '../../assets/images/company/deleteFile.svg';

import * as helpers from './helpers'

// Types
import {
  FieldsNameDoesItCost,
  FieldsNameDoesItCostSource,
  FieldsNameMentions,
  FieldsNameMentionsSource,
  FieldsNameNotes,
  FieldsNameNotesSource,
  FieldsNameWhatItDoes,
  FieldsNameWhatItDoesSource,
  FieldsNameWhoUsesIt,
  FieldsNameWhoUsesItSource,
  FieldsSolutionName,
  FieldsSolutionNameSource,
  ITitleName,
} from './types';
import { IPutDataSolution, IActions } from '../../services/api/types';
import { RecordStatus } from '../../stores/types';

// Styles
import {
  PageHeader,
  StyledInput,
  StyledForm,
  StyledCommentWrapper,
  DisplayFlex,
  HeaderButton,
  WrapperInput,
  WrapperNote,
  StyledRecordAuditComponent,
  StyledPageFooter,
  WrapperInputData,
  StyledErr,
  StyledSelectMultiple,
} from './styles';
import { shortString } from '../../library/Utils';

let messageError = 'The brochure not be more than 50 mb';
let validationMessageError: string;

/**
 * TODO_: check all:
 *   "solution\?.solution\.(\w+)\.(\w+)."
 *   add endpoint with all that values.
 */
const Solution = observer(({ solutionSlug }: any): JSX.Element => {
  const history = useHistory();
  const pageRef = useRef<any>();
  const { setModalProps, getModalProps } = useInjection(ModalStore);
  const {
    //solution,
    getSolution,
    getIsLoading,
    getCompaniesList,
    companiesList,
    changeStatusRecord,
    putSolution,
    autoSaveSolution,
    getStatus,
    pushBrochures,
    getErrorMessage,
  } = useInjection(CompanyStore);

  const [solution, solutionSet] = useState<any>();
  const refTitle = useRef<HTMLInputElement>(null);
  const [valueSubCategories, _setValueSubCategories] = useState<Items[] | undefined>();
  const [isAutoSave, setIsAutoSave] = useState<boolean>(true);
  const [imgInputName, setImgInputName] = useState<string>('');
  const [brochureError, setBrochureError] = useState(false);
  const [logoValid, setLogoValid] = useState<boolean>(false);
  const [companyValid, setCompanyValid] = useState<boolean>(false);
  const [showErrorValid, setShowErrorValid] = useState<boolean>(false);
  const [pagePercent, setPagePercent] = useState<number>(0);
  const [brochureName, setBrochureName] = useState<any[]>([]);
  const [logoValue, setLogoValue] = useState<any>();
  const [pageRefValue] = useState<any>(pageRef);
  const imgInput = useRef<any>();
  const fileInput = useRef<any>();
  const [performedUpdate, performedUpdateSet] = useState<boolean>(false); // don't display "saved" on first load

  const solutionId = solution?.solution?.id;

  useEffect(() => {
    (async () => {
      solutionSet(await getSolution(solutionSlug))
    })()
  }, []);

  useEffect(() => {
    if (solution?.solution?.brochures) {
      setBrochureName(solution?.solution?.brochures?.items);
    }
  }, [solution?.solution?.brochures]);

  useEffect(() => {
    const onScroll = () => {
      const body = document.documentElement.scrollHeight;
      const currentOffset = document.documentElement.clientHeight;
      const height = body - currentOffset;
      const currentPosition = height - window.pageYOffset;
      const res = Math.round((currentPosition * 100) / height);
      setPagePercent(100 - res);
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [pageRefValue]);

  const countLines = () => {
    const el = refTitle?.current;
    const divHeight = el?.offsetHeight;
    if (divHeight && el && divHeight > 80) {
      el.style.fontSize = '18px';
      el.style.lineHeight = '16px';
    }
  };
  countLines();

  const encodeImageFileAsURL = (element: any): any => {
    const logo = element[0];
    const readerLogo = new FileReader();
    readerLogo.readAsDataURL(element[0]);
    readerLogo.onload = (e) => {
      e // don't know how it was used, function was empty
    };

    if (logo?.name?.includes('.svg') || logo?.name?.includes('.png') || logo?.name?.includes('.jpg') ) {
      logo.src = URL.createObjectURL(logo);
      const reader = new FileReader();
      reader.onloadend = function (e) {
        const image = new Image();
        image.src = e?.target?.result as any;
        image.onload = () => {
          const height = image?.height;
          const width = image?.width;
          if (height > 1000 || width > 1000) {
            messageError = 'Height and Width must not exceed 1000px.';
            imgInput.current.value = ''
            setBrochureError(true);
            setLogoValid(true)
            setTimeout(() => {
              setBrochureError(false);
              setLogoValid(false)

              logo.src = ""
              messageError = ''
            }, 7000);
          }  else {
            setLogoValue(reader.result);
           setImgInputName(element[0].name);
          }
        };
        
      };
      reader.readAsDataURL(logo);
    } else {
      messageError = 'Please upload valid file type: PNG, JPG or SVG';
      setBrochureError(true);
      setTimeout(() => {
        setBrochureError(false);
        messageError = ''
      }, 5000);
    }
  };

  const onLoadFile = (file: any) => {
    const newData = new FormData();
    newData.append('solution_id', solutionId);
    newData.append('file', file);
    if (file.size > 52428800) {
      messageError = "The brochure can't exceed 50 MB"
      setBrochureError(true);
      return setTimeout(() => {
        setBrochureError(false);
      }, 6000);
    }
    return pushBrochures(newData, solutionId, 'solution');
  };

  useEffect(() => {
    const message = getErrorMessage();
    if (message.includes('LogoBase64')) {
      messageError = 'The logo can`t exceed 1000 x 1000 px';
      setBrochureError(true);
      setTimeout(() => {
        setBrochureError(false);
      }, 7000);
    }
  }, [getErrorMessage()]);

  const checkingForm = (value: string, regExp: RegExp) => value && !value.match(regExp);

  const onSubmit = async (data: any, setFieldError: any) => {
    console.debug('submitting')
    const _selectItemsToStrings = (fieldName: string) => data[fieldName]?.map((el: SelectItem) => el?.value || el)

    const companyId = _selectItemsToStrings(FieldsSolutionName.selectCompanyToLinkWithSolution)?.[0]
    const title = data[FieldsSolutionName.solutionNameInTitle]
    let profilePhoneNumber = data[FieldsNameNotes.ownerPhone];
    const profilePhoneNumberInput = data[FieldsNameNotes.ownerPhone]?.replace(/\s/g, '')

    let fileLogo = solution?.solution.logo.value;
    if (logoValue) {
      fileLogo = logoValue;
      if (imgInput.current.files[0].size > 5242880) {
        setLogoValid(true);
        scrollToElement({ query: '#validationImg' });
      }
    }

    if (!companyId) {
      scrollToElement({ query: `#${ITitleName.COMPANY_NAME}` });
      setCompanyValid(true);
    }

    if (!title) {
      setFieldError(FieldsSolutionName.solutionNameInTitle, 'Please enter Solution Name');
    }

    if (profilePhoneNumberInput?.length < 10) {
      profilePhoneNumber = solution?.solution.catalisto_internal_screening_notes.vendor_profile_owner_telephone.value || '';
      setFieldError(FieldsNameNotes.ownerPhone, "Telephone number can't be less than 10 characters");
    }
    if (profilePhoneNumberInput?.length > 13) {
      profilePhoneNumber = solution?.solution.catalisto_internal_screening_notes.vendor_profile_owner_telephone.value || '';
      setFieldError(FieldsNameNotes.ownerPhone, "Telephone number can't exceed 13 characters");
    }
    console.debug('data: ', data)

    const solutionData = {
      brochures_id: brochureName?.map((el) => el?.id) || [],
      logo_base64: fileLogo,
      logo_base64_comment: data[FieldsNameNotesSource.logoSource],
      brochures_comment: data[FieldsNameNotesSource.brochuresSource],
      logo_file_name: imgInput?.current.files[0]?.name || '',
      video_links: data[FieldsNameWhatItDoes.youtubeLink],
      video_links_comment: data[FieldsNameWhatItDoesSource.youtubeLinkSource],
      company_id: companyId || solution?.solution?.company?.id,
      title: title || solution?.solution.title.value,
      title_comment: data[FieldsSolutionNameSource.solutionNameInTitleSource],
      master_categories_id: _selectItemsToStrings(FieldsNameWhatItDoes.categories),
      master_categories_comment: data[FieldsNameWhatItDoesSource.categoriesSource],
      slug: data[FieldsSolutionName.slug],
      comparison_reviews: data[FieldsNameMentions.comprassion],
      comparison_reviews_comment: data[FieldsNameMentionsSource.comprassionSource],
      technical_reviews: data[FieldsNameMentions.technical],
      technical_reviews_comment: data[FieldsNameMentionsSource.technicalSource],
      sub_categories_id: _selectItemsToStrings(FieldsNameWhatItDoes.subCategories),
      sub_categories_comment: data[FieldsNameWhatItDoesSource.subCategoriesSource],
      tags: data[FieldsNameWhatItDoes.otherCategories],
      tags_comment: data[FieldsNameWhatItDoesSource.otherCategoriesSource],
      short_description: data[FieldsNameWhatItDoes.shortDescription],
      short_description_comment: data[FieldsNameWhatItDoesSource.shortDescriptionSource],
      long_description: data[FieldsNameWhatItDoes.longDescription],
      long_description_comment: data[FieldsNameWhatItDoesSource.longDescriptionSource],
      key_differentiators: data[FieldsNameWhatItDoes.keyDifferentiators],
      key_differentiators_comment: data[FieldsNameWhatItDoesSource.keyDifferentiatorsSource],
      customer_industry_id: _selectItemsToStrings(FieldsNameWhoUsesIt.industry),
      customer_industry_comment: data[FieldsNameWhoUsesItSource.industrySource],
      customer_type_id: _selectItemsToStrings(FieldsNameWhoUsesIt.type),
      customer_type_comment: data[FieldsNameWhoUsesItSource.typeSource],
      customer_location_id: _selectItemsToStrings(FieldsNameWhoUsesIt.location),
      customer_location_comment: data[FieldsNameWhoUsesItSource.locationSource],
      customer_description: data[FieldsNameWhoUsesIt.customerDescription],
      customer_description_comment: data[FieldsNameWhoUsesItSource.customerDescriptionSource],
      customer_marquee: data[FieldsNameWhoUsesIt.marquee],
      customer_marquee_comment: data[FieldsNameWhoUsesItSource.marqueeSource],
      customer_user_group_id: _selectItemsToStrings(FieldsNameWhoUsesIt.group),
      customer_user_group_comment: data[FieldsNameWhoUsesItSource.groupSource],
      customer_languages_supported_id: _selectItemsToStrings(FieldsNameWhoUsesIt.languages),
      customer_languages_supported_comment: data[FieldsNameWhoUsesItSource.languagesSource],
      price_start: data[FieldsNameDoesItCost.starts],
      price_start_comment: data[FieldsNameDoesItCostSource.startsSource],
      price_to: data[FieldsNameDoesItCost.to],
      price_to_comment: data[FieldsNameDoesItCostSource.toSource],
      price_structure: data[FieldsNameDoesItCost.pricing],
      price_structure_comment: data[FieldsNameDoesItCostSource.pricingSource],
      return_investment: data[FieldsNameDoesItCost.return],
      return_investment_comment: data[FieldsNameDoesItCostSource.returnSource],
      awards: data[FieldsNameMentions.awards],
      awards_comment: data[FieldsNameMentionsSource.awardsSource],
      catalisto_notes_product: data[FieldsNameNotes.notesOnProduct],
      catalisto_notes_product_comment: data[FieldsNameNotesSource.notesOnProductSource],
      catalisto_notes_on_competitors: data[FieldsNameNotes.notesOnCompretitors],
      catalisto_notes_on_competitors_comment: data[FieldsNameNotesSource.notesOnCompretitorsSource],
      focus_on_in_next_interview: data[FieldsNameNotes.nextInterview],
      focus_on_in_next_interview_comment: data[FieldsNameNotesSource.nextInterviewSource],
      vendor_profile_owner_name: data[FieldsNameNotes.ownerName],
      vendor_profile_owner_name_comment: data[FieldsNameNotesSource.ownerNameSource],
      vendor_profile_owner_title: data[FieldsNameNotes.ownerTitle],
      vendor_profile_owner_title_comment: data[FieldsNameNotesSource.ownerTitleSource],
      vendor_profile_owner_email: checkingForm(data[FieldsNameNotes.ownerEmail], EmailExp) ? '' : data[FieldsNameNotes.ownerEmail],
      vendor_profile_owner_email_comment: data[FieldsNameNotesSource.ownerEmailSource],
      vendor_profile_owner_telephone: profilePhoneNumber,
      vendor_profile_owner_telephone_comment: data[FieldsNameNotesSource.ownerPhoneSource],
    } as IPutDataSolution;
    if (isAutoSave) {
      setIsAutoSave(true);
      return autoSaveSolution(solutionId, solutionData);
    }


    await putSolution(solutionId, solutionData)

    performedUpdateSet(true)
    setTimeout(() => performedUpdateSet(false), 3000)
  };

  const deleteSolution = () => {
    const companySolution = { id: solution?.solution.record_id, title: solution?.solution.title.value };
    setModalProps(true, { type: 'delete', companySolution });
  };

  const filteringBySelectedmasterCategories = (items: any, parentValues: any[]): Items[] => {
    const selectedParentId = parentValues?.map((el) => el?.value || el);
    const subCategoryItems = items?.filter((el: any) => {
      return selectedParentId?.includes(el?.parent_category) ? el : null;
    });
    return !parentValues?.length ? items : subCategoryItems;
  };

  if (!solution?.solution) {
    return (
      <>
        {/*<Layout pageRef={pageRefValue}>
            Loading
        </Layout>*/}
        <Loader />
      </>
    )
  }

  const renderFooter = () => {
    return (
      <StyledPageFooter>
        <StyledRecordAuditComponent>
          <h3>Record Audit</h3>
          <ul>
            <li>
              <div>
                Allocated:{' '}
                <p className="audite-date">
                  {solution?.solution?.history?.allocated && solution?.solution?.history?.allocated > 0
                    ? displayDate(solution?.solution.history.allocated)
                    : '-'}
                </p>
              </div>
            </li>
            <li>
              <div>
                Created:{' '}
                <p className="audite-date">
                  {solution?.solution?.history?.created_at && solution?.solution?.history?.created_at > 0
                    ? displayDate(solution?.solution.history.created_at)
                    : '-'}
                </p>
              </div>
            </li>
            <li>
              <div>
                {` Reviewed & Approved:`}{' '}
                <p className="audite-date">
                  {solution?.solution?.history?.approved && solution?.solution?.history?.approved > 0
                    ? displayDate(solution?.solution.history.approved)
                    : '-'}
                </p>
              </div>
            </li>
            <li>
              <div>
                Updated:{' '}
                <p className="audite-date">
                  {solution?.solution?.history?.updated_at && solution?.solution?.history?.updated_at > 0
                    ? displayDate(solution?.solution.history.updated_at)
                    : '-'}
                </p>
              </div>
            </li>
          </ul>
        </StyledRecordAuditComponent>
      </StyledPageFooter>
    );
  };
  const isLoading = getIsLoading();
  const logoImg = solution?.solution?.logo?.value || mockupSvg;

  console.debug('solution2: ', solution)
  console.log('companyValid: ', companyValid)
  console.log('companiesList: ', companiesList)
  const isDisabled =
    (solution?.solution.record_stage === RecordStatus.reviewRecordStage ||
      solution?.solution.record_stage === RecordStatus.completedRecordStage) &&
    !solution?.solution?.record_accept_actions?.length;
  console.log('isDisabled: ', isDisabled)
  // COMPANY_NAME

  console.log('company items: ', companiesList
                          ? companiesList.sort((a: any, b: any) => {
                              if (a.title < b.title) {
                                return -1;
                              }
                              if (a.title > b.title) {
                                return 1;
                              }
                              return 0;
                            })
                          : companiesList)
  console.log('default value: ', solution?.solution.company)

  const sortedComments = [...(solution?.solution?.comments || [])].sort((a, b) => b.created_at - a.created_at);
  const researcherAction = !!solution?.solution.record_accept_actions && solution?.solution.record_accept_actions?.length > 0;


  return (
    <>
      {performedUpdate && (getStatus() === 'OK') && <ErrorBox status="OK" text="Saved" />}
      {brochureError && <ErrorBox status="error" text={messageError} />}
      {showErrorValid && <ErrorBox status="error" text={validationMessageError} />}
      {isLoading && <Loader />}
      {getModalProps().isOpen && <Modal />}
      <BtnPageScroll currentPosition={pagePercent} goTop={() => scrollToElement({ toTop: true })} goBottom={() => scrollToElement({ toBottom: true })} />
      <Layout pageRef={pageRefValue} researcher={!researcherAction}>
        <PageHeader>
          <div className="header-wrapper">
            <HeaderButton type="button" onClick={() => history.push('/records')}>
              <img src={backSvg} alt="back" />
              <span> Back</span>
            </HeaderButton>
            <p className="header-title ">SOLUTION Record </p>
            <DisplayFlex>
              <img src={logoValue || logoImg} alt="company logo" />
              <div>
                <h2 ref={refTitle}>{solution?.solution?.title?.value || 'noname'}</h2>
                {solution?.solution?.company?.title && (
                  <p className="header-by__company">
                    by
                    <a href={`/company/${solution?.solution.company.slug}`}>
                      {solution?.solution.company.title}
                    </a>
                  </p>
                )}
              </div>
            </DisplayFlex>
          </div>
          {sortedComments?.length ? (
            <StyledCommentWrapper>
              <span className="header-date">{displayDate(sortedComments[0]?.created_at)}</span>
              <h3>{sortedComments[0]?.created_by_full_name}</h3>
              <p className="header-text">{shortString(sortedComments[0]?.body || '', 350)}</p>
              <p className="header-text--margin">
                {sortedComments.length || 0} {sortedComments.length === 1 ? 'comment' : 'comments'} available
              </p>
              <HeaderButton
                onClick={() => {
                  setModalProps(true, { type: 'showComments', comments: sortedComments });
                }}
                type="button"
              >
                <span>Show all comments</span>
              </HeaderButton>
            </StyledCommentWrapper>
          ) : null}
        </PageHeader>
        <Formik
          onSubmit={(values, { setFieldError }) => onSubmit(values, setFieldError)}
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{
            [FieldsSolutionName.selectCompanyToLinkWithSolution]: [],
            [FieldsSolutionName.solutionNameInTitle]: '',
            [FieldsSolutionName.slug]: '',

            [FieldsNameWhatItDoes.brochure]: '',
            [FieldsNameWhatItDoes.categories]: [],
            [FieldsNameWhatItDoes.subCategories]: [],
            [FieldsNameWhatItDoes.otherCategories]: [],
            [FieldsNameWhatItDoes.description]: '',
            [FieldsNameWhatItDoes.longDescription]: '',
            [FieldsNameWhatItDoes.keyDifferentiators]: '',
            [FieldsNameWhatItDoes.logoImg]: '',
            [FieldsNameWhatItDoes.youtubeLink]: '',

            [FieldsNameWhoUsesIt.industry]: [],
            [FieldsNameWhoUsesIt.type]: [],
            [FieldsNameWhoUsesIt.location]: [],
            [FieldsNameWhoUsesIt.customerDescription]: '',
            [FieldsNameWhoUsesIt.marquee]: '',
            [FieldsNameWhoUsesIt.languages]: [],
            [FieldsNameWhoUsesIt.productLanguages]: [],
            [FieldsNameWhoUsesIt.group]: [],

            [FieldsNameDoesItCost.starts]: '',
            [FieldsNameDoesItCost.to]: '',
            [FieldsNameDoesItCost.pricing]: '',
            [FieldsNameDoesItCost.return]: '',

            [FieldsNameMentions.awards]: '',
            [FieldsNameMentions.comprassion]: '',
            [FieldsNameMentions.technical]: '',

            [FieldsNameNotes.notesOnProduct]: '',
            [FieldsNameNotes.notesOnCompretitors]: '',
            [FieldsNameNotes.nextInterview]: '',
            [FieldsNameNotes.ownerName]: '',
            [FieldsNameNotes.ownerTitle]: '',
            [FieldsNameNotes.ownerEmail]: '',
            [FieldsNameNotes.ownerPhone]: '',
            [FieldsNameNotes.code]: '',

            [FieldsSolutionNameSource.selectCompanyToLinkWithSolutionSource]: '',
            [FieldsSolutionNameSource.solutionNameInTitleSource]: '',

            [FieldsNameWhatItDoesSource.brochureSource]: '',
            [FieldsNameWhatItDoesSource.categoriesSource]: '',
            [FieldsNameWhatItDoesSource.subCategoriesSource]: '',
            [FieldsNameWhatItDoesSource.otherCategoriesSource]: '',
            [FieldsNameWhatItDoesSource.descriptionSource]: '',
            [FieldsNameWhatItDoesSource.longDescriptionSource]: '',
            [FieldsNameWhatItDoesSource.keyDifferentiatorsSource]: '',
            [FieldsNameWhatItDoesSource.logoImgSource]: '',
            [FieldsNameWhatItDoesSource.youtubeLinkSource]: '',

            [FieldsNameWhoUsesItSource.industrySource]: '',
            [FieldsNameWhoUsesItSource.typeSource]: '',
            [FieldsNameWhoUsesItSource.locationSource]: '',
            [FieldsNameWhoUsesItSource.customerDescriptionSource]: '',
            [FieldsNameWhoUsesItSource.marqueeSource]: '',
            [FieldsNameWhoUsesItSource.languagesSource]: '',
            [FieldsNameWhoUsesItSource.productLanguagesSource]: '',
            [FieldsNameWhoUsesItSource.groupSource]: '',

            [FieldsNameDoesItCostSource.startsSource]: '',
            [FieldsNameDoesItCostSource.toSource]: '',
            [FieldsNameDoesItCostSource.pricingSource]: '',
            [FieldsNameDoesItCostSource.returnSource]: '',

            [FieldsNameMentionsSource.awardsSource]: '',
            [FieldsNameMentionsSource.comprassionSource]: '',
            [FieldsNameMentionsSource.technicalSource]: '',

            [FieldsNameNotesSource.notesOnProductSource]: '',
            [FieldsNameNotesSource.notesOnCompretitorsSource]: '',
            [FieldsNameNotesSource.nextInterviewSource]: '',
            [FieldsNameNotesSource.ownerNameSource]: '',
            [FieldsNameNotesSource.ownerTitleSource]: '',
            [FieldsNameNotesSource.ownerEmailSource]: '',
            [FieldsNameNotesSource.ownerPhoneSource]: '',
          }}
          enableReinitialize
        >
          {({ handleSubmit, setFieldValue, touched, errors, values }) => {
            setShowErrorValid(false);
            if (touched[FieldsSolutionName.selectCompanyToLinkWithSolution] && errors[FieldsSolutionName.selectCompanyToLinkWithSolution]) {
              validationMessageError = 'Please select Company';
              setShowErrorValid(true);
            }

            return (
              <StyledForm onSubmit={handleSubmit}>
                <ButtonGroup
                  modalAction={(type: any) =>
                    setModalProps(true, { type: 'leaveComment', context: { actionType: type, recordType: 'solution' } })
                  }
                  isPublished={solution?.solution.published}
                  statusRecord={solution?.solution.record_stage}
                  recordAcceptActions={solution?.solution.record_accept_actions}
                  onDelete={deleteSolution}
                  handleSubmit={() => {
                    setIsAutoSave(false);
                    handleSubmit();
                  }}
                  onAction={(statusActions: IActions) => changeStatusRecord(statusActions, 'solution')}
                />

                <FormComponent title={ITitleName.SOLUTION_NAME}>
                  <WrapperInput>
                    <label htmlFor={FieldsSolutionName.solutionNameInTitle}>Solution Name in Title:</label>
                    <StyledInput
                      maxCharacters={255}
                      isValid={touched[FieldsSolutionName.solutionNameInTitle] && errors[FieldsSolutionName.solutionNameInTitle]}
                      disabled={isDisabled}
                      defaultValue={solution?.solution.title.value || ''}
                      id={FieldsSolutionName.solutionNameInTitle}
                      name={FieldsSolutionName.solutionNameInTitle}
                      placeholder="Enter Solution Name"
                    />
                    {touched[FieldsSolutionName.solutionNameInTitle] && errors[FieldsSolutionName.solutionNameInTitle] ? (
                      <StyledErr>{errors[FieldsSolutionName.solutionNameInTitle]}</StyledErr>
                    ) : null}
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(solution?.solution.title.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsSolutionNameSource.solutionNameInTitleSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution.title.source || ''}
                          id={FieldsSolutionNameSource.solutionNameInTitleSource}
                          name={FieldsSolutionNameSource.solutionNameInTitleSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsSolutionName.slug}>Slug</label>
                    <StyledInput
                      maxCharacters={1000}
                      isValid={touched[FieldsSolutionName.slug] && errors[FieldsSolutionName.slug]}
                      disabled
                      defaultValue={solution?.solution.slug.value}
                      id={FieldsSolutionName.slug}
                      name={FieldsSolutionName.slug}
                      placeholder="Enter Slug"
                    />
                    {touched[FieldsSolutionName.slug] && errors[FieldsSolutionName.slug] ? (
                      <StyledErr>{errors[FieldsSolutionName.slug]}</StyledErr>
                    ) : null}
                    
                  </WrapperInput>
                </FormComponent>
                <FormComponent title={ITitleName.COMPANY_NAME} id={ITitleName.COMPANY_NAME}>
                  <WrapperInput onClick={() => setCompanyValid(false)}>
                    <label htmlFor={FieldsSolutionName.selectCompanyToLinkWithSolution}>Select Company to Link With Solution:</label>
                    <StyledSelectMultiple
                      disabled={isDisabled}
                      className="search"
                      onChange={setFieldValue}
                      onSearch={getCompaniesList}
                      selectionLimit
                      items={
                        companiesList
                          ? companiesList.sort((a: any, b: any) => {
                              if (a.title < b.title) {
                                return -1;
                              }
                              if (a.title > b.title) {
                                return 1;
                              }
                              return 0;
                            })
                          : companiesList
                      }
                      defaultSearchValue={solution?.solution.company}
                      id={FieldsSolutionName.selectCompanyToLinkWithSolution}
                      name={FieldsSolutionName.selectCompanyToLinkWithSolution}
                      placeholder=""
                    />
                    {companyValid ? <StyledErr>Select company</StyledErr> : null}
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(solution?.solution.logo.updated_at)}</span>
                        </p>
                      </div>
                    </WrapperNote>
                  </WrapperInput>
                </FormComponent>
                <FormComponent title={ITitleName.WHAT_IT_DOES}>
                  <WrapperInput>
                    <label htmlFor={FieldsNameWhatItDoes.categories}>Master Categories:</label>
                    <SelectMultiple
                      disabled={isDisabled}
                      defaultValue={solution?.solution.what_it_does.master_categories.ids}
                      onChange={setFieldValue}
                      items={solution?.vocabulary?.categories} // TODO_: get vocabulary from backend, add endpoint
                      id={FieldsNameWhatItDoes.categories}
                      name={FieldsNameWhatItDoes.categories}
                      placeholder="Select Categories"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(solution?.solution.what_it_does.master_categories.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameWhatItDoesSource.categoriesSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution.what_it_does.master_categories.source || ''}
                          id={FieldsNameWhatItDoesSource.categoriesSource}
                          name={FieldsNameWhatItDoesSource.categoriesSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameWhatItDoes.subCategories}>Subcategories:</label>
                    <SelectMultiple
                      disabled={isDisabled}
                      subCategory
                      defaultValue={solution?.solution.what_it_does.sub_categories.ids}
                      onChange={setFieldValue}
                      filteredItem={filteringBySelectedmasterCategories(
                        valueSubCategories || solution?.vocabulary?.categories, // TODO_: get vocabulary from backend, add endpoint
                        values[FieldsNameWhatItDoes.categories]
                      )}
                      items={valueSubCategories || solution?.vocabulary?.categories} // TODO_: get vocabulary from backend, add endpoint
                      id={FieldsNameWhatItDoes.subCategories}
                      name={FieldsNameWhatItDoes.subCategories}
                      placeholder="Select Subcategories"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(solution?.solution.what_it_does.sub_categories.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameWhatItDoesSource.subCategoriesSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution.what_it_does.sub_categories.source || ''}
                          id={FieldsNameWhatItDoesSource.subCategoriesSource}
                          name={FieldsNameWhatItDoesSource.subCategoriesSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameWhatItDoes.otherCategories}>Other Tags:</label>
                    <StyledInput
                      maxCharacters={2048}
                      disabled={isDisabled}
                      defaultValue={solution?.solution.what_it_does.tags.value}
                      onChange={setFieldValue}
                      id={FieldsNameWhatItDoes.otherCategories}
                      name={FieldsNameWhatItDoes.otherCategories}
                      placeholder="Enter Other Tags"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(solution?.solution?.what_it_does?.tags?.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameWhatItDoesSource.otherCategoriesSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution?.what_it_does?.tags?.source || ''}
                          id={FieldsNameWhatItDoesSource.otherCategoriesSource}
                          name={FieldsNameWhatItDoesSource.otherCategoriesSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameWhatItDoes.shortDescription}>Short Description:</label>
                    <StyledInput
                      maxCharacters={2048}
                      disabled={isDisabled}
                      defaultValue={solution?.solution.what_it_does?.short_description?.value || ''}
                      id={FieldsNameWhatItDoes.shortDescription}
                      name={FieldsNameWhatItDoes.shortDescription}
                      placeholder="Enter Short Description"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(solution?.solution?.what_it_does?.short_description?.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameWhatItDoesSource.shortDescriptionSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution?.what_it_does?.short_description?.source || ''}
                          id={FieldsNameWhatItDoesSource.shortDescriptionSource}
                          name={FieldsNameWhatItDoesSource.shortDescriptionSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameWhatItDoes.longDescription}>Long Description:</label>
                    <TextArea
                      maxCharacters={3000}
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={solution?.solution?.what_it_does?.long_description?.value}
                      id={FieldsNameWhatItDoes.longDescription}
                      name={FieldsNameWhatItDoes.longDescription}
                      placeholder="Enter Long Description"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(solution?.solution?.what_it_does?.long_description?.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameWhatItDoesSource.longDescriptionSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution?.what_it_does?.long_description?.source}
                          id={FieldsNameWhatItDoesSource.longDescriptionSource}
                          name={FieldsNameWhatItDoesSource.longDescriptionSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameWhatItDoes.keyDifferentiators}>Key Differentiators:</label>
                    <StyledInput
                      maxCharacters={2048}
                      disabled={isDisabled}
                      defaultValue={solution?.solution?.what_it_does?.key_differentations?.value}
                      id={FieldsNameWhatItDoes.keyDifferentiators}
                      name={FieldsNameWhatItDoes.keyDifferentiators}
                      placeholder="Enter Key Differentiators"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(solution?.solution?.what_it_does?.key_differentations?.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameWhatItDoesSource.keyDifferentiatorsSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution?.what_it_does?.key_differentations?.source || ''}
                          id={FieldsNameWhatItDoesSource.keyDifferentiatorsSource}
                          name={FieldsNameWhatItDoesSource.keyDifferentiatorsSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInputData id="validationImg">
                    <WrapperInput>
                      <label>
                        <img src={imgSvg} alt="img" />{' '}
                        <input
                          className="input-file"
                          onClick={() => setLogoValid(false)}
                          disabled={isDisabled}
                          id="inputLogo"
                          ref={imgInput}
                          onChange={(e) => {
                            if (e.target.files) {
                              encodeImageFileAsURL(e.target.files);
                            }
                          }}
                          name={FieldsNameWhatItDoes.logoImg}
                          accept=".png,.jpg,.svg"
                          type="file"
                          alt="img input"
                        />
                        <span>Upload logo</span>
                      </label>
                      {imgInputName && (
                        <div className="inputFileWrapper">
                          <p className="inputFile">{imgInputName}</p>
                          <button
                            onClick={() => {
                              setLogoValid(false);
                              if (imgInputName) {
                                setImgInputName('');
                                imgInput.current.value = '';
                              }
                            }}
                            type="button"
                            className="deleteFile"
                            aria-label="delete file"
                          >
                            <img className="inputFile--delete" src={deleteFileSvg} alt="delete ico" />
                          </button>
                        </div>
                      )}
                      <ul>
                        <li>PNG or JPG or SVG</li>
                        <li>1000x1000px</li>
                        <li>No More than 5 mb</li>
                      </ul>
                      <p className={logoValid ? 'logoValid' : 'none'}>{messageError}</p>
                      <WrapperNote className="sourceToFile">
                        <div className="date">
                          <p>
                            {' '}
                            Editing Date:<span>{displayDate(solution?.solution?.logo?.updated_at)}</span>
                          </p>
                        </div>
                        <WrapperInput>
                          <label htmlFor={FieldsNameNotesSource.logoSource}>Source </label>
                          <StyledInput
                            maxCharacters={1000}
                            disabled={isDisabled}
                            defaultValue={solution?.solution?.logo?.source}
                            id={FieldsNameNotesSource.logoSource}
                            name={FieldsNameNotesSource.logoSource}
                            placeholder="Enter Source"
                          />
                        </WrapperInput>
                      </WrapperNote>
                    </WrapperInput>
                    <WrapperInput>
                      <div>
                        <label>
                          <img src={fileSvg} alt="" />
                          <input
                            className="input-file"
                            disabled={isDisabled}
                            ref={fileInput}
                            onChange={(e) => {
                              if (e.target.files) {
                                onLoadFile(e.target.files[0]);
                              }
                            }}
                            name={FieldsNameWhatItDoes.brochure}
                            accept=".doc,.docx,.pdf,.ttf,.pttx"
                            type="file"
                            alt="img input"
                          />
                          <span>Upload product brochure</span>
                        </label>
                      </div>
                      <div className="brochureWrapper">
                        <ul>
                          <li>PDF,PTTX,DOC,TTF</li>
                          <li>No more than 50 mb</li>
                        </ul>
                        {brochureName?.length ? (
                          <ul className="inputFileWrapperList">
                            {brochureName.map(
                              (el) =>
                                el && (
                                  <li key={el?.id || brochureName.length + 1}>
                                    <p className="inputFile">{el.title}</p>
                                    <button
                                      onClick={() => {
                                        if (brochureName) {
                                          const res = brochureName.filter((name) => name.id !== el.id);
                                          setBrochureName(res);
                                          fileInput.current.value = '';
                                        }
                                      }}
                                      type="button"
                                      className="deleteFile"
                                      aria-label="delete file"
                                    >
                                      <img className="inputFile--delete" src={deleteFileSvg} alt="delete ico" />
                                    </button>
                                  </li>
                                )
                            )}
                          </ul>
                        ) : null}
                      </div>
                      <WrapperNote className="sourceToFile">
                        <div className="date">
                          <p>
                            {' '}
                            Editing Date:<span>{displayDate(solution?.solution?.brochures?.created_at)}</span>
                          </p>
                        </div>
                        <WrapperInput>
                          <label htmlFor={FieldsNameNotesSource.brochuresSource}>Source </label>
                          <StyledInput
                            maxCharacters={1000}
                            disabled={isDisabled}
                            defaultValue={solution?.solution?.brochures?.source}
                            id={FieldsNameNotesSource.brochuresSource}
                            name={FieldsNameNotesSource.brochuresSource}
                            placeholder="Enter Source"
                          />
                        </WrapperInput>
                      </WrapperNote>
                    </WrapperInput>
                  </WrapperInputData>
                  <WrapperInput>
                    <label htmlFor={FieldsNameWhatItDoes.youtubeLink}>Video Profiles:</label>
                    <StyledInput
                      maxCharacters={512}
                      disabled={isDisabled}
                      defaultValue={solution?.solution.what_it_does?.video_link?.value || ''}
                      id={FieldsNameWhatItDoes.youtubeLink}
                      name={FieldsNameWhatItDoes.youtubeLink}
                      placeholder="Enter Link"
                    />
                    {touched[FieldsNameWhatItDoes.youtubeLink] && errors[FieldsNameWhatItDoes.youtubeLink] ? (
                      <StyledErr>{errors[FieldsNameWhatItDoes.youtubeLink]}</StyledErr>
                    ) : null}
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(solution?.solution.what_it_does?.video_link?.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameWhatItDoesSource.youtubeLinkSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution.what_it_does?.video_link?.source || ''}
                          id={FieldsNameWhatItDoesSource.youtubeLinkSource}
                          name={FieldsNameWhatItDoesSource.youtubeLinkSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                </FormComponent>
                <FormComponent title={ITitleName.WHO_USES_IT}>
                  <WrapperInput>
                    <label htmlFor={FieldsNameWhoUsesIt.industry}>Customer Industry:</label>
                    <SelectMultiple
                      disabled={isDisabled}
                      defaultValue={solution?.solution?.who_uses_it?.customer_industry?.ids}
                      onChange={setFieldValue}
                      items={solution?.vocabulary?.customer_industry} // TODO_: get vocabulary from backend, add endpoint
                      id={FieldsNameWhoUsesIt.industry}
                      name={FieldsNameWhoUsesIt.industry}
                      placeholder="Select Industry"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(solution?.solution?.who_uses_it?.customer_industry?.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameWhoUsesItSource.industrySource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution?.who_uses_it?.customer_industry?.source || ''}
                          id={FieldsNameWhoUsesItSource.industrySource}
                          name={FieldsNameWhoUsesItSource.industrySource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameWhoUsesIt.type}>Customer Type:</label>
                    <SelectMultiple
                      disabled={isDisabled}
                      defaultValue={solution?.solution?.who_uses_it?.customer_type?.ids}
                      onChange={setFieldValue}
                      items={solution?.vocabulary?.customer_type} // TODO_: get vocabulary from backend, add endpoint
                      id={FieldsNameWhoUsesIt.type}
                      name={FieldsNameWhoUsesIt.type}
                      placeholder="Select Customer Type"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(solution?.solution?.who_uses_it?.customer_type?.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameWhoUsesItSource.typeSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution?.who_uses_it?.customer_type?.source || ''}
                          id={FieldsNameWhoUsesItSource.typeSource}
                          name={FieldsNameWhoUsesItSource.typeSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameWhoUsesIt.location}>Customer Location:</label>
                    <SelectMultiple
                      disabled={isDisabled}
                      defaultValue={solution?.solution?.who_uses_it?.customer_location?.ids}
                      onChange={setFieldValue}
                      items={solution?.vocabulary?.countries} // TODO_: get vocabulary from backend, add endpoint
                      id={FieldsNameWhoUsesIt.location}
                      name={FieldsNameWhoUsesIt.location}
                      placeholder="Select Customer Location"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(solution?.solution?.who_uses_it?.customer_location?.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameWhoUsesItSource.locationSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution.who_uses_it.customer_location.source || ''}
                          id={FieldsNameWhoUsesItSource.locationSource}
                          name={FieldsNameWhoUsesItSource.locationSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameWhoUsesIt.customerDescription}>Customer Description:</label>
                    <StyledInput
                      maxCharacters={2048}
                      disabled={isDisabled}
                      defaultValue={solution?.solution.who_uses_it.customer_description.value || ''}
                      id={FieldsNameWhoUsesIt.customerDescription}
                      name={FieldsNameWhoUsesIt.customerDescription}
                      placeholder="Enter Description"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(solution?.solution.who_uses_it.customer_description.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameWhoUsesItSource.customerDescriptionSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution.who_uses_it.customer_description.source || ''}
                          id={FieldsNameWhoUsesItSource.customerDescriptionSource}
                          name={FieldsNameWhoUsesItSource.customerDescriptionSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameWhoUsesIt.marquee}>Marquee Customers:</label>
                    <StyledInput
                      maxCharacters={2048}
                      disabled={isDisabled}
                      defaultValue={solution?.solution.who_uses_it.customer_marquee.value || ''}
                      id={FieldsNameWhoUsesIt.marquee}
                      name={FieldsNameWhoUsesIt.marquee}
                      placeholder="Enter Marquee Customers"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(solution?.solution.who_uses_it.customer_marquee.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameWhoUsesItSource.marqueeSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution.who_uses_it.customer_marquee.source || ''}
                          id={FieldsNameWhoUsesItSource.marqueeSource}
                          name={FieldsNameWhoUsesItSource.marqueeSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameWhoUsesIt.languages}>Customer Support Languages:</label>
                    <SelectMultiple
                      disabled={isDisabled}
                      defaultValue={solution?.solution.who_uses_it.customer_language.ids}
                      onChange={setFieldValue}
                      items={solution?.vocabulary?.customer_language} // TODO_: get vocabulary from backend, add endpoint
                      id={FieldsNameWhoUsesIt.languages}
                      name={FieldsNameWhoUsesIt.languages}
                      placeholder="Select Languages"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(solution?.solution.who_uses_it.customer_language.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameWhoUsesItSource.languagesSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution.who_uses_it.customer_language.source || ''}
                          id={FieldsNameWhoUsesItSource.languagesSource}
                          name={FieldsNameWhoUsesItSource.languagesSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameWhoUsesIt.productLanguages}>Product Languages Supported:</label>
                    <SelectMultiple
                      disabled={isDisabled}
                      defaultValue={solution?.solution.who_uses_it.customer_language.ids}
                      onChange={setFieldValue}
                      items={solution?.vocabulary?.customer_language} // TODO_: get vocabulary from backend, add endpoint
                      id={FieldsNameWhoUsesIt.productLanguages}
                      name={FieldsNameWhoUsesIt.productLanguages}
                      placeholder="Select Languages"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(solution?.solution.who_uses_it.customer_language.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameWhoUsesItSource.productLanguagesSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution.who_uses_it.customer_language.source || ''}
                          id={FieldsNameWhoUsesItSource.productLanguagesSource}
                          name={FieldsNameWhoUsesItSource.productLanguagesSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameWhoUsesIt.group}>Customer User Group (ie Buyer):</label>
                    <SelectMultiple
                      disabled={isDisabled}
                      defaultValue={solution?.solution.who_uses_it.customer_user_group.ids}
                      onChange={setFieldValue}
                      items={solution?.vocabulary?.customer_user_group} // TODO_: get vocabulary from backend, add endpoint
                      id={FieldsNameWhoUsesIt.group}
                      name={FieldsNameWhoUsesIt.group}
                      placeholder="Select Customer User Group"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(solution?.solution.who_uses_it.customer_user_group.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameWhoUsesItSource.groupSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution.who_uses_it.customer_user_group.source || ''}
                          id={FieldsNameWhoUsesItSource.groupSource}
                          name={FieldsNameWhoUsesItSource.groupSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                </FormComponent>
                <FormComponent title={ITitleName.WHAT_DOES_IT_COST}>
                  <WrapperInput>
                    <label htmlFor={FieldsNameDoesItCost.starts}>Starts From (per contract):</label>
                    <StyledInput
                      maxCharacters={255}
                      disabled={isDisabled}
                      defaultValue={solution?.solution.who_does_it_cost.price_from.value || ''}
                      id={FieldsNameDoesItCost.starts}
                      name={FieldsNameDoesItCost.starts}
                      placeholder="Enter Cost"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(solution?.solution.who_does_it_cost.price_from.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameDoesItCostSource.startsSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution.who_does_it_cost.price_from.source || ''}
                          id={FieldsNameDoesItCostSource.startsSource}
                          name={FieldsNameDoesItCostSource.startsSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameDoesItCost.to}>To (per contract):</label>
                    <StyledInput
                      maxCharacters={255}
                      disabled={isDisabled}
                      defaultValue={solution?.solution.who_does_it_cost.price_to.value || ''}
                      id={FieldsNameDoesItCost.to}
                      name={FieldsNameDoesItCost.to}
                      placeholder="Enter Cost"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(solution?.solution.who_does_it_cost.price_to.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameDoesItCostSource.toSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution.who_does_it_cost.price_to.source || ''}
                          id={FieldsNameDoesItCostSource.toSource}
                          name={FieldsNameDoesItCostSource.toSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameDoesItCost.pricing}>Pricing Structure Description:</label>
                    <StyledInput
                      maxCharacters={2048}
                      disabled={isDisabled}
                      defaultValue={solution?.solution.who_does_it_cost.price_structure.value || ''}
                      id={FieldsNameDoesItCost.pricing}
                      name={FieldsNameDoesItCost.pricing}
                      placeholder="Enter Description"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(solution?.solution.who_does_it_cost.price_structure.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameDoesItCostSource.pricingSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution.who_does_it_cost.price_structure.source || ''}
                          id={FieldsNameDoesItCostSource.pricingSource}
                          name={FieldsNameDoesItCostSource.pricingSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameDoesItCost.return}>Return on Investment:</label>
                    <StyledInput
                      maxCharacters={2048}
                      disabled={isDisabled}
                      defaultValue={solution?.solution.who_does_it_cost.return_investment.value || ''}
                      id={FieldsNameDoesItCost.return}
                      name={FieldsNameDoesItCost.return}
                      placeholder="Enter Description"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(solution?.solution.who_does_it_cost.return_investment.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameDoesItCostSource.returnSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution.who_does_it_cost.return_investment.source || ''}
                          id={FieldsNameDoesItCostSource.returnSource}
                          name={FieldsNameDoesItCostSource.returnSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                </FormComponent>
                <FormComponent title={ITitleName.SPECIAL_MENTIONS}>
                  <WrapperInput>
                    <label htmlFor={FieldsNameMentions.awards}>Awards:</label>
                    <TextArea
                      maxCharacters={3000}
                      disabled={isDisabled}
                      defaultValue={helpers.renderArrayToString(solution?.solution.special_mentions.awards?.values)}
                      id={FieldsNameMentions.awards}
                      name={FieldsNameMentions.awards}
                      placeholder="Enter Awards"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(solution?.solution.special_mentions.awards?.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameMentionsSource.awardsSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution.special_mentions.awards?.source || ''}
                          id={FieldsNameMentionsSource.awardsSource}
                          name={FieldsNameMentionsSource.awardsSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameMentions.comprassion}>Comparison Reviews:</label>
                    <TextArea
                      maxCharacters={2048}
                      disabled={isDisabled}
                      defaultValue={solution?.solution.special_mentions.comparison_reviews?.value || ''}
                      id={FieldsNameMentions.comprassion}
                      name={FieldsNameMentions.comprassion}
                      placeholder="Enter Reviews"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(solution?.solution.special_mentions.comparison_reviews?.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameMentionsSource.comprassionSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution.special_mentions.comparison_reviews?.source || ''}
                          id={FieldsNameMentionsSource.comprassionSource}
                          name={FieldsNameMentionsSource.comprassionSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameMentions.technical}>Technical Reviews:</label>
                    <TextArea
                      maxCharacters={2048}
                      disabled={isDisabled}
                      defaultValue={solution?.solution.special_mentions.technical_reviews?.value || ''}
                      id={FieldsNameMentions.technical}
                      name={FieldsNameMentions.technical}
                      placeholder="Enter Reviews"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(solution?.solution.special_mentions.technical_reviews?.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameMentionsSource.technicalSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution.special_mentions?.technical_reviews.source || ''}
                          id={FieldsNameMentionsSource.technicalSource}
                          name={FieldsNameMentionsSource.technicalSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                </FormComponent>
                <FormComponent title={ITitleName.NOTES_ON_PRODUCT}>
                  <WrapperInput>
                    <label htmlFor={FieldsNameNotes.notesOnProduct}>Catalisto notes on Product:</label>
                    <TextArea
                      maxCharacters={5000}
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={solution?.solution.catalisto_internal_screening_notes.notes_on_product.value || ''}
                      id={FieldsNameNotes.notesOnProduct}
                      name={FieldsNameNotes.notesOnProduct}
                      placeholder="Enter Notes"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(solution?.solution.catalisto_internal_screening_notes.notes_on_product.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameNotesSource.notesOnProductSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution.catalisto_internal_screening_notes.notes_on_product.source || ''}
                          id={FieldsNameNotesSource.notesOnProductSource}
                          name={FieldsNameNotesSource.notesOnProductSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameNotes.notesOnCompretitors}>Catalisto Notes on Competitors:</label>
                    <TextArea
                      maxCharacters={5000}
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={solution?.solution.catalisto_internal_screening_notes.notes_on_competitors.value}
                      id={FieldsNameNotes.notesOnCompretitors}
                      name={FieldsNameNotes.notesOnCompretitors}
                      placeholder="Enter Notes"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(solution?.solution.catalisto_internal_screening_notes.notes_on_competitors.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameNotesSource.notesOnCompretitorsSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution.catalisto_internal_screening_notes.notes_on_competitors.source || ''}
                          id={FieldsNameNotesSource.notesOnCompretitorsSource}
                          name={FieldsNameNotesSource.notesOnCompretitorsSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameNotes.nextInterview}>Notes To Focus On In Next Interview:</label>
                    <TextArea
                      maxCharacters={5000}
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={solution?.solution.catalisto_internal_screening_notes.focus_on_in_next_interview.value || ''}
                      id={FieldsNameNotes.nextInterview}
                      name={FieldsNameNotes.nextInterview}
                      placeholder="Enter Notes"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>
                            {displayDate(solution?.solution.catalisto_internal_screening_notes.focus_on_in_next_interview.updated_at)}
                          </span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameNotesSource.nextInterviewSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution.catalisto_internal_screening_notes.focus_on_in_next_interview.source || ''}
                          id={FieldsNameNotesSource.nextInterviewSource}
                          name={FieldsNameNotesSource.nextInterviewSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameNotes.ownerName}>Product Profile Owner: Name</label>
                    <StyledInput
                      maxCharacters={100}
                      disabled={isDisabled}
                      defaultValue={solution?.solution.catalisto_internal_screening_notes.vendor_profile_owner_name.value || ''}
                      id={FieldsNameNotes.ownerName}
                      name={FieldsNameNotes.ownerName}
                      placeholder="Enter Name"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>
                            {displayDate(solution?.solution.catalisto_internal_screening_notes.vendor_profile_owner_name.updated_at)}
                          </span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameNotesSource.ownerNameSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution.catalisto_internal_screening_notes.vendor_profile_owner_name.source || ''}
                          id={FieldsNameNotesSource.ownerNameSource}
                          name={FieldsNameNotesSource.ownerNameSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameNotes.ownerTitle}>Product Profile Owner: Title</label>
                    <StyledInput
                      maxCharacters={100}
                      disabled={isDisabled}
                      defaultValue={solution?.solution.catalisto_internal_screening_notes.vendor_profile_owner_title.value || ''}
                      id={FieldsNameNotes.ownerTitle}
                      name={FieldsNameNotes.ownerTitle}
                      placeholder="Enter Title"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>
                            {displayDate(solution?.solution.catalisto_internal_screening_notes.vendor_profile_owner_title.updated_at)}
                          </span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameNotesSource.ownerTitleSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution.catalisto_internal_screening_notes.vendor_profile_owner_title.source || ''}
                          id={FieldsNameNotesSource.ownerTitleSource}
                          name={FieldsNameNotesSource.ownerTitleSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameNotes.ownerEmail}>Product Profile Owner: Email</label>
                    <StyledInput
                      maxCharacters={100}
                      isValid={checkingForm(values[FieldsNameNotes.ownerEmail], EmailExp)}
                      disabled={isDisabled}
                      defaultValue={solution?.solution.catalisto_internal_screening_notes.vendor_profile_owner_email.value || ''}
                      id={FieldsNameNotes.ownerEmail}
                      name={FieldsNameNotes.ownerEmail}
                      placeholder="Enter Email"
                    />
                    {checkingForm(values[FieldsNameNotes.ownerEmail], EmailExp) ? (
                      <StyledErr>Please enter valid email address</StyledErr>
                    ) : null}
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>
                            {displayDate(solution?.solution.catalisto_internal_screening_notes.vendor_profile_owner_email.updated_at)}
                          </span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameNotesSource.ownerEmailSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution.catalisto_internal_screening_notes.vendor_profile_owner_email.source || ''}
                          id={FieldsNameNotesSource.ownerEmailSource}
                          name={FieldsNameNotesSource.ownerEmailSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameNotes.ownerPhone}>Product Profile Owner: Telephone</label>
                    <InternationalPhoneInput
                      disabled={isDisabled}
                      isValid={touched[FieldsNameNotes.ownerPhone] && errors[FieldsNameNotes.ownerPhone]}
                      defaultValue={solution?.solution.catalisto_internal_screening_notes.vendor_profile_owner_telephone.value}
                      id={FieldsNameNotes.ownerPhone}
                      name={FieldsNameNotes.ownerPhone}
                      placeholder="1-234-5678-90"
                    />
                    {touched[FieldsNameNotes.ownerPhone] && errors[FieldsNameNotes.ownerPhone] ? (
                      <StyledErr>{errors[FieldsNameNotes.ownerPhone]}</StyledErr>
                    ) : null}
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>
                            {displayDate(solution?.solution.catalisto_internal_screening_notes.vendor_profile_owner_telephone.updated_at)}
                          </span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameNotesSource.ownerPhoneSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={solution?.solution.catalisto_internal_screening_notes.vendor_profile_owner_telephone.source || ''}
                          id={FieldsNameNotesSource.ownerPhoneSource}
                          name={FieldsNameNotesSource.ownerPhoneSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                </FormComponent>

                <div className="wrapper-footer" id="bottom">
                  <ButtonGroup
                    modalAction={(type: any) =>
                      setModalProps(true, { type: 'leaveComment', context: { actionType: type, recordType: 'solution' } })
                    }
                    isPublished={solution?.solution.published}
                    statusRecord={solution?.solution.record_stage}
                    recordAcceptActions={solution?.solution.record_accept_actions}
                    onDelete={deleteSolution}
                    handleSubmit={() => {
                      setIsAutoSave(false);
                      handleSubmit();
                    }}
                    onAction={(statusActions: IActions) => changeStatusRecord(statusActions, 'solution')}
                  />
                  {renderFooter()}
                </div>
              </StyledForm>
            );
          }}
        </Formik>
        {getModalProps().isOpen && <Modal />}
      </Layout>
    </>
  );
});
export default Solution;
