import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useOuterClick from './useOuterClick';
import { StyledDropDoun, Wrapper } from './styles';

import arrowDownSvg from '../../assets/images/arrow-down.svg';
import avatarSvg from '../../assets/images/avatar.svg';

interface IProps {
  logout: () => void;
  fullName: string;
  role: string;
}

function UserDropdown({ logout, fullName, role }: IProps): JSX.Element {
  const [visible, setVisible] = useState(false);
  const innerRef = useOuterClick(() => {
    setVisible(false);
  });

  return (
    <Wrapper>
      <p className="role">{role}</p>
      <img src={avatarSvg} alt="avatar" />
      <StyledDropDoun ref={innerRef as any}>
        <button type="button" onClick={() => setVisible(!visible)}>
          <p>{fullName}</p>
          <img className="toggler" src={arrowDownSvg} alt="arrow down" />
        </button>
        <ul className={`menu ${visible ? 'menu_visible' : ''}`}>
          <li className="item">
            <Link to="/" onClick={logout} id="signOut">
              <span>sign out</span>
            </Link>
          </li>
        </ul>
      </StyledDropDoun>
    </Wrapper>
  );
}

export default UserDropdown;
