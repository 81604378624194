import { injectable } from 'inversify';
import { buildQuery, fetchWithTimeout } from '../../library/Utils';
import { TokenService } from './TokenService';
import {
  ILoginParam,
  IUserData,
  IOtpInfo,
  IReciveryCode,
  ISetupWebAuthn,
  IRegisterNewCredential,
  INewCredentialStatus,
  ICheckWebAuthn,
  ICheckWebAuthnVerify,
  ICompanyInfo,
  IPutDataCompany,
  ISolutionInfo,
  IPutDataSolution,
  ISearchCompany,
  IActions,
  ICheckToken,
} from './types';

enum METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

const tokenService = new TokenService();
const apiUrl = process.env.REACT_APP_API_URL;
const apiUrlV2 = process.env.REACT_APP_API_V2_URL;

@injectable()
export class ApiService {
  private csrfToken = tokenService.getCsrfToken();

  public loginUser = (loginParam: ILoginParam, jswToken?: string): Promise<IUserData> => {
    return this.request({
      action: 'account/login',
      method: METHOD.POST,
      params: { Authorization: jswToken || '' },
      formData: { ...loginParam },
      useV2Backend: true,
    });
  };

  public getOtpData = (jswToken: string): Promise<IOtpInfo> => {
    return this.request({
      action: 'account/setupOTP',
      method: METHOD.GET,
      params: { Authorization: jswToken },
    });
  };

  public setOtpCode = (code: string, jswToken: string): Promise<IReciveryCode> => {
    return this.request({
      action: 'account/setupOTP',
      method: METHOD.POST,
      params: { Authorization: jswToken },
      formData: { numbers: code },
    });
  };

  public mfaWebauthnRegisterBegin = (jswToken: string): Promise<ISetupWebAuthn> => {
    return this.request({
      action: 'account/setupWebAuthn',
      method: METHOD.GET,
      params: { Authorization: jswToken },
      useV2Backend: true,
    });
  };

  public mfaWebauthnRegisterComplete = (newCredential: IRegisterNewCredential, jswToken: string): Promise<INewCredentialStatus> => {
    return this.request({
      action: `account/setupWebAuthn`,
      method: METHOD.POST,
      params: { Authorization: jswToken },
      formData: { ...newCredential },
      useV2Backend: true,
    });
  };

  public mfaWebauthnEnterBegin = (jswToken: string): Promise<ICheckWebAuthn> => {
    return this.request({
      action: 'account/checkWebAuthn',
      method: METHOD.GET,
      params: { Authorization: jswToken },
      useV2Backend: true,
    });
  };

  public mfaWebauthnEnterComplete = (credential: any, jswToken: string): Promise<ICheckWebAuthnVerify> => {
    return this.request({
      action: 'account/checkWebAuthn',
      method: METHOD.POST,
      params: { Authorization: jswToken },
      formData: { ...credential },
      useV2Backend: true,
    });
  };

  public checkingAuthorization = (jswToken: string): Promise<INewCredentialStatus> => {
    return this.request({
      action: 'account/check',
      method: METHOD.GET,
      params: { Authorization: jswToken },
    });
  };

  public checkingAuthorizationToken = (): Promise<ICheckToken> => {
    return this.request({
      action: 'account/check',
      method: METHOD.GET,
    });
  };

  public getCompany = (slug: string): Promise<ICompanyInfo> => {
    return this.request({
      action: `companies/admin/${slug}`,
      method: METHOD.GET,
      useV2Backend: true,
    });
  };

  public putCompanyInfo = (id: string, data: IPutDataCompany): Promise<{ status: string; message?: string }> => {
    return this.request({
      action: `companies/admin/${id}`,
      method: METHOD.PUT,
      params: '',
      formData: data,
      useV2Backend: true,
    });
  };

  public getSolution = (slug: string): Promise<ISolutionInfo> => {
    return this.request({
      action: `solutions/admin/${slug}`,
      method: METHOD.GET,
      useV2Backend: true,
    });
  };

  public putSolutionInfo = (id: string, data: IPutDataSolution): Promise<{ status: string; message?: string }> => {
    return this.request({
      action: `solutions/admin/${id}`,
      method: METHOD.PUT,
      useV2Backend: true,
      params: '',
      formData: data,
    });
  };

  public getRecords = (queries: string): Promise<any> => {
    return this.request({
      action: `records?${queries}`,
      method: METHOD.GET,
      useV2Backend: true,
    });
  };

  public getCompanyList = (query: string): Promise<ISearchCompany> => {
    return this.request({
      action: `companies/?company_name=${query}`,
      method: METHOD.GET,
    });
  };

  public changeStatusRecord = (id: string, action: IActions): Promise<{ status: string }> => {
    return this.request({
      action: `record/${id}/status`,
      method: METHOD.PUT,
      params: '',
      formData: action,
    });
  };

  public createRecord = (data: any): Promise<any> => {
    return this.request({
      action: 'records',
      method: METHOD.POST,
      params: null,
      formData: { ...data },
      useV2Backend: true,
    });
  };

  public updateRecord = (recordId: string, data: any): Promise<any> => {
    return this.request({
      action: `records/${recordId}`,
      method: METHOD.PUT,
      params: null,
      formData: { ...data },
      useV2Backend: true,
    });
  };

  public deleteRecord = (recordId: string): Promise<any> => {
    return this.request({
      action: `records/${recordId}`,
      method: METHOD.DELETE,
      params: null,
      useV2Backend: true,
    });
  };

  public getCompanies = (company: string): Promise<any> => {
    return this.request({
      action: `companies-short?company_name=${company}`,
      method: METHOD.GET,
      useV2Backend: true,
    });
  };

  public getResearchers = (): Promise<any> => {
    return this.request({
      action: `users-short?role=researcher`,
      method: METHOD.GET,
      useV2Backend: true,
    });
  };

  public pustBrochures = (data: any): Promise<any> => {
    return this.request({
      action: `brochures`,
      method: METHOD.POST,
      params: null,
      formData: data,
      typeFile: true,
    });
  };

  private async request({
    action,
    method = METHOD.POST,
    params,
    formData,
    typeFile = false,
    useV2Backend = false,
  }: {
    action: string;
    method?: string;
    params?: any;
    formData?: any | FormData;
    typeFile?: boolean;
    useV2Backend?: boolean,
  }): Promise<any> {
    let url = (useV2Backend ? apiUrlV2 : apiUrl) + action;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const options: any = {
      method,
      headers: {
        'X-JWT': await tokenService.getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        'X-Csrf-Token': this.csrfToken || '',
        ...params,
      },
    };

    if (formData) {
      if (typeFile) {
        delete options.headers['Content-Type'];
        options.body = formData;
      } else {
        options.body = JSON.stringify(formData);
      }
    } else if (method === METHOD.GET && formData) {
      url = `${url}?${buildQuery(formData)}`;
    } else {
      options.body = JSON.stringify(formData);
    }

    return fetchWithTimeout(url, options, 15000)
      .then(async (response): Promise<any> => {
        let body = {};
        try {
          body = await response.json();
        } catch (e) {
          body = { error: `Server response with status - ${response.status}` };
        }
        return body;
      })
      .then((res) => res)
      .catch((error): any => {
        console.log('errorAPI', error);
        return {
          status: 0,
          statusText: 'failed',
          data: { error: error.message },
        };
      });
  }
}
