import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Calendar from 'react-range-calendar';

import * as types from 'src/services/api/types'

import {
  CompanyCell,
  Container,
  Dates,
  EmptyPageContainer,
  Row,
  StyledTable,
  Tab,
  HeadCell,
  StyledButton,
  StyledSelect,
  SelectStyles,
  BodyCell,
  CreateButton,
  DatePickerToggler,
  TableFilters,
  FilterStyles,
  FilterSelect,
  TooltipBtnActions,
  ClosedButton,
  PositionWrapper,
} from './styles';

import { formatDate } from '../../library/Utils';
import sortIcon from '../../assets/images/table/sort.svg';
import bodyCorpus from '../../assets/images/table/corpus.png';
import feedbackIcon from '../../assets/images/table/feedback.svg';
import editIcon from '../../assets/images/table/edit.svg';
import deleteIcon from '../../assets/images/table/delete.svg';
import startProgressSvg from '../../assets/images/company/startProgress.svg';
import thumbUpIcon from '../../assets/images/table/thumb-up.svg';
import thumbDownIcon from '../../assets/images/table/thumb-down.svg';
import uploadIcon from '../../assets/images/table/upload-cloud.svg';
import cancelUploadIcon from '../../assets/images/table/cancel-upload.svg';
import goBackIcon from '../../assets/images/table/go-back.svg';
import arrowDownIcon from '../../assets/images/table/arrow-down.svg';
import arrowUpIcon from '../../assets/images/table/arrow-up.svg';
import newTab from '../../assets/images/table/new-tab.svg';
import Select from '../../components/Select';
import { AuthStore } from '../../stores/AuthStore';
import { RecordsStore } from '../../stores/RecordsStore';
import { ActionsType } from '../../stores/types';
import { useInjection } from '../../core/inversify';
import { ModalStore } from '../../stores/ModalStore';
import Modal from '../../components/modal';
import { Loader } from '../../components/loader';
import { TooltipTemplate } from '../../components/Tooltip';
import Pagination from '../../components/Pagination';
import { Layout } from '../Dashboard/styles';
import ErrorBox from '../../components/errorBox';

import clsoseSvg from '../../assets/images/close.svg';

enum TabId { // correspond to keys from backend
  request = 'request',
  allocated = 'allocated',
  in_progress = 'in_progress',
  review = 'review',
  completed = 'completed',
}

const TabLabel = { // shown in UI
  [TabId.request]: 'request',
  [TabId.allocated]: 'allocated',
  [TabId.in_progress]: 'in progress',
  [TabId.review]: 'review',
  [TabId.completed]: 'completed',
}

type TableProps = {
  columns: Array<{ name: string; sortable: boolean; actions?: Array<{ icon: string }>; type: string }>;
  records: any;
  currentTabId: TabId;
};

type IEmptyPage = {
  type?: 'allocator' | 'researcher';
};

const defaultStageColors = [
  'linear-gradient(134.72deg, #FC4D9A 0%, #9C0745 101.5%)',
  'linear-gradient(30.58deg, #FF9500 5.94%, #FFBB00 98.99%)',
  'linear-gradient(134.72deg, #5A63D8 0%, #CD90E1 101.5%)',
  'linear-gradient(225deg, #73CCD8 0%, #2B6B9F 100%)',
  'linear-gradient(134.72deg, #6FFF00 0%, #005BFF 101.5%)',
];

const filtersByTable = {
  [TabId.request]: ['recordType', 'datepicker'],
  [TabId.allocated]: ['recordType', 'researcher', 'datepicker'],
  [TabId.in_progress]: ['recordType', 'researcher', 'datepicker'],
  [TabId.review]: ['recordType', 'researcher', 'datepicker'],
  [TabId.completed]: ['recordType', 'researcher', 'publish', 'datepicker'],
};

const columnsByTable = {
  [TabId.request]: [
    { type: 'history', name: 'Date of Creation', sortable: true },
    { type: 'company', name: 'company', sortable: true },
    { type: 'solution', name: 'solution', sortable: true },
    { type: 'researcher', name: 'researcher', sortable: false },
    {
      type: 'actions',
      name: 'actions',
      actions: [
        {
          icon: feedbackIcon,
          value: ActionsType.comment,
          name: 'Comment',
        },
        {
          icon: editIcon,
          value: ActionsType.edit,
          name: 'Edit',
        },
        {
          icon: deleteIcon,
          value: ActionsType.delete,
          name: 'Delete',
        },
      ],
      sortable: false,
    },
  ],
  [TabId.allocated]: [
    { type: 'history', name: 'date of allocation', sortable: true },
    { type: 'company', name: 'company', sortable: true },
    { type: 'solution', name: 'solution', sortable: true },
    { type: 'researcher', name: 'researcher', sortable: true },
    {
      type: 'actions',
      name: 'actions',
      actions: [
        {
          icon: feedbackIcon,
          value: ActionsType.comment,
          name: 'Comment',
        },
        {
          icon: startProgressSvg,
          value: ActionsType.startProgress,
          name: 'Start Progress',
        },
        {
          icon: editIcon,
          value: ActionsType.edit,
          name: 'Edit',
        },
        {
          icon: deleteIcon,
          value: ActionsType.delete,
          name: 'Delete',
        },
      ],
      sortable: false,
    },
  ],
  [TabId.in_progress]: [
    { type: 'history', name: 'date of allocation', sortable: true },
    { type: 'company', name: 'company', sortable: true },
    { type: 'solution', name: 'solution', sortable: true },
    { type: 'researcher', name: 'researcher', sortable: true },
    {
      type: 'actions',
      name: 'actions',
      actions: [
        {
          icon: feedbackIcon,
          value: ActionsType.comment,
          name: 'Comment',
        },
        {
          icon: startProgressSvg,
          value: ActionsType.review,
          text: 'Submit to Review',
        },
        {
          icon: editIcon,
          value: ActionsType.edit,
          name: 'Edit',
        },
        {
          icon: deleteIcon,
          value: ActionsType.delete,
          name: 'Delete',
        },
      ],
      sortable: false,
    },
  ],
  [TabId.review]: [
    { type: 'history', name: 'date', sortable: true },
    { type: 'company', name: 'company', sortable: true },
    { type: 'solution', name: 'solution', sortable: true },
    { type: 'researcher', name: 'researcher', sortable: true },
    {
      type: 'actions',
      name: 'actions',
      actions: [
        {
          icon: feedbackIcon,
          value: ActionsType.comment,
          name: 'Comment',
        },
        {
          icon: editIcon,
          value: ActionsType.edit,
          review: true,
          name: 'Edit',
        },
        {
          icon: deleteIcon,
          value: ActionsType.delete,
          name: 'Delete',
        },
        {
          icon: thumbUpIcon,
          value: ActionsType.approve,
          review: true,
          name: 'Approve',
        },
        {
          icon: thumbDownIcon,
          value: ActionsType.reject,
          review: true,
          name: 'Reject',
        },
        {
          icon: uploadIcon,
          value: ActionsType.publish,
          review: true,
          name: 'Publish',
        },
      ],
      sortable: false,
    },
  ],
  [TabId.completed]: [
    { type: 'history', name: 'date', sortable: true },
    { type: 'company', name: 'company', sortable: true },
    { type: 'solution', name: 'solution', sortable: true },
    { type: 'researcher', name: 'researcher', sortable: true },
    {
      type: 'actions',
      name: 'actions',
      actions: [
        {
          icon: feedbackIcon,
          value: ActionsType.comment,
          name: 'Comment',
        },
        {
          icon: goBackIcon,
          value: ActionsType.rework,
          review: true,
          name: 'Rework',
        },
        {
          icon: editIcon,
          value: ActionsType.edit,
          review: true,
          name: 'Edit',
        },
        {
          icon: deleteIcon,
          value: ActionsType.delete,
          name: 'Delete',
        },
        {
          icon: uploadIcon,
          value: ActionsType.publish,
          review: true,
          name: 'Publish',
        },
        {
          icon: cancelUploadIcon,
          value: ActionsType.unpublish,
          review: true,
          name: 'Unpublish',
        },
      ],
      sortable: false,
    },
  ],
};

const Actions = ({ actions, record }: any) => {
  const { setModalProps } = useInjection(ModalStore);
  const recordsStore = useInjection(RecordsStore);
  const authStore = useInjection(AuthStore);
  const isResearcher = authStore.getIsResearcher();

  const handleClick = (value: ActionsType) => {
    switch (value) {
      case ActionsType.edit:
        return setModalProps(true, { type: 'editRecord', record });
      case ActionsType.delete:
        return setModalProps(true, { type: 'deleteRecord', record });
      case ActionsType.comment:
        return setModalProps(true, { type: 'showComments', comments: record.comments });
      case ActionsType.rework:
        return setModalProps(true, { type: 'leaveComment', context: { id: record.id, typeAction: ActionsType.rework } });
      case ActionsType.reject:
        return setModalProps(true, { type: 'leaveComment', context: { id: record.id, typeAction: ActionsType.reject } });
      default:
        return recordsStore.changeStatusRecord(record.id, value);
    }
  };

  return (
    <BodyCell className="actions">
      {actions.map(({ icon, text, value, review, name }: any) => {
        const isDisabled = (): boolean => {
          switch (value) {
            case ActionsType.delete:
              return record.published;
            case ActionsType.rework:
              return record.published;
            case ActionsType.unpublish:
              return !record.published;
            case ActionsType.publish:
              return record.published;
            case ActionsType.edit:
              if (isResearcher && review) {
                return true;
              }
              return false;
            case ActionsType.comment:
              return !record.comments.length;
            default:
              return false;
          }
        };
        if (isResearcher && value === ActionsType.delete) {
          return null;
        }

        if (isResearcher && value === ActionsType.edit) {
          return null;
        }

        if (isResearcher && value === ActionsType.reject && review) {
          return null;
        }

        if (isResearcher && value === ActionsType.approve && review) {
          return null;
        }

        if (isResearcher && value === ActionsType.publish && review) {
          return null;
        }
        if (isResearcher && value === ActionsType.edit && review) {
          return null;
        }

        if (isResearcher && value === ActionsType.unpublish && review) {
          return null;
        }

        if (isResearcher && value === ActionsType.rework && review) {
          return null;
        }

        if ((!isResearcher && value === ActionsType.startProgress) || (!isResearcher && value === ActionsType.review)) {
          return null;
        }

        return (
          <StyledButton
            disabled={isDisabled()}
            key={icon}
            text={text}
            onClick={() => {
              handleClick(value);
            }}
          >
            <img src={icon} alt="action" />
            <TooltipBtnActions>{name}</TooltipBtnActions>
            {text && <span>{text}</span>}
          </StyledButton>
        );
      })}
    </BodyCell>
  );
};

const Date = ({ dates, index, currentTabId }: any): JSX.Element => {
  const renderGroupOfDates = () => {
    switch (currentTabId) {
      case TabId.request:
        return <>{dates.created_at && <div>{formatDate(dates.created_at)}</div>}</>;
      case TabId.allocated:
      case TabId.in_progress:
        return <>{dates.allocated && <div>{formatDate(dates.allocated)}</div>}</>;
      case TabId.review:
        return (
          <>
            <TooltipTemplate dates={dates} />
            {dates.allocated && <div>{formatDate(dates.allocated)}</div>}
            {dates.sent_to_review && <div>{formatDate(dates.send_to_review)}</div>}
          </>
        );
      case TabId.completed:
        return (
          <>
            <TooltipTemplate dates={dates} />
            {dates.allocated && <div>{formatDate(dates.allocated)}</div>}
            {dates.send_to_review && <div>{formatDate(dates.send_to_review)}</div>}
            {dates.approved && <div>{formatDate(dates.approved)}</div>}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <BodyCell className="date">
      <Dates content={(index + 1) as never /* something went wrong with this specifc typing */}>
        {renderGroupOfDates()}
      </Dates>
    </BodyCell>
  );
};

/* eslint-disable react/jsx-no-target-blank */

const Company = ({ data }: any): JSX.Element => (
  <BodyCell className="company">
    <CompanyCell withLink={Boolean(data.url)} href={`/company/${data?.slug}`}>
      {data.title}
      {data.url && (
        <a href={data.url} target="_blank">
          <button
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <img src={newTab} alt="new tab" />
          </button>
        </a>
      )}
    </CompanyCell>
  </BodyCell>
);

const Solution = ({ data }: any): JSX.Element => (
  <BodyCell className="solution">
    <CompanyCell withLink={Boolean(data?.url)} href={`/solution/${data?.slug}`}>
      {data?.title}
    </CompanyCell>
  </BodyCell>
);

const Researcher = observer(({ data }: any): JSX.Element => {
  const { getResearchers, updateRecord } = useInjection(RecordsStore);
  const [researcher, setResearcher] = useState<{ label: string; value: string } | null>(null);
  const researchers = (getResearchers() || []).map(({ full_name, id }: any) => ({ value: id, label: full_name }));
  const authStore = useInjection(AuthStore);
  const isResearcher = authStore.getIsResearcher();
  if (isResearcher) {
    return <>{null}</>;
  }
  return (
    <BodyCell className="researcher">
      {data.researcher?.full_name ? (
        <div>{data.researcher.full_name}</div>
      ) : (
        <Select
          onChange={(v: { value: string; label: string }) => {
            setResearcher(v);
            const updatedRecord = { company: data.company, researcher: { id: v.value } };
            updateRecord(data.id, updatedRecord);
          }}
          value={researcher}
          items={researchers}
          CustomSelect={StyledSelect}
          styles={SelectStyles}
          placeholder="add researcher"
        />
      )}
    </BodyCell>
  );
});

const renderCellContent = (column: any, record: any, index: number, currentTabId: TabId) => {
  switch (column.type) {
    case 'history':
      return <Date key={column.type} dates={record[column.type]} columnType={column.type} index={index} currentTabId={currentTabId} />;
    case 'company':
      return <Company key={column.type} data={record[column.type]} />;
    case 'solution': {
      return <Solution key={column.type} data={record[column.type]} />;
    }
    case 'researcher':
      return <Researcher key={column.type} data={record} />;
    case 'actions':
      return <Actions className="actions" key={column.type} actions={column.actions} record={record} />;
    default:
      return null;
  }
};

enum SortBy {
  date = 'date',
  dateReverse = 'date reverse',
  company = 'company',
  companyReverse = 'company reverse',
  solution = 'solution',
  solutionReverse = 'solution reverse',
  researcher = 'researcher',
  researcherReverse = 'researcher reverse',
}

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
function Table({ columns, records, currentTabId }: TableProps): JSX.Element {
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.date);
  const authStore = useInjection(AuthStore);
  const isResearcher = authStore.getIsResearcher();

  const { getPages, getlimitPerPage } = useInjection(RecordsStore);
  const page = getPages();
  const limitPage = getlimitPerPage();

  const sortFunction = (a = '', b = '') => {
    if (a === '') {
      return 1;
    }
    if (b === '') {
      return -1;
    }

    return a.toLowerCase().localeCompare(b.toLowerCase());
  };

  const sortByType = (items: any, key: SortBy): any => {
    switch (key) {
      case SortBy.dateReverse:
        return items.sort((a: any, b: any) => {
          if (currentTabId === TabId.request) {
            return a.history.created_at - b.history.created_at;
          }
          return a.history.allocated - b.history.allocated;
        });
      case SortBy.date:
        return items.sort((a: any, b: any) => {
          if (currentTabId === TabId.request) {
            return b.history.created_at - a.history.created_at;
          }

          return b.history.allocated - a.history.allocated;
        });
      case SortBy.company:
        return items.sort((a: any, b: any) => sortFunction(a.company.title, b.company.title));
      case SortBy.companyReverse:
        return items.sort((a: any, b: any) => sortFunction(b.company.title, a.company.title));
      case SortBy.solution:
        return items.sort((a: any, b: any) => sortFunction(a.solution.title, b.solution.title));
      case SortBy.solutionReverse:
        return items.sort((a: any, b: any) => sortFunction(b.solution.title, a.solution.title));
      case SortBy.researcher:
        return items.sort((a: any, b: any) => sortFunction(a.researcher?.full_name, b.researcher?.full_name));
      case SortBy.researcherReverse:
        return items.sort((a: any, b: any) => sortFunction(b.researcher?.full_name, a.researcher?.full_name));
      default:
        return items;
    }
  };

  const sortedRecords = sortByType(records, sortBy);

  return (
    <StyledTable>
      <Row>
        {columns.map(({ name, sortable, type }: any, index: number) => {
          if (isResearcher && type === 'researcher') {
            return null;
          }
          return (
            <HeadCell
              className={type === 'history' ? 'date' : type}
              key={name}
              sortable={sortable}
              style={{ background: defaultStageColors[index % 5] }}
            >
              <div className="contentContainer">
                <div>{name}</div>
                {sortable && (
                  <img
                    onClick={() => {
                      switch (type) {
                        case 'history':
                          if (sortBy === SortBy.date) {
                            setSortBy(SortBy.dateReverse);
                          } else {
                            setSortBy(SortBy.date);
                          }
                          break;
                        case 'company':
                          if (sortBy === SortBy.company) {
                            setSortBy(SortBy.companyReverse);
                          } else {
                            setSortBy(SortBy.company);
                          }
                          break;
                        case 'solution':
                          if (sortBy === SortBy.solution) {
                            setSortBy(SortBy.solutionReverse);
                          } else {
                            setSortBy(SortBy.solution);
                          }
                          break;
                        case 'researcher':
                          if (sortBy === SortBy.researcher) {
                            setSortBy(SortBy.researcherReverse);
                          } else {
                            setSortBy(SortBy.researcher);
                          }
                          break;
                        default:
                          setSortBy(SortBy.date);
                      }
                    }}
                    src={sortIcon}
                    alt="sort"
                  />
                )}
              </div>
              <div className="columnHorizontalLine" style={{ background: defaultStageColors[index % 5] }} />
            </HeadCell>
          );
        })}
      </Row>

      {sortedRecords
        .map((record: any, index: number) => {
          return (
            <Row className="bodyRow" key={record.id || index}>
              {columns.map((column: any) => {
                return renderCellContent(column, record, index, currentTabId);
              })}
            </Row>
          );
        })
        .slice(limitPage * (page - 1), limitPage * page)}
    </StyledTable>
  );
}

function EmptyPage({ type = 'allocator' }: IEmptyPage): JSX.Element {
  const { setModalProps } = useInjection(ModalStore);
  const authStore = useInjection(AuthStore);
  const isResearcher = authStore.getIsResearcher();
  return (
    <EmptyPageContainer>
      {type === 'allocator' ? (
        <>
          {!isResearcher && (
            <CreateButton onClick={() => setModalProps(true, { type: 'createRecord' })}>
              <img src={bodyCorpus} alt="corpus" />
              Create record
            </CreateButton>
          )}
          <div>
            <div className="emptyPageTitle">There is no records yet.</div>
            {!isResearcher && <div className="emptyPageSubtitle">Click the button to create</div>}
          </div>
        </>
      ) : (
        <div className="emptyPageTitle">There is no records yet.</div>
      )}
    </EmptyPageContainer>
  );
}

const TypedDate = window.Date as any;

const getTimestamp = (date: string) => {
  return new TypedDate(date).getTime() / 1000;
};

const Records = observer((): JSX.Element => {
  const {
    //setRecords,
    getRecords,
    fetchRecordsData,
    getIsLoading,
    setCurrentUrl,
    getResearchersData,
    getResearchers,
    setPages,
    getPages,
    getlimitPerPage,
    getShowNotification,
    searchValue,
    //setSearchRecords,
    //getSearchData,
    //searchResultData,
  } = useInjection(RecordsStore);
  const authStore = useInjection(AuthStore);
  const isResearcher = authStore.getIsResearcher();
  const [currentTabId, currentTabIdSet] = useState<TabId>(TabId.request);
  const [recordTypeFilter, setRecordTypeFilter] = useState<{ value: string; label: string } | null>(null);
  const [researcherFilter, setResearcherFilter] = useState<{ value: string; label: string } | null>(null);
  const [publishFilter, setPublishFilter] = useState<{ value: string; label: string } | null>(null);
  const [datepickerVisible, setDatepickerVisible] = useState(false);
  const [dateRange, setDateRange] = useState<{ range: string[]; set: boolean }>({
    range: [new TypedDate(), new TypedDate()],
    set: false,
  });

  const notification = getShowNotification();
  const pages = getPages();
  const limitPage = getlimitPerPage();

  const recordsData: types.RecordsListResponse = getRecords()
  const recordsCurrentTab = recordsData?.records?.[currentTabId] || []

  const filterTouched = Boolean(recordTypeFilter?.value || researcherFilter?.value || publishFilter?.value || dateRange.set);
  const roundedPage = Math.ceil(recordsCurrentTab?.length / limitPage);

  useEffect(() => {
    if (pages > roundedPage) {
      setPages(roundedPage);
    } else {
      setPages(1);
    }
  }, [roundedPage]);

  useEffect(() => {
    if (isResearcher) {
      currentTabIdSet(TabId.allocated);
    }
  }, [isResearcher]);

  const { getModalProps, setModalProps } = useInjection(ModalStore);

  const formUrl = (withoutFilter = false) => {
    if (withoutFilter) {
      return `category=${currentTabId}`;
    }

    const queryRecordType = recordTypeFilter?.value ? `filter_by=${recordTypeFilter?.value}` : '';
    const queryPublish =
      publishFilter?.value === 'published' || publishFilter?.value === 'unpublished' ? `published=${publishFilter?.value}` : '';
    const queryResearcher = researcherFilter?.value ? `filter_by_researcher_id=${researcherFilter.value}` : '';

    let queryDate = '';
    if (dateRange.set) {
      if (getTimestamp(dateRange.range[0]) - getTimestamp(dateRange.range[1]) === 0) {
        queryDate = `date_start=${getTimestamp(dateRange.range[0])}date_end=${getTimestamp(dateRange.range[1]) + 86400}`;
      } else {
        queryDate = `date_start=${getTimestamp(dateRange.range[0])}date_end=${getTimestamp(dateRange.range[1])}`;
      }
    }

    const queryTitle = searchValue ? `title=${searchValue}` : ''
    const queryCategory = searchValue ? '' : `category=${currentTabId}`  // search in all tabs / categories

    const query = [queryTitle, queryCategory, queryRecordType, queryPublish, queryDate, queryResearcher].filter(Boolean).join('&')
    return query
  };

  useEffect(() => {
    //setRecords({ ...recordsData, records: [] });
    if (!searchValue) {
      fetchRecordsData(formUrl());
      setCurrentUrl(formUrl());
    }
    setPages(1);
  }, [currentTabId]);

  useEffect(() => {
    (async () => {
      console.debug('search value changed: ', searchValue)
      const query = formUrl()
      console.debug('query: ', query)
      //await getSearchData(query)
      //setSearchRecords()
      await fetchRecordsData(query)
    })()
  }, [searchValue]);

  useEffect(() => {
    if (filterTouched) {
      fetchRecordsData(formUrl());
      setCurrentUrl(formUrl());
    }
  }, [publishFilter, recordTypeFilter, dateRange, researcherFilter]);

  useEffect(() => {
    getResearchersData();
  }, []);

  const isLoading = getIsLoading();
  const researchers = (getResearchers() || []).map(({ full_name, id }: any) => ({ value: id, label: full_name }));

  console.debug('currentTabId: ', currentTabId)
  console.debug('recordsCurrentTab: ', recordsCurrentTab)

  /* eslint-disable  jsx-a11y/no-static-element-interactions */

  const titleRef = useRef<HTMLHeadingElement>(null);
  const handleScrollClick = (e: any) => {
    e.current.scrollIntoView({ behavior: 'smooth' });
  };
  const tabIdsAll = Object.values(TabId)
  return (
    <>
      {notification.show && <ErrorBox status={notification.type as 'OK' | 'error'} text={notification.text} />}
      {getModalProps().isOpen && <Modal />}
      <Layout>
        <Container>
          <div className="title">Records</div>
          <div className="subtitle">Working panel</div>
          <div className="tabContainer">
            {tabIdsAll.map((tabId: TabId, index: number) => {
              if (isResearcher && (tabId === TabId.request)) {
                return null;
              }
              const tabLabel: string = (TabLabel as any)[tabId as any]
              return (
                <Tab active={currentTabId === tabId} key={tabId} tabIndex={index} onClick={() => currentTabIdSet(tabId)}>
                  {tabLabel}{' '}
                  <span>
                    {
                      recordsData?.records?.[tabId] &&
                      recordsData?.counts_per_category?.[tabId] ||
                      0
                    }
                  </span>
                </Tab>
              );
            })}
          </div>
          <div className="tableContainer" ref={titleRef}>
            <>
              {isLoading && <Loader />}
              {!recordsCurrentTab?.length && !filterTouched && !isLoading ? (
                <EmptyPage />
              ) : (
                <>
                  <div className="tableHelpers">
                    {!isResearcher && (
                      <CreateButton onClick={() => setModalProps(true, { type: 'createRecord' })}>
                        <img src={bodyCorpus} alt="corpus" />
                        Create record
                      </CreateButton>
                    )}
                    <TableFilters>
                      {filtersByTable[currentTabId].map((filter) => {
                        switch (filter) {
                          case 'recordType':
                            return (
                              <Select
                                key="recordType"
                                onChange={(v: { value: string; label: string }) => setRecordTypeFilter(v)}
                                value={recordTypeFilter}
                                items={[
                                  { value: 'all', label: 'all' },
                                  { value: 'company', label: 'company' },
                                  { value: 'solution', label: 'solution' },
                                ]}
                                CustomSelect={FilterSelect}
                                styles={FilterStyles}
                                placeholder="Record type"
                              />
                            );
                          case 'researcher':
                            if (isResearcher) {
                              return null;
                            }
                            return (
                              <PositionWrapper>
                                <Select
                                  key="researchers"
                                  onChange={(v: { value: string; label: string }) => setResearcherFilter(v)}
                                  value={researcherFilter}
                                  items={researchers}
                                  CustomSelect={FilterSelect}
                                  styles={FilterStyles}
                                  placeholder="Researcher"
                                />
                                {researcherFilter && (
                                  <ClosedButton
                                    onClick={() => {
                                      fetchRecordsData(formUrl(true));
                                      setResearcherFilter(null);
                                    }}
                                  >
                                    <img src={clsoseSvg} alt="" />
                                  </ClosedButton>
                                )}
                              </PositionWrapper>
                            );
                          case 'publish':
                            return (
                              <Select
                                key="publish"
                                onChange={(v: { value: string; label: string }) => setPublishFilter(v)}
                                value={publishFilter}
                                items={[
                                  { value: 'all', label: 'all' },
                                  { value: 'published', label: 'published' },
                                  { value: 'unpublished', label: 'unpublished' },
                                ]}
                                CustomSelect={FilterSelect}
                                styles={FilterStyles}
                                placeholder="Publish"
                              />
                            );
                          case 'datepicker':
                            return (
                              <>
                                <DatePickerToggler
                                  key="datepicker"
                                  onClick={() => {
                                    setDatepickerVisible((prev) => !prev);
                                  }}
                                >
                                  Date
                                  <img src={datepickerVisible ? arrowUpIcon : arrowDownIcon} alt="arrow" />
                                  <div
                                    className="datepicker"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <Calendar
                                      visible={datepickerVisible}
                                      dateRange={dateRange.range}
                                      onDateClick={(minDate: string, maxDate: string) => {
                                        setDateRange({ range: [minDate, maxDate], set: true });
                                        setPages(1);
                                        setTimeout(() => {
                                          setDatepickerVisible(false);
                                        }, 500);
                                      }}
                                      type="free-range"
                                    />
                                  </div>
                                  {dateRange.set && (
                                    <ClosedButton
                                      onClick={() => {
                                        fetchRecordsData(formUrl(true));
                                        setDateRange({ range: [new TypedDate(), new TypedDate()], set: false });
                                        setTimeout(() => {
                                          setDatepickerVisible(false);
                                        }, 500);
                                      }}
                                    >
                                      <img src={clsoseSvg} alt="" />
                                    </ClosedButton>
                                  )}
                                </DatePickerToggler>
                              </>
                            );
                          default:
                            return null;
                        }
                      })}
                    </TableFilters>
                  </div>
                  <Table columns={columnsByTable[currentTabId]} records={recordsCurrentTab} currentTabId={currentTabId} />
                </>
              )}
              {recordsData.records?.[currentTabId]?.length >= limitPage + 1 ? (
                <Pagination
                  pagesCount={roundedPage}
                  currentPage={pages}
                  onChangePage={(page: number) => {
                    setPages(page);
                    handleScrollClick(titleRef);
                  }}
                />
              ) : null}
            </>
          </div>
        </Container>
      </Layout>
    </>
  );
});

export default Records;
