import 'reflect-metadata';
//import { inject } from 'inversify';
import { injectable } from 'inversify';
import { IApplication } from './types';
// import { computed, makeObservable, when } from 'mobx';

@injectable()
export class Application implements IApplication {
  // constructor(
  // @inject(ApiService) public api: ApiService
  // ) {
  // makeObservable(this);
  // when(() => this.readyToInit, this.init);
  // }

  // @computed get readyToInit(): boolean {
  //   // ToDo check on some waitings for exampe LocalStorage init
  //   return false;
  // }

  private init = async (): Promise<void> => {};
}
