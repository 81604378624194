import styled from '@emotion/styled';

export const ButtonBoxStyled = styled.div<{ opacity: boolean }>`
  width: 530px;
  padding: 25px;
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 30px;
  ${({ opacity }) => opacity && 'opacity:0;'}

  button,a {
    width: 160px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f2f4f8;
    border: none;
    background-color: #f2f4f8;
    border-radius: 6px;
    color: #1d2553;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;

    & img {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }

  button[disabled] {
    opacity: 0.7;
    cursor: auto;
  }
`;
