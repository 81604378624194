import React from 'react';
import Button from '@material-ui/core/Button';
import { StyledWrapper } from './styles';

import arrowTop from '../../assets/images/company/arrowTop.svg';
import arrowBottom from '../../assets/images/company/arrowBottom.svg';

interface IProps {
  goTop: () => void;
  goBottom: () => void;
  currentPosition: number;
}

export const BtnPageScroll = ({ goTop, goBottom, currentPosition }: IProps): JSX.Element => {
  return (
    <StyledWrapper>
      <Button onClick={goTop}>
        <img src={arrowTop} alt="arrow top" />
      </Button>
      <p>{currentPosition >= 100 ? 100 : currentPosition} %</p>
      <Button onClick={goBottom}>
        <img src={arrowBottom} alt="arrow bottom" />
      </Button>
    </StyledWrapper>
  );
};
