/* eslint-disable prettier/prettier */
import styled from '@emotion/styled';

export const TooltipCustomize = styled.div`
  background-color: #fff;
  border-radius: 6px;
  padding: 12px 8px 12px 28px;
  text-transform: capitalize;
  font-size: 14px;

  ul {
    display: block;
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  li {
    margin-bottom: 10px;
    &::marker {
      color: #2b6b9f;
    }

    &:first-of-type::marker {
      color: #9c0745;
    }
    &:last-of-type::marker {
      color: #438a0c;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    h3 {
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      color: #1d2553;
    }

    p {
      margin: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      color: #777c98;
    }
  }
`;
