import React from 'react';
import SideBar from '../SideBar';
import { StyledLayout } from './styles';

interface IProps {
  children: any;
  pageRef?: any;
  researcher?: boolean;
}

const Layout = ({ children, pageRef, researcher }: IProps): JSX.Element => {
  return (
    <StyledLayout>
      <SideBar />
      <div style={{ flex: '1', marginBottom: `${researcher ? '110px' : '0'}` }} ref={pageRef}>
        {children}
      </div>
    </StyledLayout>
  );
};

export default Layout;
