import { RecordStatus, ActionsType } from '../../stores/types';

export interface ISignInInfo {
  status: string;
  csrf_token: string;
}

export interface IFetch {
  status: string;
  csrf_token: string;
}

export interface ILoginParam {
  email: string;
  hash_password: string;
  otp?: string;
  otp_recovery?: string;
}

export interface IActions {
  approve?: boolean;
  reject?: boolean;
  publish?: boolean;
  unpublish?: boolean;
  rework?: boolean;
  comment?: string;
  review?: boolean;
  start_progress?: boolean;
}

export enum Roles {
  // TODO_: use in IUser
  RESEARCHER = "researcher",
  ALLOCATOR = "allocator",
}

export interface DataShort {
  id: string;
  slug: string;
  title: string;
  url: string;
}

export interface ISearchCompany {
  status?: string;
  message?: string;
  companies: [
    {
      id: string;
      name: string;
    }
  ];
}

export interface ICheckToken {
  status?: string;
  token: string;
  need_step: [[string]];
  message?: string;
}

export interface IUserData {
  status: string;
  message?: string;
  token?: string;
  user?: IUser;
  need_step?: [string];
}

export interface IUser {
  email: string;
  first_name: string;
  last_name: string;
  roles: [string];
  hash_password?: string;
}

export interface IOtpInfo {
  csrf_token: string;
  qr_code_base64: string;
  manual_code: string;
  message?: string;
  status?: string;
}

export interface IReciveryCode {
  status: string;
  message: string;
  recovery_codes: Array<string>;
}

export interface ISetupWebAuthn {
  publicKey: {
    challenge: any;
    rp: {
      name: any;
      id: any;
    };
    user: any;
    pubKeyCredParams: [
      {
        alg: number;
        type: any;
      }
    ];
    authenticatorSelection: {
      requireResidentKey: boolean;
      userVerification: any;
    };
    timeout: number;
  };
  token: string;
  status?: string;
  message?: string;
}

export interface ICheckWebAuthn {
  publicKey: {
    challenge: string;
    timeout: number;
    rpId: string;
    allowCredentials: any;
  };
  token: string;
  user: any;
  status?: string;
  message?: string;
}

export interface ICheckWebAuthnVerify {
  status: string;
  message: string;
  token?: string;
  user?: IUser;
}

export interface IRegisterNewCredential {
  id: string;
  rawId: string;
  type: string;
  keyName: string;
  token: string;
  response: {
    attestationObject: string;
    clientDataJSON: string;
  };
  status?: string;
  message?: string;
}

export interface INewCredentialStatus {
  status: string;
  message: string;
  keys?: [{ name: string }];
  token?: string;
  user?: IUser;
}

export enum ErrorMessage {
  wrongEmailOrPAssword = 'Invalid email address or password',
  wrongPasscode = 'wrong passcode',
  wrongRecoveryCode = 'Wrong recovery code',
}

export enum ITextError {
  wrongEmailOrPAssword = 'Invalid email address or password',
  somethingWentWrong = 'Something went wrong',
  wrongPasscode = 'You have entered an Invalid code',
  wrongRecoveryCode = '',
}

export enum IStatus {
  operationSuccessful = 'operation successful',
}

export enum INeedStep {
  START = 'step_one',
  OTP = 'enter_otp',
  QR = 'setup_otp',
  KEY_ENTER = 'webauthn_enter',
  KEY_SETUP = 'webauthn_setup',
  ADD_KEY = 'add_key',
  DONE = 'done',
  AUTHORIZED = 'authorized',
  KEY_LIST = 'key_list',
  RECOVERY = 'Recovery',
}

export interface ICompanyInfo {
  status?: string;
  message?: string;
  company: {
    brochures: {
      created_at: number;
      source: string;
      items: {
          id: string;
          title: string;
        }[];
    };
    slug: {
      source: string;
      updated_at: number;
      value: string;
    };
    id: string;
    record_id: string;
    published: boolean;
    record_stage: RecordStatus;
    record_accept_actions: ActionsType[] | null;
    history: {
      updated_at: number;
      created_at: number;
      approved: number;
      allocated: number;
    };
    comments: [
      {
        id: string;
        created_at: number;
        created_by_id: string;
        created_by_full_name: string;
        body: string;
      }
    ];
    solutions: {
        id: string;
        slug: string;
        title: string;
    }[];
    main_info: {
      legal_name: {
        value: string;
        updated_at: number;
        source: string;
      };

      name: {
        value: string;
        updated_at: number;
        source: string;
      };

      trading_as: {
        value: string;
        updated_at: number;
        source: string;
      };
      founded_date: {
        value: string;
        updated_at: number;
        source: string;
      };
      incorporated_in: {
        id: string;
        updated_at: number;
        source: string;
      };
      incorporated_in_state: {
        value: string;
        updated_at: number;
        source: string;
      };
      operation_status: {
        id: string;
        updated_at: number;
        source: string;
      };
    };
    contacts: {
      head_office_address_line_1: {
        value: string;
        updated_at: number;
        source: string;
      };
      head_office_address_line_2: {
        value: string;
        updated_at: number;
        source: string;
      };
      head_office_city: {
        value: string;
        updated_at: number;
        source: string;
      };
      head_office_country: {
        id: string;
        updated_at: number;
        source: string;
      };
      head_office_state: {
        value: string;
        updated_at: number;
        source: string;
      };
      head_office_zip: {
        value: string;
        updated_at: number;
        source: string;
      };
      head_office_phone: {
        value: string;
        updated_at: number;
        source: string;
      };
      head_office_website: {
        value: string;
        updated_at: number;
        source: string;
      };
      head_office_email: {
        value: string;
        updated_at: number;
        source: string;
      };
      linkedin: {
        value: string;
        updated_at: number;
        source: string;
      };
      twitter: {
        value: string;
        updated_at: number;
        source: string;
      };
      facebook: {
        value: string;
        updated_at: number;
        source: string;
      };
      youtube: {
        value: string;
        updated_at: number;
        source: string;
      };
      other_offices_number: {
        value: string;
        updated_at: number;
        source: string;
      };
      other_offices: [
        {
          city: {
            value: string;
            updated_at: number;
            source: string;
          };
          country: {
            id: string;
            updated_at: number;
            source: string;
          };
          location: {
            value: string;
            updated_at: number;
            source: string;
          };
        }
      ];
    };
    what_whey_do: {
      video_links: {
        source: string;
        updated_at: number;
        value: string;
      };
      master_categories: {
        values: [string];
        ids: [string];
        updated_at: number;
        source: string;
      };
      sub_categories: {
        values: [string];
        ids: [string];
        updated_at: number;
        source: string;
      };
      tags: {
        value: string;
        updated_at: number;
        source: string;
      };
      short_description: {
        value: string;
        updated_at: number;
        source: string;
      };
      long_description: {
        value: string;
        updated_at: number;
        source: string;
      };
      revenue_mix: {
        value: string;
        updated_at: number;
        source: string;
      };
    };
    logo: {
      value: string;
      updated_at: number;
      source: string;
    };
    who_they_do_it_for: {
      custumer_industry: {
        values: [string];
        ids: [string];
        updated_at: number;
        source: string;
      };
      customer_type: {
        values: [string];
        ids: [string];
        updated_at: number;
        source: string;
      };
      customer_size_employees: {
        values: [string];
        ids: [string];
        updated_at: number;
        source: string;
      };
      customer_size_reveneues: {
        values: [string];
        ids: [string];
        updated_at: number;
        source: string;
      };
      customer_location: {
        values: [string];
        ids: [string];
        updated_at: number;
        source: string;
      };
      customer_description: {
        value: string;
        updated_at: number;
        source: string;
      };
      customer_marquee: {
        value: string;
        updated_at: number;
        source: string;
      };
      customer_user_group: {
        values: [string];
        ids: [string];
        updated_at: number;
        source: string;
      };
      customer_langueages_supported: {
        values: [string];
        ids: [string];
        updated_at: number;
        source: string;
      };
    };
    what_stage_are_they_at: {
      private_public: {
        id: string;
        updated_at: number;
        source: string;
      };
      public_company_exchange: {
        value: string;
        updated_at: number;
        source: string;
      };
      public_company_ticker: {
        value: string;
        updated_at: number;
        source: string;
      };
      public_company_capital_raised: {
        value: string;
        updated_at: number;
        source: string;
      };
      public_company_last_founding_round: {
        value: string;
        updated_at: number;
        source: string;
      };
      company_phase: {
        id: string;
        updated_at: number;
        source: string;
      };
      company_org_size_employees: {
        id: string;
        updated_at: number;
        source: string;
      };
      company_org_size_revenues: {
        id: string;
        updated_at: number;
        source: string;
      };
      company_org_size_ebitda: {
        id: string;
        updated_at: number;
        source: string;
      };
    };
    who_has_an_interest: {
      ceo: {
        value: string;
        updated_at: number;
        source: string;
      };
      board_members: {
        value: string;
        updated_at: number;
        source: string;
      };
      controlling_investors: {
        value: string;
        updated_at: number;
        source: string;
      };
      investor_nationality: {
        ids: string;
        updated_at: number;
        source: string;
      };
      original_founders: {
        value: string;
        updated_at: number;
        source: string;
      };
    };
    special_mentions: {
      mentors: {
        value: string;
        updated_at: number;
        source: string;
      };
      mentees: {
        value: string;
        updated_at: number;
        source: string;
      };
      awards: {
        values: string[];
        updated_at: number;
        source: string;
      };
      graduated_from: {
        values: string[];
        updated_at: number;
        source: string;
      };
      industry_memberships: {
        values: string[];
        updated_at: number;
        source: string;
      };
      special_classes: {
        values: [string];
        ids: [string];
        updated_at: number;
        source: string;
      };
    };
    compliance_and_risk: {
      audited_finances: {
        id: string;
        updated_at: number;
        source: string;
      };
      compliance_audits: {
        id: string;
        updated_at: number;
        source: string;
      };
      security_standards: {
        values: [string];
        ids: [string];
        updated_at: number;
        source: string;
      };
    };
    catalisto_internal_screening_notes: {
      company_would_like_to_connect_with: {
        values: [string];
        ids: [string];
        updated_at: number;
        source: string;
      };
      catalisto_notes_on_managment: {
        value: string;
        updated_at: number;
        source: string;
      };
      sustainability_risk_of_vendor: {
        id: string;
        updated_at: number;
        source: string;
      };
      focus_on_in_next_interview: {
        value: string;
        updated_at: number;
        source: string;
      };
      vendor_profile_owner_name: {
        value: string;
        updated_at: number;
        source: string;
      };
      vendor_profile_owner_title: {
        value: string;
        updated_at: number;
        source: string;
      };
      vendor_profile_owner_email: {
        value: string;
        updated_at: number;
        source: string;
      };
      vendor_profile_owner_telephone: {
        value: string;
        updated_at: number;
        source: string;
      };
    };
  };
  vocabulary: {
    categories: [
      {
        id: string;
        title: string;
        parent_id: string;
      }
    ];
    countries: [
      {
        id: string;
        title: string;
      }
    ];
    operating_status: [
      {
        id: string;
        title: string;
      }
    ];
    customer_industry: [
      {
        id: string;
        title: string;
      }
    ];
    customer_type: [
      {
        id: string;
        title: string;
      }
    ];
    customer_size_employees: [
      {
        id: string;
        title: string;
      }
    ];
    customer_size_revenues: [
      {
        id: string;
        title: string;
      }
    ];
    customer_user_group: [
      {
        id: string;
        title: string;
      }
    ];
    customer_language: [
      {
        id: string;
        title: string;
      }
    ];
    vendor_type: [
      {
        id: string;
        title: string;
      }
    ];
    company_phase: [
      {
        id: string;
        title: string;
      }
    ];
    org_size_employees: [
      {
        id: string;
        title: string;
      }
    ];
    org_size_revenues: [
      {
        id: string;
        title: string;
      }
    ];
    ebitda: [
      {
        id: string;
        title: string;
      }
    ];
    nationality: [
      {
        id: string;
        title: string;
      }
    ];
    special_classes: [
      {
        id: string;
        title: string;
      }
    ];
    compliance_audit: [
      {
        id: string;
        title: string;
      }
    ];
    security_standards: [
      {
        id: string;
        title: string;
      }
    ];
    vendor_sustainability: [
      {
        id: string;
        title: string;
      }
    ];
    vendor_interests: [
      {
        id: string;
        title: string;
      }
    ];
  };
}

interface IOtherOffice {
  city: string;
  location: string;
  country_id: string;
}

export interface IPutDataCompany {
  // Identifiers
  name: string;
  name_comment: string;
  slug: string;
  // Main info
  legal_name: string;
  legal_name_comment: string;
  trading_as: string;
  trading_as_comment: string;
  logo_file_name: string;
  incorporated_in_state: string;
  incorporated_in_state_comment: string;
  founded_date: number;
  founded_date_comment: string;
  incorporated_in_id: string;
  incorporated_in_comment: string;
  operation_status_id: string;
  operation_status_comment: string;
  head_office_address_line_1: string;
  head_office_address_line_1_comment: string;
  head_office_address_line_2: string;
  head_office_address_line_2_comment: string;
  head_office_city: string;
  head_office_city_comment: string;
  head_office_country_id: string;
  head_office_country_comment: string;
  head_office_state: string;
  head_office_state_comment: string;
  head_office_zip: string;
  head_office_zip_comment: string;
  head_office_phone: string;
  head_office_phone_comment: string;
  head_office_website: string;
  head_office_website_comment: string;
  head_office_email: string;
  head_office_email_comment: string;
  linkedin: string;
  linkedin_comment: string;
  twitter: string;
  twitter_comment: string;
  facebook: string;
  facebook_comment: string;
  youtube: string;
  youtube_comment: string;
  other_offices_number: number;
  other_offices_number_comment: string;
  other_offices: IOtherOffice[];
  other_offices_comment: string;
  master_categories_id: [[string]];
  master_categories_comment: string;
  sub_categories_id: [[string]];
  sub_categories_comment: string;
  tags: string;
  tags_comment: string;
  short_description: string;
  short_description_comment: string;
  long_description: string;
  long_description_comment: string;
  revenue_mix: string;
  revenue_mix_comment: string;
  logo_base64: string;
  logo_base64_comment: string;
  customer_industry_id: [[string]];
  customer_industry_comment: string;
  customer_type_id: [[string]];
  customer_type_comment: string;
  customer_size_employees_id: [[string]];
  customer_size_employees_comment: string;
  customer_size_revenues_id: [[string]];
  customer_size_revenues_comment: string;
  customer_location_id: [[string]];
  customer_location_comment: string;
  customer_description: string;
  customer_description_comment: string;
  customer_marquee: string;
  customer_marquee_comment: string;
  customer_user_group_id: [[string]];
  customer_user_group_comment: string;
  customer_languages_supported_id: [[string]];
  customer_languages_supported_comment: string;
  private_public_id: string;
  private_public_comment: string;
  public_company_exchange: string;
  public_company_exchange_comment: string;
  public_company_ticker: string;
  public_company_ticker_comment: string;
  public_company_capital_raised: string;
  public_company_capital_raised_comment: string;
  public_company_last_founding_round: string;
  public_company_last_founding_round_comment: string;
  company_phase_id: string;
  company_phase_comment: string;
  company_org_size_employees_id: string;
  company_org_size_employees_comment: string;
  company_org_size_revenues_id: string;
  company_org_size_revenues_comment: string;
  company_org_size_ebitda_id: string;
  company_org_size_ebitda_comment: string;
  ceo: string;
  ceo_comment: string;
  board_members: string;
  board_members_comment: string;
  controlling_investors: string;
  controlling_investors_comment: string;
  investors_nationality_id: string;
  investors_nationality_comment: string;
  original_founders: string;
  original_founders_comment: string;
  mentors: string;
  mentors_comment: string;
  mentees: string;
  mentees_comment: string;
  awards: string;
  awards_comment: string;
  graduated_from: string;
  graduated_from_comment: string;
  industry_memberships: string;
  industry_memberships_comment: string;
  special_classes_id: [[string]];
  special_classes_comment: string;
  audited_finances_id: string;
  audited_finances_comment: string;
  compliance_audits_id: string;
  compliance_audits_comment: string;
  security_standards_id: [[string]];
  security_standards_comment: string;
  company_would_like_to_connect_with_id: [[string]];
  company_would_like_to_connect_with_comment: string;
  catalisto_notes_on_management: string;
  catalisto_notes_on_management_comment: string;
  sustainability_risk_of_vendor_id: string;
  sustainability_risk_of_vendor_comment: string;
  focus_on_in_next_interview: string;
  focus_on_in_next_interview_comment: string;
  vendor_profile_owner_name: string;
  vendor_profile_owner_name_comment: string;
  vendor_profile_owner_title: string;
  vendor_profile_owner_title_comment: string;
  vendor_profile_owner_email: string;
  vendor_profile_owner_email_comment: string;
  vendor_profile_owner_telephone: string;
  vendor_profile_owner_telephone_comment: string;
  brochures_id: string[];
  brochures_comment: string;
}

export interface ISolutionInfo {
  status?: string;
  message?: string;
  solution: {
    brochures: {
      created_at: number;
      source: string;
      items: [
        {
          id: string;
          title: string;
        }
      ];
    };
    record_accept_actions: ActionsType[] | null;
    published: boolean;
    record_stage: RecordStatus;
    id: string;
    slug: {
      source: string;
      updated_at: number;
      value: string;
    };
    record_id: string;
    title: {
      value: string;
      updated_at: number;
      source: string;
    };
    company: {
      id: string;
      title: string;
    };
    history: {
      updated_at: number;
      created_at: number;
      approved: number;
      start_work_at: number;
      send_to_review: number;
      allocated: number;
      assigned_to_company: number;
    };
    comments: [
      {
        id: string;
        created_at: number;
        created_by_id: string;
        created_by_full_name: string;
        body: string;
      }
    ];
    logo: {
      value: string;
      updated_at: number;
      source: string;
    };
    what_it_does: {
      video_link: {
        value: string;
        updated_at: number;
        source: string;
      };
      master_categories: {
        values: [string];
        ids: [string];
        updated_at: number;
        source: string;
      };
      sub_categories: {
        values: [string];
        ids: [string];
        updated_at: number;
        source: string;
      };
      tags: {
        value: string;
        updated_at: number;
        source: string;
      };
      short_description: {
        value: string;
        updated_at: number;
        source: string;
      };
      long_description: {
        value: string;
        updated_at: number;
        source: string;
      };
      key_differentations: {
        value: string;
        updated_at: number;
        source: string;
      };
    };
    who_uses_it: {
      customer_industry: {
        values: [string];
        ids: [string];
        updated_at: number;
        source: string;
      };
      customer_type: {
        values: [string];
        ids: [string];
        updated_at: number;
        source: string;
      };
      customer_location: {
        values: [string];
        ids: [string];
        updated_at: number;
        source: string;
      };
      customer_description: {
        value: string;
        updated_at: number;
        source: string;
      };
      customer_marquee: {
        value: string;
        updated_at: number;
        source: string;
      };
      customer_user_group: {
        values: [string];
        ids: [string];
        updated_at: number;
        source: string;
      };
      customer_language: {
        values: [string];
        ids: [string];
        updated_at: number;
        source: string;
      };
    };
    who_does_it_cost: {
      price_from: {
        value: string;
        updated_at: number;
        source: string;
      };
      price_to: {
        value: string;
        updated_at: number;
        source: string;
      };
      price_structure: {
        value: string;
        updated_at: number;
        source: string;
      };
      return_investment: {
        value: string;
        updated_at: number;
        source: string;
      };
    };
    special_mentions: {
      awards: {
        values: string[];
        updated_at: number;
        source: string;
      };
      comparison_reviews: {
        value: string;
        updated_at: number;
        source: string;
      };
      technical_reviews: {
        value: string;
        updated_at: number;
        source: string;
      };
    };
    catalisto_internal_screening_notes: {
      notes_on_product: {
        value: string;
        updated_at: number;
        source: string;
      };
      notes_on_competitors: {
        value: string;
        updated_at: number;
        source: string;
      };
      focus_on_in_next_interview: {
        value: string;
        updated_at: number;
        source: string;
      };
      vendor_profile_owner_name: {
        value: string;
        updated_at: number;
        source: string;
      };
      vendor_profile_owner_title: {
        value: string;
        updated_at: number;
        source: string;
      };
      vendor_profile_owner_email: {
        value: string;
        updated_at: number;
        source: string;
      };
      vendor_profile_owner_telephone: {
        value: string;
        updated_at: number;
        source: string;
      };
    };
  };
  vocabulary: {
    categories: [
      {
        id: string;
        title: string;
        parent_id: string;
      },
      {
        id: string;
        title: string;
      }
    ];
    countries: [
      {
        id: string;
        title: string;
      },
      {
        id: string;
        title: string;
      }
    ];
    customer_industry: [
      {
        id: string;
        title: string;
      },
      {
        id: string;
        title: string;
      },
      {
        id: string;
        title: string;
      }
    ];
    customer_type: [
      {
        id: string;
        title: string;
      },
      {
        id: string;
        title: string;
      },
      {
        id: string;
        title: string;
      }
    ];
    customer_user_group: [
      {
        id: string;
        title: string;
      },
      {
        id: string;
        title: string;
      },
      {
        id: string;
        title: string;
      }
    ];
    customer_language: [
      {
        id: string;
        title: string;
      },
      {
        id: string;
        title: string;
      },
      {
        id: string;
        title: string;
      }
    ];
  };
}

export interface IPutDataSolution {
  title: string;
  logo_file_name: string;
  brochures_comment: string;
  brochures_id: string[];
  title_comment: string;
  logo_base64: string;
  company_id: string;
  video_links: string;
  video_links_comment: string;
  master_categories_id: [[string]];
  master_categories_comment: string;
  sub_categories_id: [[string]];
  sub_categories_comment: string;
  tags: string;
  comparison_reviews: string;
  comparison_reviews_comment: string;
  technical_reviews: string;
  technical_reviews_comment: string;
  tags_comment: string;
  short_description: string;
  short_description_comment: string;
  long_description: string;
  long_description_comment: string;
  key_differentiators: string;
  key_differentiators_comment: string;
  customer_industry_id: [[string]];
  customer_industry_comment: string;
  customer_type_id: [[string]];
  customer_type_comment: string;
  customer_location_id: [[string]];
  customer_location_comment: string;
  customer_description: string;
  customer_description_comment: string;
  customer_marquee: string;
  customer_marquee_comment: string;
  customer_user_group_id: [[string]];
  customer_user_group_comment: string;
  customer_languages_supported_id: [[string]];
  customer_languages_supported_comment: string;
  price_start: string;
  price_start_comment: string;
  price_to: string;
  price_to_comment: string;
  price_structure: string;
  price_structure_comment: string;
  return_investment: string;
  return_investment_comment: string;
  awards: string;
  awards_comment: string;
  catalisto_notes_product: string;
  catalisto_notes_product_comment: string;
  catalisto_notes_on_competitors: string;
  catalisto_notes_on_competitors_comment: string;
  focus_on_in_next_interview: string;
  focus_on_in_next_interview_comment: string;
  vendor_profile_owner_name: string;
  vendor_profile_owner_name_comment: string;
  vendor_profile_owner_title: string;
  vendor_profile_owner_title_comment: string;
  vendor_profile_owner_email: string;
  vendor_profile_owner_email_comment: string;
  vendor_profile_owner_telephone: string;
  vendor_profile_owner_telephone_comment: string;
}

interface Record {
  id: string;
  stage: string;
  published: boolean;
  record_type: string;
  accept_actions: string[];
  company: DataShort | undefined;
  solution: DataShort | undefined;
  researcher: {
    id: string;
    full_name: string;
  };
  comments: {
    id: string;
    created_at: number;
    created_by_id: string;
    created_by_full_name: string;
    body: string;
  }[];
  history: {
    created_at: number;
    updated_at: number;
    approved: number;
    start_work_at: number;
    published_at: number;
    send_to_review: number;
    allocated: number;
    assigned_to_company: number;
    log: {
      title: string;
      created_at: number;
    }[];
  };
}

export interface RecordsListResponse {
    status: string;
    query: any; // passed from frontend
    counts_per_category: {
      request: number;
      allocated: number;
      in_progress: number;
      review: number;
      completed: number;
    };
    records: {
      request: Record[];
      allocated: Record[];
      in_progress: Record[];
      review: Record[];
      completed: Record[];
    }
}
