import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Box = styled.div<{ status: 'OK' | 'error' }>`
  position: fixed;
  top: 10%;
  right: 10%;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 300px;
  min-height: 60px;
  margin-bottom: 20px;
  padding: 20px 0 20px 0;
  border-radius: 6px;
  z-index: 10;

  ${({ status }) =>
    status === 'OK'
      ? css`
          background: linear-gradient(134.72deg, #6fff00 0%, #005bff 101.5%);
        `
      : css`
          background: linear-gradient(134.72deg, #fc4d9a 0%, #9c0745 101.5%);
        `}
  @media screen and (min-width: 375px ) and (max-width: 740px) {
    width: 245px;
  }

  img {
    position: absolute;
    left: 8%;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: 27px;
  }

  p {
    position: relative;
    padding: 0;
    width: 160px;
    margin: 0;
    text-align: center;
    align-self: center;
    margin-left: auto;
    margin-right: 20%;
    font-size: 14px;
    line-height: 100%;
    color: #ffffff;
    opacity: 1;
    &::before {
      content: '';
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 30px;
      background-color: #fff;
      opacity: 0.5;
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;

    transform: rotate(-45deg);
    right: 20px;
    top: -7px;
    width: 20px;
    height: 20px;

    ${({ status }) =>
      status === 'OK'
        ? css`
            background: linear-gradient(134.72deg, #1f88b8 0%, #157acf 101.5%);
          `
        : css`
            background: linear-gradient(134.72deg, #b81c5e 0%, #b11657 101.5%);
          `}
  }
  animation: show 0.3s linear;
  @keyframes show {
    0% {
      top: -2%;
    }
    25% {
      top: 4%;
    }
    50% {
      top: 9%;
    }
    75% {
      top: 14%;
    }
    100% {
      top: 10%;
    }
  }
`;
