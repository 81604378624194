// Core
import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { useInjection } from '../../../core/inversify';

// Store
import { AuthStore } from '../../../stores/AuthStore';

// Components
import TextField from '../../../components/input/TextField';

// Styles
import { WrapperForm, StyledButton, StyledErr } from '../styles';

export enum FieldName {
  code = 'code',
}

const SignupSchema = Yup.object().shape({
  [FieldName.code]: Yup.string()
    .min(6, 'Code must be at 6 characters')
    .max(6, 'Code must be at 6 characters')
    .required('Please enter code.')
    .matches(/^[0-9]+$/, 'Enter valid code.'),
});
interface IProps {
  nextSteps: () => void;
}

const StepTwo = observer(({ nextSteps }: IProps): JSX.Element => {
  const authStore = useInjection(AuthStore);
  const [showCode, setShowCode] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [QRCode, setQRCode] = useState<string | null>('');

  useEffect(() => {
    authStore.getOtpData();
  }, []);

  useEffect(() => {
    if (authStore.getRecoveryCodes().length >= 2) {
      nextSteps();
    }
  }, [authStore.getRecoveryCodes().length]);

  useEffect(() => {
    if (authStore.getOtp()?.qr_code_base64) {
      const img = authStore.getOtp()?.qr_code_base64;
      setQRCode(img as string);
    }
  }, [authStore.getOtp()]);

  const onSubmit = () => {
    authStore.setOtpCode(value);
  };

  return (
    <Formik
      validationSchema={SignupSchema}
      initialValues={{
        [FieldName.code]: '',
      }}
      onSubmit={() => onSubmit()}
    >
      {({ values, errors, touched, isValid }) => {
        setValue(values[FieldName.code]);
        return (
          <WrapperForm>
            <h2>Set up Authenticator</h2>
            <p className="QR-code--step">Step 1 - scan</p>
            <div className="QR-code" />
            {!showCode && (
              <button type="button" className="button-step__two--transparent" onClick={() => setShowCode(true)}>
                Can`t scan it?
              </button>
            )}
            <p className="QR-code--step">Step 2 - enter code</p>
            <div className="QR-code">
              <img src={QRCode?.toString()} alt="the QR code" />
            </div>
            {!showCode && (
              <button className="sign-in__btn--code" type="button" onClick={() => setShowCode(true)}>
                Can`t scan it?
              </button>
            )}
            {showCode && <p>{authStore.getOtp()?.manual_code}</p>}
            <p>Enter the 6-digit code you see in the app.</p>
            <TextField name={FieldName.code} placeholder="Enter code" autocomplete={false} />
            {errors.code && touched.code ? <StyledErr>{errors.code}</StyledErr> : null}
            <StyledButton disabled={!isValid} className="button-step__two" type="submit">
              <p> Verify</p>
            </StyledButton>
          </WrapperForm>
        );
      }}
    </Formik>
  );
});

export default StepTwo;
