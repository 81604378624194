import styled from '@emotion/styled';
import logo from '../../assets/logo-footer.svg';

export const StyledFooter = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  background-color: #1d2553;
  z-index: 20;
  div.logo-header {
    margin-left: 30px;
    width: 142px;
    height: 33px;
    background-image: url(${logo});
    background-repeat: no-repeat;
  }
  p {
    width: 100px;
    margin-left: auto;
    margin-right: 30px;
    color: #ababb3;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    text-align: right;
  }
`;
