import { useEffect, useRef, MutableRefObject } from 'react';

function useOuterClick(callback: () => void): MutableRefObject<HTMLDivElement | null | undefined> {
  const innerRef = useRef<HTMLDivElement | null>();
  const callbackRef = useRef<any>();

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    function handleClick(e: any) {
      if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target)) {
        callbackRef.current(e);
      }
    }
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  return innerRef;
}

export default useOuterClick;
