// Core
import styled from '@emotion/styled';
import opnenSvg from '../../../assets/images/company/openSelect.svg';
import selectCloseChip from '../../../assets/images/company/selectCloseChip.svg';

export const WrapperMultiselect = styled.div<{ toggleSvg: boolean; displayInput: any }>`
  position: relative;

  & div.searchWrapper {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding-left: 10px;
    height: 30px;
    border: none;
    background-color: #f2f4f8;
    border-radius: 6px;
    font-weight: 400;
    border-radius: 6px;
    color: #1d2553;
    font-size: 16px;
    outline: none;
    text-transform: none;
    width: 98%;
    max-width: 250px;
    height: 40px;

    & input {
      max-width: 150px;
      width: 20px;
      font-weight: 400;
      margin: 0;
      margin-bottom: 6px;
      color: #1d2553;
      font-size: 16px;
      ${({ displayInput }) => (displayInput ? 'display:none;' : null)}
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      background-image: url(${opnenSvg});
      width: 26px;
      height: 26px;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
    }

    & span {
      position: relative;
      min-width: 190px;
      width: 250px;
      height: 30px;
      background-color: transparent;
      border-radius: 6px;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: #1d2553;
      overflow-x: hidden;
      & img.closeIcon {
        opacity: 0;
        position: absolute;
        width: 26px;
        height: 26px;
        z-index: 300;
        margin-left: auto;
        right: 8px;
      }

      &::before {
        content: '';
        position: absolute;
        display: block;
        width: 46px;
        height: 16px;
        right: 0;
        z-index: 0;
      }

      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 46px;
        height: 30px;
        right: 0;
        background-color: #f2f4f8;
        background-image: url(${selectCloseChip});
        background-repeat: no-repeat;
        background-position: center;
        z-index: 1;
      }
    }
    & input {
      height: 30px;
    }
    :-moz-placeholder {
      line-height: 40px;
    }
    ::-moz-placeholder {
      line-height: 40px;
    }
  }
  & div.displayNone {
    display: none;
  }

  &.head_country {
    & div.searchWrapper {
      max-width: 465px;
      width: 465px;
      & input {
        width: 100px;
      }
      & span {
        width: 460px;
        margin: 0;
        &::after {
          right: 0;
        }
      }
    }
  }

  &.other_country {
    & div.optionListContainer {
      top: 40px;
    }
    & div.searchWrapper {
      max-width: 465px;
      width: 100%;

      & input {
        width: 100px;
      }
      & span {
        margin: 0;
        min-width: 100%;
        &::after {
          right: 0;
        }
      }
    }
  }

  &.search {
    & div.searchWrapper {
      max-width: 465px;
      width: 100%;

      & input {
        width: 300px;
      }
      & span {
        margin: 0;
        min-width: 100%;
        &::after {
          right: 0;
        }
      }
    }
  }
`;

export const Overlay = styled.div`
  position: absolute;
  min-width: 500px;
  height: 50px;
  z-index: 1000;
  background-color: transparent;
`;
