import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import startProgressSvg from '../../assets/images/company/startProgress.svg';
import approveSvg from '../../assets/images/company/approve.svg';
import deleteSvg from '../../assets/images/company/delete.svg';
import publishSvg from '../../assets/images/company/publish.svg';
import rejectSvg from '../../assets/images/company/reject.svg';
import reworkSvg from '../../assets/images/table/go-back.svg';
import saveSvg from '../../assets/images/company/save.svg';
import cancelUploadIcon from '../../assets/images/table/cancel-upload.svg';
import { ButtonBoxStyled } from './styles';

// Types
import { IActions } from '../../services/api/types';
import { ActionsType, RecordStatus } from '../../stores/types';

interface IProps {
  handleSubmit: () => void;
  onAction: (statusAction: IActions) => void;
  onDelete: () => void;
  modalAction: (action: any) => void;
  statusRecord?: RecordStatus | undefined;
  isPublished: boolean | undefined;
  recordAcceptActions: ActionsType[] | undefined | null;
}

export const ButtonGroup = ({
  handleSubmit,
  onAction,
  onDelete,
  statusRecord,
  isPublished,
  recordAcceptActions,
  modalAction,
}: IProps): JSX.Element => {
  const isReviewStage = statusRecord === RecordStatus.reviewRecordStage || statusRecord === RecordStatus.completedRecordStage;
  const startActionsAccept = recordAcceptActions?.includes(ActionsType.startProgress);
  const deleteActionsAccept = recordAcceptActions?.includes(ActionsType.delete);
  const approveActionsAccept = recordAcceptActions?.includes(ActionsType.approve);
  const publishActionsAccept = recordAcceptActions?.includes(ActionsType.publish);
  const rejectActionsAccept = recordAcceptActions?.includes(ActionsType.reject);
  const reviewActionsAccept = recordAcceptActions?.includes(ActionsType.review);
  const reworkActionsAccept = recordAcceptActions?.includes(ActionsType.rework);
  const unpublishActionsAccept = recordAcceptActions?.includes(ActionsType.unpublish);

  const isDisabled = () => {
    switch (statusRecord) {
      case RecordStatus.allocatedRecordStage:
        return true;
      case RecordStatus.inProgressRecordStage:
        return true;
      case RecordStatus.requestRecordStage:
        return true;
      default:
        return false;
    }
  };
  if (!recordAcceptActions?.length) {
    return <>{null}</>;
  }

  const leaveComment = (actionsType: ActionsType) => {
    modalAction(actionsType);
  };

  return (
    <ButtonBoxStyled opacity={isReviewStage && !recordAcceptActions}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Button disabled={isReviewStage && !recordAcceptActions} type="button" onClick={handleSubmit}>
            <img src={saveSvg} alt="save" />
            Save
          </Button>
        </Grid>
        {reworkActionsAccept && (
          <Grid item xs={4}>
            <Button type="button" disabled={isPublished} onClick={() => leaveComment(ActionsType.rework)}>
              <img src={reworkSvg} alt="Rework" />
              Rework
            </Button>
          </Grid>
        )}
        {publishActionsAccept && (
          <Grid item xs={4}>
            <Button type="button" disabled={isDisabled() || isPublished} onClick={() => onAction({ [ActionsType.publish]: true })}>
              <img src={publishSvg} alt="Publish" />
              Publish
            </Button>
          </Grid>
        )}
        {unpublishActionsAccept && (
          <Grid item xs={4}>
            <Button type="button" disabled={!isPublished} onClick={() => onAction({ [ActionsType.unpublish]: true })}>
              <img src={cancelUploadIcon} alt="Unpublish" />
              Unpublish
            </Button>
          </Grid>
        )}
        {deleteActionsAccept && (
          <Grid item xs={4}>
            <Button disabled={isPublished} type="button" onClick={onDelete}>
              <img src={deleteSvg} alt=" Delete" />
              Delete
            </Button>
          </Grid>
        )}
        {statusRecord !== RecordStatus.completedRecordStage && approveActionsAccept && (
          <Grid item xs={4}>
            <Button type="button" onClick={() => onAction({ [ActionsType.approve]: true })}>
              <img src={approveSvg} alt=" Approve" />
              Approve
            </Button>
          </Grid>
        )}
        {rejectActionsAccept && (
          <Grid item xs={4}>
            <Button type="button" onClick={() => leaveComment(ActionsType.reject)}>
              <img src={rejectSvg} alt="Reject" />
              Reject
            </Button>
          </Grid>
        )}
        {startActionsAccept && (
          <Grid item xs={4}>
            <Button type="button" onClick={() => onAction({ [ActionsType.startProgress]: true })}>
              <img src={startProgressSvg} alt="start progress" />
              Start progress
            </Button>
          </Grid>
        )}
        {reviewActionsAccept && (
          <Grid item xs={4}>
            <Link to="/records" type="button" onClick={() => onAction({ [ActionsType.review]: true })}>
              <img src={startProgressSvg} alt="Reject" />
              Submit to review
            </Link>
          </Grid>
        )}
      </Grid>
    </ButtonBoxStyled>
  );
};
