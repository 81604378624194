import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
//import { TokenService } from '../../services/api/TokenService';
import { useInjection } from '../../core/inversify';

// Store
import { AuthStore } from '../../stores/AuthStore';

// Steps
import StepOne from './steps/stepOne';
import StepTwo from './steps/stepTwo';
import StepTwoApp from './steps/stepTwoApp';
import StepTwoRecoveryCode from './steps/stepTwoRecoveryCode';
import EndStepAddKey from './steps/endStepAddKey';
import EndStepRegistrationKey from './steps/endStepRegistrationKey';
import EndStepVerify from './steps/endStepVerify';

// Components
import Steps from '../../components/stepsProgress';
import { Loader } from '../../components/loader';
import ErrorBox from '../../components/errorBox';

// Types
import { ILoginParam, INeedStep, ErrorMessage, ITextError } from '../../services/api/types';

// Styles
import { Layout, SignUpImg, FlexBox } from './styles';

enum ISteps {
  stepOne = 1,
  stepTwo = 2,
  stepTwoApp = 'stepTwoApp',
  stepTwoRecoveryCode = 'stepTwoRecoveryCode',
  endStepVerify = 'endStepVerify',
  endStepAddKey = 'endStepAddKey',
  endStepRegistrationKey = 'endStepRegistrationKey',
}

const LogIn = observer((): JSX.Element => {
  const authStore = useInjection(AuthStore);
  const [renderStep, setRenderStep] = useState<ISteps>(ISteps.stepOne);
  const [textMessage, setTextMessage] = useState<string>('');
  //const tokenService = useInjection(TokenService);

  useEffect(() => {
    authStore.checkTokenStop()
    authStore.logout()
  }, []);

  useEffect(() => {
    const needStep = authStore.getNeedStep()
    if (needStep.includes(INeedStep.OTP)) {
      setRenderStep(ISteps.stepTwoApp);
    } else if (needStep.includes(INeedStep.QR)) {
      setRenderStep(ISteps.stepTwo);
    } else if (needStep.includes(INeedStep.KEY_SETUP)) {
      setRenderStep(ISteps.endStepAddKey);
    } else if (needStep.includes(INeedStep.KEY_ENTER)) {
      setRenderStep(ISteps.endStepVerify);
    } else if (needStep.includes(INeedStep.ADD_KEY)) {
      setRenderStep(ISteps.endStepAddKey);
    } else if (needStep.includes(INeedStep.KEY_LIST)) {
      setRenderStep(ISteps.endStepRegistrationKey);
    } else if (needStep.includes(INeedStep.START)) {
      setRenderStep(ISteps.stepOne);
    } else if (needStep.includes(INeedStep.DONE)) {
      authStore.setIsAuthorized(true);  // from v1 backend, v2 backend returns empty [], sets in store
    } else if (needStep.includes(INeedStep.RECOVERY)) {
      setRenderStep(ISteps.stepTwoRecoveryCode);
    }

    return () => setRenderStep(ISteps.stepOne);
  }, [authStore.getNeedStep()]);

  useEffect(() => {
    const error = authStore.getError()
    const errorMessage = authStore.getErrorMessage()
    if (error)
      console.error('Login error: ', error)
    if (errorMessage)
      console.error('Login errorMessage: ', errorMessage)

    if (errorMessage === ErrorMessage.wrongEmailOrPAssword) {
      setTextMessage(ITextError.wrongEmailOrPAssword);
    } else if (errorMessage === ErrorMessage.wrongPasscode) {
      setTextMessage(ITextError.wrongPasscode);
    } else if (errorMessage === ErrorMessage.wrongRecoveryCode) {
      setTextMessage(ITextError.wrongRecoveryCode);
    } else if (errorMessage || error) {
      setTextMessage(ITextError.somethingWentWrong);
    }
    if (renderStep === ISteps.endStepVerify && error && !authStore.getNeedStep().includes(INeedStep.KEY_ENTER)) {
      setRenderStep(ISteps.endStepAddKey);
    }

    const mainPageNotError = !error
    const isLocalDevEnv = window.location.hostname === 'localhost'
    if (mainPageNotError && isLocalDevEnv)
      setTextMessage('auth with Yubikey doesn\'t work on localhost.\nUse Windows Hello or Chrome WebAuthN emulator')
  }, [authStore.getError()]);

  const setCurrentStep = () => {
    switch (renderStep) {
      case ISteps.stepOne:
        return <StepOne login={(loginParam: ILoginParam) => authStore.loginUser(loginParam)} />;
      case ISteps.stepTwo:
        return <StepTwo nextSteps={() => setRenderStep(ISteps.stepTwoRecoveryCode)} />;
      case ISteps.stepTwoApp:
        return <StepTwoApp />;
      case ISteps.endStepVerify:
        return <EndStepVerify nextSteps={() => setRenderStep(ISteps.stepOne)} />;
      case ISteps.endStepRegistrationKey:
        return <EndStepRegistrationKey nextSteps={() => setRenderStep(ISteps.endStepAddKey)} />;
      case ISteps.endStepAddKey:
        return <EndStepAddKey nextSteps={() => setRenderStep(ISteps.endStepVerify)} />;
      case ISteps.stepTwoRecoveryCode:
        return <StepTwoRecoveryCode nextSteps={() => setRenderStep(ISteps.endStepAddKey)} />;
      default:
        return <StepOne login={(loginParam: ILoginParam) => authStore.loginUser(loginParam)} />;
    }
  };

  const stepsPtogress = (): number => {
    switch (renderStep) {
      case ISteps.stepOne:
        return 1;
      case ISteps.stepTwo:
        return 2;
      case ISteps.stepTwoApp:
        return 2;
      case ISteps.stepTwoRecoveryCode:
        return 2;
      case ISteps.endStepAddKey:
        return 3;
      case ISteps.endStepVerify:
        return 3;
      case ISteps.endStepRegistrationKey:
        return 3;
      default:
        return 1;
    }
  };

  return (
    <>
      {authStore.getIsLoading() && <Loader />}
      {authStore.getError() && <ErrorBox status="error" text={textMessage} />}
      {!authStore.getError() && textMessage && <ErrorBox status="OK" text={textMessage} />}
      <Layout>
        <FlexBox>
          <Steps steps={3} currentStep={stepsPtogress()} />
          {setCurrentStep()}
        </FlexBox>
        <SignUpImg />
      </Layout>
    </>
  );
});

export default LogIn;
