import { observable, action, makeAutoObservable, toJS } from 'mobx';
import { injectable, decorate } from 'inversify';

export interface IModalStore {
  getModalProps: () => any;
}

export class ModalStore implements IModalStore {
  constructor() {
    makeAutoObservable(this);
  }

  @observable isOpen = false;

  @observable context = null;

  @action setModalProps = (isOpen: boolean, context: any): void => {
    this.isOpen = isOpen;
    this.context = context;
  };

  @action getModalProps = (): { isOpen: boolean; context: any } => ({ isOpen: this.isOpen, context: toJS(this.context) });
}
decorate(injectable(), ModalStore);
