//import axios from 'axios';

export const fetchWithTimeout = (url: string, options: any, timeout: number): Promise<any> => {
  return Promise.race([fetch(url, options), new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout error')), timeout))]);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const buildQuery = (params: { [key: string]: any }): any => {
  return Object.keys(params)
    .filter((key: string) => !!params[key])
    .map((key: string) => {
      if (Array.isArray(params[key])) {
        return params[key]
          .map((item: string) => `${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`)
          .toString()
          .join('&');
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    })
    .join('&');
};

export const formatDate = (date: number): string => {
  const d = new Date(date * 1000);
  const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
  const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
  return `${mo}. ${da}. ${ye}`;
};

export const shortString = (name: string, signs: number): string => {
  if (name.length > signs) {
    return `${name.slice(0, signs)}...`;
  }
  return name;
};
