import styled from '@emotion/styled';
import logo from '../../assets/logo-header.svg';

export const StyledHeader = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  color: #fff;
  padding: 10px 0;
  z-index: 2;
  div.logo-header {
    margin-left: 30px;
    width: 142px;
    height: 33px;
    background-image: url(${logo});
    background-repeat: no-repeat;
    margin-right: 90px;
  }
`;
