import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Formik } from 'formik';

import { scrollToElement } from 'src/helpers/scroll-to-anchor'
import { displayDate } from 'src/helpers/display-date'
import * as helpers from 'src/pages/CompanyPage/helpers'
import * as apiTypes from 'src/services/api/types'

import { useInjection } from '../../core/inversify';

// Store
import { ModalStore } from '../../stores/ModalStore';
import { CompanyStore } from '../../stores/Company&SolutionStore';

// Utils
import { EmailExp, numberExp, linkedinExp, facebookExp, twitterExp, youtubeExp } from '../../components/utils';

// Components
import { Loader } from '../../components/loader';
import ErrorBox from '../../components/errorBox';
import Layout from '../../components/Layout';
import { FormComponent } from './formComponent';
import { SelectMultiple, SelectItem, Items } from '../../components/input/SelectMultiple';
import MySelect from '../../components/input/Select';
import InternationalPhoneInput from '../../components/input/PhoneInput';
import TextArea from '../../components/input/TextArea';
import { BtnPageScroll } from '../../components/BtnPageScroll';
import { ButtonGroup } from '../../components/ButtonGroup';
import Modal from '../../components/modal';

// IMG and SVG 
import backSvg from '../../assets/images/company/back.svg';
import mockupSvg from '../../assets/images/company/mockup.svg';
import imgSvg from '../../assets/images/company/img.svg';
import fileSvg from '../../assets/images/company/file.svg';
import deleteFileSvg from '../../assets/images/company/deleteFile.svg';

// Types
import {
  ITitleName,
  FieldsNameContacts,
  FieldsNameTheyDoItFor,
  FieldsNameTheyDo,
  FieldsNameStage,
  FieldsNameRisk,
  FieldsNameNotes,
  FieldsNameMentions,
  FieldsNameMain,
  FieldsNameInterest,
  FieldsNameContactsSource,
  FieldsNameInterestSource,
  FieldsNameMainSource,
  FieldsNameMentionsSource,
  FieldsNameNotesSource,
  FieldsNameRiskSource,
  FieldsNameStageSource,
  FieldsNameTheyDoItForSource,
  FieldsNameTheyDoSource,
} from './types';
import { IPutDataCompany, IActions } from '../../services/api/types';
import { RecordStatus } from '../../stores/types';

// Styles
import {
  PageHeader,
  StyledForm,
  StyledCommentWrapper,
  DisplayFlex,
  HeaderButton,
  WrapperInput,
  WrapperNote,
  StyledRecordAuditComponent,
  StyledPageFooter,
  WrapperInputData,
  StyledInput,
  StyledInputGroup,
  StyledListProducts,
  StyledErr,
  StyledSelectMultiple,
} from './styles';
import { shortString } from '../../library/Utils';

let messageError = `The brochure can't exceed 50 MB`;
let validationMessageError: string;

const Company = observer(({ companySlug }: any): JSX.Element => {
  const refTitle = useRef<HTMLInputElement>(null);
  const companyStore = useInjection(CompanyStore);
  const { setModalProps, getModalProps } = useInjection(ModalStore);
  const history = useHistory();
  const pageRef = useRef<any>();
  const [otherContacts, otherContactsSet] = useState<any>([]);
  const [showErrorValid] = useState<boolean>(false);
  const [isAutoSave, setIsAutoSave] = useState<boolean>(true);
  const [imgInputName, setImgInputName] = useState<string>('');
  const [logoValue, setLogoValue] = useState<any>();
  const [brochureError, setBrochureError] = useState(false);
  const [logoValid, setLogoValid] = useState<boolean>(false);
  const [pagePercent, setPagePercent] = useState<number>(0);
  const [pageRefValue] = useState<any>(pageRef);
  const [brochureName, setBrochureName] = useState<any[]>([]);
  const [performedUpdate, performedUpdateSet] = useState<boolean>(false); // don't display "saved" on first load
  const imgInput = useRef<any>();
  const fileInput = useRef<any>();

  const companyData = companyStore.getCompany()
  var company = companyData?.company
  var companyId = company?.id
  const error = companyStore.getError()

  useEffect(() => {
    companyStore.fetchCompany(companySlug);
  }, []);

  useEffect(() => {
    const message = companyStore.getErrorMessage();
    if (message.includes('LogoBase64')) {
      messageError = 'The logo must not be more than 1000 x 1000px';
      setBrochureError(true);
      setTimeout(() => {
        setBrochureError(false);
      }, 7000);
    }
  }, [companyStore.getErrorMessage()]);

  useEffect(() => {
    const brochuresChanged = company?.brochures?.items && (company?.brochures?.items !== brochureName)
    const bothNotEmpty = (company?.brochures?.items?.length !== 0) && (brochureName?.length !== 0) // [] !== [] in JS
    if (brochuresChanged && bothNotEmpty) {
      setBrochureName(company?.brochures?.items as any[]);
    }
  }, [company?.brochures]);

  useEffect(() => {
    const isEmpty = !company?.contacts?.other_offices?.length
    const isDummy = (otherContacts?.length === 1) && (otherContacts[0] === 0)
    if (isEmpty) {
      otherContactsSet([0]);
    } else if (!isDummy) {
      const res: number[] = [];
      company?.contacts?.other_offices?.forEach((_, index) => {
        res.push(index);
      });
      otherContactsSet(res);
    }
  }, [...(company?.contacts?.other_offices || [])]);

  useEffect(() => {
    const onScroll = () => {
      const body = document.documentElement.scrollHeight;
      const currentOffset = document.documentElement.clientHeight;
      const height = body - currentOffset;
      const currentPosition = height - window.pageYOffset;
      const res = Math.round((currentPosition * 100) / height);
      setPagePercent(100 - res);
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [pageRef]);


  const countLines = () => {
    const el = refTitle?.current;
    const divHeight = el?.offsetHeight;
    if (divHeight && el && divHeight > 80) {
      el.style.fontSize = '18px';
      el.style.lineHeight = '16px';
    }
  };
  countLines();

  const checkingForm = (value: string, regExp: RegExp) => value && !value.match(regExp);

  const deleteCompany = () => {
    if (!company?.record_id)
      throw new Error(`can't delete company - record_id missing`)
    const companySolution = {
      id: company.record_id,
      title: company.main_info.legal_name.value,
    };
    setModalProps(true, { type: 'delete', companySolution });
  };

  const filteringBySelectedmasterCategories = (items: any, parentValues: any[]): Items[] => {
    const selectedParentId = parentValues?.map((el) => el?.value || el);
    const subCategoryItems = items?.filter((el: any) => (selectedParentId?.includes(el?.parent_category) ? el : null));
    return !parentValues?.length ? items : subCategoryItems;
  };

  const renderFooter = () => {
    return (
      <StyledPageFooter>
        <StyledRecordAuditComponent>
          <h3>Record Audit</h3>
          <ul>
            <li>
              <div>
                Allocated:{' '}
                <p className="audite-date">
                  {company?.history.allocated && company?.history.allocated > 0
                    ? displayDate(company?.history.allocated)
                    : '-'}
                </p>
              </div>
            </li>
            <li>
              <div>
                Created:{' '}
                <p className="audite-date">
                  {company?.history.created_at && company?.history.created_at > 0
                    ? displayDate(company?.history.created_at)
                    : '-'}
                </p>
              </div>
            </li>
            <li>
              <div>
                {` Reviewed & Approved:`}{' '}
                <p className="audite-date">
                  {company?.history.approved && company?.history.approved > 0
                    ? displayDate(company?.history.approved)
                    : '-'}
                </p>
              </div>
            </li>
            <li>
              <div>
                Updated:{' '}
                <p className="audite-date">
                  {company?.history.updated_at && company?.history.updated_at > 0
                    ? displayDate(company?.history.updated_at)
                    : '-'}
                </p>
              </div>
            </li>
          </ul>
        </StyledRecordAuditComponent>
      </StyledPageFooter>
    );
  };
  const encodeImageFileAsURL = (element: any): any => {
    const logo = element[0];
    const readerLogo = new FileReader();
    readerLogo.readAsDataURL(element[0]);
    readerLogo.onload = (e) => {
      e // don't know what it should do, it was empty, so left the same
    };

    if (logo?.name?.includes('.svg') || logo?.name?.includes('.png') || logo?.name?.includes('.jpg') ) {
      logo.src = URL.createObjectURL(logo);
      const reader = new FileReader();
      reader.onloadend = function (e) {
        const image = new Image();
        image.src = e?.target?.result as any;
        image.onload = () => {
          const height = image?.height;
          const width = image?.width;
          if (height > 1000 || width > 1000) {
            messageError = 'Height and Width must not exceed 1000px.';
            imgInput.current.value = ''
            setBrochureError(true);
            setLogoValid(true)
            setTimeout(() => {
              setBrochureError(false);
              setLogoValid(false)

              logo.src = ""
              messageError = ''
            }, 7000);
          }  else {
            setLogoValue(reader.result);
           setImgInputName(element[0].name);
          }
        };
      };
      reader.readAsDataURL(logo);
    } else {
      messageError = 'Please upload valid file type: PNG, JPG or SVG';
      setBrochureError(true);
      setTimeout(() => {
        setBrochureError(false);
        messageError = ''
      }, 5000);
    }
  };

  const onLoadFile = (file: any) => {
    if (!companyId) {
      throw new Error(`can't load file - companyId missing`)
    }
    const newData = new FormData();
    newData.append('company_id', companyId);
    newData.append('file', file);

    if (file.size > 52428800) {
      messageError = "The brochure can't exceed 50 MB"
      setBrochureError(true);
      return setTimeout(() => {
        setBrochureError(false);
        messageError=''
      }, 6000);
    }
    return companyStore.pushBrochures(newData, companyId, 'company');
  };

  const onSubmit = async (data: any, setFieldError: any) => {
    var fileLogo: any = logoValue || company?.logo.value
    var revenue = data[FieldsNameTheyDo.revenue]
    var phoneNumber = data[FieldsNameContacts.officesPhone]
    var profilePhoneNumber = data[FieldsNameNotes.profilePhone]

    var validateResult = helpers.updateDataValidate({
      data, setFieldError, companyId, company, logoValue, revenue, imgInput, phoneNumber, profilePhoneNumber,
    })

    company = (company as apiTypes.ICompanyInfo['company'])
    companyId = (companyId as string)

    if (validateResult.revenueReseted)
      revenue = validateResult.revenueReseted
    if (validateResult.phoneNumberReseted !== undefined)
      phoneNumber = validateResult.phoneNumberReseted
    if (validateResult.profilePhoneNumberReseted !== undefined)
      profilePhoneNumber = validateResult.profilePhoneNumberReseted

    if (validateResult.invalidLogoErrorMessage) {
      messageError = validateResult.invalidLogoErrorMessage
      setLogoValid(true);
      scrollToElement({ query: '#validationImg' });
    }

    const _selectItemsToStrings = (fieldName: string) => data[fieldName]?.map((el: SelectItem) => el?.value || el)

    const companyUpdateData = { // TODO_: move to helpers
      brochures_id: brochureName?.map((el) => el?.id) || [],
      brochures_comment: data[FieldsNameMainSource.brochureSource],
      logo_file_name: imgInput?.current?.files[0]?.name || '',
      legal_name: !data[FieldsNameMain.legalName]
        ? company.main_info.legal_name.value
        : data[FieldsNameMain.legalName],
      slug: data[FieldsNameMain.slug],
      trading_as: data[FieldsNameMain.trading],
      incorporated_in_id: data[FieldsNameMain.incorporated]?.map((el: SelectItem) => el.value || el)[0],
      incorporated_in_comment: data[FieldsNameMainSource.incorporatedSource],
      operation_status_id: data[FieldsNameMain.status],
      operation_status_comment: data[FieldsNameMainSource.statusSource],
      name: !data[FieldsNameMain.nameInTitle] ? company.main_info.name.value : data[FieldsNameMain.nameInTitle],
      incorporated_in_state: data[FieldsNameMain.incorporatedState],
      incorporated_in_state_comment: data[FieldsNameMainSource.incorporatedStateSource],

      head_office_address_line_1: data[FieldsNameContacts.addressOne],
      head_office_address_line_2: data[FieldsNameContacts.addressTwo],
      head_office_city: data[FieldsNameContacts.city],
      head_office_country_id: data[FieldsNameContacts.country]?.map((el: SelectItem) => el.value || el)[0],
      head_office_country_comment: data[FieldsNameContactsSource.countrySource],
      head_office_state: data[FieldsNameContacts.state],
      head_office_zip: data[FieldsNameContacts.code],
      head_office_phone: phoneNumber,
      head_office_website: data[FieldsNameContacts.webSite],
      youtube: checkingForm(data[FieldsNameContacts.youtube], youtubeExp) ? '' : data[FieldsNameContacts.youtube],
      head_office_email: checkingForm(data[FieldsNameContacts.mail], EmailExp) ? '' : data[FieldsNameContacts.mail],
      linkedin: checkingForm(data[FieldsNameContacts.linkedin], linkedinExp) ? '' : data[FieldsNameContacts.linkedin],
      twitter: checkingForm(data[FieldsNameContacts.twitter], twitterExp) ? '' : data[FieldsNameContacts.twitter],
      facebook: checkingForm(data[FieldsNameContacts.facebook], facebookExp) ? '' : data[FieldsNameContacts.facebook],
      other_offices_number: +data[FieldsNameContacts.counterOfOffice],
      other_offices: otherContacts?.map((_: any, index: any) => {
        return {
          city: data[FieldsNameContacts.otherCity + index],
          address: data[FieldsNameContacts.otherAddress + index],
          country_id: data[FieldsNameContacts.otherCountry + index]?.map((el: SelectItem) => el.value || el)[0],
        };
      }),

      master_categories_id: _selectItemsToStrings(FieldsNameTheyDo.categories),
      master_categories_comment: data[FieldsNameTheyDoSource.categoriesSource],
      sub_categories_id: _selectItemsToStrings(FieldsNameTheyDo.subCategories),
      sub_categories_comment: data[FieldsNameTheyDoSource.subCategoriesSource],
      tags: data[FieldsNameTheyDo.otherCategories],
      short_description: data[FieldsNameTheyDo.description],
      long_description: data[FieldsNameTheyDo.longDescription],
      revenue_mix: checkingForm(data[FieldsNameTheyDo.revenue], numberExp) ? '0' : revenue,
      logo_base64: fileLogo,
      customer_industry_id: _selectItemsToStrings(FieldsNameTheyDoItFor.industry),

      customer_type_id: _selectItemsToStrings(FieldsNameTheyDoItFor.type),
      customer_size_employees_id: _selectItemsToStrings(FieldsNameTheyDoItFor.sizeEmployees),
      customer_size_revenues_id: _selectItemsToStrings(FieldsNameTheyDoItFor.sizeRevenues),
      customer_location_id: _selectItemsToStrings(FieldsNameTheyDoItFor.locationCustomer),
      customer_description: data[FieldsNameTheyDoItFor.customerDescription],
      customer_marquee: data[FieldsNameTheyDoItFor.marquee],
      customer_user_group_id: _selectItemsToStrings(FieldsNameTheyDoItFor.userGroup),
      customer_languages_supported_id: _selectItemsToStrings(FieldsNameTheyDoItFor.languages),

      private_public_id: data[FieldsNameStage.privat],
      public_company_exchange: data[FieldsNameStage.exchange],
      public_company_ticker: data[FieldsNameStage.ticker],
      public_company_capital_raised: data[FieldsNameStage.capital],
      public_company_last_founding_round: data[FieldsNameStage.listRound],
      company_phase_id: data[FieldsNameStage.phase],
      company_org_size_employees_id: data[FieldsNameStage.orgEmployees],
      company_org_size_revenues_id: data[FieldsNameStage.orgRevenues],
      company_org_size_ebitda_id: data[FieldsNameStage.ebitda],
      video_links: data[FieldsNameTheyDo.youtubeLink],
      video_links_comment: data[FieldsNameTheyDoSource.youtubeLinkSource],
      ceo: data[FieldsNameInterest.ceo],
      board_members: data[FieldsNameInterest.board],
      controlling_investors: data[FieldsNameInterest.controlInvestors],
      investors_nationality_id: _selectItemsToStrings(FieldsNameInterest.investorsNationality),
      original_founders: data[FieldsNameInterest.original],

      mentors: data[FieldsNameMentions.mentors],
      mentees: data[FieldsNameMentions.mentees],
      awards: data[FieldsNameMentions.awards],
      graduated_from: data[FieldsNameMentions.graduated],
      industry_memberships: data[FieldsNameMentions.industryMentions],
      special_classes_id: _selectItemsToStrings(FieldsNameMentions.classes),

      audited_finances_id: data[FieldsNameRisk.audited],
      compliance_audits_id: data[FieldsNameRisk.compliance],
      security_standards_id: _selectItemsToStrings(FieldsNameRisk.security),

      company_would_like_to_connect_with_id: _selectItemsToStrings(FieldsNameNotes.connect),
      sustainability_risk_of_vendor_id: data[FieldsNameNotes.risk],
      sustainability_risk_of_vendor_comment: data[FieldsNameNotesSource.riskSource],
      catalisto_notes_on_management: data[FieldsNameNotes.notesOnManagement],
      catalisto_notes_on_management_comment: data[FieldsNameNotesSource.notesOnManagementSource],
      focus_on_in_next_interview: data[FieldsNameNotes.notesFocusNextInterview],
      focus_on_in_next_interview_comment: data[FieldsNameNotesSource.notesFocusNextInterviewSource],
      vendor_profile_owner_name: data[FieldsNameNotes.profileName],
      vendor_profile_owner_title: data[FieldsNameNotes.profileTitle],
      vendor_profile_owner_email: checkingForm(data[FieldsNameNotes.profileEmail], EmailExp) ? '' : data[FieldsNameNotes.profileEmail],
      vendor_profile_owner_telephone: profilePhoneNumber,
      audited_finances_comment: data[FieldsNameRiskSource.auditedSource],
      awards_comment: data[FieldsNameMentionsSource.awardsSource],
      board_members_comment: data[FieldsNameInterestSource.boardSource],
      ceo_comment: data[FieldsNameInterestSource.ceoSource],
      company_org_size_revenues_comment: data[FieldsNameStageSource.orgRevenuesSource],
      company_org_size_employees_comment: data[FieldsNameStageSource.orgEmployeesSource],
      company_phase_comment: data[FieldsNameStageSource.phaseSource],
      company_would_like_to_connect_with_comment: data[FieldsNameNotesSource.connectSource],
      compliance_audits_comment: data[FieldsNameRiskSource.auditedSource],
      controlling_investors_comment: data[FieldsNameInterestSource.controlInvestorsSource],
      company_org_size_ebitda_comment: data[FieldsNameStageSource.ebitdaSource],
      customer_location_comment: data[FieldsNameTheyDoItForSource.locationCustomerSource],
      customer_size_employees_comment: data[FieldsNameTheyDoItForSource.sizeEmployeesSource],
      customer_description_comment: data[FieldsNameTheyDoItForSource.customerDescriptionSource],
      customer_industry_comment: data[FieldsNameTheyDoItForSource.industrySource],
      customer_languages_supported_comment: data[FieldsNameTheyDoItForSource.languagesSource],
      customer_marquee_comment: data[FieldsNameTheyDoItForSource.marqueeSource],
      customer_size_revenues_comment: data[FieldsNameTheyDoItForSource.sizeRevenuesSource],
      customer_type_comment: data[FieldsNameTheyDoItForSource.typeSource],
      customer_user_group_comment: data[FieldsNameTheyDoItForSource.userGroupSource],
      facebook_comment: data[FieldsNameContactsSource.facebookSource],
      founded_date: checkingForm(data[FieldsNameMain.founded], numberExp) ? '' : data[FieldsNameMain.founded],
      founded_date_comment: data[FieldsNameMainSource.foundedSource],
      graduated_from_comment: data[FieldsNameMentionsSource.graduatedSource],
      head_office_address_line_1_comment: data[FieldsNameContactsSource.addressOneSource],
      head_office_address_line_2_comment: data[FieldsNameContactsSource.addressTwoSource],
      head_office_city_comment: data[FieldsNameContactsSource.citySource],

      head_office_email_comment: data[FieldsNameContactsSource.mailSource],
      head_office_phone_comment: data[FieldsNameContactsSource.officesPhoneSource],
      head_office_state_comment: data[FieldsNameContactsSource.stateSource],
      head_office_website_comment: data[FieldsNameContactsSource.webSiteSource],
      head_office_zip_comment: data[FieldsNameContactsSource.codeSource],
      industry_memberships_comment: data[FieldsNameMentionsSource.industryMentionsSource],
      investors_nationality_comment: data[FieldsNameInterestSource.investorsNationalitySource],
      legal_name_comment: data[FieldsNameMainSource.legalNameSours],
      linkedin_comment: data[FieldsNameContactsSource.linkedinSource],
      logo_base64_comment: data[FieldsNameMainSource.logoSource],
      long_description_comment: data[FieldsNameTheyDoSource.longDescriptionSource],
      mentees_comment: data[FieldsNameMentionsSource.menteesSource],
      mentors_comment: data[FieldsNameMentionsSource.mentorsSource],
      name_comment: data[FieldsNameMainSource.nameInTitleSours],
      original_founders_comment: data[FieldsNameInterestSource.originalSource],
      other_offices_comment: data[FieldsNameContactsSource.otherOfficesSource],
      other_offices_number_comment: data[FieldsNameContactsSource.counteOfOfficeSource],
      private_public_comment: data[FieldsNameStageSource.privatSource],
      public_company_capital_raised_comment: data[FieldsNameStageSource.capitalSource],
      public_company_exchange_comment: data[FieldsNameStageSource.exchangeSource],
      public_company_last_founding_round_comment: data[FieldsNameStageSource.listRoundSource],
      public_company_ticker_comment: data[FieldsNameStageSource.tickerSource],
      revenue_mix_comment: data[FieldsNameTheyDoSource.revenueSource],
      security_standards_comment: data[FieldsNameRiskSource.securitySource],
      short_description_comment: data[FieldsNameTheyDoSource.descriptionSource],
      special_classes_comment: data[FieldsNameMentionsSource.classesSource],
      tags_comment: data[FieldsNameTheyDoSource.otherCategoriesSource],
      trading_as_comment: data[FieldsNameMainSource.tradingSource],
      twitter_comment: data[FieldsNameContactsSource.twitterSource],
      vendor_profile_owner_email_comment: data[FieldsNameNotesSource.profileEmailSource],
      vendor_profile_owner_name_comment: data[FieldsNameNotesSource.profileNameSource],
      vendor_profile_owner_telephone_comment: data[FieldsNameNotesSource.profilePhoneSource],
      vendor_profile_owner_title_comment: data[FieldsNameNotesSource.profileTitleSource],
      youtube_comment: data[FieldsNameContactsSource.youtubeSource],
    } as IPutDataCompany;

    if (isAutoSave) {
      return companyStore.autoSaveCompany(companyId, companyUpdateData);
    }
    setIsAutoSave(true);
    await companyStore.putCompany(companyId, companyUpdateData)

    performedUpdateSet(true)
    setTimeout(() => performedUpdateSet(false), 3000)
  };

  const isLoading = companyStore.getIsLoading();
  const logoImg = company?.logo?.value ? company?.logo?.value : mockupSvg;

  const isDisabled =
    (company?.record_stage === RecordStatus.reviewRecordStage ||
      company?.record_stage === RecordStatus.completedRecordStage) &&
    !company?.record_accept_actions;

  const sortedComments = [...(company?.comments || [])].sort((a, b) => b.created_at - a.created_at);

  const researcherAction =
    !!company?.record_accept_actions && company?.record_accept_actions?.length > 0;

  const companyExists = (company as apiTypes.ICompanyInfo['company']) // we'll render it only if exists

  return ( // TODO_: move repeated parts to helpers / components
    <>
      {performedUpdate && (companyStore.getStatus() === 'OK') && <ErrorBox status="OK" text="Saved" />}
      {brochureError && <ErrorBox status="error" text={messageError} />}
      {showErrorValid && <ErrorBox status="error" text={validationMessageError} />}
      {error && <ErrorBox status="error" text={messageError} />}
      {isLoading && <Loader />}
      {getModalProps().isOpen && <Modal />}
      <BtnPageScroll currentPosition={pagePercent} goTop={() => scrollToElement({ toTop: true })} goBottom={() => scrollToElement({ toBottom: true })} />
      {!isLoading && !error && companyExists &&
      <Layout pageRef={pageRefValue} researcher={!researcherAction}>
        <PageHeader>
          <div className="header-wrapper">
            <HeaderButton type="button" onClick={() => history.push('/records')}>
              <img src={backSvg} alt="back" />
              <span> Back</span>
            </HeaderButton>
            <p className="header-title">Company Record</p>
            <DisplayFlex>
              <img src={logoValue || logoImg} alt="company logo" />
              <h2 ref={refTitle}>{companyExists?.main_info?.name?.value || 'no name'}</h2>
            </DisplayFlex>
          </div>
          {sortedComments?.length ? (
            <StyledCommentWrapper>
              <span className="header-date">{displayDate(sortedComments[0]?.created_at)}</span>
              <h3>{sortedComments[0]?.created_by_full_name}</h3>
              <p className="header-text">{shortString(sortedComments[0]?.body || '', 350)}</p>
              <p className="header-text--margin">
                {sortedComments.length || 0} {sortedComments.length === 1 ? 'comment' : 'comments'} available
              </p>
              <HeaderButton
                onClick={() => {
                  setModalProps(true, { type: 'showComments', comments: sortedComments });
                }}
                type="button"
              >
                <span>Show all comments</span>
              </HeaderButton>
            </StyledCommentWrapper>
          ) : null}
        </PageHeader>
        <Formik
          onSubmit={(values, { setFieldError }) => onSubmit(values, setFieldError)}
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={helpers.getInitialFormValues()}
          enableReinitialize
        >
          {({ handleSubmit, setFieldValue, touched, errors, values, status }) => {
            status // we don't use it yet, left for linter
            return (
              <StyledForm onSubmit={handleSubmit}>
                <ButtonGroup
                  modalAction={(type: any) =>
                    setModalProps(true, { type: 'leaveComment', context: { actionType: type, recordType: 'company' } })
                  }
                  isPublished={companyExists.published}
                  statusRecord={companyExists.record_stage}
                  handleSubmit={() => {
                    setIsAutoSave(false);
                    handleSubmit();
                  }}
                  recordAcceptActions={companyExists.record_accept_actions}
                  onDelete={deleteCompany}
                  onAction={(statusActions: IActions) => companyStore.changeStatusRecord(statusActions, 'company')}
                />
                <FormComponent title={ITitleName.COMPANY_NAME} id="top">
                  <WrapperInput>
                    <label htmlFor={FieldsNameMain.nameInTitle}>Company Name in Title:</label>
                    <StyledInput
                      maxCharacters={140}
                      isValid={touched[FieldsNameMain.nameInTitle] && errors[FieldsNameMain.nameInTitle]}
                      disabled={isDisabled}
                      defaultValue={companyExists.main_info.name.value}
                      id={FieldsNameMain.nameInTitle}
                      name={FieldsNameMain.nameInTitle}
                      placeholder="Enter Company Name"
                    />
                    {touched[FieldsNameMain.nameInTitle] && errors[FieldsNameMain.nameInTitle] ? (
                      <StyledErr>{errors[FieldsNameMain.nameInTitle]}</StyledErr>
                    ) : null}
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.main_info.name.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameMainSource.nameInTitleSours}>Source</label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.main_info.name.source}
                          id={FieldsNameMainSource.nameInTitleSours}
                          name={FieldsNameMainSource.nameInTitleSours}
                          placeholder="Enter Source "
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameMain.slug}>Slug</label>
                    <StyledInput
                      maxCharacters={1000}
                      isValid={touched[FieldsNameMain.slug] && errors[FieldsNameMain.slug]}
                      disabled
                      defaultValue={companyExists?.slug?.value}
                      id={FieldsNameMain.slug}
                      name={FieldsNameMain.slug}
                      placeholder="Enter Slug"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists?.slug?.updated_at)}</span>
                        </p>
                      </div>
                    </WrapperNote>
                  </WrapperInput>
                </FormComponent>
                <FormComponent title={ITitleName.MAIN_INFO} id={ITitleName.MAIN_INFO}>
                  <WrapperInput>
                    <label htmlFor={FieldsNameMain.legalName}>Legal Name of Company</label>
                    <StyledInput
                      maxCharacters={140}
                      isValid={touched[FieldsNameMain.legalName] && errors[FieldsNameMain.legalName]}
                      disabled={isDisabled}
                      defaultValue={companyExists.main_info.legal_name.value}
                      id={FieldsNameMain.legalName}
                      name={FieldsNameMain.legalName}
                      placeholder="Enter Legal Company Name"
                    />
                    {touched[FieldsNameMain.legalName] && errors[FieldsNameMain.legalName] ? (
                      <StyledErr>{errors[FieldsNameMain.legalName]}</StyledErr>
                    ) : null}
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.main_info.legal_name.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameMainSource.legalNameSours}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.main_info.legal_name.source}
                          id={FieldsNameMainSource.legalNameSours}
                          name={FieldsNameMainSource.legalNameSours}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameMain.trading}>Trading As</label>
                    <StyledInput
                      maxCharacters={140}
                      disabled={isDisabled}
                      defaultValue={companyExists.main_info.trading_as.value}
                      id={FieldsNameMain.trading}
                      name={FieldsNameMain.trading}
                      placeholder="Enter Trading As"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.main_info.trading_as.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameMainSource.tradingSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.main_info.trading_as.source}
                          id={FieldsNameMainSource.tradingSource}
                          name={FieldsNameMainSource.tradingSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameMain.founded}>Founded (date):</label>
                    <StyledInput
                      maxCharacters={4}
                      maxValues={3000}
                      isValid={checkingForm(values[FieldsNameMain.founded], numberExp)}
                      disabled={isDisabled}
                      defaultValue={companyExists?.main_info?.founded_date?.value}
                      id={FieldsNameMain.founded}
                      name={FieldsNameMain.founded}
                      placeholder="Enter year"
                    />
                    {checkingForm(values[FieldsNameMain.founded], numberExp) ? <StyledErr>Please enter numbers only</StyledErr> : null}
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.main_info.founded_date.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameMainSource.foundedSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.main_info.founded_date.source}
                          id={FieldsNameMainSource.foundedSource}
                          name={FieldsNameMainSource.foundedSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <div className="dFlexNoWrap">
                      <label htmlFor={FieldsNameMain.incorporated}>Incorporated in (jurisdiction):</label>
                      <StyledSelectMultiple
                        disabled={isDisabled}
                        onChange={setFieldValue}
                        selectionLimit
                        items={companyStore.company?.vocabulary.countries}
                        defaultValue={[companyExists.main_info.incorporated_in?.id].filter(Boolean)}
                        id={FieldsNameMain.incorporated}
                        name={FieldsNameMain.incorporated}
                        placeholder="Enter Jurisdiction"
                      />
                    </div>
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.main_info.incorporated_in.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameMainSource.incorporatedSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.main_info.incorporated_in.source}
                          id={FieldsNameMainSource.incorporatedSource}
                          name={FieldsNameMainSource.incorporatedSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <div className="dFlexNoWrap">
                      <label htmlFor={FieldsNameMain.incorporatedState}>State </label>
                      <StyledInput
                        maxCharacters={120}
                        disabled={isDisabled}
                        defaultValue={companyExists.main_info.incorporated_in_state.value}
                        id={FieldsNameMain.incorporatedState}
                        name={FieldsNameMain.incorporatedState}
                        placeholder="Enter State"
                      />
                    </div>
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.main_info.incorporated_in_state.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameMain.incorporatedState}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.main_info.incorporated_in_state.source}
                          id={FieldsNameMainSource.incorporatedStateSource}
                          name={FieldsNameMainSource.incorporatedStateSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameMain.status}>Operating Status:</label>
                    <MySelect
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={companyExists.main_info.operation_status.id}
                      items={companyStore.company?.vocabulary.operating_status}
                      id={FieldsNameMain.status}
                      name={FieldsNameMain.status}
                      placeholder="Select Status"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.main_info.operation_status.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameMainSource.statusSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.main_info.operation_status.source}
                          id={FieldsNameMainSource.statusSource}
                          name={FieldsNameMainSource.statusSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                </FormComponent>
                <FormComponent title={ITitleName.CONTACTS}>
                  <WrapperInput>
                    <label htmlFor={FieldsNameContacts.addressOne}>Head Office Address Line 1:</label>
                    <StyledInput
                      maxCharacters={1024}
                      disabled={isDisabled}
                      defaultValue={companyExists.contacts.head_office_address_line_1.value}
                      id={FieldsNameContacts.addressOne}
                      name={FieldsNameContacts.addressOne}
                      placeholder="Enter Address"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(companyExists.contacts.head_office_address_line_1.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameContactsSource.addressOneSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.contacts.head_office_address_line_1.source}
                          id={FieldsNameContactsSource.addressOneSource}
                          name={FieldsNameContactsSource.addressOneSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameContacts.addressTwo}>Head Office Address Line 2:</label>
                    <StyledInput
                      maxCharacters={1024}
                      disabled={isDisabled}
                      defaultValue={companyExists.contacts.head_office_address_line_2.value}
                      id={FieldsNameContacts.addressTwo}
                      name={FieldsNameContacts.addressTwo}
                      placeholder="Enter Address"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(companyExists.contacts.head_office_address_line_2.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameContactsSource.addressTwoSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.contacts.head_office_address_line_2.source}
                          id={FieldsNameContactsSource.addressTwoSource}
                          name={FieldsNameContactsSource.addressTwoSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameContacts.city}>Head Office Town/ City:</label>
                    <StyledInput
                      maxCharacters={100}
                      disabled={isDisabled}
                      defaultValue={companyExists.contacts.head_office_city.value}
                      id={FieldsNameContacts.city}
                      name={FieldsNameContacts.city}
                      placeholder="Enter Town/ City"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.contacts.head_office_city.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameContactsSource.citySource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.contacts.head_office_city.source}
                          id={FieldsNameContactsSource.citySource}
                          name={FieldsNameContactsSource.citySource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameContacts.state}>Head Office State:</label>
                    <StyledInput
                      maxCharacters={100}
                      disabled={isDisabled}
                      defaultValue={companyExists.contacts.head_office_state.value}
                      id={FieldsNameContacts.state}
                      name={FieldsNameContacts.state}
                      placeholder="Enter State"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.contacts.head_office_state.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameContactsSource.stateSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.contacts.head_office_state.source}
                          id={FieldsNameContactsSource.stateSource}
                          name={FieldsNameContactsSource.stateSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameContacts.country}>Head Office Country:</label>
                    <StyledSelectMultiple
                      className="head_country"
                      disabled={isDisabled}
                      selectionLimit
                      onChange={setFieldValue}
                      defaultValue={[companyExists?.contacts?.head_office_country?.id].filter(Boolean)}
                      items={companyStore.company?.vocabulary.countries}
                      id={FieldsNameContacts.country}
                      name={FieldsNameContacts.country}
                      placeholder="Select Country"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.contacts.head_office_country.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameContactsSource.countrySource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.contacts.head_office_country.source}
                          id={FieldsNameContactsSource.countrySource}
                          name={FieldsNameContactsSource.countrySource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameContacts.code}>Head Office Zip/ Post Code:</label>
                    <StyledInput
                      maxCharacters={50}
                      disabled={isDisabled}
                      defaultValue={companyExists.contacts.head_office_zip.value}
                      id={FieldsNameContacts.code}
                      name={FieldsNameContacts.code}
                      placeholder="Enter Zip/Post Code"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.contacts.head_office_zip.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameContactsSource.codeSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.contacts.head_office_zip.source}
                          id={FieldsNameContactsSource.codeSource}
                          name={FieldsNameContactsSource.codeSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameContacts.counterOfOffice}>Other Offices:</label>
                    <StyledInput
                      maxCharacters={100}
                      type="number"
                      disabled={isDisabled}
                      defaultValue={companyExists.contacts.other_offices_number.value}
                      id={FieldsNameContacts.counterOfOffice}
                      name={FieldsNameContacts.counterOfOffice}
                      placeholder="Enter Number Of Offices"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.contacts.other_offices_number.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameContactsSource.counteOfOfficeSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.contacts.other_offices_number.source}
                          id={FieldsNameContactsSource.counteOfOfficeSource}
                          name={FieldsNameContactsSource.counteOfOfficeSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <div>
                    <WrapperInput>
                      <StyledInputGroup className="decor">
                        {otherContacts?.map((el: any, index: any) => {
                          return (
                            <Fragment key={el + index || 90}>
                              <div className="flexWrap">
                                <div className="dflex">
                                  <label htmlFor={FieldsNameContacts.otherCity + index}>Other City:</label>
                                  <StyledInput
                                    maxCharacters={100}
                                    disabled={
                                      companyExists.record_stage === RecordStatus.reviewRecordStage ||
                                      (companyExists.record_stage === RecordStatus.completedRecordStage &&
                                        !companyExists?.record_accept_actions)
                                    }
                                    defaultValue={
                                      companyExists?.contacts?.other_offices?.[index]?.city?.value
                                        ? companyExists?.contacts?.other_offices[index]?.city?.value
                                        : ''
                                    }
                                    onChange={setFieldValue}
                                    className="input-group"
                                    id={FieldsNameContacts.otherCity + index}
                                    name={FieldsNameContacts.otherCity + index}
                                    placeholder="Enter City"
                                  />
                                </div>
                                <div className="dflex">
                                  <label htmlFor={index && FieldsNameContacts.otherCountry + index}>Other Country:</label>
                                  <StyledSelectMultiple
                                    className="othercountry"
                                    disabled={
                                      companyExists.record_stage === RecordStatus.reviewRecordStage ||
                                      (companyExists.record_stage === RecordStatus.completedRecordStage &&
                                        !companyExists?.record_accept_actions)
                                    }
                                    selectionLimit
                                    onChange={setFieldValue}
                                    defaultValue={
                                      //companyExists?.contacts?.other_offices?.[index]?.country?.id
                                      //  ? companyExists?.contacts?.other_offices[index]?.country?.id.split(' ')
                                      //  : ['']
                                      [companyExists?.contacts?.other_offices?.[index]?.country?.id].filter(Boolean)
                                    }
                                    items={companyStore.company?.vocabulary.countries}
                                    id={FieldsNameContacts.otherCountry + index}
                                    name={FieldsNameContacts.otherCountry + index}
                                    placeholder="Enter Country"
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="dflex">
                                  <label htmlFor={FieldsNameContacts.otherAddress + index}>
                                    Other
                                    <br /> Address:
                                  </label>
                                  <StyledInput
                                    maxCharacters={100}
                                    disabled={
                                      companyExists.record_stage === RecordStatus.reviewRecordStage ||
                                      (companyExists.record_stage === RecordStatus.completedRecordStage &&
                                        !companyExists?.record_accept_actions)
                                    }
                                    onChange={setFieldValue}
                                    defaultValue={
                                      companyExists?.contacts?.other_offices?.[index]?.location?.value
                                        ? companyExists?.contacts?.other_offices[index]?.location?.value
                                        : ''
                                    }
                                    className="input-group"
                                    id={FieldsNameContacts.otherAddress + index}
                                    name={FieldsNameContacts.otherAddress + index}
                                    placeholder="Enter Address"
                                  />
                                </div>
                              </div>
                            </Fragment>
                          );
                        })}
                        <button
                          disabled={isDisabled}
                          type="button"
                          onClick={() => {
                            const newArr = [...otherContacts, otherContacts.length];
                            otherContactsSet(newArr);
                          }}
                        >
                          <span>+ Add one more address</span>{' '}
                        </button>
                      </StyledInputGroup>
                      <WrapperNote>
                        <div className="date">
                          <p>
                            {' '}
                            Editing Date:<span>{displayDate(companyExists.contacts.other_offices_number.updated_at)}</span>
                          </p>
                        </div>
                        <WrapperInput>
                          <label htmlFor={FieldsNameContactsSource.otherOfficesSource}>Source </label>
                          <StyledInput
                            maxCharacters={1000}
                            disabled={
                              companyExists.record_stage === RecordStatus.reviewRecordStage ||
                              (companyExists.record_stage === RecordStatus.completedRecordStage &&
                                !companyExists?.record_accept_actions)
                            }
                            onChange={setFieldValue}
                            defaultValue={companyExists.contacts.other_offices_number.source}
                            id={FieldsNameContactsSource.otherOfficesSource}
                            name={FieldsNameContactsSource.otherOfficesSource}
                            placeholder="Enter Source"
                          />
                        </WrapperInput>
                      </WrapperNote>
                    </WrapperInput>
                  </div>
                  <WrapperInput className="phone" id="HeadOffice">
                    <label htmlFor={FieldsNameContacts.officesPhone}>Head Office Telephone Number:</label>
                    <InternationalPhoneInput
                      isValid={touched[FieldsNameContacts.officesPhone] && errors[FieldsNameContacts.officesPhone]}
                      defaultValue={companyExists?.contacts?.head_office_phone?.value}
                      id={FieldsNameContacts.officesPhone}
                      name={FieldsNameContacts.officesPhone}
                      placeholder="1-234-5678-90"
                    />
                    {touched[FieldsNameContacts.officesPhone] && errors[FieldsNameContacts.officesPhone] ? (
                      <StyledErr>{errors[FieldsNameContacts.officesPhone]}</StyledErr>
                    ) : null}
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.contacts.head_office_phone.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameContactsSource.officesPhoneSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.contacts.head_office_phone.source}
                          id={FieldsNameContactsSource.officesPhoneSource}
                          name={FieldsNameContactsSource.officesPhoneSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameContacts.webSite}>Head Office Website:</label>
                    <StyledInput
                      maxCharacters={100}
                      disabled={isDisabled}
                      defaultValue={companyExists.contacts.head_office_website.value}
                      id={FieldsNameContacts.webSite}
                      name={FieldsNameContacts.webSite}
                      placeholder="Enter Office Website"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.contacts.head_office_website.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameContactsSource.webSiteSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.contacts.head_office_website.source}
                          id={FieldsNameContactsSource.webSiteSource}
                          name={FieldsNameContactsSource.webSiteSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameContacts.mail}>For Sales Enquiries (Email):</label>
                    <StyledInput
                      maxCharacters={100}
                      isValid={checkingForm(values[FieldsNameContacts.mail], EmailExp)}
                      disabled={isDisabled}
                      defaultValue={companyExists.contacts.head_office_email.value}
                      id={FieldsNameContacts.mail}
                      name={FieldsNameContacts.mail}
                      placeholder="Enter Email"
                    />
                    {checkingForm(values[FieldsNameContacts.mail], EmailExp) ? (
                      <StyledErr>Please enter valid email address</StyledErr>
                    ) : null}
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.contacts.head_office_email.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameContactsSource.mailSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.contacts.head_office_email.source}
                          id={FieldsNameContactsSource.mailSource}
                          name={FieldsNameContactsSource.mailSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameContacts.linkedin}>Linkedin Profile:</label>
                    <StyledInput
                      isValid={checkingForm(values[FieldsNameContacts.linkedin], linkedinExp)}
                      maxCharacters={255}
                      disabled={isDisabled}
                      defaultValue={companyExists.contacts.linkedin.value}
                      id={FieldsNameContacts.linkedin}
                      name={FieldsNameContacts.linkedin}
                      placeholder="Enter Link"
                    />
                    {checkingForm(values[FieldsNameContacts.linkedin], linkedinExp) ? (
                      <StyledErr>Please enter valid link to LinkedIn Profile</StyledErr>
                    ) : null}
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.contacts.linkedin.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameContactsSource.linkedinSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.contacts.linkedin.source}
                          id={FieldsNameContactsSource.linkedinSource}
                          name={FieldsNameContactsSource.linkedinSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameContacts.twitter}>Twitter Handle:</label>
                    <StyledInput
                      isValid={checkingForm(values[FieldsNameContacts.twitter], twitterExp)}
                      maxCharacters={255}
                      disabled={isDisabled}
                      defaultValue={companyExists.contacts.twitter.value}
                      id={FieldsNameContacts.twitter}
                      name={FieldsNameContacts.twitter}
                      placeholder="Enter Link"
                    />
                    {checkingForm(values[FieldsNameContacts.twitter], twitterExp) ? (
                      <StyledErr>Please enter valid link to Twitter Handle</StyledErr>
                    ) : null}
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.contacts.twitter.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameContactsSource.twitterSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.contacts.twitter.source}
                          id={FieldsNameContactsSource.twitterSource}
                          name={FieldsNameContactsSource.twitterSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameContacts.facebook}>Facebook Page:</label>
                    <StyledInput
                      isValid={checkingForm(values[FieldsNameContacts.facebook], facebookExp)}
                      maxCharacters={255}
                      disabled={isDisabled}
                      defaultValue={companyExists.contacts.facebook.value}
                      id={FieldsNameContacts.facebook}
                      name={FieldsNameContacts.facebook}
                      placeholder="Enter Link"
                    />
                    {checkingForm(values[FieldsNameContacts.facebook], facebookExp) ? (
                      <StyledErr>Please enter valid link to Facebook Page</StyledErr>
                    ) : null}
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.contacts.facebook.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameContactsSource.facebookSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.contacts.facebook.source}
                          id={FieldsNameContactsSource.facebookSource}
                          name={FieldsNameContactsSource.facebookSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameContacts.youtube}>YouTube:</label>
                    <StyledInput
                      isValid={checkingForm(values[FieldsNameContacts.youtube], youtubeExp)}
                      maxCharacters={255}
                      disabled={isDisabled}
                      defaultValue={companyExists.contacts?.youtube?.value}
                      id={FieldsNameContacts.youtube}
                      name={FieldsNameContacts.youtube}
                      placeholder="Enter Link"
                    />
                    {checkingForm(values[FieldsNameContacts.youtube], youtubeExp) ? (
                      <StyledErr>Please enter valid link to YouTube</StyledErr>
                    ) : null}
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.contacts.youtube.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameContactsSource.youtubeSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.contacts.youtube.source}
                          id={FieldsNameContactsSource.youtubeSource}
                          name={FieldsNameContactsSource.youtubeSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                </FormComponent>
                <FormComponent title={ITitleName.WHAT_THEY_DO}>
                  <WrapperInput>
                    <label htmlFor={FieldsNameTheyDo.categories}>Master Categories:</label>
                    <SelectMultiple
                      disabled={isDisabled}
                      defaultValue={companyExists.what_whey_do.master_categories.ids}
                      onChange={setFieldValue}
                      items={companyStore.company?.vocabulary.categories}
                      id={FieldsNameTheyDo.categories}
                      name={FieldsNameTheyDo.categories}
                      placeholder="Select Categories"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.what_whey_do.master_categories.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameTheyDoSource.categoriesSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.what_whey_do.master_categories.source}
                          id={FieldsNameTheyDoSource.categoriesSource}
                          name={FieldsNameTheyDoSource.categoriesSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameTheyDo.subCategories}>Subcategories:</label>
                    <SelectMultiple
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      subCategory
                      defaultValue={companyExists.what_whey_do.sub_categories.ids}
                      filteredItem={filteringBySelectedmasterCategories(
                        companyStore.company?.vocabulary.categories,
                        values[FieldsNameTheyDo.categories]
                      )}
                      items={companyStore.company?.vocabulary.categories}
                      id={FieldsNameTheyDo.subCategories}
                      name={FieldsNameTheyDo.subCategories}
                      placeholder="Select Subcategories"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.what_whey_do.sub_categories.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameTheyDoSource.subCategoriesSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.what_whey_do.sub_categories.source}
                          id={FieldsNameTheyDoSource.subCategoriesSource}
                          name={FieldsNameTheyDoSource.subCategoriesSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameTheyDo.otherCategories}>Other Tags:</label>
                    <StyledInput
                      maxCharacters={255}
                      disabled={isDisabled}
                      defaultValue={companyExists.what_whey_do.tags.value}
                      id={FieldsNameTheyDo.otherCategories}
                      name={FieldsNameTheyDo.otherCategories}
                      placeholder="Select Tags"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.what_whey_do.tags.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameTheyDoSource.otherCategoriesSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.what_whey_do.tags.source}
                          id={FieldsNameTheyDoSource.otherCategoriesSource}
                          name={FieldsNameTheyDoSource.otherCategoriesSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameTheyDo.description}>Short Description:</label>
                    <StyledInput
                      maxCharacters={500}
                      disabled={isDisabled}
                      defaultValue={companyExists.what_whey_do.short_description.value}
                      id={FieldsNameTheyDo.description}
                      name={FieldsNameTheyDo.description}
                      placeholder="Enter Short Description"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.what_whey_do.short_description.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameTheyDoSource.descriptionSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.what_whey_do.short_description.source}
                          id={FieldsNameTheyDoSource.descriptionSource}
                          name={FieldsNameTheyDoSource.descriptionSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameTheyDo.longDescription}>Long Description:</label>
                    <TextArea
                      maxCharacters={3000}
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={companyExists.what_whey_do.long_description.value}
                      id={FieldsNameTheyDo.longDescription}
                      name={FieldsNameTheyDo.longDescription}
                      placeholder="Enter Long Description"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.what_whey_do.long_description.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameTheyDoSource.longDescriptionSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.what_whey_do.long_description.source}
                          id={FieldsNameTheyDoSource.longDescriptionSource}
                          name={FieldsNameTheyDoSource.longDescriptionSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameTheyDo.revenue}>% Revenue - Cybersecurity and Risk:</label>
                    <StyledInput
                      maxCharacters={3}
                      maxValues={100}
                      isValid={
                        checkingForm(values[FieldsNameTheyDo.revenue], numberExp) ||
                        (errors[FieldsNameTheyDo.revenue] && touched[FieldsNameTheyDo.revenue])
                      }
                      disabled={isDisabled}
                      defaultValue={companyExists?.what_whey_do?.revenue_mix?.value}
                      id={FieldsNameTheyDo.revenue}
                      name={FieldsNameTheyDo.revenue}
                      placeholder="Enter % Revenue"
                    />
                    {checkingForm(values[FieldsNameTheyDo.revenue], numberExp) ||
                    (errors[FieldsNameTheyDo.revenue] && touched[FieldsNameTheyDo.revenue]) ? (
                      <StyledErr>Please enter numbers only</StyledErr>
                    ) : null}
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.what_whey_do.revenue_mix.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameTheyDoSource.revenueSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.what_whey_do.revenue_mix.source}
                          id={FieldsNameTheyDoSource.revenueSource}
                          name={FieldsNameTheyDoSource.revenueSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInputData id="validationImg">
                    <WrapperInput>
                      <label>
                        <img src={imgSvg} alt="img" />{' '}
                        <input
                          className="input-file"
                          disabled={
                            companyExists.record_stage === RecordStatus.reviewRecordStage ||
                            (companyExists.record_stage === RecordStatus.completedRecordStage &&
                              !companyExists.record_accept_actions)
                          }
                          id="inputLogo"
                          ref={imgInput}
                          onClick={() => setLogoValid(false)}
                          onChange={(e) => {
                          
                            if (e.target.files) {
                              encodeImageFileAsURL(e.target.files);
                            }
                          }}
                          name={FieldsNameTheyDo.logoImg}
                          accept=".png,.jpg,.svg"
                          type="file"
                          alt="img input"
                        />
                        <span>Upload logo</span>
                      </label>

                      {imgInputName && (
                        <div className="inputFileWrapper">
                          <p className="inputFile">{imgInputName}</p>
                          <button
                            onClick={() => {
                              setLogoValid(false);
                              if (imgInputName) {
                                setImgInputName('');
                                imgInput.current.value = '';
                              }
                            }}
                            type="button"
                            className="deleteFile"
                            aria-label="delete file"
                          >
                            <img className="inputFile--delete" src={deleteFileSvg} alt="delete ico" />
                          </button>
                        </div>
                      )}
                      <ul>
                        <li>PNG or JPG or SVG</li>
                        <li>1000x1000px</li>
                        <li>No More than 5 mb</li>
                      </ul>
                      <p className={logoValid ? 'logoValid' : 'none'}>{messageError}</p>
                      <WrapperNote className="sourceToFile">
                        <div className="date">
                          <p>
                            {' '}
                            Editing Date:<span>{displayDate(companyExists?.logo?.updated_at)}</span>
                          </p>
                        </div>
                        <WrapperInput>
                          <label htmlFor={FieldsNameMainSource.logoSource}>Source </label>
                          <StyledInput
                            maxCharacters={1000}
                            disabled={isDisabled}
                            defaultValue={companyExists?.logo?.source}
                            id={FieldsNameMainSource.logoSource}
                            name={FieldsNameMainSource.logoSource}
                            placeholder="Enter Source"
                          />
                        </WrapperInput>
                      </WrapperNote>
                    </WrapperInput>
                    <WrapperInput>
                      <div>
                        <label>
                          <img src={fileSvg} alt="" />
                          <input
                            className="input-file"
                            disabled={isDisabled}
                            ref={fileInput}
                            onChange={(e) => {
                              if (e.target.files) {
                                onLoadFile(e.target.files[0]);
                              }
                            }}
                            name={FieldsNameTheyDo.brochure}
                            accept=".doc,.docx,.pdf,.ttf,.pttx"
                            type="file"
                            alt="img input"
                          />
                          <span>Upload product brochure</span>
                        </label>
                      </div>
                      <div className="brochureWrapper">
                        <ul>
                          <li>PDF,PTTX,DOC,TTF</li>
                          <li>No more than 50 mb</li>
                        </ul>
                        {brochureName?.length ? (
                          <ul className="inputFileWrapperList">
                            {brochureName?.map(
                              (el) =>
                                el && (
                                  <li key={el?.id || brochureName.length + 1}>
                                    <p className="inputFile">{el.title}</p>
                                    <button
                                      onClick={() => {
                                        if (brochureName) {
                                          const res = brochureName.filter((name) => name.id !== el.id);
                                          setBrochureName(res);
                                          fileInput.current.value = '';
                                        }
                                      }}
                                      type="button"
                                      className="deleteFile"
                                      aria-label="delete file"
                                    >
                                      <img className="inputFile--delete" src={deleteFileSvg} alt="delete ico" />
                                    </button>
                                  </li>
                                )
                            )}
                          </ul>
                        ) : null}
                        <WrapperNote className="sourceToFile sourceToFile--position">
                          <div className="date">
                            <p>
                              {' '}
                              Editing Date:<span>{displayDate(companyExists?.brochures?.created_at)}</span>
                            </p>
                          </div>
                          <WrapperInput>
                            <label htmlFor={FieldsNameMainSource.brochureSource}>Source </label>
                            <StyledInput
                              maxCharacters={1000}
                              disabled={isDisabled}
                              defaultValue={companyExists?.brochures?.source}
                              id={FieldsNameMainSource.brochureSource}
                              name={FieldsNameMainSource.brochureSource}
                              placeholder="Enter Source"
                            />
                          </WrapperInput>
                        </WrapperNote>
                      </div>
                    </WrapperInput>
                  </WrapperInputData>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameTheyDo.youtubeLink}>Video Profiles:</label>
                    <StyledInput
                      maxCharacters={512}
                      disabled={isDisabled}
                      defaultValue={companyExists.what_whey_do.video_links.value}
                      id={FieldsNameTheyDo.youtubeLink}
                      name={FieldsNameTheyDo.youtubeLink}
                      placeholder="Enter Link "
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.what_whey_do.video_links.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameTheyDoSource.youtubeLinkSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.what_whey_do.video_links.source}
                          id={FieldsNameTheyDoSource.youtubeLinkSource}
                          name={FieldsNameTheyDoSource.youtubeLinkSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                </FormComponent>
                <FormComponent title={ITitleName.COMPANY_PRODUCTS_SOLUTIONS}>
                  <StyledListProducts>
                    {companyExists.solutions
                      ? companyExists.solutions.map(
                          (el: any) =>
                            el?.title && (
                              <li key={el.id}>
                                <Link to={`/solution/${el.slug}`}>
                                  {el.title}
                                  <span />
                                </Link>
                              </li>
                            )
                        )
                      : 'The company does not have any solutions yet'}
                  </StyledListProducts>
                </FormComponent>
                <FormComponent title={ITitleName.WHO_THEY_DO_IT_FOR}>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameTheyDoItFor.industry}>Customer Industry:</label>
                    <SelectMultiple
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={companyExists.who_they_do_it_for.custumer_industry.ids}
                      items={companyStore.company?.vocabulary.customer_industry}
                      id={FieldsNameTheyDoItFor.industry}
                      name={FieldsNameTheyDoItFor.industry}
                      placeholder="Select Industry"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(companyExists.who_they_do_it_for.custumer_industry.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameTheyDoItForSource.industrySource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.who_they_do_it_for.custumer_industry.source}
                          id={FieldsNameTheyDoItForSource.industrySource}
                          name={FieldsNameTheyDoItForSource.industrySource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameTheyDoItFor.type}>Customer Type:</label>
                    <SelectMultiple
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={companyExists.who_they_do_it_for.customer_type.ids}
                      items={companyStore.company?.vocabulary.customer_type}
                      id={FieldsNameTheyDoItFor.type}
                      name={FieldsNameTheyDoItFor.type}
                      placeholder="Select Customer Type"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.who_they_do_it_for.customer_type.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameTheyDoItForSource.typeSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.who_they_do_it_for.customer_type.source}
                          id={FieldsNameTheyDoItForSource.typeSource}
                          name={FieldsNameTheyDoItForSource.typeSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameTheyDoItFor.sizeEmployees}>Customer Size (employees):</label>
                    <SelectMultiple
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={companyExists.who_they_do_it_for.customer_size_employees.ids}
                      items={companyStore.company?.vocabulary.customer_size_employees}
                      id={FieldsNameTheyDoItFor.sizeEmployees}
                      name={FieldsNameTheyDoItFor.sizeEmployees}
                      placeholder="Select Customer Size (employees)"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(companyExists.who_they_do_it_for.customer_size_employees.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameTheyDoItForSource.sizeEmployeesSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.who_they_do_it_for.customer_size_employees.source}
                          id={FieldsNameTheyDoItForSource.sizeEmployeesSource}
                          name={FieldsNameTheyDoItForSource.sizeEmployeesSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameTheyDoItFor.sizeRevenues}>Customer Size (revenues):</label>
                    <SelectMultiple
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={companyExists.who_they_do_it_for.customer_size_reveneues.ids}
                      items={companyStore.company?.vocabulary.customer_size_revenues}
                      id={FieldsNameTheyDoItFor.sizeRevenues}
                      name={FieldsNameTheyDoItFor.sizeRevenues}
                      placeholder="Select Customer Size (revenues)"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(companyExists.who_they_do_it_for.customer_size_reveneues.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameTheyDoItForSource.sizeRevenuesSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.who_they_do_it_for.customer_size_reveneues.source}
                          id={FieldsNameTheyDoItForSource.sizeRevenuesSource}
                          name={FieldsNameTheyDoItForSource.sizeRevenuesSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameTheyDoItFor.locationCustomer}>Customer Location:</label>
                    <SelectMultiple
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={companyExists.who_they_do_it_for.customer_location.ids}
                      items={companyStore.company?.vocabulary.countries}
                      id={FieldsNameTheyDoItFor.locationCustomer}
                      name={FieldsNameTheyDoItFor.locationCustomer}
                      placeholder="Select Customer Location"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(companyExists.who_they_do_it_for.customer_location.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameTheyDoItForSource.locationCustomerSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.who_they_do_it_for.customer_location.source}
                          id={FieldsNameTheyDoItForSource.locationCustomerSource}
                          name={FieldsNameTheyDoItForSource.locationCustomerSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameTheyDoItFor.customerDescription}>Customer Description:</label>
                    <StyledInput
                      maxCharacters={2048}
                      disabled={isDisabled}
                      defaultValue={companyExists.who_they_do_it_for.customer_description.value}
                      id={FieldsNameTheyDoItFor.customerDescription}
                      name={FieldsNameTheyDoItFor.customerDescription}
                      placeholder="Enter Customer Description"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(companyExists.who_they_do_it_for.customer_description.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameTheyDoItForSource.customerDescriptionSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.who_they_do_it_for.customer_description.source}
                          id={FieldsNameTheyDoItForSource.customerDescriptionSource}
                          name={FieldsNameTheyDoItForSource.customerDescriptionSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameTheyDoItFor.marquee}>Marquee Customers:</label>
                    <StyledInput
                      maxCharacters={2048}
                      disabled={isDisabled}
                      defaultValue={companyExists.who_they_do_it_for.customer_marquee.value}
                      id={FieldsNameTheyDoItFor.marquee}
                      name={FieldsNameTheyDoItFor.marquee}
                      placeholder="Enter Marquee Customers"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(companyExists.who_they_do_it_for.customer_marquee.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameTheyDoItForSource.marqueeSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.who_they_do_it_for.customer_marquee.source}
                          id={FieldsNameTheyDoItForSource.marqueeSource}
                          name={FieldsNameTheyDoItForSource.marqueeSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameTheyDoItFor.userGroup}>Customer User Group (ie Buyer):</label>
                    <SelectMultiple
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={companyExists.who_they_do_it_for.customer_user_group.ids}
                      items={companyStore.company?.vocabulary.customer_user_group}
                      id={FieldsNameTheyDoItFor.userGroup}
                      name={FieldsNameTheyDoItFor.userGroup}
                      placeholder="Select Customer User Group"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(companyExists.who_they_do_it_for.customer_user_group.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameTheyDoItForSource.userGroupSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.who_they_do_it_for.customer_user_group.source}
                          id={FieldsNameTheyDoItForSource.userGroupSource}
                          name={FieldsNameTheyDoItForSource.userGroupSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameTheyDoItFor.languages}>Customer Languages Supported:</label>
                    <SelectMultiple
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={companyExists.who_they_do_it_for.customer_langueages_supported.ids}
                      items={companyStore.company?.vocabulary.customer_language}
                      id={FieldsNameTheyDoItFor.languages}
                      name={FieldsNameTheyDoItFor.languages}
                      placeholder="Select Languages"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>
                            {displayDate(companyExists.who_they_do_it_for.customer_langueages_supported.updated_at)}
                          </span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameTheyDoItForSource.languagesSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.who_they_do_it_for.customer_langueages_supported.source}
                          id={FieldsNameTheyDoItForSource.languagesSource}
                          name={FieldsNameTheyDoItForSource.languagesSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                </FormComponent>
                <FormComponent title={ITitleName.WHAT_STAGE_ARE_THEY_AT}>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameStage.privat}>Private/ Public:</label>
                    <MySelect
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={companyExists.what_stage_are_they_at.private_public.id}
                      items={companyStore.company?.vocabulary.vendor_type}
                      id={FieldsNameStage.privat}
                      name={FieldsNameStage.privat}
                      placeholder="Select Stage"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(companyExists.what_stage_are_they_at.private_public.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameStageSource.privatSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.what_stage_are_they_at.private_public.source}
                          id={FieldsNameStageSource.privatSource}
                          name={FieldsNameStageSource.privatSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameStage.exchange}>Public Company Exchange:</label>
                    <StyledInput
                      maxCharacters={1024}
                      isValid={touched[FieldsNameStage.exchange] && errors[FieldsNameStage.exchange]}
                      disabled={isDisabled}
                      defaultValue={companyExists.what_stage_are_they_at.public_company_exchange.value}
                      id={FieldsNameStage.exchange}
                      name={FieldsNameStage.exchange}
                      placeholder="Enter Details"
                    />
                    {touched[FieldsNameStage.exchange] && errors[FieldsNameStage.exchange] ? (
                      <StyledErr>{errors[FieldsNameStage.exchange]}</StyledErr>
                    ) : null}
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>
                            {displayDate(companyExists.what_stage_are_they_at.public_company_exchange.updated_at)}
                          </span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameStageSource.exchangeSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.what_stage_are_they_at.public_company_exchange.source}
                          id={FieldsNameStageSource.exchangeSource}
                          name={FieldsNameStageSource.exchangeSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameStage.ticker}>Public Company Ticker:</label>
                    <StyledInput
                      maxCharacters={1024}
                      isValid={touched[FieldsNameStage.ticker] && errors[FieldsNameStage.ticker]}
                      disabled={isDisabled}
                      defaultValue={companyExists.what_stage_are_they_at.public_company_ticker.value}
                      id={FieldsNameStage.ticker}
                      name={FieldsNameStage.ticker}
                      placeholder="Enter Source"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(companyExists.who_has_an_interest.board_members.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameStageSource.tickerSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.what_stage_are_they_at.public_company_ticker.source}
                          id={FieldsNameStageSource.tickerSource}
                          name={FieldsNameStageSource.tickerSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameStage.capital}>Private Company: Capital Raised:</label>
                    <StyledInput
                      maxCharacters={1024}
                      isValid={touched[FieldsNameStage.capital] && errors[FieldsNameStage.capital]}
                      disabled={isDisabled}
                      defaultValue={companyExists.what_stage_are_they_at.public_company_capital_raised.value}
                      id={FieldsNameStage.capital}
                      name={FieldsNameStage.capital}
                      placeholder="Enter Details"
                    />
                    {touched[FieldsNameStage.capital] && errors[FieldsNameStage.capital] ? (
                      <StyledErr>{errors[FieldsNameStage.capital]}</StyledErr>
                    ) : null}
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>
                            {displayDate(companyExists.what_stage_are_they_at.public_company_capital_raised.updated_at)}
                          </span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameStageSource.capitalSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.what_stage_are_they_at.public_company_capital_raised.source}
                          id={FieldsNameStageSource.capitalSource}
                          name={FieldsNameStageSource.capitalSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameStage.listRound}>Private Company: Last Funding Round</label>
                    <StyledInput
                      maxCharacters={1024}
                      disabled={isDisabled}
                      defaultValue={companyExists.what_stage_are_they_at.public_company_last_founding_round.value}
                      id={FieldsNameStage.listRound}
                      name={FieldsNameStage.listRound}
                      placeholder="Enter Details"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>
                            {displayDate(
                              companyExists.what_stage_are_they_at.public_company_last_founding_round.updated_at
                            )}
                          </span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameStageSource.listRoundSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.what_stage_are_they_at.public_company_last_founding_round.source}
                          id={FieldsNameStageSource.listRoundSource}
                          name={FieldsNameStageSource.listRoundSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameStage.phase}>Company Phase:</label>
                    <MySelect
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={companyExists.what_stage_are_they_at.company_phase.id}
                      items={companyStore.company?.vocabulary.company_phase}
                      id={FieldsNameStage.phase}
                      name={FieldsNameStage.phase}
                      placeholder="Select Phase"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(companyExists.what_stage_are_they_at.company_phase.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameStageSource.phaseSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.what_stage_are_they_at.company_phase.source}
                          id={FieldsNameStageSource.phaseSource}
                          name={FieldsNameStageSource.phaseSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameStage.orgEmployees}>Org Size (# employees):</label>
                    <MySelect
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={companyExists.what_stage_are_they_at.company_org_size_employees.id}
                      items={companyStore.company?.vocabulary.org_size_employees}
                      id={FieldsNameStage.orgEmployees}
                      name={FieldsNameStage.orgEmployees}
                      placeholder="Select Org Size"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>
                            {displayDate(companyExists.what_stage_are_they_at.company_org_size_employees.updated_at)}
                          </span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameStageSource.orgEmployeesSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.what_stage_are_they_at.company_org_size_employees.source}
                          id={FieldsNameStageSource.orgEmployeesSource}
                          name={FieldsNameStageSource.orgEmployeesSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameStage.orgRevenues}>Org Size (revenues):</label>
                    <MySelect
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={companyExists.what_stage_are_they_at.company_org_size_revenues.id}
                      items={companyStore.company?.vocabulary.org_size_revenues}
                      id={FieldsNameStage.orgRevenues}
                      name={FieldsNameStage.orgRevenues}
                      placeholder="Select Org Size"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>
                            {displayDate(companyExists.what_stage_are_they_at.company_org_size_revenues.updated_at)}
                          </span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameStageSource.orgRevenuesSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.what_stage_are_they_at.company_org_size_revenues.source}
                          id={FieldsNameStageSource.orgRevenuesSource}
                          name={FieldsNameStageSource.orgRevenuesSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameStage.ebitda}>EBITDA:</label>
                    <MySelect
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={companyExists.what_stage_are_they_at.company_org_size_ebitda.id}
                      items={companyStore.company?.vocabulary.ebitda}
                      id={FieldsNameStage.ebitda}
                      name={FieldsNameStage.ebitda}
                      placeholder="Select EBITDA"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>
                            {displayDate(companyExists.what_stage_are_they_at.company_org_size_ebitda.updated_at)}
                          </span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameStageSource.ebitdaSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.what_stage_are_they_at.company_org_size_ebitda.source}
                          id={FieldsNameStageSource.ebitdaSource}
                          name={FieldsNameStageSource.ebitdaSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                </FormComponent>
                <FormComponent title={ITitleName.WHO_HAS_AN_INTEREST}>
                  <WrapperInput>
                    <label htmlFor={FieldsNameInterest.ceo}>CEO:</label>
                    <StyledInput
                      maxCharacters={512}
                      disabled={isDisabled}
                      defaultValue={companyExists.who_has_an_interest.ceo.value}
                      id={FieldsNameInterest.ceo}
                      name={FieldsNameInterest.ceo}
                      placeholder="Enter CEO"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.main_info.trading_as.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameInterestSource.ceoSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.who_has_an_interest.ceo.source}
                          id={FieldsNameInterestSource.ceoSource}
                          name={FieldsNameInterestSource.ceoSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameInterest.board}>Board Members:</label>
                    <StyledInput
                      maxCharacters={1024}
                      disabled={isDisabled}
                      defaultValue={companyExists.who_has_an_interest.board_members.value}
                      id={FieldsNameInterest.board}
                      name={FieldsNameInterest.board}
                      placeholder="Enter Board Members"
                    />
                    {touched[FieldsNameInterest.board] && errors[FieldsNameInterest.board] ? (
                      <StyledErr>{errors[FieldsNameInterest.board]}</StyledErr>
                    ) : null}
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(companyExists.who_has_an_interest.board_members.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameInterestSource.boardSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.who_has_an_interest.board_members.source}
                          id={FieldsNameInterestSource.boardSource}
                          name={FieldsNameInterestSource.boardSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameInterest.controlInvestors}>
                      Controlling Investors <br />
                      {`( >50% equity or voting control ):`}
                    </label>
                    <StyledInput
                      maxCharacters={1024}
                      disabled={isDisabled}
                      defaultValue={companyExists.who_has_an_interest.controlling_investors.value}
                      id={FieldsNameInterest.controlInvestors}
                      name={FieldsNameInterest.controlInvestors}
                      placeholder="Enter Controlling Investors"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(companyExists.who_has_an_interest.controlling_investors.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameInterestSource.controlInvestorsSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.who_has_an_interest.controlling_investors.source}
                          id={FieldsNameInterestSource.controlInvestorsSource}
                          name={FieldsNameInterestSource.controlInvestorsSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameInterest.investors}>Investors:</label>
                    <StyledInput
                      maxCharacters={1024}
                      disabled={isDisabled}
                      defaultValue={companyExists.who_has_an_interest.controlling_investors.value}
                      id={FieldsNameInterest.investors}
                      name={FieldsNameInterest.investors}
                      placeholder="Enter Investors"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(companyExists.who_has_an_interest.controlling_investors.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameInterestSource.investorsSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.who_has_an_interest.controlling_investors.source}
                          id={FieldsNameInterestSource.investorsSource}
                          name={FieldsNameInterestSource.investorsSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameInterest.investorsNationality}>
                      Investor Nationality
                      <br /> {` (for those holding >10% equity or voting interest:`}
                    </label>
                    <SelectMultiple
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={companyExists.who_has_an_interest.investor_nationality.ids}
                      items={companyStore.company?.vocabulary.nationality}
                      id={FieldsNameInterest.investorsNationality}
                      name={FieldsNameInterest.investorsNationality}
                      placeholder="Enter Nationality"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(companyExists.who_has_an_interest.investor_nationality.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameInterestSource.investorsNationalitySource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.who_has_an_interest.investor_nationality.source}
                          id={FieldsNameInterestSource.investorsNationalitySource}
                          name={FieldsNameInterestSource.investorsNationalitySource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameInterest.original}>Original Founders:</label>
                    <StyledInput
                      maxCharacters={1024}
                      disabled={isDisabled}
                      defaultValue={companyExists.who_has_an_interest.original_founders.value}
                      id={FieldsNameInterest.original}
                      name={FieldsNameInterest.original}
                      placeholder="Enter Original Founders"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(companyExists.who_has_an_interest.original_founders.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameInterestSource.originalSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.who_has_an_interest.original_founders.source}
                          id={FieldsNameInterestSource.originalSource}
                          name={FieldsNameInterestSource.originalSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                </FormComponent>
                <FormComponent title={ITitleName.SPECIAL_MENTIONS}>
                  <WrapperInput>
                    <label htmlFor={FieldsNameMentions.mentors}>Mentors:</label>
                    <StyledInput
                      maxCharacters={1024}
                      disabled={isDisabled}
                      defaultValue={companyExists.special_mentions.mentors.value}
                      id={FieldsNameMentions.mentors}
                      name={FieldsNameMentions.mentors}
                      placeholder="Enter Mentors"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.special_mentions.mentors.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameMentionsSource.mentorsSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.special_mentions.mentors.source}
                          id={FieldsNameMentionsSource.mentorsSource}
                          name={FieldsNameMentionsSource.mentorsSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameMentions.mentees}>Mentees:</label>
                    <StyledInput
                      maxCharacters={1024}
                      disabled={isDisabled}
                      defaultValue={companyExists.special_mentions.mentees.value}
                      id={FieldsNameMentions.mentees}
                      name={FieldsNameMentions.mentees}
                      placeholder="Enter Mentees"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.special_mentions.mentees.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameMentionsSource.menteesSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.special_mentions.mentees.source}
                          id={FieldsNameMentionsSource.menteesSource}
                          name={FieldsNameMentionsSource.menteesSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameMentions.awards}>Awards:</label>
                    <TextArea
                      maxCharacters={5000}
                      disabled={isDisabled}
                      defaultValue={helpers.renderArrayToString(companyExists.special_mentions.awards.values)}
                      id={FieldsNameMentions.awards}
                      name={FieldsNameMentions.awards}
                      placeholder="Enter Awards"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.special_mentions.awards.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameMentionsSource.awardsSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.special_mentions.awards.source}
                          id={FieldsNameMentionsSource.awardsSource}
                          name={FieldsNameMentionsSource.awardsSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameMentions.graduated}>Graduated From:</label>
                    <StyledInput
                      maxCharacters={1024}
                      disabled={isDisabled}
                      defaultValue={helpers.renderArrayToString(companyExists.special_mentions.graduated_from.values)}
                      id={FieldsNameMentions.graduated}
                      name={FieldsNameMentions.graduated}
                      placeholder="Enter Details"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.special_mentions.graduated_from.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameMentionsSource.graduatedSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.special_mentions.graduated_from.source}
                          id={FieldsNameMentionsSource.graduatedSource}
                          name={FieldsNameMentionsSource.graduatedSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameMentions.industryMentions}>Industry Memberships:</label>
                    <StyledInput
                      maxCharacters={1024}
                      disabled={isDisabled}
                      defaultValue={helpers.renderArrayToString(companyExists.special_mentions.industry_memberships.values)}
                      id={FieldsNameMentions.industryMentions}
                      name={FieldsNameMentions.industryMentions}
                      placeholder="Enter Industry"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(companyExists.special_mentions.industry_memberships.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameMentionsSource.industryMentionsSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.special_mentions.industry_memberships.source}
                          id={FieldsNameMentionsSource.industryMentionsSource}
                          name={FieldsNameMentionsSource.industryMentionsSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameMentions.classes}>Special Classes:</label>
                    <SelectMultiple
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={companyExists.special_mentions.special_classes.ids}
                      items={companyStore.company?.vocabulary.special_classes}
                      id={FieldsNameMentions.classes}
                      name={FieldsNameMentions.classes}
                      placeholder="Select Classes"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:<span>{displayDate(companyExists.special_mentions.special_classes.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameMentionsSource.classesSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.special_mentions.special_classes.source}
                          id={FieldsNameMentionsSource.classesSource}
                          name={FieldsNameMentionsSource.classesSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                </FormComponent>
                <FormComponent title={ITitleName.COMPLIANCE_RISK}>
                  <WrapperInput>
                    <label htmlFor={FieldsNameRisk.audited}>Audited Financials:</label>
                    <MySelect
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={companyExists.compliance_and_risk.audited_finances.id}
                      items={[
                        { id: 'yes', title: 'Yes' },
                        { id: 'no', title: 'No' },
                        { id: '', title: 'Тothing' },
                      ]}
                      id={FieldsNameRisk.audited}
                      name={FieldsNameRisk.audited}
                      placeholder="Select Option"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(companyExists.compliance_and_risk.audited_finances.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameRiskSource.auditedSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.compliance_and_risk.audited_finances.source}
                          id={FieldsNameRiskSource.auditedSource}
                          name={FieldsNameRiskSource.auditedSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameRisk.compliance}>Compliance Audits:</label>
                    <MySelect
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={companyExists?.compliance_and_risk?.compliance_audits.id}
                      items={companyStore.company?.vocabulary?.compliance_audit}
                      id={FieldsNameRisk.compliance}
                      name={FieldsNameRisk.compliance}
                      placeholder="Select Option"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(companyExists.compliance_and_risk.compliance_audits.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameRiskSource.complianceSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.compliance_and_risk.compliance_audits.source}
                          id={FieldsNameRiskSource.complianceSource}
                          name={FieldsNameRiskSource.complianceSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameRisk.security}>Security Standards:</label>
                    <SelectMultiple
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={companyExists.compliance_and_risk.security_standards.ids}
                      items={companyStore.company?.vocabulary.security_standards}
                      id={FieldsNameRisk.security}
                      name={FieldsNameRisk.security}
                      placeholder="Select Security Standards"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>{displayDate(companyExists.compliance_and_risk.security_standards.updated_at)}</span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameRiskSource.securitySource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.compliance_and_risk.security_standards.source}
                          id={FieldsNameRiskSource.securitySource}
                          name={FieldsNameRiskSource.securitySource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                </FormComponent>
                <FormComponent title={ITitleName.CATALISTO_INTERNAL_SCREENING_NOTES}>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameNotes.connect}>Company Would Like to Connect With:</label>
                    <SelectMultiple
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={companyExists.catalisto_internal_screening_notes.company_would_like_to_connect_with.ids}
                      items={companyStore.company?.vocabulary.vendor_interests}
                      id={FieldsNameNotes.connect}
                      name={FieldsNameNotes.connect}
                      placeholder="Select Company"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>
                            {displayDate(
                              companyExists.catalisto_internal_screening_notes?.company_would_like_to_connect_with
                                ?.updated_at
                            )}
                          </span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameNotesSource.connectSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={
                            companyExists.catalisto_internal_screening_notes.company_would_like_to_connect_with.source
                          }
                          id={FieldsNameNotesSource.connectSource}
                          name={FieldsNameNotesSource.connectSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameNotes.notesOnManagement}>
                      Catalisto Notes on Management,
                      <br /> Technology and Financial Resilience:
                    </label>
                    <TextArea
                      maxCharacters={5000}
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={companyExists.catalisto_internal_screening_notes.catalisto_notes_on_managment.value}
                      id={FieldsNameNotes.notesOnManagement}
                      name={FieldsNameNotes.notesOnManagement}
                      placeholder="Enter Notes"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>
                            {displayDate(
                              companyExists.catalisto_internal_screening_notes.catalisto_notes_on_managment.updated_at
                            )}
                          </span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameNotesSource.notesOnManagementSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={
                            companyExists.catalisto_internal_screening_notes.catalisto_notes_on_managment.source
                          }
                          id={FieldsNameNotesSource.notesOnManagementSource}
                          name={FieldsNameNotesSource.notesOnManagementSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameNotes.risk}>Sustainability Risk of Vendor:</label>
                    <MySelect
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={companyExists.catalisto_internal_screening_notes.sustainability_risk_of_vendor.id}
                      items={companyStore.company?.vocabulary.vendor_sustainability}
                      id={FieldsNameNotes.risk}
                      name={FieldsNameNotes.risk}
                      placeholder="Select Option"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>
                            {displayDate(
                              companyExists.catalisto_internal_screening_notes.sustainability_risk_of_vendor.updated_at
                            )}
                          </span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameNotesSource.riskSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={
                            companyExists.catalisto_internal_screening_notes.sustainability_risk_of_vendor.source
                          }
                          id={FieldsNameNotesSource.riskSource}
                          name={FieldsNameNotesSource.riskSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput className="decor">
                    <label htmlFor={FieldsNameNotes.notesFocusNextInterview}>
                      Catalisto Notes to Focus <br /> on in Next Interview:
                    </label>
                    <TextArea
                      maxCharacters={5000}
                      disabled={isDisabled}
                      onChange={setFieldValue}
                      defaultValue={companyExists.catalisto_internal_screening_notes.focus_on_in_next_interview.value}
                      id={FieldsNameNotes.notesFocusNextInterview}
                      name={FieldsNameNotes.notesFocusNextInterview}
                      placeholder="Enter Details"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>
                            {displayDate(
                              companyExists.catalisto_internal_screening_notes.focus_on_in_next_interview.updated_at
                            )}
                          </span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameNotesSource.notesFocusNextInterviewSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.catalisto_internal_screening_notes.focus_on_in_next_interview.source}
                          id={FieldsNameNotesSource.notesFocusNextInterviewSource}
                          name={FieldsNameNotesSource.notesFocusNextInterviewSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameNotes.profileName}>Vendor Profile Owner: Name</label>
                    <StyledInput
                      maxCharacters={100}
                      disabled={isDisabled}
                      defaultValue={
                        companyExists.catalisto_internal_screening_notes.vendor_profile_owner_name.value === '{}'
                          ? ''
                          : companyExists.catalisto_internal_screening_notes.vendor_profile_owner_name.value
                      }
                      id={FieldsNameNotes.profileName}
                      name={FieldsNameNotes.profileName}
                      placeholder="Enter Name"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>
                            {displayDate(
                              companyExists.catalisto_internal_screening_notes.vendor_profile_owner_name.updated_at
                            )}
                          </span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameNotesSource.profileNameSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.catalisto_internal_screening_notes.vendor_profile_owner_name.source}
                          id={FieldsNameNotesSource.profileNameSource}
                          name={FieldsNameNotesSource.profileNameSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameNotes.profileTitle}>Vendor Profile Owner: Title</label>
                    <StyledInput
                      maxCharacters={100}
                      disabled={isDisabled}
                      defaultValue={
                        companyExists.catalisto_internal_screening_notes.vendor_profile_owner_title.value === '{}'
                          ? ''
                          : companyExists.catalisto_internal_screening_notes.vendor_profile_owner_title.value
                      }
                      id={FieldsNameNotes.profileTitle}
                      name={FieldsNameNotes.profileTitle}
                      placeholder="Enter Title"
                    />
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>
                            {displayDate(
                              companyExists.catalisto_internal_screening_notes.vendor_profile_owner_title.updated_at
                            )}
                          </span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameNotesSource.profileTitleSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.catalisto_internal_screening_notes.vendor_profile_owner_title.source}
                          id={FieldsNameNotesSource.profileTitleSource}
                          name={FieldsNameNotesSource.profileTitleSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameNotes.profileEmail}>Vendor Profile Owner: Email</label>
                    <StyledInput
                      maxCharacters={100}
                      isValid={checkingForm(values[FieldsNameNotes.profileEmail], EmailExp)}
                      disabled={isDisabled}
                      defaultValue={
                        companyExists.catalisto_internal_screening_notes.vendor_profile_owner_email.value === '{}'
                          ? ''
                          : companyExists.catalisto_internal_screening_notes.vendor_profile_owner_email.value
                      }
                      id={FieldsNameNotes.profileEmail}
                      name={FieldsNameNotes.profileEmail}
                      placeholder="Enter Email"
                    />
                    {checkingForm(values[FieldsNameNotes.profileEmail], EmailExp) ? (
                      <StyledErr>Please enter valid email address</StyledErr>
                    ) : null}
                    <WrapperNote>
                      <div className="date">
                        <p>
                          {' '}
                          Editing Date:
                          <span>
                            {displayDate(
                              companyExists.catalisto_internal_screening_notes.vendor_profile_owner_email.updated_at
                            )}
                          </span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameNotesSource.profileEmailSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={companyExists.catalisto_internal_screening_notes.vendor_profile_owner_email.source}
                          id={FieldsNameNotesSource.profileEmailSource}
                          name={FieldsNameNotesSource.profileEmailSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                  <WrapperInput>
                    <label htmlFor={FieldsNameNotes.profilePhone}>Vendor Profile Owner: Telephone</label>
                    <InternationalPhoneInput
                      disabled={isDisabled}
                      isValid={touched[FieldsNameNotes.profilePhone] && errors[FieldsNameNotes.profilePhone]}
                      defaultValue={companyExists?.catalisto_internal_screening_notes?.vendor_profile_owner_telephone?.value}
                      id={FieldsNameNotes.profilePhone}
                      name={FieldsNameNotes.profilePhone}
                      placeholder="1-234-5678-90"
                    />
                    {touched[FieldsNameNotes.profilePhone] && errors[FieldsNameNotes.profilePhone] ? (
                      <StyledErr>{errors[FieldsNameNotes.profilePhone]}</StyledErr>
                    ) : null}
                    <WrapperNote>
                      <div className="date">
                        <p>
                          Editing Date:
                          <span>
                            {displayDate(
                              companyExists.catalisto_internal_screening_notes.vendor_profile_owner_telephone.updated_at
                            )}
                          </span>
                        </p>
                      </div>
                      <WrapperInput>
                        <label htmlFor={FieldsNameNotesSource.profilePhoneSource}>Source </label>
                        <StyledInput
                          maxCharacters={1000}
                          disabled={isDisabled}
                          defaultValue={
                            companyExists.catalisto_internal_screening_notes.vendor_profile_owner_telephone.source
                          }
                          id={FieldsNameNotesSource.profilePhoneSource}
                          name={FieldsNameNotesSource.profilePhoneSource}
                          placeholder="Enter Source"
                        />
                      </WrapperInput>
                    </WrapperNote>
                  </WrapperInput>
                </FormComponent>
                <div className="wrapper-footer" id="bottom">
                  <ButtonGroup
                    modalAction={(type: any) =>
                      setModalProps(true, { type: 'leaveComment', context: { actionType: type, recordType: 'company' } })
                    }
                    isPublished={companyExists.published}
                    statusRecord={companyExists.record_stage}
                    handleSubmit={() => {
                      setIsAutoSave(false);
                      handleSubmit();
                    }}
                    recordAcceptActions={companyExists.record_accept_actions}
                    onDelete={deleteCompany}
                    onAction={(statusActions: IActions) => companyStore.changeStatusRecord(statusActions, 'company')}
                  />
                  {renderFooter()}
                </div>
              </StyledForm>
            );
          }}
        </Formik>
        {getModalProps().isOpen && <Modal />}
      </Layout>
      }
    </>
  );
});

export default Company;
