// Core
import styled from '@emotion/styled';

export const StyledTextField = styled.textarea`
  background: #f2f4f8;
  border-radius: 6px;
  min-height: 100px;
  width: calc(100% - 15px);
  border: none;
  margin-top: 10px;
  padding: 20px;
  outline: none;
  font-style: normal;
  font-weight: 400;
  color: #1d2553;
  font-size: 16px;
  resize: none;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  :-moz-placeholder {
    line-height: 40px;
  }
  ::-webkit-input-placeholder {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 16px;
  }
  ::-moz-placeholder {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 16px;
  }
  :-ms-input-placeholder {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 16px;
  }
  :-moz-placeholder {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 16px;
  }

  ::-moz-placeholder {
    line-height: 40px;
  }
  &.input-group {
    min-width: 155px;
    width: 200px;
  }
`;


export const Wrapper = styled.div`
  position: relative;
`


export const StyledCurrentCharacters = styled.p`
  position: absolute;
  right: 30px;
  top: -17px;

  font-size: 14px;
  opacity: 0.7;
  span{
    font-weight: 600;
  }
`