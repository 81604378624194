import { observable, action, makeAutoObservable, toJS } from 'mobx';
import { inject, injectable, decorate } from 'inversify';

import * as types from 'src/services/api/types'

import { ApiService } from '../../services/api/ApiService';
import { ITextError } from '../../services/api/types';
import { AuthStore } from '../AuthStore'
import { ActionsType } from '../types';

export class RecordsStore {
  constructor() {
    makeAutoObservable(this);
  }

  @inject(ApiService)
  private api!: ApiService;

  @inject(AuthStore)
  private authStore!: AuthStore;

  @observable private error = false;

  @observable private isLoading = false;

  @observable private errorMessage = '';

  @observable private showNotification = { show: false, type: 'OK', text: '' };

  @observable recordsData: types.RecordsListResponse | {} = {};

  @observable searchResultData: any | null = null;

  @observable searchRecordsData: types.RecordsListResponse | {} = {};

  @observable companiesList = [];

  @observable researchers = [];

  @observable pages = 1;

  @observable limitPerPage = 10;

  @observable searchValue = '';

  @observable currentTab = 'request';

  @observable currentUrl = '';

  @action getCurrentUrl = (): string => {
    const alreadySetUrl = this.currentUrl
    if (alreadySetUrl)
      return this.currentUrl

    const defaultValue = this.authStore.getIsResearcher() ? 'allocated' : 'request'
    return defaultValue
  };

  @action setSearchValue = (value: string): void => {
    this.searchValue = value;
  };

  @action setCurrentUrl = (tab: string): void => {
    this.currentUrl = tab;
  };

  /*@action setSearchRecords = (): void => { // TODO: delete
    //const filterMatchingRecords
    //const recordsNew = {
    //  request: 
    //}
    //  this.searchRecordsData.records?.filter(
    //  (el: any) => el?.company?.title?.toLowerCase()?.includes(this.searchValue.toLowerCase()) || el?.solution?.title?.toLowerCase()?.includes(this.searchValue.toLowerCase())
    //);
    //this.setRecords(
    //  this.searchValue
    //    ? { ...this.searchRecordsData, records: newRecordsList }
    //    : { ...this.recordsData, records: this.searchRecordsData.records }
    //);
  };*/

  @action getError = (): boolean => {
    return this.error;
  };

  @action getIsLoading = (): boolean => {
    return this.isLoading;
  };

  @action getShowNotification = (): { show: boolean; type: string; text: string } => {
    return this.showNotification;
  };

  @action getErrorMessage = (): string => {
    return this.errorMessage;
  };

  @action getRecords = (): any => {
    return toJS(this.recordsData);
  };

  @action getCompaniesList = (): any => {
    return toJS(this.companiesList);
  };

  @action getResearchers = (): any => {
    return toJS(this.researchers);
  };

  //@action setRecords = (data: any): any => {
  //  this.recordsData = data;
  //};

  @action setSearchResultData = (data: any): any => {

    this.searchResultData = data;

  };

  @action setShowNotification = (setShowNotification: { show: boolean; type: 'OK' | 'error'; text: string }): void => {
    this.showNotification = setShowNotification;
  };

  @action setResearchers = (data: any): void => {
    this.researchers = data;
  };

  @action private setErrorMessage = (message: string) => {
    this.errorMessage = message;
  };

  @action private setListOfCompanies = (data: any): void => {
    this.companiesList = data;
  };

  @action private setError = (error: boolean) => {
    this.error = error;
  };

  @action private setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action setPages = (data: number): void => {
    this.pages = data;
  };

  @action getPages = (): number => {
    return toJS(this.pages);
  };

  @action getlimitPerPage = (): number => {
    return toJS(this.limitPerPage);
  };

  search(data: any[]): number {
    if (!data) {
      return 0
    }
    const filteredData = data?.filter(
      (el: any) => el?.company?.title?.toLowerCase()?.includes(this.searchValue.toLowerCase()) || el?.solution?.title?.toLowerCase()?.includes(this.searchValue.toLowerCase())
    );

    return filteredData.length;
  };

  /*public getSearchData = async (queries: string) => {
    try {
      this.setErrorMessage('');
      console.debug('getSearchData() queries: ', queries)

      if (this.searchValue) {
        this.setLoading(true);
        const requestData = await this.api.getRecords(`category=request${queries}`);
        const allocatedData = await this.api.getRecords(`category=allocated${queries}`);
        const progressData = await this.api.getRecords(`category=in progress${queries}`);
        const reviewData = await this.api.getRecords(`category=review${queries}`);
        const completedData = await this.api.getRecords(`category=completed${queries}`);
        this.setSearchResultData({
          allocated: this.search(allocatedData?.records),
          completed: this.search(completedData?.records),
          in_progress: this.search(progressData?.records),
          request: this.search(requestData?.records),
          review: this.search(reviewData?.records),
        });
      } else {
        this.setSearchResultData(null);
      }
    } catch (e) {
      this.setError(true);
    } finally {
      this.setLoading(false);
    }
  };*/

  @action fetchRecordsData = async (queries: string): Promise<void> => {
    try {
      console.debug('fetchRecordsData() queries: ', queries)
      this.setErrorMessage('');
      this.setLoading(true);
      const res = await this.api.getRecords(queries);
      this.recordsData = res;
      this.searchRecordsData = res;
      //if (this.searchValue) {
      //  this.setSearchRecords();
      //}
    } catch (e) {
      this.setError(true);
    } finally {
      this.setLoading(false);
    }
  };

  public createRecord = async (data: any): Promise<void> => {
    try {
      this.setLoading(true);
      this.setErrorMessage('');
      const response = await this.api.createRecord(data);
      const isError = response.status !== 'OK'
      if (isError) throw response
      this.fetchRecordsData(this.currentUrl);
      this.setShowNotification({ show: true, type: 'OK', text: 'Record successfully added' });
      setTimeout(() => {
        this.setShowNotification({ show: false, type: 'OK', text: 'Record successfully added' });
      }, 3000);
    } catch (e) {
      console.error('error creating record: ', e)
      this.setError(true);
      this.setShowNotification({ show: true, type: 'error', text: ITextError.somethingWentWrong });
      setTimeout(() => {
        this.setShowNotification({ show: false, type: 'error', text: ITextError.somethingWentWrong });
      }, 3000);
    } finally {
      this.setLoading(false);
    }
  };

  public changeStatusRecord = async (id: string, statusAction: ActionsType, comment?:string): Promise<void> => {
    if (statusAction !== ActionsType.delete && statusAction !== ActionsType.edit) {
      try {
        this.setLoading(true);
        this.setErrorMessage('');
        this.setError(false);
        console.debug('changeStatusRecord id: ', id)
        console.debug('changeStatusRecord statusAction: ', statusAction)
        await this.updateRecord(id, {
          action: statusAction,
          comment,
        })
      } catch (e) {
        this.setError(true);
      } finally {
        this.setLoading(false);
      }
    }
  };

  public deleteRecord = async (recordId: string): Promise<void> => {
    try {
      this.setLoading(true);
      this.setErrorMessage('');
      await this.api.deleteRecord(recordId);

      this.fetchRecordsData(this.currentUrl);
    } catch (e) {
      this.setError(true);
    } finally {
      this.setLoading(false);
    }
  };

  public updateRecord = async (recordId: string, record: any): Promise<void> => {
    try {
      this.setLoading(true);
      this.setErrorMessage('');
      await this.api.updateRecord(recordId, record);
      this.fetchRecordsData(this.currentUrl);
    } catch (e) {
      this.setError(true);
    } finally {
      this.setLoading(false);
    }
  };

  public getResearchersData = async (): Promise<void> => {
    try {
      this.setErrorMessage('');
      const res = await this.api.getResearchers();
      const ERROR = `you don't have access to this`;
      if (res.message === ERROR) {
        this.setErrorMessage(res.message);
      }
      this.setResearchers(res.users || []);
    } catch (e) {
      console.error('error getting researchers: ', e)
      this.setError(true);
    }
  };

  public getCompanies = async (company: string): Promise<void> => {
    try {
      this.setErrorMessage('');
      const res = await this.api.getCompanies(company);
      this.setListOfCompanies(res.companies || []);
    } catch (e) {
      console.error('error getting companies: ', e)
      this.setError(true);
    }
  };
}
decorate(injectable(), RecordsStore);
