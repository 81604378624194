import 'reflect-metadata';
import { Container, interfaces } from 'inversify';
import React, { useContext } from 'react';
import { Provider as InversifyProvider } from 'inversify-react';
import { IApplication } from './types';
import { Application } from './index';
import { ApiService } from '../services/api/ApiService';
import { TokenService } from '../services/api/TokenService';
import { AuthStore } from '../stores/AuthStore';
import { CompanyStore } from '../stores/Company&SolutionStore';
import { RecordsStore } from '../stores/RecordsStore';
import { IModalStore, ModalStore } from '../stores/ModalStore';

const AppContainer = new Container();

AppContainer.bind<ApiService>(ApiService).to(ApiService).inSingletonScope();
AppContainer.bind<AuthStore>(AuthStore).to(AuthStore).inSingletonScope();
AppContainer.bind<RecordsStore>(RecordsStore).to(RecordsStore).inSingletonScope();
AppContainer.bind<IModalStore>(ModalStore).to(ModalStore).inSingletonScope();
AppContainer.bind<TokenService>(TokenService).to(TokenService).inSingletonScope();
AppContainer.bind<IApplication>(IApplication).to(Application).inSingletonScope();
AppContainer.bind<CompanyStore>(CompanyStore).to(CompanyStore).inSingletonScope();

export const appContainer = AppContainer;
export const App = AppContainer.get<IApplication>(IApplication);

const InversifyContext = React.createContext<{ container: Container | null }>({ container: null });

export const Provider: React.FC<{ container: Container }> = (props: any) => {
  const { container, children } = props;
  return (
    <InversifyContext.Provider value={{ container }}>
      <InversifyProvider container={container}>{children}</InversifyProvider>
    </InversifyContext.Provider>
  );
};

export function useInjection<T>(identifier: interfaces.ServiceIdentifier<T>): T {
  const { container } = useContext(InversifyContext);

  if (!container) {
    throw new Error();
  }

  return container.get<T>(identifier);
}
