/**
 * Added custom helper bc previous npm library `react-scrollable-anchor` had error:
 *   like:  "arguments is undefined"
 *   similar to:  https://stackoverflow.com/questions/59475870/reactjs-debounce-a-function-that-has-as-an-argument-a-state-value-whats-the-b
 *   and to: https://stackoverflow.com/questions/69464377/getting-error-in-console-uncaught-referenceerror-arguments-is-not-defined-after
 * It was updated long time ago (version 0.6.1 in 2018).
 * 
 * There is alternative library: `react-anchor-link-smooth-scroll`.
 * It also was updated long time ago (in 2019).
 * 
 * Recent browsers have native smooth scroll behavior in JS, so using it.
 */
export function scrollToElement(
    args: {
      query?: string;
      toTop?: boolean;
      toBottom?: boolean;
    }
) {
  if (args.toTop) {
    window.scrollTo({top: 0, behavior: 'smooth'})
  }
  else if (args.toBottom) {
    var bottomCoordinateY = document.body.scrollHeight
    //window.scrollTo(0, bottomCoordinateY)
    window.scrollTo({top: bottomCoordinateY, behavior: 'smooth'})
  }
  else if (args.query) {
    document.querySelector(args.query)?.scrollIntoView({
        behavior: 'smooth'
    })
  }
  else
    console.warn(`no args passed to scrollToElement()`)
}

export default scrollToElement
