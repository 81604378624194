// Core
import styled from '@emotion/styled';

export const StyledTextField = styled.input`
  padding: 0;
  height: 40px;
  border: none;
  background-color: #ffffff;
  font-weight: 400;
  border-radius: 6px;
  color: #1d2553;
  font-size: 16px;
  outline: none;
  text-transform: none;
  width: 100%;
  min-width: 350px;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;

  :-moz-placeholder {
    line-height: 40px;
  }
  ::-webkit-input-placeholder {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 16px;
  }
  ::-moz-placeholder {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 16px;
  }
  :-ms-input-placeholder {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 16px;
  }
  :-moz-placeholder {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 16px;
  }

  ::-moz-placeholder {
    line-height: 40px;
  }
  &.input-group {
    min-width: 155px;
    width: 200px;
  }

  &.phone {
    min-width: 0px;
    width: 345px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
`


export const StyledCurrentCharacters = styled.p`
  position: absolute;
  right: 30px;
  top: -17px;

  font-size: 14px;
  opacity: 0.7;
  span{
    font-weight: 600;
  }
`