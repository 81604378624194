import styled from '@emotion/styled';
import CastomInputFormik from '../../components/input/CastomInputFormik';
import { PhoneCodeInput } from '../../components/input/PhoneCodeInput';
import linkSvg from '../../assets/images/company/link.svg';
import mockFileSvg from '../../assets/images/company/mockFile.svg';

export const PageHeader = styled.header`
  position: relative;
  display: flex;
  width: calc(100%-30px);
  padding-top: 30px;
  height: 250px;
  margin-bottom: 50px;
  &::before {
    content: '';
    display: block;
    position: absolute;
    background: #d2d3dd;
    height: 2px;
    width: 90%;
    left: 105px;
    bottom: -5px;
  }

  div.header-wrapper {
    width: 800px;
    padding-top: 40px;
  }

  h2 {
    margin: 0;
    padding: 0;
    position: relative;
    max-width: 600px;
    word-break: break-all;
    &::before {
      content: '';
      display: block;
      height: 2px;
      width: 90px;
      position: absolute;
      top: -17px;
      left: 0;
      background: linear-gradient(134.72deg, #fc4d9a 0%, #9c0745 101.5%);
    }
  }

  p.header-by__company {
    margin: 0;
    margin-bottom: 10px;
    display: flex;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 1px;

    a {
      text-decoration: inherit;
      position: relative;
      margin-left: 10px;
      background: linear-gradient(134.72deg, #fc4d9a 0%, #9c0745 101.5%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: table;
      text-transform: uppercase;
      margin-right: 0;
    }
    a::before {
      content: '';
      display: block;
      position: absolute;
      right: -70px;
      top: 50%;
      transform: translate(-50%);
      height: 2px;
      width: 35px;
      background: linear-gradient(134.72deg, #fc4d9a 0%, #9c0745 101.5%);
    }
  }

  & p.header-title {
    margin: 0;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: #777c98;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const DisplayFlex = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  h2 {
    margin: 0;
  }
  img {
    width: 80px;
    height: 80px;
    margin-right: 25px;
  }
`;
export const HeaderButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 50px;

  &:hover,
  &:focus {
    opacity: 0.5;
  }

  img {
    margin-right: 5px;
  }
  span {
    background: linear-gradient(134.72deg, #fc4d9a 0%, #9c0745 101.5%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: table;
    text-transform: uppercase;
  }
`;

export const StyledCommentWrapper = styled.div`
  max-width: 500px;
  margin-right: auto;
  h3 {
    position: relative;
    margin: 0;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #1d2553;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -100%;
      left: -10px;
      width: 2px;
      height: 30px;
      background: linear-gradient(134.72deg, #fc4d9a 0%, #9c0745 101.5%);
    }
  }
  button {
    margin: 0;
    padding: 0;
  }
  p {
    margin: 0;
    margin: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #777c98;
  }
  p.header-text {
    margin: 0;
    margin-bottom: 30px;
    line-height: 18px;
    max-height: 90px;
    overflow: hidden;
  }
  p.header-text--margin {
    margin: 0;
    margin-bottom: 5px;
  }

  span.date {
    color: #777c98;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
  }
`;

export const StyledInput = styled(CastomInputFormik)<{ isValid?: any }>`
  background-color: #f2f4f8;
  border-radius: 6px;
  margin-top: 5px;
  padding-left: 15px;
  width: calc(100% - 15px);
  ${({ isValid }) => (!isValid ? null : 'border: 1px solid #FC4D9A;')}
`;

export const WrapperInput = styled.div`
  & div.phoneCodeWrapper {
    display: flex;
    align-items: center;
    & .phone {
      margin-bottom: 16px;
    }
  }

  display: block;
  position: relative;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  label {
    color: #777c98;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
  }
`;

export const StyledFormComponent = styled.div`
  width: 100%;
  padding: 25px;
  border-radius: 6px;
  background-color: #fff;
  margin-bottom: 30px;
  div.wrapper {
    position: relative;
    width: 370px;
  }
  p {
    margin: 0;
    text-align: left;
    width: fit-content;
  }
  button {
    margin-bottom: 0;
    position: relative;
    background-color: transparent;
    border: none;
    color: #1d2553;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    cursor: pointer;

    & img {
      position: absolute;
      top: -5px;
      right: -30%;
    }
  }

  & div.decor {
    margin-bottom: 50px;
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: -40px;
      background-color: #f2f4f8;
    }
  }
`;
export const StyledForm = styled.form`
  position: relative;
  width: 530px;
  & div.wrapper-footer {
    position: relative;
    margin-bottom: 150px;
  }
`;

export const WrapperNote = styled.div`
  display: flex;
  align-items: center;
  position: absolute !important;
  right: -130%;
  top: 0;
  width: 570px !important;
  div.date {
    display: flex;
    padding-top: 23px;
    flex-wrap: nowrap;
    margin-right: 2%;
    text-align: unset;
    p {
      width: 200px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #777c98;
    }
    span {
      margin-left: 5px;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: #1d2553;
    }
  }
  & div {
    margin: 0;
  }
  input {
    background-color: #fff;
    width: 300px;
  }

  &.sourceToFile {
    right: -135%;
    top: -25px;
    & label {
      color: #777c98;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 130%;
    }
  }
  &.sourceToFile--position {
    top: -40px;
  }
`;

export const StyledPageFooter = styled.footer`
  position: absolute;
  top: 10px;
  right: -140%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 50px 0;
  & div:first-of-type {
    margin: 0;
  }
  h3 {
    color: #1d2553;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
`;

export const StyledRecordAuditComponent = styled.div`
  margin-left: 6.5%;
  width: 400px;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    position: relative;
    margin-bottom: 21px;
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -10px;
      background-color: #d2d3dd;
      width: 100%;
      height: 1px;
    }
  }

  li:last-child {
    margin-bottom: 0;
    &::after {
      display: none;
    }
  }
  & div {
    margin: 0;
    height: 35px;
    color: #1d2553;
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 5px;
      height: 5px;
      left: -10px;
      top: 50%;
      transform: translateY(-60%);
      border-radius: 50%;
      background: linear-gradient(134.72deg, #fc4d9a 0%, #9c0745 101.5%);
    }

    & p.audite-date {
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: '#777c98';
      margin-left: auto;
      opacity: 0.5;
      span {
        margin-left: 10px;
        color: '#777c98';
      }
      &::before {
        display: none;
      }
    }
  }
`;

export const WrapperInputData = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
  padding-top: 20px;
  & div {
    position: relative;
    width: 100%;
  }
  div.inputFileWrapper {
    margin: 0;
    list-style: none;
    position: absolute;
    align-items: center;
    display: flex;
    right: 20px;
    top: -10px;
    width: 200px;
    padding-right: 30px;

    img.inputFile--delete {
      position: relative;
      left: 0;
    }
  }

  & div.brochureWrapper {
    display: flex;
    flex-wrap: nowrap;
  }

  & ul.inputFileWrapperList {
    margin: 0;
    margin-left: 100px;
    list-style: none;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    width: 200px;
    padding-right: 30px;
    & li {
      display: flex;
      margin-bottom: 10px;
      opacity: 1;
      & button {
        & img.inputFile--delete {
          position: relative;
          left: 0;
        }
      }
    }
    li::after {
      display: none;
    }
  }
  p.none {
    display: none;
  }
  p.logoValid {
    display: block;
    color: #fc4d9a;
    margin-bottom: 10px;
  }
  & p.inputFile {
    display: flex;
    align-items: center;
    right: 0;
    width: 100%;
    min-width: 136px;
    padding-left: 30px;
    overflow: hidden;
    text-align: left;
    color: #1d2553;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    overflow-y: visible;

    white-space: -moz-pre-wrap;
    white-space: -hp-pre-wrap;
    white-space: -o-pre-wrap;
    white-space: -pre-wrap;
    white-space: pre-wrap;
    white-space: pre-line;
    word-wrap: break-word;
    word-break: break-all;

    & button.deleteFile {
      position: relative;
      padding: 0;
      width: 14px;
      height: 14px;
      z-index: 1000;
      & > img.inputFile--delete {
        position: relative;
        left: 0;
      }
    }
  }

  p.inputFile::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    background-image: url(${mockFileSvg});
  }

  img {
    width: 12px;
    height: 12px;
    position: absolute;
    left: -15px;
    top: -50%;
    transform: translateY(50%);
  }
  label {
    width: fit-content;
    position: relative;
    cursor: pointer;
    display: flex;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 1px;
    span {
      background: linear-gradient(134.72deg, #fc4d9a 0%, #9c0745 101.5%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-transform: uppercase;
      cursor: pointer;
      z-index: 1999;
    }
  }

  & input.input-file {
    width: 100%;
    height: 22px;
    position: absolute;
    left: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 1000;
  }
  ul {
    list-style: none;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    opacity: 0.5;
    padding-left: 10px;
    li {
      position: relative;
    }
    li::after {
      content: '';
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);

      display: block;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: #777c98;
    }
  }
`;

export const StyledInputGroup = styled.div`
  position: relative;
  & .dflex {
    width: 230px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-right: 40px;
    & > div.dRow {
      display: block;
      flex-direction: row;
    }
  }
  & div {
    display: flex;
    align-content: center;
  }
  button {
    position: absolute;
    right: 0;
    bottom: 20px;
    padding-top: 10px;
    transform: translateX(-25px);
    span {
      background: linear-gradient(134.72deg, #fc4d9a 0%, #9c0745 101.5%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: table;
      text-transform: uppercase;
    }
    &:hover,
    &:focus {
      opacity: 0.6;
    }
  }
`;

export const StyledListProducts = styled.ul`
  list-style: none;
  margin: 0;
  padding: 20px 0;
  padding-left: 10px;
  & li {
    position: relative;
    margin-bottom: 45px;
    color: #777c98;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 1px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      background: #d2d3dd;
      height: 1px;
      width: 100%;
      left: 0;
      bottom: -20px;
    }

    &:last-child {
      margin: 0;
      &::after {
        content: none;
      }
    }
    a {
      position: relative;
      text-decoration: none;
      color: #777c98;
      &::before {
        content: '';
        display: block;
        position: absolute;
        background-image: url(${linkSvg});
        background-position: center;
        background-repeat: no-repeat;
        height: 20px;
        width: 20px;
        right: -20px;
        bottom: 50%;
        transform: translateY(50%);
      }
    }
  }
`;

export const StyledErr = styled.div`
  width: 180px;
  color: #fc4d9a;
  align-self: flex-start;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 15px;
`;

export const WrapperChildren = styled.div<{ toggle: boolean }>`
  ${(p) =>
    p.toggle
      ? `
  opacity: 1;
  height: 100%;
  display:block;
  position: relative;`
      : `
  opacity: 0;
  position: absolute;
  display:none;
  height: 0px;`}
`;

export const StyledSelectMultiple = styled(PhoneCodeInput)`
  margin-top: 5px;
  margin-bottom: 16px;
`;
