import React from 'react';
import { components } from 'react-select';
import arrowDownIcon from '../../assets/images/table/arrow-down.svg';
import arrowUpIcon from '../../assets/images/table/arrow-up.svg';

export interface SelectItem {
  value: string | number;
  label?: string;
}

export interface IProps {
  onChange: (v: any) => void;
  onBlur?: (name: string, touched: boolean) => void;
  value: { value: string; label: string } | null;
  width?: string;
  items: SelectItem[];
  placeholder?: string;
  styles?: any;
  menuIsOpen?: boolean;
  menuPosition?: any;
  menuPortalTarget?: any;
  onFocus?: () => void;
  CustomSelect: any;
}

/* eslint-disable react/jsx-props-no-spreading */
const Select = ({ items, placeholder, onChange, value, styles, CustomSelect, ...rest }: IProps): JSX.Element => {
  const DropdownIndicator = ({ selectProps, ...props }: any) => (
    <components.DropdownIndicator {...props}>
      <img src={selectProps.menuIsOpen ? arrowUpIcon : arrowDownIcon} alt="arrow" />
    </components.DropdownIndicator>
  );
  const createOption = (option: any) => ({ value: option.value, label: option.label });

  const handleChange = (v: any) => {
    onChange(v);
  };

  return (
    <CustomSelect
      {...rest}
      options={items.map((option: any) => createOption(option))}
      components={{ DropdownIndicator }}
      placeholder={placeholder}
      isSearchable={false}
      styles={styles}
      onChange={(e: any) => handleChange(e)}
      value={value}
    />
  );
};

export default Select;
