// Core
import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { observer } from 'mobx-react-lite';
import { useInjection } from '../../../core/inversify';

// Store
import { AuthStore } from '../../../stores/AuthStore';

// Styles
import { StyledEndStep, RecoveryBtnWrapper, StyledButton, FlexColumn } from '../styles';

interface IProps {
  nextSteps: () => void;
}

const StepTwoRecoveryCode = observer(({ nextSteps }: IProps): JSX.Element => {
  const authStore = useInjection(AuthStore);
  const [showFooter, setShowfooter] = useState<boolean>(false);

  return (
    <StyledEndStep>
      <h2>Recovery codes</h2>
      <p>
        In case you ever lose your phone or trusted device, you will need a recovery code to access your account and prove that it is your
        account. Please print, write down or download a copy of these codes and keep them in a safe location.
      </p>
      <ul>
        {authStore.getRecoveryCodes().map((el) => (
          <li key={el}>{el}</li>
        ))}
      </ul>
      <FlexColumn>
        <CopyToClipboard text={authStore.getRecoveryCodes().join(' ')}>
          <StyledButton>
            <p>Copy codes</p>
          </StyledButton>
        </CopyToClipboard>
        <StyledButton disabled={showFooter} onClick={() => setShowfooter(true)}>
          <p> Complete</p>
        </StyledButton>
      </FlexColumn>
      {showFooter && (
        <>
          <p className="QR-code__bottom--step">Have you copied the recovery codes?</p>
          <RecoveryBtnWrapper>
            <StyledButton onClick={() => setShowfooter(false)}>No</StyledButton>
            <StyledButton onClick={() => nextSteps()}>Yes</StyledButton>
          </RecoveryBtnWrapper>
        </>
      )}
    </StyledEndStep>
  );
});
export default StepTwoRecoveryCode;
