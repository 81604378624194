import { injectable } from 'inversify';
import jwt_decode from 'jwt-decode';

export interface IJwtToken {
  exp: number;
  iat: number;
  iss: string;
  key: string;
}

@injectable()
export class TokenService {
  public getToken = (): string => {
    const tokenString = localStorage.getItem('token');
    if (tokenString) {
      const userToken = JSON.parse(tokenString as string);
      return userToken;
    }
    return '';
  };

  public saveToken = (userToken: string): void => {
    localStorage.setItem('token', JSON.stringify(userToken));
  };

  public getCsrfToken = (): string => {
    const tokenString = localStorage.getItem('csrfToken');
    try {
      if (tokenString) {
        const userToken = JSON.parse(tokenString);
        return userToken;
      }
    } catch (e){
      console.log(e);
    }
    return '';
  };

  public saveCsrfToken = (userToken: string): void => {
    localStorage.setItem('csrfToken', JSON.stringify(userToken));
  };

  public saveIsAauthorized = (userToken: boolean): void => {
    localStorage.setItem('isAuthorized', JSON.stringify(userToken));
  };

  public getIsAuthorized = (): boolean => {
    const isAuthorizedString = localStorage.getItem('isAuthorized')
    const isAuthorized = isAuthorizedString ? JSON.parse(isAuthorizedString) : false
    return isAuthorized
  };

  public parseLogoutTime = (jwtToken: string): number => {
    const jwt: IJwtToken = jwt_decode(jwtToken)
    const time = jwt.exp * 1000
    return time
  };

  public getLogoutTime = (): number | undefined => {
    const valueJson = localStorage.getItem('logoutTime')
    if (!valueJson)
      return undefined
    const value = JSON.parse(valueJson as string)
    return value
  };

  public saveLogoutTime = (logoutTime: number): void => {
    localStorage.setItem('logoutTime', logoutTime.toString());
  };

  public getFullName = (): string => {
    const value = localStorage.getItem('fullName');
    return JSON.parse(value as string);
  };

  public saveFullName = (fullName: string): void => {
    localStorage.setItem('fullName', JSON.stringify(fullName));
  };

  public saveRole = (role: string): void => {
    if (!role) return;
    localStorage.setItem('role', JSON.stringify(role));
  };

  public getRole = (): string => {
    const value = localStorage.getItem('role');
    return JSON.parse(value as string);
  };
}
