import styled from '@emotion/styled';
import { Form } from 'formik';
import TextField from '../../components/input/TextField';
import AdminButton from '../../components/Button';
import LoginImg from '../../assets/login.png';

export const StyledTextField = styled(TextField)`
  width: 100%;
`;

export const WrapperForm = styled(Form)`
  position: relative;
  overflow: hidden;
  width: 350px;
  margin-right: auto;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  min-height: auto;
  padding-bottom: 260px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  button.sign-in__btn {
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
  }
  p.blocked {
    margin-top: 20px;
    width: 250px;
    color: #777c98;
    font-size: 16px;
    line-height: 130%;
    & a {
      margin-left: 5px;
      color: #1d2553;
    }
  }
  button.sign-in__btn--code {
    margin: 25px 0;
    border: none;
    background-color: transparent;
    color: #1d2553;
    font-size: 16px;
    line-height: 130%;
    cursor: pointer;
  }

  div.sign-in__description--required.verify {
    width: 340px;
    &::after {
      content: ' ';
      display: none;
    }
  }

  .sign-in__description--required {
    position: relative;
    &::after {
      content: ' ';
      border-radius: 100%;
      position: absolute;
      top: 23%;
      right: -10px;
      width: 5px;
      height: 5px;
      background: linear-gradient(134.72deg, #fc4d9a 0%, #9c0745 101.5%);
    }
  }

  .sign-in__required {
    display: flex;
    flex-wrap: wrap;
    input {
      width: 350px;
      padding-left: 12px;
    }

    @media screen and (min-width: 320px) and (max-width: 740px) {
      width: 280px;

      &:first-of-type {
        margin-right: 0;
      }

      input {
        width: 100%;
      }
    }
  }

  img.sign-in__svg {
    position: absolute;
    top: 34%;
    left: 10px;
  }

  img.sign-in__svg--password {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  img.sign-in__svg--valid {
    position: absolute;
    top: 23%;
    right: -30px;
    cursor: pointer;
  }
  img.key-verify {
    margin-bottom: 20px;
  }

  input {
    padding-left: 48px;
    @media screen and (min-width: 320px) and (max-width: 740px) {
      width: 280px;
      font-size: 12px;
    }
  }

  button.button-step__two {
    margin-top: 20px;
  }

  button.button-step__two--transparent {
    border: none;
    background-color: transparent;
    color: #1d2553;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    cursor: pointer;
  }

  h2,
  p {
    padding: 0;
    margin: 0;
  }

  h2 {
    color: #1d2553;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 28px;
  }
  h2.key-verify {
    margin-bottom: 5px;
  }

  p {
    font-size: 14px;
    color: #777c98;
    font-weight: 400;
    text-align: center;
    margin-bottom: 20px;

    @media screen and (min-width: 320px) and (max-width: 740px) {
      font-size: 12px;
    }
  }
  p.sign-in__description--recovery {
    margin: 0;
  }

  p.sign-in__description--bottom {
    position: relative;
    margin-bottom: 16px;
    &::before {
      content: '';
      position: absolute;
      width: 90px;
      height: 1px;
      background: #ffffff;
      opacity: 0.2;
      top: 50%;
      left: -87%;
    }
    &::after {
      content: '';
      position: absolute;
      width: 90px;
      height: 1px;
      background: #ffffff;
      opacity: 0.2;
      top: 50%;
      right: -87%;
    }
  }
  p.sign-in__text {
    margin-bottom: 5px;
  }
  p.sign-in__text--bottom {
    color: #1d2553;
    margin: 10px;
  }

  a {
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;

    @media screen and (min-width: 320px) and (max-width: 740px) {
      font-size: 14px;
    }
  }

  span {
    padding: 0px 5px;
  }

  @media screen and (min-width: 320px) and (max-width: 740px) {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 88px;
    padding-bottom: 124px;
  }
`;

export const StyledValidationElement = styled.div`
  border-radius: 50%;
  width: 5px;
  height: 5px;
  background: linear-gradient(134.72deg, #fc4d9a 0%, #9c0745 101.5%);
`;

export const RelativeBox = styled.div`
  min-width: 350px;
  position: relative;
  display: flex;
  margin-top: 20px;
  &:first-of-type {
    margin-top: 0;
  }

  @media screen and (min-width: 320px) and (max-width: 740px) {
    width: 280px;
  }
`;
export const StyledButton = styled(AdminButton)<{ disabled?: boolean }>`
  position: relative;
  display: flex;
  width: 350px;
  height: 40px;
  border-radius: 6px;
  color: #fff;
  p {
    position: relative;
    color: #fff;
    z-index: 11;
  }
  p::before {
    content: '';
    position: absolute;
    top: 50%;
    right: -30px;
    width: 20px;
    height: 1px;
    background-color: #fff;
  }
  &[disabled] {
    font-size: 16px;
    background: #777c98;
    cursor: alias;
  }
  &.MuiButton-root {
    margin-top: 20px;
    & p {
      margin: 0;
      align-self: center;
      justify-self: center;
    }
  }
`;

export const StyledErr = styled.div`
  width: 180px;
  color: #fc4d9a;
  align-self: flex-start;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 15px;
`;

export const StyledButtonResend = styled(AdminButton)`
  position: relative;
  width: 350px;
  height: 40px;
  border-radius: 6px;
  background: transparent;
  border: 1px solid #777c98;
  border-radius: 6px;

  &::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 1px;
    background-color: #fff;
    right: 85px;
  }
  @media screen and (min-width: 320px) and (max-width: 740px) {
    width: 280px;
  }
`;

export const SignUpImg = styled.div`
  width: 660px;
  min-height: 500px;
  background-image: url(${LoginImg});
  background-position: center;
  background-size: cover;
  opacity: 0.6;

  @media screen and (min-width: 375px) and (max-width: 1199px) {
    width: 200px;
    display: none;
  }
`;

export const Layout = styled.div`
  width: 100%;
  height: 90vh;
  background-color: #f2f4f8;
  display: flex;
`;

export const FlexBox = styled.div<{ step?: number }>`
  display: flex;
  width: 500px;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  align-content: center;
`;

export const StyledEndStep = styled.div`
  position: relative;
  width: 500px;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  text-align: center;
  align-content: center;
  color: #777c98;
  font-size: 16px;
  line-height: 130%;
  padding-bottom: 300px;

  h2,
  p {
    padding: 0;
    margin: 0;
  }
  p.bold {
    font-weight: 500;
    font-size: 18px;
  }

  h2 {
    color: #1d2553;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 8px;
  }

  h2.key-add__title {
    position: relative;
    margin-bottom: 60px;
  }

  p {
    font-weight: 400;
    text-align: center;
    margin-bottom: 10px;
  }
  p.key-add__text {
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      display: block;
      right: 70px;
      bottom: 40%;
      height: 1px;
      width: 100px;
      background-color: #777c98;
      opacity: 0.4;
    }
    &::before {
      content: '';
      position: absolute;
      display: block;
      left: 80px;
      bottom: 40%;
      height: 1px;
      width: 90px;
      background-color: #777c98;
      opacity: 0.4;
    }
  }
  p.key-name {
    color: #1d2553;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
  }

  p.key-name__used {
    color: #1d2553;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    span {
      margin-left: auto;
    }
  }

  p.QR-code__bottom--step {
    width: 200px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  ul {
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -o-box-orient: horizontal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    flex-wrap: wrap;
    width: 230px;
    margin-left: auto;
    margin-right: auto;
    color: #777c98;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    img {
      margin-bottom: 20px;
    }
    li {
      position: relative;
      min-width: 100px;
      margin-bottom: 20px;
    }
    li:nth-of-type(odd) {
      margin-right: 30px;
    }
  }
  ul.key {
    width: 350px;
    list-style: none;
    margin-bottom: 20px;

    li {
      margin: 0;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      div {
        display: flex;
        flex-wrap: wrap;
        height: 70px;
        align-content: center;
      }
      p {
        margin: 0;
        text-align: left;
        &:first-of-type {
          margin-bottom: 10px;
        }
        span {
          margin-left: 50px;
        }
      }
    }
  }

  button {
    margin-left: auto;
    margin-right: auto;
    width: 230px;
    &::before {
      right: 22%;
    }
  }

  button.button__save-key {
    width: 350px;
  }
  button.button__add-key {
    position: relative;
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 1px;
    color: #9c0745;
    border: none;
    background-color: transparent;
    cursor: pointer;
    text-transform: uppercase;
    &::before {
      content: '';
      position: absolute;
      display: block;
      left: -25%;
      top: -15px;
      height: 1px;
      width: 350px;
      background-color: #777c98;
      opacity: 0.4;
    }
    &::after {
      content: '';
      position: absolute;
      display: block;
      left: -25%;
      bottom: -15px;
      height: 1px;
      width: 350px;
      background-color: #777c98;
      opacity: 0.4;
    }
  }

  img.key-name__svg {
    margin-right: 15px;
  }
  & div.verify {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const FlexColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  span {
    color: #fff;
  }

  button {
    &:first-of-type {
      background: transparent;
      border: 1px solid #777c98;
      border-radius: 6px;
      &:hover,
      &:active {
        background-color: #9c074546;
        .MuiButton-label p {
          color: #fff;
        }
      }
      .MuiButton-label p {
        color: #777c98;

        &::before {
          display: none;
        }
      }
    }
  }
`;

export const BtnWrapper = styled.div`
  button {
    width: 100px;
    height: 40px;
    &:first-of-type {
      margin-right: 30px;
      .MuiButton-label {
        color: #777c98;
      }
    }
  }
`;

export const RecoveryBtnWrapper = styled.div`
  button {
    width: 100px;
    height: 40px;
    &:first-of-type {
      margin-right: 30px;
      background: transparent;
      border: 1px solid #777c98;
      border-radius: 6px;
      .MuiButton-label {
        color: #777c98;
      }
    }
  }
`;
