// Core
import styled from '@emotion/styled';
import opnenSvg from '../../../assets/images/company/openSelect.svg';
import selectCloseChip from '../../../assets/images/company/selectCloseChip.svg';

export const WrapperMultiselect = styled.div<{ toggleSvg: boolean }>`
  position: relative;
  & div.list {
    padding: 10px;
    display: none;
    position: absolute;
    opacity: 1;
    top: -10px;
    right: -250px;
    z-index: 100;
    width: 250px;
    max-height: 300px;
    height: auto;
    background-color: #fff;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    ul {
      margin-right: 0;
      list-style: none;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      li {
        width: 100%;
        margin-bottom: 10px;
        font-style: normal;
        font-weight: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #1d2553;
      }
    }
  }

  &:hover {
    div.list {
      display: block;
    }
  }

  & div.searchWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 10px;
    height: 30px;
    border: none;
    background-color: #f2f4f8;
    font-weight: 400;
    border-radius: 6px;
    color: #1d2553;
    font-size: 16px;
    outline: none;
    text-transform: none;
    width: 97%;
    min-width: 440px;
    min-height: 40px;
    height: fit-content;

    &::after {
      content: '';
      display: block;
      position: absolute;
      background-image: url(${opnenSvg});
      width: 26px;
      height: 26px;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
    }

    & .optionListContainer {
      z-index: 20;
    }

    & span {
      position: relative;
      min-width: 110px;
      width: 110px;
      height: 30px;
      background-color: #fff;
      border-radius: 6px;
      margin: 5px;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: #777c98;
      overflow-x: hidden;
      z-index: 1;

      & img.closeIcon {
        opacity: 0;
        position: absolute;
        width: 16px;
        height: 16px;
        z-index: 300;
        margin-left: auto;
        right: 8px;
      }

      &::before {
        content: '';
        position: absolute;
        display: block;
        width: 46px;
        height: 16px;
        right: 0;
        background-color: #fff;
        z-index: 0;
      }

      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 16px;
        height: 16px;
        right: 8px;
        background-color: #fff;
        background-image: url(${selectCloseChip});
        background-repeat: no-repeat;
        background-position: center;
        z-index: 1;
      }
    }
    & input {
      height: 30px;
      font-weight: 400;
      border-radius: 6px;
      color: #1d2553;
      font-size: 16px;
    }
    :-moz-placeholder {
      line-height: 40px;
    }
    & input {
      font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
      ::-webkit-input-placeholder {
        font-family: Montserrat;
        font-weight: 400;
        font-size: 16px;
      }
      ::-moz-placeholder {
        font-family: Montserrat;
        font-weight: 400;
        font-size: 16px;
      }
      :-ms-input-placeholder {
        font-family: Montserrat;
        font-weight: 400;
        font-size: 16px;
      }
      :-moz-placeholder {
        font-family: Montserrat;
        font-weight: 400;
        font-size: 16px;
      }
    }

    ::-moz-placeholder {
      line-height: 40px;
    }
  }
`;

export const Overlay = styled.div`
  position: absolute;
  min-width: 500px;
  height: 50px;
  z-index: 1000;
  background-color: transparent;
`;
