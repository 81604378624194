import React, { useEffect, useState } from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useInjection } from './core/inversify';
import { TokenService } from './services/api/TokenService';

// Components
import SeaechBar from './components/SeaechBar';
import Footer from './components/Footer';
import Header from './components/Header';
import UserDropdown from './components/userDropdown';

// Pages
import Dashboard from './pages/Dashboard';
import LogIn from './pages/Login/index';
import Company from './pages/CompanyPage';
import Solution from './pages/SolutionPage';

// Store
import { RecordsStore } from './stores/RecordsStore';
import { AuthStore } from './stores/AuthStore';
import Records from './pages/Records';

export const NavigationComponent = observer((): JSX.Element => {
  const customHistory = createBrowserHistory();
  const authStore = useInjection(AuthStore);
  const recordStore = useInjection(RecordsStore);
  const tokenService = useInjection(TokenService);
  const [isAuthorized, setIsAuthorized] = useState(tokenService.getIsAuthorized() || false);

  const logoutRedirect = () => {
    customHistory.push('')
    setIsAuthorized(false) // force reload to redirect to login page
  }

  //const logout = () => {
  //  customHistory.push('');
  //  setIsAuthorized(false);
  //};

  /*const isActiveUser = () => {
    const noActiveDelay = 60;
    let nowNoActive = 0;
    const intervalID = setInterval(() => {
      nowNoActive += 1;
      if (nowNoActive >= noActiveDelay) {
        authStore.logout(logout);
        clearInterval(intervalID);
      }
    }, 60000);

    function activeUser() {
      nowNoActive = 0;
    }

    document.onmousemove = activeUser;
  };*/

  useEffect(() => {
    recordStore.getRecords()
  }, [])

  useEffect(() => {
    setIsAuthorized(tokenService.getIsAuthorized())

    if (authStore.getIsAuthorized())
      authStore.checkTokenStart(logoutRedirect)
    else
      authStore.checkTokenStop()
  }, [authStore.getIsAuthorized()])

  const headerItem = () => {
    return (
      <>
        <Link to="/">
          <div className="logo-header" />
        </Link>
        {isAuthorized && <SeaechBar />}
        {isAuthorized && (
          <UserDropdown role={tokenService.getRole()} logout={() => authStore.logout(logoutRedirect)} fullName={tokenService.getFullName()} />
        )}
      </>
    );
  };

  const footerItem = () => {
    return (
      <Link to="/">
        <div className="logo-header" />
      </Link>
    );
  };

  const isHealthcheck = ['/health_check', '/health_check/'].includes(location.pathname)

  return (
    <Router>
      {isHealthcheck ?  // should be lightweight
        (<Route path="/health_check"><h3>Ok</h3></Route>)
        :
        (
          <Route path="/">
            <Header>{headerItem()}</Header>
            <div>
              <Switch>
                {!isAuthorized ? (
                  <Route exact path="" render={() => <LogIn />} />
                ) : (
                  <>
                    <Route exact path="/" render={() => <Dashboard />} />
                    <Route path="/company/:slug" render={(routerProps) => <Company companySlug={routerProps.match.params.slug} />} />
                    <Route path="/solution/:slug" render={(routerProps) => <Solution solutionSlug={routerProps.match.params.slug} />} />
                    <Route path="/records" render={() => <Records />} />
                  </>
                )}
              </Switch>
            </div>
            <Footer>{footerItem()}</Footer>
          </Route>
        )
      }
    </Router>
  );
});
