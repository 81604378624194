// Core
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useInjection } from '../../../core/inversify';

// Components
import { LoginLoader } from '../../../components/loginLoader';
// Store
import { AuthStore } from '../../../stores/AuthStore';
// Types
import { INeedStep } from '../../../services/api/types';
// Styles
import { StyledEndStep, FlexColumn, StyledButton } from '../styles';

interface IProps {
  nextSteps: () => void;
}

const EndStepVerify = observer(({ nextSteps }: IProps): JSX.Element => {
  const authStore = useInjection(AuthStore);
  const [userAgentIsSafari, setUserAgentIsSafari] = useState(false);
  useEffect(() => {
    if (navigator.userAgent.includes('Safari')) {
      setUserAgentIsSafari(true);
    }
    if (authStore.getNeedStep().includes(INeedStep.KEY_ENTER) && !navigator.userAgent.includes('Safari')) {
      authStore.mfaWebauthnEnterBegin();
    }
  }, []);

  const renderContent = () => {
    if (authStore.getNeedStep().includes(INeedStep.KEY_ENTER)) {
      return (
        <>
          <p>Insert your yubikey and touch it to verify sign in</p>
          {userAgentIsSafari && !authStore.getError() && (
            <StyledButton
              onClick={() => {
                setUserAgentIsSafari(false);
                authStore.mfaWebauthnEnterBegin();
              }}
            >
              <p> Start</p>
            </StyledButton>
          )}
          {!authStore.getError() && !userAgentIsSafari && <LoginLoader />}
          {authStore.getError() && (
            <FlexColumn className="verify">
              <StyledButton onClick={() => nextSteps()}>
                <p>BACK to start</p>
              </StyledButton>
              <StyledButton onClick={() => authStore.mfaWebauthnEnterBegin()}>
                <p> TRY AGAIN</p>
              </StyledButton>
            </FlexColumn>
          )}
        </>
      );
    }
    return (
      <>
        <p>Interact with your authenticator.</p>
      </>
    );
  };

  return (
    <StyledEndStep>
      <h2 className="key-verify">Verify</h2>
      {renderContent()}
    </StyledEndStep>
  );
});

export default EndStepVerify;
