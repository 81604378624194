import * as apiTypes from 'src/services/api/types'

import { FieldsNameContacts } from './types'
import { FieldsNameTheyDoItFor } from './types'
import { FieldsNameTheyDo } from './types'
import { FieldsNameStage } from './types'
import { FieldsNameRisk } from './types'
import { FieldsNameNotes } from './types'
import { FieldsNameMentions } from './types'
import { FieldsNameMain } from './types'
import { FieldsNameInterest } from './types'
import { FieldsNameContactsSource } from './types'
import { FieldsNameInterestSource } from './types'
import { FieldsNameMainSource } from './types'
import { FieldsNameMentionsSource } from './types'
import { FieldsNameNotesSource } from './types'
import { FieldsNameRiskSource } from './types'
import { FieldsNameStageSource } from './types'
import { FieldsNameTheyDoItForSource } from './types'
import { FieldsNameTheyDoSource } from './types'

export function getInitialFormValues() {
  return {
    // undefined if ids
    [FieldsNameMain.legalName]: '',
    [FieldsNameMain.trading]: '',
    [FieldsNameMain.status]: '',
    [FieldsNameMain.incorporated]: undefined,
    [FieldsNameMain.incorporatedState]: '',
    [FieldsNameMainSource.incorporatedSource]: '',
    [FieldsNameMainSource.incorporatedStateSource]: '',
    [FieldsNameMain.founded]: '',
    [FieldsNameMain.nameInTitle]: '',
    [FieldsNameMain.slug]: '',

    [FieldsNameContacts.addressOne]: '',
    [FieldsNameContacts.addressTwo]: '',
    [FieldsNameContacts.city]: '',
    [FieldsNameContacts.country]: undefined,
    [FieldsNameContacts.state]: '',
    [FieldsNameContacts.code]: '',
    [FieldsNameContacts.otherOffices]: '',
    [FieldsNameContacts.otherCity]: '',
    [FieldsNameContacts.otherCountry]: undefined,
    [FieldsNameContacts.otherAddress]: '',
    [FieldsNameContacts.officesPhone]: '',
    [FieldsNameContacts.webSite]: '',
    [FieldsNameContacts.mail]: '',
    [FieldsNameContacts.linkedin]: '',
    [FieldsNameContacts.twitter]: '',
    [FieldsNameContacts.facebook]: '',
    [FieldsNameContacts.youtube]: '',
    [FieldsNameContacts.counterOfOffice]: 0,
    [FieldsNameTheyDo.categories]: [],
    [FieldsNameTheyDo.subCategories]: [],
    [FieldsNameTheyDo.otherCategories]: '',
    [FieldsNameTheyDo.description]: '',
    [FieldsNameTheyDo.longDescription]: '',
    [FieldsNameTheyDo.revenue]: '',
    [FieldsNameTheyDo.logoImg]: null,
    [FieldsNameTheyDo.brochure]: '',

    [FieldsNameTheyDoItFor.industry]: [],
    [FieldsNameTheyDoItFor.type]: [],
    [FieldsNameTheyDoItFor.sizeEmployees]: [],
    [FieldsNameTheyDoItFor.sizeRevenues]: [],
    [FieldsNameTheyDoItFor.locationCustomer]: [],
    [FieldsNameTheyDoItFor.customerDescription]: '',
    [FieldsNameTheyDoItFor.marquee]: '',
    [FieldsNameTheyDoItFor.userGroup]: [],
    [FieldsNameTheyDoItFor.languages]: [],

    [FieldsNameStage.privat]: undefined,
    [FieldsNameStage.exchange]: '',
    [FieldsNameStage.ticker]: '',
    [FieldsNameStage.capital]: '',
    [FieldsNameStage.listRound]: '',
    [FieldsNameStage.phase]: undefined,
    [FieldsNameStage.orgEmployees]: undefined,
    [FieldsNameStage.orgRevenues]: undefined,
    [FieldsNameStage.ebitda]: undefined,

    [FieldsNameInterest.ceo]: '',
    [FieldsNameInterest.board]: '',
    [FieldsNameInterest.investors]: '',
    [FieldsNameInterest.controlInvestors]: '',
    [FieldsNameInterest.investorsNationality]: '',
    [FieldsNameInterest.original]: '',

    [FieldsNameMentions.mentors]: '',
    [FieldsNameMentions.mentees]: '',
    [FieldsNameMentions.awards]: '',
    [FieldsNameMentions.graduated]: '',
    [FieldsNameMentions.industryMentions]: '',
    [FieldsNameMentions.classes]: [],

    [FieldsNameRisk.audited]: undefined,
    [FieldsNameRisk.compliance]: undefined,
    [FieldsNameRisk.security]: [],

    [FieldsNameNotes.connect]: [],
    [FieldsNameNotes.risk]: undefined,
    [FieldsNameNotes.notesOnManagement]: '',
    [FieldsNameNotesSource.notesOnManagementSource]: '',
    [FieldsNameNotes.notesFocusNextInterview]: '',
    [FieldsNameNotesSource.notesFocusNextInterviewSource]: '',
    [FieldsNameNotes.profileName]: '',
    [FieldsNameNotes.profileTitle]: '',
    [FieldsNameNotes.profileEmail]: '',
    [FieldsNameNotes.profilePhone]: '',

    [FieldsNameMainSource.nameSource]: '',
    [FieldsNameMainSource.tradingSource]: '',
    [FieldsNameMainSource.statusSource]: '',
    [FieldsNameMainSource.foundedSource]: '',
    [FieldsNameMainSource.slugSource]: '',

    [FieldsNameContactsSource.addressOneSource]: '',
    [FieldsNameContactsSource.addressTwoSource]: '',
    [FieldsNameContactsSource.citySource]: '',
    [FieldsNameContactsSource.countrySource]: '',
    [FieldsNameContactsSource.stateSource]: '',
    [FieldsNameContactsSource.codeSource]: '',
    [FieldsNameContactsSource.otherOfficesSource]: '',
    [FieldsNameContactsSource.otherCitySource]: '',
    [FieldsNameContactsSource.officesPhoneSource]: '',
    [FieldsNameContactsSource.webSiteSource]: '',
    [FieldsNameContactsSource.mailSource]: '',
    [FieldsNameContactsSource.linkedinSource]: '',
    [FieldsNameContactsSource.twitterSource]: '',
    [FieldsNameContactsSource.facebookSource]: '',
    [FieldsNameContactsSource.youtubeSource]: '',

    [FieldsNameTheyDoSource.categoriesSource]: '',
    [FieldsNameTheyDoSource.subCategoriesSource]: '',
    [FieldsNameTheyDoSource.otherCategoriesSource]: '',
    [FieldsNameTheyDoSource.descriptionSource]: '',
    [FieldsNameTheyDoSource.longDescriptionSource]: '',
    [FieldsNameTheyDoSource.revenueSource]: '',

    [FieldsNameTheyDoItForSource.industrySource]: '',
    [FieldsNameTheyDoItForSource.typeSource]: '',
    [FieldsNameTheyDoItForSource.sizeEmployeesSource]: '',
    [FieldsNameTheyDoItForSource.sizeRevenuesSource]: '',
    [FieldsNameTheyDoItForSource.locationCustomerSource]: '',
    [FieldsNameTheyDoItForSource.customerDescriptionSource]: '',
    [FieldsNameTheyDoItForSource.marqueeSource]: '',
    [FieldsNameTheyDoItForSource.userGroupSource]: '',
    [FieldsNameTheyDoItForSource.languagesSource]: '',

    [FieldsNameStageSource.privatSource]: '',
    [FieldsNameStageSource.exchangeSource]: '',
    [FieldsNameStageSource.tickerSource]: '',
    [FieldsNameStageSource.capitalSource]: '',
    [FieldsNameStageSource.listRoundSource]: '',
    [FieldsNameStageSource.phaseSource]: '',
    [FieldsNameStageSource.orgEmployeesSource]: '',
    [FieldsNameStageSource.orgRevenuesSource]: '',
    [FieldsNameStageSource.ebitdaSource]: '',

    [FieldsNameInterestSource.ceoSource]: '',
    [FieldsNameInterestSource.boardSource]: '',
    [FieldsNameInterestSource.investorsSource]: '',
    [FieldsNameInterestSource.controlInvestorsSource]: '',
    [FieldsNameInterestSource.investorsNationalitySource]: '',
    [FieldsNameInterestSource.originalSource]: '',

    [FieldsNameMentionsSource.mentorsSource]: '',
    [FieldsNameMentionsSource.menteesSource]: '',
    [FieldsNameMentionsSource.awardsSource]: '',
    [FieldsNameMentionsSource.graduatedSource]: '',
    [FieldsNameMentionsSource.industryMentionsSource]: '',
    [FieldsNameMentionsSource.classesSource]: '',

    [FieldsNameRiskSource.auditedSource]: '',
    [FieldsNameRiskSource.complianceSource]: '',
    [FieldsNameRiskSource.securitySource]: '',

    [FieldsNameNotesSource.connectSource]: '',
    [FieldsNameNotesSource.riskSource]: '',
    [FieldsNameNotesSource.profileNameSource]: '',
    [FieldsNameNotesSource.profileTitleSource]: '',
    [FieldsNameNotesSource.profileEmailSource]: '',
    [FieldsNameNotesSource.profilePhoneSource]: '',
  }
}

export function updateDataValidate(
    {data, setFieldError, companyId, company, logoValue, revenue, imgInput, phoneNumber, profilePhoneNumber}:
    {
      data: any;
      setFieldError: any,
      companyId: string | undefined;
      company: apiTypes.ICompanyInfo['company'] | undefined;
      logoValue: any;
      revenue: string;
      imgInput: any;
      phoneNumber: string;
      profilePhoneNumber: string;
    }
): {
    revenueReseted: string | undefined;
    phoneNumberReseted: string | undefined;
    profilePhoneNumberReseted: string | undefined;
    invalidLogoErrorMessage: string | undefined;
} {
  if (!companyId) {
    throw new Error(`can't submit - companyId missing`)
  }
  if (!company)
    throw new Error(`can't submit - company missing`)

  var revenueReseted
  var invalidLogoErrorMessage
  var phoneNumberReseted
  var profilePhoneNumberReseted

  if (phoneNumber?.replace(/\s/g, '').length < 10) {
    phoneNumberReseted = company?.contacts?.head_office_phone?.value || '';
    setFieldError(FieldsNameContacts.officesPhone, "Telephone number can't be less than 10 characters");
  }
  if (phoneNumber?.replace(/\s/g, '').length > 13) {
    phoneNumberReseted = company?.contacts?.head_office_phone?.value || '';
    setFieldError(FieldsNameContacts.officesPhone, "Telephone number can't exceed 13 characters");
  }

  if (profilePhoneNumber?.replace(/\s/g, '').length > 13) {
    profilePhoneNumberReseted = company?.catalisto_internal_screening_notes?.vendor_profile_owner_telephone?.value || '';
    setFieldError(FieldsNameNotes.profilePhone, "Telephone number can't exceed 13 characters");
  }

  if (profilePhoneNumber?.replace(/\s/g, '').length < 10) {
    profilePhoneNumberReseted = company?.catalisto_internal_screening_notes?.vendor_profile_owner_telephone?.value || '';
    setFieldError(FieldsNameNotes.profilePhone, "Telephone number can't be less than 10 characters");
  }

  if (!data[FieldsNameMain.legalName]) {
    setFieldError(FieldsNameMain.legalName, 'Please enter Company Name');
  }
  if (!data[FieldsNameMain.nameInTitle]) {
    setFieldError(FieldsNameMain.nameInTitle, 'Please enter Company Name');
  }

  if (revenue && Number(revenue) > 100) {
    revenueReseted = '0'
    setFieldError(FieldsNameTheyDo.revenue, 'Values from 0 to 100%')
  }

  if (logoValue) {
    if (imgInput?.current?.files[0]?.size > 5242880) {
      invalidLogoErrorMessage = "The logo can`t exceed 5 MB"
    }
  }

  return {
    revenueReseted,
    phoneNumberReseted,
    profilePhoneNumberReseted,
    invalidLogoErrorMessage,
  }
}

/**
 * Render array from backend to corresponding string.
 * Input: [
 *   "University 1",
 *   "University 2",
 *   "College 2",
 * ]
 * Output:
 *   "University 1, University 2, College 2"
 * Would be rendered with raw string interpolation as:
 *   "University 1,University 2,College 2"
 */
export function renderArrayToString(array: string[] | null | undefined): string {
  if (!array)
    array = []
  return array.join(', ')
}
