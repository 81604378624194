import React, { useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import { RecordsStore } from '../../stores/RecordsStore';
import { useInjection } from '../../core/inversify';

import { StyledSearchBar, Wrapper } from './styles';

const SeaechBar = (): JSX.Element => {
  const { push } = useHistory();
  const [value, setvalue] = useState<string>('');
  const { setSearchValue } = useInjection(RecordsStore);

  const onSubmit = () => {
    const routeState = { searchQuery: value };
    setSearchValue(value);
    push('/records', routeState);
  };

  return (
    <Wrapper>
      <SearchIcon />
      <StyledSearchBar
        name="search"
        onChange={(e) => {
          setvalue(e.target.value);
          if (!e.target.value) {
            setSearchValue('');
          }
        }}
        value={value}
        placeholder="Search in admin panel"
        inputProps={{ 'aria-label': 'search' }}
        onKeyDown={(e) => (e.key === 'Enter' ? onSubmit() : null)}
      />
      {value && (
        <button
          onClick={() => {
            setSearchValue('');
            setvalue('');
          }}
        >
          <CloseIcon />
        </button>
      )}
    </Wrapper>
  );
};

export default SeaechBar;
