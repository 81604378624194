// Core
import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import { Multiselect } from 'multiselect-react-dropdown';
import { ISearchCompany } from '../../../services/api/types';

// Styles
import { WrapperMultiselect, Overlay } from './styles';

export interface SelectItem {
  value: string | number;
  label?: string;
}

export interface Items {
  id: string;
  title: string;
  parent_category?: string;
  sub_category?: boolean;
}

export interface IProps {
  name: string;
  className?: string;
  onChange: (name: string, valuse: string | string[]) => void;
  onSearch?: (query: string) => Promise<any>;
  setSubCategories?: (items: Items[] | undefined) => void;
  items?: Items[] | null;
  defaultSearchValue?: Items;
  searchItems?: ISearchCompany;
  defaultValue?: string[] | undefined;
  placeholder?: string;
  disabled?: boolean;
  id?: string;
  isSubCategory?: boolean;
  selectionLimit?: boolean;
}
const itemsOption = (elements: Items[] | undefined | null): any | void => {
  if (elements) {
    return elements?.map((el) => ({ value: el.id, label: el.title }));
  }
  return [];
};

export const PhoneCodeInput = observer(
  ({
    id,
    placeholder,
    onChange,
    className,
    items,
    defaultValue,
    defaultSearchValue,
    isSubCategory = false,
    name,
    onSearch,
    selectionLimit = false,
    disabled = false,
    setSubCategories,
  }: IProps): JSX.Element => {
    const [optionsValue, setOptionsValue] = useState<any>();
    const [value, setValue] = useState<any>();
    const [toggleSvg, setToggleSvg] = useState<boolean>(false);
    const [selectedValues, setSelectedValues] = useState<SelectItem | null>();
    const [valueSearch, setValueSearch] = useState<string>('');
    const { submitForm } = useFormikContext<any>();

    useEffect(() => {
      onChange(name, value);
    }, [value]);

    useEffect(() => {
      if (isSubCategory) {
        const subCategory = items?.filter((el) => el.sub_category);
        setOptionsValue(itemsOption(subCategory));
      } else {
        setOptionsValue(itemsOption(items));
      }
      if (defaultValue) {
        onChange(name, defaultValue[0]);
        // eslint-disable-next-line
        setDefaultValue(defaultValue[0]);
      }
      if (defaultSearchValue) {
        // eslint-disable-next-line
        setDefaultValue([defaultSearchValue.id]);
        onChange(name, [defaultSearchValue.id]);
        setSelectedValues({ value: defaultSearchValue.id, label: defaultSearchValue.title });
      }
    }, [items, defaultSearchValue]);

    useEffect(() => {
      if (onSearch && valueSearch) {
        setOptionsValue(itemsOption(items));
      }
    }, [valueSearch, items]);

    const setDefaultValue = (val: string | string[]) => {
      const defaultSelectedValues = items?.filter((el) => val === el.id);
      if (defaultSelectedValues) {
        setValue(defaultSelectedValues.map((el) => el.id));
      }
      setSelectedValues(itemsOption(defaultSelectedValues));
    };

    const handleSelect = (selectedElement: SelectItem[]) => {
      if (setSubCategories && items) {
        const filteredItems: Items[] = [];
        selectedElement.forEach((selectedItem) => {
          items.forEach((el) => {
            if (selectedItem.value === el.parent_category) {
              filteredItems.push(el);
            }
          });
        });
        setSubCategories(filteredItems);
      }
      setValue(selectedElement);
    };
    return (
      <WrapperMultiselect
        displayInput={value?.length || !value}
        toggleSvg={toggleSvg}
        onClick={() => {
          setToggleSvg(true);
        }}
        onBlur={() => {
          setToggleSvg(false);
          setTimeout(() => {
            submitForm();
          }, 500);
        }}
        className={className}
      >
        {disabled && <Overlay />}
        <Multiselect
          selectionLimit={selectionLimit ? 1 : -1}
          placeholder={selectedValues || value ? ' ' : placeholder}
          id={id}
          options={optionsValue}
          onSearch={(e) => {
            if (onSearch) {
              onSearch(e);
              setValueSearch(e);
            }
          }}
          selectedValues={defaultSearchValue ? [{ value: defaultSearchValue.id, label: defaultSearchValue.title }] : selectedValues}
          onSelect={(selectedElement: SelectItem[]) => handleSelect(selectedElement)}
          onRemove={() => {
            setValue([]);
            setTimeout(() => {
              submitForm();
            }, 500);
          }}
          displayValue="label"
        />
      </WrapperMultiselect>
    );
  }
);
