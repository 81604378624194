// Core
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Field } from 'formik';

export const StyledTextField = styled(Field)<{ width: string; type: string; as?: string }>`
  padding: 0;
  min-height: 40px;
  border: none;
  background-color: #ffffff;
  font-weight: 400;
  border-radius: 6px;
  color: #1d2553;
  font-size: 16px;
  outline: none;
  text-transform: none;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  resize: none;
  ${({ width }) => `width:${width}px;`}

  :-moz-placeholder {
    line-height: 40px;
  }
  ::-webkit-input-placeholder {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 16px;
  }
  ::-moz-placeholder {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 16px;
  }
  :-ms-input-placeholder {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 16px;
  }
  :-moz-placeholder {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 16px;
  }

  ::-moz-placeholder {
    line-height: 40px;
  }
  &.input-group {
    min-width: 155px;
    width: 220px;
  }

  ${({ type }) =>
    type === 'records'
      ? css`
          background: #f2f4f8;
          padding-left: 38px;
          &::placeholder {
            color: #d2d3dd;
          }
        `
      : null}

  ${({ as }) =>
    as === 'textarea'
      ? css`
          display: block;
          padding: 10px 10px 10px 38px;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;

          &::placeholder {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
          }
        `
      : null}
`;
