// Core
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useInjection } from '../../../core/inversify';

// Store
import { AuthStore } from '../../../stores/AuthStore';

// Component
import TextField from '../../../components/input/TextField';
import ErrorBox from '../../../components/errorBox';

// Styles
import { WrapperForm, StyledButton, StyledErr } from '../styles';

let errorMessage: string;
const SignupSchema = Yup.object().shape({
  FieldName: Yup.string().max(256, 'The name is too long').required('Please enter name'),
});

const detectWebAuthnSupport = (): boolean => {
  if (window.PublicKeyCredential === undefined || typeof window.PublicKeyCredential !== 'function') {
    errorMessage = "Oh no! This browser doesn't currently support WebAuthn.";
    if (window.location.protocol === 'http:' && window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1') {
      errorMessage = 'WebAuthn only supports secure connections. For testing over HTTP, you can use the origin "localhost".';
    }
    return true;
  }
  return false;
};
interface IProps {
  nextSteps: () => void;
}

const EndStepAddKey = observer(({ nextSteps }: IProps): JSX.Element => {
  const authStore = useInjection(AuthStore);
  const [showError, setShowError] = useState(false);
  useEffect(() => {
    setShowError(detectWebAuthnSupport());
  }, []);

  const onSubmit = async (name: string) => {
    nextSteps();
    setTimeout(() => authStore.mfaWebauthnRegisterBegin(name), 500);
  };

  return (
    <>
      <Formik
        validationSchema={SignupSchema}
        initialValues={{
          name: 'Default Yubikey',
        }}
        onSubmit={(value) => onSubmit(value.name)}
      >
        {({ errors, touched, values }) => {
          return (
            <WrapperForm>
              {showError && <ErrorBox status="error" text={errorMessage} />}
              <h2 className="key-verify">Add Security key</h2>
              <p>Enter the name of your security key.</p>
              <TextField name="name" placeholder="Enter Key Name" />
              {touched.name && errors.name ? <StyledErr>{errors.name}</StyledErr> : null}
              <StyledButton onClick={() => onSubmit(values.name)} type="submit">
                <p>Done</p>
              </StyledButton>
            </WrapperForm>
          );
        }}
      </Formik>
    </>
  );
});

export default EndStepAddKey;
