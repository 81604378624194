import styled from '@emotion/styled';

export const StepProgress = styled.div<{ currentStep: number }>`
  display: flex;
  position: relative;
  justify-content: center;
  align-self: center;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  transform: translateX(-15px);
  max-width: 370px;
  padding-left: 30px;
  height: 20px;
  div.box {
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-self: center;
    justify-content: center;
    width: 100%;
  }
  div.step {
    font-size: 11px;
    display: flex;
    width: 21px;
    height: 20px;
    padding: 0;
    margin: 0;
    padding-top: 3px;
    display: inline-block;
    color: #d2d3dd;
    border-radius: 50%;
    border: 1px solid #d2d3dd;
    text-align: center;
    font-size: 11px;
    margin-left: 84px;
  }

  div.step {
    position: relative;
    &::before {
      content: '';
      width: 60px;
      height: 1px;
      background-color: #d2d3dd;
      position: absolute;
      top: 50%;
      left: -385%;
    }
  }
  div.step:nth-of-type(1) {
    margin-left: 0;
    &::before {
      content: none;
    }
  }
  div.step:nth-of-type(${({ currentStep }) => currentStep}) {
    background: linear-gradient(134.72deg, #fc4d9a 0%, #9c0745 101.5%);
    opacity: 1;
    border: none;
    width: 21px;
    height: 20px;
    padding-top: 3px;
  }
  @media screen and (min-width: 375px) and (max-width: 740px) {
    width: 375px;
    left: 23%;
    span:nth-of-type(2n) {
      margin: 0 84px;
      &::after {
        content: '';
        width: 65px;
        height: 1px;
        background-color: #d2d3dd;
        position: absolute;
        top: 45%;
        right: 47%;
      }
    }
  }
`;
