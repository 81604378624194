import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import { Link } from 'react-router-dom';
import { SideBarStyled, StyledPopover } from './stiles';
//import { StyledTypography } from './stiles';

// img and svg
import analiticsSvg from '../../assets/images/dashboard/analitics.svg';
import contentSvg from '../../assets/images/dashboard/content.svg';
import usersSvg from '../../assets/images/dashboard/users.svg';

const SideBar = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState(null);

  /*const handlePopoverOpen = (event: any) => {
    // setAnchorEl(event.currentTarget);
  };*/

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <SideBarStyled>
      <ul>
        {/* <li>
          <StyledTypography aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handlePopoverOpen}>
            <button type="button">
              <img src={} alt="content" />
            </button>
          </StyledTypography>
        </li> */}
        <li>
          <p className="popover">To Records</p>
          <Link to="/records">
            <button type="button">
              <img src={contentSvg} alt="analitics" />
            </button>
          </Link>
        </li>
        {/* <li>
          <button type="button">
            <img src={usersSvg} alt="manageuser" />
          </button>
        </li> */}
      </ul>

      <Popover id="mouse-over-popover" open={open} anchorEl={anchorEl} onClose={handlePopoverClose} disableRestoreFocus>
        <StyledPopover>
          <div>
            <button type="button">
              <img src={contentSvg} alt="content" />
              Content
            </button>
          </div>
          <div>
            <button type="button">
              <img src={analiticsSvg} alt="analitics" />
              Analitics
            </button>
          </div>
          <div>
            <button type="button">
              <img src={usersSvg} alt="manageuser" />
              Manage Users
            </button>
          </div>
        </StyledPopover>
      </Popover>
    </SideBarStyled>
  );
};

export default SideBar;
