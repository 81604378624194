import styled from '@emotion/styled';
import InputBase from '@material-ui/core/InputBase';

export const StyledSearchBar = styled(InputBase)`
  input {
    width: 400px;
    padding: 10px 40px;
    background-color: #f2f4f8;
    border-radius: 6px;
  }
`;
export const Wrapper = styled.div`
  position: relative;
  & > svg.MuiSvgIcon-root {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    z-index: 2;
    fill: #9c0745;
  }

  & button {
    position: absolute;
    top: 55%;
    right: 10px;
    border: none;
    background-color: transparent;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 3;
    & svg.MuiSvgIcon-root {
      fill: #9c0745;
    }
  }
`;
