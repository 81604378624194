// Core
import React, { useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';

// Styles
import { StyledTextField, StyledCurrentCharacters,Wrapper } from './styles';

export interface IProps {
  name: string;
  className?: string;
  type?: string;
  width?: string;
  placeholder?: string;
  disabled?: boolean;
  autocomplete?: boolean;
  id?: string;
  defaultValue?: string;
  onChange?: (name: string, value: string) => void;
  originValue?: string;
  maxCharacters: number;
  maxValues?: number;
}

const Text = observer(
  ({ name, id, defaultValue = '', maxCharacters, maxValues, placeholder, className, disabled = false }: IProps): JSX.Element => {
    /* eslint-disable react/jsx-props-no-spreading */
    const { setFieldValue, submitForm } = useFormikContext<any>();
    const [charactersLeft, setCharactersLeft] = useState<number>()
    const inputRef = useRef<any>(null);
    
    useEffect(() => {
      setCharactersLeft(maxCharacters - inputRef?.current?.value?.length);
    }, [inputRef, defaultValue]);

    useEffect(() => {
      setFieldValue(name, defaultValue);
    }, [defaultValue]);

    return (
      <Wrapper>
        <StyledTextField
          ref={inputRef}
          defaultValue={defaultValue}
          onBlur={() => {
            setFieldValue(name, inputRef.current.value);
            setTimeout(() => {
              submitForm();
            }, 500);
          }}
          onChange={()=>setCharactersLeft(maxCharacters - inputRef?.current?.value?.length)}
          className={className}
          disabled={disabled}
          name={name}
          min="0"
          max={maxValues}
          size={maxCharacters}
          maxLength={maxCharacters}
          placeholder={placeholder}
          id={id}
        />
        {inputRef?.current?.value && <StyledCurrentCharacters> characters left: <span>{charactersLeft}</span></StyledCurrentCharacters>
        }
      </Wrapper>
    );
  }
);

export default Text;
