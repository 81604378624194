import React from 'react';
import './App.css';
import { Provider, appContainer } from './core/inversify';
import { NavigationComponent } from './router';

function Root(): JSX.Element {
  return (
    <Provider container={appContainer}>
      <NavigationComponent />
    </Provider>
  );
}

export default Root;
