// Core
import styled from '@emotion/styled';
import opnenSvg from '../../../assets/images/company/openSelect.svg';

export const StyledWrapper = styled.div<{ isValid?: any }>`
  padding: 0;
  padding-left: 10px;
  margin-bottom: 10px;
  height: 40px;
  border: none;
  outline: none;
  text-transform: none;
  width: 100%;
  min-width: 200px;
  margin-top: 5px;

  &.input-group {
    min-width: 155px;
    width: 200px;
  }

  & .PhoneInput {
    display: flex;
  }

  & .PhoneInput--focus {
    & .PhoneInputInput {
      border: none;
      outline: none;
    }
  }

  & .PhoneInputCountry {
    position: relative;
    width: 80px;
    height: 40px;
    margin-right: 20px;

    & .PhoneInputCountrySelect {
      position: absolute;
      width: 80px;
      height: 40px;
      border: none;
      opacity: 0;
      z-index: 1;
      cursor: pointer;
    }
    & .PhoneInputCountryIcon {
      position: absolute;
      top: 50%;
      left: 20%;
      transform: translateY(-35%);
      width: 40px;
      height: 40px;
      cursor: pointer;

      &::after {
        content: '';
        position: absolute;
        top: 0%;
        right: -60%;
        display: block;
        width: 20px;
        height: 20px;
        background-image: url(${opnenSvg});
      }
    }
  }

  & .PhoneInputInput {
    width: 76%;
    background-color: #f2f4f8;
    border: none;
    border-radius: 6px;
    padding-left: 20px;
    color: #1d2553;
    font-size: 16px;
    font-weight: 400;

    :-moz-placeholder {
      line-height: 40px;
    }

    ::-moz-placeholder {
      line-height: 40px;
    }
    ${({ isValid }) => (!isValid ? null : 'border: 1px solid #FC4D9A;')}
    &:focus {
      border: none;
    }
  }
`;
