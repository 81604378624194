// Core
import React, { useState } from 'react';

// Styles
import { Box } from './styles';
import svg from '../../assets/images/unable.svg';

interface IProps {
  text: string;
  status: 'OK' | 'error';
}

const ErrorBox = ({ text, status }: IProps): JSX.Element | null => {
  const [showErrorBox, setShowErrorBox] = useState(true);
  setTimeout(() => {
    return setShowErrorBox(false);
  }, 7000);

  return showErrorBox ? (
    <Box status={status}>
      <img src={svg} alt="text" />
      <p>{text}</p>
    </Box>
  ) : null;
};

export default ErrorBox;
