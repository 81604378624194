import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Select from 'react-select';
import agreement from '../../assets/images/table/agreement.svg';
import file from '../../assets/images/table/table-background.png';
import Button from '../../components/Button';

export const PositionWrapper = styled.div`
  position: relative;
  margin-right: 10px;
`;

export const ClosedButton = styled(Button)`
  .MuiButton-root {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }

  &.MuiButton-text {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    min-width: 20px;
    background: #fff;
    z-index: 10000;
    &.MuiButton-root:hover {
      background-color: #fff;
    }
  }
`;

export const Container = styled.div`
  font-style: normal;
  flex-grow: 1;
  background: #f2f4f8;
  padding-top: 50px;
  padding-left: 55px;
  padding: 50px 0 50px 90;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;

  & > div.title {
    font-size: 36px;
    font-weight: 800;
    line-height: 44px;
    letter-spacing: 3px;
    color: #1d2553;
    text-transform: uppercase;
  }

  & > div.subtitle {
    font-size: 11px;
    font-weight: 600;
    line-height: 13.5px;
    letter-spacing: 1px;
    color: #777c98;
    margin-top: 5px;
  }

  & div.tabContainer {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    background: #e8eaf0;
    align-self: flex-start;
    border-radius: 6px 6px 0 0;
  }

  & div.tableContainer {
    background: #fff;
    padding: 40px 20px 120px 50px;
  }

  & div.tableHelpers {
    display: flex;
    justify-content: space-between;
  }
`;

export const Tab = styled.div<{ active: boolean }>`
  font-size: 11px;
  font-weight: 600;
  line-height: 13.5px;
  letter-spacing: 1px;
  color: #777c98;
  padding: 14px 60px;
  position: relative;
  cursor: pointer;
  user-select: none;

  &:not(:first-of-type)::before {
    position: absolute;
    display: block;
    content: '';
    width: 1px;
    height: 24px;
    top: 50%;
    left: 0;
    background: rgba(29, 37, 83, 0.2);
    transform: translateY(-50%);
  }

  & span {
    color: #1d2553;
  }

  ${({ active }) =>
    active
      ? css`
          color: #1d2553;
          background: #ffffff;
          border-radius: 6px 6px 0 0;
          & span {
            color: #fc4d9a;
          }

          &::before {
            display: none !important;
          }

          & + div::before {
            display: none !important;
          }
        `
      : null}
`;

export const StyledTable = styled.div`
  margin-top: 20px;

  & button.researcherButton {
    background: transparent;
    border: none;
    padding: 5px;
    text-transform: uppercase;
    color: #d2d3dd;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 1px;
    cursor: pointer;
  }
`;

export const Row = styled.div<{ iconName?: string }>`
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: #1d2553;
  position: relative;
  display: flex;

  & > div {
    flex: 1 1;

    &.actions {
      flex-basis: 220px;
    }

    &.date {
      flex-basis: 164px;
    }
    &.company {
      flex-basis: 250px;
    }

    &.solution {
      flex-basis: 250px;
    }

    &.researcher {
      flex-basis: 200px;
    }
  }

  &.bodyRow:not(:last-child) {
    border-bottom: 1px solid #d2d3dd;
  }

  &.bodyRow > div {
    min-height: 80px;
    align-items: center;
  }

  & > div:not(:last-child) {
    margin-right: 10px;
  }

  ${({ iconName }) => {
    let icon;
    if (iconName === 'agreement') {
      icon = agreement;
    }

    switch (icon) {
      case 'agreement':
        icon = agreement;
        break;
      default:
        break;
    }
    return icon
      ? css`
          &::after {
            display: block;
            position: absolute;
            width: 20px;
            height: 20px;
            content: '';
            background: url(${icon}) no-repeat center;
            top: 50%;
            left: -35px;
            transform: translateY(-50%);
          }
        `
      : null;
  }};
`;

export const HeadCell = styled.div<{ sortable: boolean }>`
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;

  .contentContainer {
    display: flex;
    display: -webkit-box;
    justify-content: space-between;
  }

  ${({ sortable }) =>
    sortable
      ? css`
          img {
            margin-left: 10px;
            display: block;
            cursor: pointer;
          }
        `
      : null}
  .columnHorizontalLine {
    width: 100%;
    height: 2px;
    border-radius: 6px;
    margin-top: 5px;
  }
`;

export const BodyCell = styled.div`
  display: flex;
  align-items: center;
`;

export const CompanyCell = styled.a<{ withLink?: boolean }>`
  color: #1d2553;
  text-decoration: none;
  display: inline-block;
  position: relative;
  word-break: break-all;

  &:hover {
    color: #fc4d9a;
  }

  & > a {
    display: inline-block;

    & > button {
      border: none;
      background: #fff;
      display: flex;
      align-items: center;
      cursor: pointer;

      & img {
        display: block;
      }
    }
  }
`;

export const Dates = styled.div<{ content: number; }>`
  position: relative;
  padding-left: 30px;

  &::before {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    content: '${({ content }) => {
      if (content < 10) {
        return `0${content}`;
      }
      return content;
    }}';
    font-size: 10px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0px;
    color: #777c98;
  }

  & > div {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

export const CreateButton = styled.button`
  padding: 14px 45px 14px 68px;
  background: #f2f4f8;
  color: #1d2553;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  border-radius: 6px;
  margin-right: 32px;
  cursor: pointer;
  position: relative;

  img {
    position: absolute;
    display: block;
    top: 50%;
    left: 45px;
    transform: translateY(-50%);
  }
`;

export const EmptyPageContainer = styled.div`
  width: 100%;
  min-height: 524px;
  background: url(${file}) no-repeat right 115px bottom 80px;
  display: flex;
  align-items: flex-start;

  .emptyPageTitle {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 1px;
    color: #777c98;
  }

  .emptyPageSubtitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    color: #d2d3dd;
  }
`;

export const StyledButton = styled.button<{ text?: string }>`
  position: relative;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #777c98;
  background: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;

  &[disabled] {
    img {
      opacity: 0.4;
    }
  }
  &[disabled]:hover {
    background: transparent;
    cursor: auto;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover {
    background: #f2f4f8;
    color: #1d2553;
    & div {
      display: block;
    }
  }

  ${({ text }) =>
    text
      ? css`
          padding: 9px 28px 9px 8px;
        `
      : css`
          padding: 5px;
        `}

  img {
    display: inline-block;
    vertical-align: middle;
  }

  span {
    margin-left: 5px;
    vertical-align: middle;
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  position: relative;
  padding: 0;
  margin-right: 10px;
  border: none;
`;
export const FilterSelect = styled(Select)`
  width: 100%;
  position: relative;
  margin-right: 10px;
  border: none;
`;

export const TableFilters = styled.div`
  flex: 1 0;
  display: flex;
  justify-content: flex-end;
  & > div {
    flex: 0 1 250px;
  }
`;

export const DatePickerToggler = styled.button`
  position: relative;
  height: 40px;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 1px;
  color: rgba(119, 124, 152, 1);
  text-transform: uppercase;
  text-align: left;
  padding-left: 16px;
  border: none;
  border-radius: 6px;
  background: #f2f4f8;
  min-width: 106px;
  cursor: pointer;

  & > img {
    position: absolute;
    display: block;
    padding: 8px;
    background: #fff;
    right: 8px;
    border-radius: 6px;
    top: 50%;
    transform: translateY(-50%);
  }

  & > .datepicker {
    position: absolute;
    top: 40px;
    right: 0;
  }
`;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const SelectStyles = {
  container: () => ({
    background: '#F2F4F8',
    borderRadius: '6px 6px 0 0',
    padding: '8px 0px 8px 13px',
    borderBottom: '1px solid #fff',
    height: '27px',
    cursor: 'pointer',
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: '#D2D3DD',
    fontSize: '11px',
    fontWeight: 600,
    lineHeight: ' 13px',
    letterSpacing: '1px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  control: () => ({
    fontSize: '11px',
    display: 'flex',
    border: 'none',
  }),
  option: (styles: any, { isFocused, isSelected }: any) => ({
    ...styles,
    padding: '8px 13px',
    fontSize: '11px',
    fontWeight: 600,
    lineHeight: ' 13px',
    letterSpacing: '1px',
    width: '100%',
    borderTop: '1px solid #fff',
    background: '#F2F4F8',
    cursor: 'pointer',
    color: isFocused || isSelected ? 'rgba(252, 77, 154, 1)' : '#1D2553',
  }),
  menu: (styles: any) => ({
    ...styles,
    marginTop: 0,
    position: 'absolute',
    bottom: 0,
    left: 0,
  }),
  menuList: (styles: any) => ({
    ...styles,
    padding: '0',
    background: '#F2F4F8',
    borderRadius: '0px 0px 6px 6px',
  }),
};

export const FilterStyles = {
  container: () => ({
    background: '#F2F4F8',
    borderRadius: '6px 6px 0 0',
    padding: '7px 0px 6px 16px',
    borderBottom: '1px solid #fff',
    cursor: 'pointer',
    flexBasis: '200px',
  }),

  placeholder: (styles: any) => ({
    ...styles,
    color: '#D2D3DD',
    fontSize: '11px',
    fontWeight: 600,
    lineHeight: ' 13px',
    letterSpacing: '1px',
  }),
  singleValue: () => ({
    color: 'rgba(119, 124, 152, 1)',
    fontWeight: '600',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (styles: any) => ({
    ...styles,
    background: '#fff',
    marginRight: '8px',
    borderRadius: '6px',
  }),
  valueContainer: (styles: any) => ({
    ...styles,
    padding: 0,
  }),
  control: () => ({
    fontSize: '11px',
    display: 'flex',
    border: 'none',
  }),
  option: (styles: any, { isFocused, isSelected }: any) => ({
    ...styles,
    padding: '13px 15px',
    fontSize: '11px',
    fontWeight: 600,
    lineHeight: ' 13px',
    letterSpacing: '1px',
    width: '100%',
    borderTop: '1px solid #fff',
    background: '#F2F4F8',
    cursor: 'pointer',
    color: isFocused || isSelected ? 'rgba(252, 77, 154, 1)' : 'rgba(119, 124, 152, 1)',
  }),
  menu: (styles: any) => ({
    ...styles,
    marginTop: 0,
    position: 'absolute',
    bottom: 0,
    left: 0,
  }),
  menuList: (styles: any) => ({
    ...styles,
    padding: '0',
    background: '#F2F4F8',
    borderRadius: '0px 0px 6px 6px',
  }),
};

export const TooltipBtnActions = styled.div`
  position: absolute;
  display: none;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.5;
`;
